import React from 'react';
import { ShiftTimeNameActionsContainer } from './TeamShiftNameActions.style';
import { ShiftTimeNameActionsViewProps } from './TeamShiftNameActions.type';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { TeamShiftNameForm } from './TeamShiftNameForm';
import Modal from 'components/Modal/Modal';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { ShowTeamShiftNameActionsModal } from 'states/global/Modal';
import { CRUD } from 'variables';
import { ShiftTimeNameActionsState } from './TeamShiftNameActions.atom';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { Box, Typography } from '@mui/material';

export const ShiftTimeNameActionsContent: React.FC<ShiftTimeNameActionsViewProps> = ({
    addTeamShiftNameLoading,
    handleSubmit,
    initialValues,
    removeTeamShiftName
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const shiftTimeNameActionsState = useRecoilValue(ShiftTimeNameActionsState);
    const resetShiftTimeNameActionsState = useResetRecoilState(ShiftTimeNameActionsState);
    const closeAnnouncementsActionModal = useResetRecoilState(ShowTeamShiftNameActionsModal);
    const isRemoveAction: boolean = shiftTimeNameActionsState.action === CRUD.REMOVE;

    const button = (): JSX.Element => {
        const textButton: string = shiftTimeNameActionsState.action === CRUD.EDIT ? 'save' : 'add';
        const buttonType: string = isRemoveAction ? 'error' : 'success';

        return addTeamShiftNameLoading ? (
            <Box sx={{ marginTop: '-20px' }}>
                <UiLoadingPage size='25px' testid='ShiftTimeNameActions-addTeamShiftNameLoading' />
            </Box>
        ) : (
            <UiButton
                variant='contained'
                form='teamShiftName-form'
                type='submit'
                color={isRemoveAction ? 'error' : 'primary'}
                skin={buttonType}
                testid='ShiftTimeNameActions-saveOrEditOrRemove-button'
                onClick={() => {
                    isRemoveAction && removeTeamShiftName();
                }}
            >
                {isRemoveAction ? translate('t.remove') : translate(`t.${textButton}`)}
            </UiButton>
        );
    };

    const createRemoveMessage = (): JSX.Element => {
        return (
            <Box data-testid='ShiftTimeNameActions-removeMessage-container'>
                <Box sx={{ margin: '21px 12px;' }}>
                    <Typography data-testid='ShiftTimeNameActions-removeMessage'>
                        {translate('p.are_you_sure_to_delete')}
                    </Typography>
                </Box>
            </Box>
        );
    };

    return (
        <ShiftTimeNameActionsContainer data-testid='shiftTimeNameActionsContent'>
            <Modal
                testid='ShiftTimeNameActions-actions-modal'
                type='ShowTeamShiftNameActionsModal'
                leftTitle={`${translate(`t.${shiftTimeNameActionsState.action}`)} ${translate(`t.team`)}`}
                height={isRemoveAction ? 175 : 300}
                content={
                    isRemoveAction ? (
                        createRemoveMessage()
                    ) : (
                        <TeamShiftNameForm initialValues={initialValues} handleSubmit={handleSubmit} />
                    )
                }
                onClose={() => {
                    closeAnnouncementsActionModal();
                    resetShiftTimeNameActionsState();
                }}
                buttons={button()}
                width={390}
                id='shiftTimeNameActions-actions-modal'
            />
        </ShiftTimeNameActionsContainer>
    );
};
