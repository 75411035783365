import { RecoilState, atom } from 'recoil';
import { TableInitStateProps } from 'components/Ui/UiTable/TableAtom';
import { SortingRule } from 'react-table';

let tableAtoms = {};
let refreshAtoms = {};

type ChangeNumberPayload = {
    type: TableActions.PAGE_CHANGED | TableActions.PAGE_SIZE_CHANGED | TableActions.TOTAL_COUNT_CHANGED;
    payload: number;
};

type ChangeArrayPayload = {
    type: TableActions.PAGE_SORT_CHANGED | TableActions.PAGE_FILTER_CHANGED;
    payload: Array<SortingRule<{}>>;
};

type ReducerActionTypes = ChangeNumberPayload | ChangeArrayPayload;

export enum TableActions {
    PAGE_CHANGED = 'PAGE_CHANGED',
    PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED',
    PAGE_SORT_CHANGED = 'PAGE_SORT_CHANGED',
    PAGE_FILTER_CHANGED = 'PAGE_FILTER_CHANGED',
    TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED'
}

// to delete after new Uitable migration
export const TableAtom = (key?: string): RecoilState<TableInitStateProps> => {
    if (key && !tableAtoms[key]) {
        tableAtoms[key] = atom<TableInitStateProps>({
            key: key,
            default: {
                queryPageIndex: 0,
                queryPageSize: 10,
                totalCount: 0,
                queryPageSortBy: [],
                queryPageFilter: [],
                tableKey: ''
                // ready: false
            }
        });
    } else if (!key && !tableAtoms['Table']) {
        tableAtoms['Table'] = atom<TableInitStateProps>({
            key: 'Table',
            default: {
                queryPageIndex: 0,
                queryPageSize: 10,
                totalCount: 0,
                queryPageSortBy: [],
                queryPageFilter: [],
                tableKey: ''
                // ready: false
            }
        });
    }

    return tableAtoms[key ?? 'Table'];
};

export const TableDispatcher = (previousState: TableInitStateProps, setState) => {
    return (action: ReducerActionTypes) => {
        const newState = {
            PAGE_CHANGED: { ...previousState, queryPageIndex: action.payload },
            PAGE_SIZE_CHANGED: { ...previousState, queryPageSize: action.payload },
            PAGE_SORT_CHANGED: { ...previousState, queryPageSortBy: action.payload },
            PAGE_FILTER_CHANGED: { ...previousState, queryPageFilter: action.payload, queryPageIndex: 0 },
            TOTAL_COUNT_CHANGED: { ...previousState, totalCount: action.payload }
        }[action.type];

        setState(newState);
    };
};

export const TableRefreshAtom = (key?: string): RecoilState<boolean> => {
    if (key && !refreshAtoms[key]) {
        refreshAtoms[key] = atom<boolean>({
            key: key,
            default: false
        });
    } else if (!key && !refreshAtoms['TableRefreshAtom']) {
        refreshAtoms['TableRefreshAtom'] = atom<boolean>({
            key: 'TableRefreshAtom',
            default: false
        });
    }

    return refreshAtoms[key ?? 'TableRefreshAtom'];
};
