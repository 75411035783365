export type SensorType = {
    id: number;
    name: string;
};

export enum SensorTypes {
    INTERNAL = 'Internal',
    EXTERNAL = 'External'
}

export enum SensorTypeQueryKeys {
    getLazyDropdown = 'sensorType-dropdown',
    getSensorsTypeCodebook = 'sensorType-getSensorsTypeCodebook'
}
