export type AccelerometerEventModel = {};

export type AccelerometerEventMap = {
    accelerometerEvent: SpeedIncident[];
    total: number;
};

export type SpeedIncident = {
    id: number;
    position: Position;
    measuredAt: string;
    accelY: number;
    vehicle: Vehicle;
    loaded?: number;
};

type Position = {
    latitude: number;
    longitude: number;
};

type Vehicle = {
    id: number;
    name: string;
};

export enum AccelerometerEventQueryKeys {
    getLazyDropdown = 'acelerometerEvent-getLazyDropdown'
}
