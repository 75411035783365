import { VehicleModel } from './Vehicle.type';

export type DropdownModel = {
    icon: string;
    id: number;
    maxSpeed: number;
    type: string;
    maxSpeedTemperatureL1: number | null;
    maxSpeedTemperatureL2: number | null;
    maxSpeedTemperatureL3: number | null;
    temperatureBasedMaxSpeed: boolean;
};

export type VehicleTypesCodeBook = DropdownModel[];
export type TableModel = {
    id: number;
    type: string;
    icon: string;
    maxSpeed: number;
    vehicle: Pick<VehicleModel, 'id' | 'name'>[];
    temperatureBasedMaxSpeed?: boolean;
    maxSpeedTemperatureL1?: number;
    maxSpeedTemperatureL2?: number;
    maxSpeedTemperatureL3?: number;
};

export type TableModelResponse = {
    items: TableModel[];
};

export enum VehicleTypeQueryKeys {
    getCodebook = 'vehicleType-GetVehicleTypes',
    getAll = 'vehicleType-getAll'
}
