import React, { useEffect, useReducer } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import * as view from './UiVehicleMultiselect.view';
import {
    VehicleAction,
    UiVehicleMultiselectProps,
    VehiclesData,
    VehicleActionsEnum
} from './UiVehicleMultiselect.type';
import { Wrapper } from 'helpers/wrapper';
import VehicleApi from 'api/Vehicle';
import { isCacheAvailable, getCache, cacheTimeToMilliseconds } from 'helpers/cache';
import { VehicleList, VehicleQueryKeys } from 'models/Vehicle.type';

const Vehicle = new VehicleApi();

const VehicleReducer = (state: VehiclesData, action: VehicleAction): VehiclesData => {
    switch (action.type) {
        case VehicleActionsEnum.SET_VEHICLES:
            return { ...state, vehicles: action.payload };
        case VehicleActionsEnum.SET_SELECTED_VEHICLES:
            return { ...state, selectedVehicles: action.payload };
        default:
            throw 'Unknown action in Vehicle reducer';
    }
};

const UiVehicleMultiselect: React.FC<UiVehicleMultiselectProps> = (props): JSX.Element => {
    const queryCache = useQueryClient().getQueryCache();
    const [vehicles, setVehicles] = useReducer(VehicleReducer, {
        vehicles: [],
        selectedVehicles: []
    });

    const { refetch: loadVehicleList } = useQuery([VehicleQueryKeys.getList], () => Vehicle.getList(), {
        enabled: false,
        staleTime: cacheTimeToMilliseconds(55, 'minutes'),
        cacheTime: cacheTimeToMilliseconds(60, 'minutes'),
        onSuccess: (dataOnSuccess) => setVehicles({ payload: dataOnSuccess, type: VehicleActionsEnum.SET_VEHICLES })
    });

    useEffect(() => {
        props.onSelectedVehiclesChange && props.onSelectedVehiclesChange(vehicles.selectedVehicles);
    }, [vehicles.selectedVehicles]);

    useEffect(() => {
        !isCacheAvailable([VehicleQueryKeys.getList], queryCache)
            ? loadVehicleList()
            : setVehicles({
                  payload: getCache([VehicleQueryKeys.getList], queryCache)[0]?.state?.data as VehicleList,
                  type: VehicleActionsEnum.SET_VEHICLES
              });
    }, []);

    return (
        <view.UiVehicleMultiselectContent
            vehicles={vehicles}
            customeLabel={props.customeLabel}
            setVehicles={setVehicles}
            maxAllowedVehicles={props.maxAllowedVehicles}
            data-testid={'UiVehicleMultiselect-testid'}
        />
    );
};

export default Wrapper(UiVehicleMultiselect);
