import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import HelperText from './HelperText';

interface PasswordHelperTextProps {
    error: boolean;
    passwordToValidate: string;
    fallbackHelperText?: string;
}

const PasswordHelperText: React.FC<PasswordHelperTextProps> = ({
    error,
    passwordToValidate,
    fallbackHelperText = 'error'
}): JSX.Element => {
    const { t: translate } = useTranslation();

    const getPasswordDefaultValidations = useCallback((): string => {
        if (!error) return '';

        const validations = [
            { test: (str: string) => str.length >= 8, msg: translate('t.password_min') },
            { test: (str: string) => str.length <= 20, msg: translate('t.password_max') },
            { test: (str: string) => /[A-Z]/.test(str), msg: translate('t.password_upper_case') },
            { test: (str: string) => /[a-z]/.test(str), msg: translate('t.password_lower_case') },
            { test: (str: string) => /[0-9]/.test(str), msg: translate('t.password_number') },
            {
                test: (str: string) => /[!@#$%^&*(),.?":{}|<>]/.test(str),
                msg: `${translate('t.password_special')} (!@#$%^&*(),.?":{}|<>), `
            }
        ];

        const errors = validations.filter(({ test }) => !test(passwordToValidate)).map(({ msg }) => msg);

        return errors.length ? `${translate('t.password_contains')}: ${errors.join(', ')}` : fallbackHelperText;
    }, [error, passwordToValidate, translate, fallbackHelperText]);

    return <HelperText defaultText={translate('t.required')} text={getPasswordDefaultValidations()} error={error} />;
};

export default PasswordHelperText;
