import { CustomerModel } from './Customer.type';
import { RegionModelExtended } from './Region.type';

export type LocationModel = {
    address: string;
    createdAt: string;
    customer: CustomerModel | null;
    customerId: number | null;
    email: string | null;
    enabled: boolean;
    gpsCoordinates: number[] | null;
    id: number;
    locationType: LocationType | null;
    name: string;
    region: Omit<RegionModelExtended, 'open'> | null;
    reshipmentEnabled: boolean;
    show: boolean;
    updatedAt: string;
};

type LocationType = {
    id: number;
    name: string;
};

export enum LocationQueryKeys {
    getById = 'location-getById',
    getLazyDropdown = 'location-getLazyDropdown'
}
