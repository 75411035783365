import Table from './Table.type';

export type SensorFaultyReportModel = {
    id: number;
    action: boolean;
    comment: string;
    createdAt: string;
    dateFrom: string;
    dateTo: string;
    firstActivation: string;
    problem: string;
    verifiedAt: string;
    sensor: {
        id: number;
        lastMovement: {
            id: number;
            fittingReason: string;
            removalReason: string;
        };
        sensorInfo: {
            measuredAt: string;
            sensorSerialNumber: string;
        };
        sensorType: {
            id: number;
            name: string;
        };
        serialNumberHex: string;
        wheel: null | {
            customPosition: number;
            id: number;
            vehicle: {
                id: number;
                name: string;
            };
        };
    };
    user: User;
    verifiedStatus: {
        id: number;
        name: string;
    };
};

export type TableItem = {
    action: boolean;
    createdAt: string;
    dateFrom: string;
    dateTo: string;
    id: number;
    problem: string;
    sensor: {
        id: number;
        sensorSerialNumber: string;
        lastMovement: {
            id: number;
            fittingReason: string;
            removalReason: string;
        } | null;
        sensorType: {
            id: number;
            name: string;
        };
        serialNumberHex: string;
        wheel?: {
            customPosition: number;
            id: number;
            vehicle: {
                id: number;
                name: string;
            };
        };
        sensorInfo: {
            measuredAt: string;
        };
    };
    user: User;
    firstActivation: string;
    verifiedStatus: {
        id: number;
        name: string;
    };
    verifiedAt: string;
    verifiedAtFrom?: string;
    verifiedAtTo?: string;
    createdAtFrom?: string;
    createdAtTo?: string;
    firstActivationFrom?: string;
    firstActivationTo?: string;
    lastActivationFrom?: string;
    lastActivationTo?: string;
};

type User = {
    id: number;
    firstname: string;
    lastname: string;
};
export interface TableResponse extends Table {
    items: TableItem[];
}

export enum SensorFaultyReportQueryKeys {
    getById = 'sensorFaultyReport-getById'
}
