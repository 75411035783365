import React, { useMemo } from 'react';
import { ActionButtonProps } from './AnnouncementsActions.type';
import { RequestProgress } from './AnnouncementsActions.style';
import { CRUD } from 'variables';
import { useRecoilValue } from 'recoil';
import { AnnouncementsActionsState } from '../../states/component/Announcements';
import { useTranslation } from 'react-i18next';
import Button from '../Ui/Components/UiButton/UiButton';

export const ActionButton: React.FC<ActionButtonProps> = (props): JSX.Element => {
    const announcementsActionsState = useRecoilValue(AnnouncementsActionsState);
    const { t: translate } = useTranslation();

    const formId = useMemo(() => {
        return props.currentTab ? 'announcements-form-production' : 'announcements-form-general';
    }, [props.currentTab]);

    return props.isProcessing ? (
        <RequestProgress />
    ) : (
        <Button skin='success' color='primary' variant='contained' form={formId} type='submit'>
            {announcementsActionsState.action === CRUD.EDIT ? translate('t.save') : translate('t.add')}
        </Button>
    );
};
