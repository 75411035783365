import React, { useState } from 'react';
import { ReportTableContent } from './ReportTable.view';
import { CustomerEditType, EditedMsgValues, ReportTableProps } from './ReportTable.type';
import { CustomLoading } from './ReportTable.style';
import ModalButton from 'components/Ui/Components/UiButton/UiButton';
import CircularProgress from '@mui/material/CircularProgress';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import CustomerReportApi from 'api/CustomerReport';
import { Success } from 'components/Popup/Popup';
import Checkbox from '@mui/material/Checkbox';
import { SIZE_TEXT_VARIANT } from 'components/Ui/variables';
import { CustomerReportModel } from 'models/CustomerReport.type';
import { Wrapper } from 'helpers/wrapper';
import { Column } from 'components/Ui/UiTable/UiTable.type';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';
import useTableFilter from 'components/CustomHooks/TableFilterState/TableFilterState';

const customerReportApi = new CustomerReportApi();

const ReportTable: React.FC<ReportTableProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [customerEdit, setCustomerEdit] = useState<CustomerEditType | null>(null);
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const queryTableFn = useRecoilValue(QueryFn(`customerReport-QueryFn`));

    const options = [
        { label: 'yes', value: '1' },
        { label: 'no', value: '0' }
    ];

    const updateModal = (isOpen?: boolean) => {
        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...modalAtom,
                buttons: <ModalButton skin='success'>{<CircularProgress size='1.75em' />}</ModalButton>,
                isOpen: typeof isOpen === 'undefined' ? modalAtom.isOpen : isOpen
            }
        });
    };

    const { mutate: handleChecked, isLoading } = useMutation(['checked-mutation'], customerReportApi.editCheck, {
        onSuccess: (results, variables) => {
            let value = '';

            if (variables[0].noGps != undefined) value = EditedMsgValues.NO_GPS;
            else if (variables[0].noDiskSpace != undefined) value = EditedMsgValues.NO_DISK_SPACE;
            else if (variables[0].tunnelServerDiscrepancy != undefined)
                value = EditedMsgValues.TUNNEL_SERVER_DISCREPANCY;
            else if (variables[0].cpuReport != undefined) value = EditedMsgValues.CPU_REPORT;
            else if (variables[0].nonTransmittingSensors != undefined) value = EditedMsgValues.NON_TRANSMITTING_SENSORS;
            Success({
                text: `${translate('t.edited_customer')} - ${translate(`t.${value}`)}`
            });
        },
        onSettled: () => {
            updateModal(false);
            queryTableFn.refetch instanceof Function && queryTableFn.refetch();
        }
    });

    const checkedLoading = (customer: CustomerEditType): JSX.Element | null => {
        return isLoading && customer.typeReport === customerEdit?.typeReport && customer.id === customerEdit?.id ? (
            <CustomLoading size={SIZE_TEXT_VARIANT(10)} />
        ) : null;
    };

    const columns: Column<CustomerReportModel>[] = [
        {
            Header: translate('t.customer_name'),
            accessor: (row: CustomerReportModel) => row.customer.name,
            id: 'customer.name',
            width: 70,
            height: 20,
            Cell: (props: { value: string }) => <>{props.value ?? '-'}</>
        },

        {
            Header: translate('t.no_gps'),
            accessor: 'noGps',
            width: 70,
            height: 20,
            Cell: (props: { row: { original: CustomerReportModel } }) => {
                return (
                    checkedLoading({ id: props.row.original.customer.id, typeReport: 'noGps' }) || (
                        <Checkbox
                            id='noGps'
                            data-testid='customer-report-no-gps-checkbox'
                            checked={!!props.row.original.noGps}
                            onChange={() => {
                                setCustomerEdit({ id: props.row.original.customer.id, typeReport: 'noGps' });
                                handleChecked([
                                    { noGps: !props.row.original.noGps, customer: props.row.original.customer.id }
                                ]);
                            }}
                        />
                    )
                );
            },
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });
                return (
                    <FormControl variant='outlined' size='small' sx={{ marginTop: '4px' }}>
                        <InputLabel htmlFor='disabled-action' shrink>
                            <>{column.Header}</>
                        </InputLabel>
                        <Select
                            value={cellValue?.value || ''}
                            data-testid='customer-report-no-gps-select-filter'
                            input={<OutlinedInput notched label={<>{column.Header}</>} />}
                            onChange={(e) => {
                                const value = e.target.value
                                    ? {
                                          name: column.Header,
                                          value: e.target.value,
                                          humanValue: translate(`t.${e.target.value == '1' ? 'yes' : 'no'}`)
                                      }
                                    : undefined;
                                setFilterValue(value);
                            }}
                            inputProps={{
                                name: 'enabled',
                                id: 'enabled-native-simple'
                            }}
                        >
                            {options.map((option, index) => (
                                <MenuItem value={option.value} key={index}>
                                    {translate(`t.${option.label}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }
        },
        {
            Header: translate('t.no_disk_space'),
            accessor: 'noDiskSpace',
            width: 70,
            height: 20,
            Cell: (props: { row: { original: CustomerReportModel } }) => {
                return (
                    checkedLoading({ id: props.row.original.customer.id, typeReport: 'noDiskSpace' }) || (
                        <Checkbox
                            id='noDiskSpaceCheck'
                            data-testid='customer-report-no-disk-space-checkbox'
                            checked={!!props.row.original.noDiskSpace}
                            onChange={() => {
                                setCustomerEdit({ id: props.row.original.customer.id, typeReport: 'noDiskSpace' });
                                handleChecked([
                                    {
                                        noDiskSpace: !props.row.original.noDiskSpace,
                                        customer: props.row.original.customer.id
                                    }
                                ]);
                            }}
                        />
                    )
                );
            },
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });
                return (
                    <FormControl variant='outlined' size='small' sx={{ marginTop: '10px' }}>
                        <InputLabel htmlFor='disabled-action' shrink>
                            <>{column.Header}</>
                        </InputLabel>
                        <Select
                            data-testid='customer-report-no-disk-space-select-filter'
                            value={cellValue?.value || ''}
                            input={<OutlinedInput notched label={<>{column.Header}</>} />}
                            onChange={(e) => {
                                const value = e.target.value
                                    ? {
                                          name: column.Header,
                                          value: e.target.value,
                                          humanValue: translate(`t.${e.target.value == '1' ? 'yes' : 'no'}`)
                                      }
                                    : undefined;
                                setFilterValue(value);
                            }}
                            inputProps={{
                                name: 'enabled',
                                id: 'enabled-native-simple'
                            }}
                        >
                            {options.map((option, index) => (
                                <MenuItem value={option.value} key={index}>
                                    {translate(`t.${option.label}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }
        },
        {
            Header: translate('t.tunnel_server_discrepancy'),
            accessor: 'tunnelServerDiscrepancy',
            width: 70,
            height: 20,
            Cell: (props: { row: { original: CustomerReportModel } }) => {
                return (
                    checkedLoading({ id: props.row.original.customer.id, typeReport: 'tunnelServerDiscrepancy' }) || (
                        <Checkbox
                            id='tunnelServerDiscrepancyCheck'
                            data-testid='customer-report-tunner-server-checkbox'
                            checked={!!props.row.original.tunnelServerDiscrepancy}
                            onChange={() => {
                                setCustomerEdit({
                                    id: props.row.original.customer.id,
                                    typeReport: 'tunnelServerDiscrepancy'
                                });
                                handleChecked([
                                    {
                                        tunnelServerDiscrepancy: !props.row.original.tunnelServerDiscrepancy,
                                        customer: props.row.original.customer.id
                                    }
                                ]);
                            }}
                        />
                    )
                );
            },
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });
                return (
                    <FormControl variant='outlined' size='small' sx={{ marginTop: '10px' }}>
                        <InputLabel htmlFor='disabled-action' shrink>
                            <>{column.Header}</>
                        </InputLabel>
                        <Select
                            data-testid='customer-report-tunner-server-select-filter'
                            value={cellValue?.value || ''}
                            input={<OutlinedInput notched label={<>{column.Header}</>} />}
                            onChange={(e) => {
                                const value = e.target.value
                                    ? {
                                          name: column.Header,
                                          value: e.target.value,
                                          humanValue: translate(`t.${e.target.value == '1' ? 'yes' : 'no'}`)
                                      }
                                    : undefined;
                                setFilterValue(value);
                            }}
                            inputProps={{
                                name: 'enabled',
                                id: 'enabled-native-simple'
                            }}
                        >
                            {options.map((option, index) => (
                                <MenuItem value={option.value} key={index}>
                                    {translate(`t.${option.label}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }
        },
        {
            Header: translate('t.cpu_report'),
            accessor: 'cpuReport',
            width: 70,
            height: 20,
            Cell: (props: { row: { original: CustomerReportModel } }) => {
                return (
                    checkedLoading({ id: props.row.original.customer.id, typeReport: 'cpuReport' }) || (
                        <Checkbox
                            id='cpuReportCheck'
                            data-testid='customer-report-cpu-report-checkbox'
                            checked={!!props.row.original.cpuReport}
                            onChange={() => {
                                setCustomerEdit({ id: props.row.original.customer.id, typeReport: 'cpuReport' });
                                handleChecked([
                                    {
                                        cpuReport: !props.row.original.cpuReport,
                                        customer: props.row.original.customer.id
                                    }
                                ]);
                            }}
                        />
                    )
                );
            },
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });
                return (
                    <FormControl variant='outlined' size='small' sx={{ marginTop: '10px' }}>
                        <InputLabel htmlFor='disabled-action' shrink>
                            <>{column.Header}</>
                        </InputLabel>
                        <Select
                            data-testid='customer-report-cpu-report-select-filter'
                            value={cellValue?.value || ''}
                            input={<OutlinedInput notched label={<>{column.Header}</>} />}
                            onChange={(e) => {
                                const value = e.target.value
                                    ? {
                                          name: column.Header,
                                          value: e.target.value,
                                          humanValue: translate(`t.${e.target.value == '1' ? 'yes' : 'no'}`)
                                      }
                                    : undefined;
                                setFilterValue(value);
                            }}
                            inputProps={{
                                name: 'enabled',
                                id: 'enabled-native-simple'
                            }}
                        >
                            {options.map((option, index) => (
                                <MenuItem value={option.value} key={index}>
                                    {translate(`t.${option.label}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }
        },
        {
            Header: translate('t.non_transmitting_sensors'),
            accessor: 'nonTransmittingSensors',
            width: 70,
            height: 20,
            Cell: (props: { row: { original: CustomerReportModel } }) => {
                return (
                    checkedLoading({ id: props.row.original.customer.id, typeReport: 'nonTransmittingSensors' }) || (
                        <Checkbox
                            id='nonTransmittingSensorsCheck'
                            data-testid='customer-report-non-transmitting-sensor-checkbox'
                            checked={!!props.row.original.nonTransmittingSensors}
                            onChange={() => {
                                setCustomerEdit({
                                    id: props.row.original.customer.id,
                                    typeReport: 'nonTransmittingSensors'
                                });
                                handleChecked([
                                    {
                                        nonTransmittingSensors: !props.row.original.nonTransmittingSensors,
                                        customer: props.row.original.customer.id
                                    }
                                ]);
                            }}
                        />
                    )
                );
            },
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });
                return (
                    <FormControl variant='outlined' size='small' sx={{ marginTop: '10px' }}>
                        <InputLabel htmlFor='disabled-action' shrink>
                            <>{column.Header}</>
                        </InputLabel>
                        <Select
                            value={cellValue?.value || ''}
                            data-testid='customer-report-non-transmitting-sensor-select-filter'
                            input={<OutlinedInput notched label={<>{column.Header}</>} />}
                            onChange={(e) => {
                                const value = e.target.value
                                    ? {
                                          name: column.Header,
                                          value: e.target.value,
                                          humanValue: translate(`t.${e.target.value == '1' ? 'yes' : 'no'}`)
                                      }
                                    : undefined;
                                setFilterValue(value);
                            }}
                            inputProps={{
                                name: 'enabled',
                                id: 'enabled-native-simple'
                            }}
                        >
                            {options.map((option, index) => (
                                <MenuItem value={option.value} key={index}>
                                    {translate(`t.${option.label}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }
        }
    ];

    return <ReportTableContent data-testid='ReportTable-testid' columns={columns} />;
};

export default Wrapper(ReportTable);
