import { Paginator } from './Paginator.type';

export type RegionModel = {};

export type RegionModelExtended = {
    id: number;
    shortName: string;
    translationString: string;
    open?: boolean;
};

export type RegionsWithPaginator = {
    items: RegionModelExtended[];
    paginator: Paginator;
};

export enum RegionQueryKeys {
    getAllAsCodebook = 'region-getAllAsCodebook'
}
