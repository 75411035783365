import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { GpsAlertQueryProps } from './GpsAlertQuery.type';
import SpeedEventApi from 'api/SpeedEvent';
import { GPSAlertsTable, SpeedEventQueryKeys } from 'models/SpeedEvent.type';
import { useRecoilValue } from 'recoil';
import { UserInfo } from 'states/global/User';

const SpeedEvent = new SpeedEventApi();

const GpsAlertQuery: React.FC<GpsAlertQueryProps> = ({
    children,
    customConfig,
    page,
    limit,
    vehicleId,
    measuredFrom,
    queryKey
}): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const useQueryProperties = useQuery<unknown, Error, GPSAlertsTable>(
        [queryKey || SpeedEventQueryKeys.getAlerts, userInfo.user?.customer.id],
        () => SpeedEvent.getAlerts({ limit, page, vehicleId, measuredFrom }),
        customConfig
    );

    return <>{children(useQueryProperties)}</>;
};

export default GpsAlertQuery;
