import React, { useEffect, useState } from 'react';
import { TextBox } from '../TyreManager.style';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { MountDateAtom, TyreStateAtom } from '../TyreManager.atom';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { VARIANT_BUTTON } from 'components/Ui/variables';
import useModal from 'components/CustomHooks/ModalHook';
import UiButton from 'components/Ui/Components/UiButton';
import { useMutation } from '@tanstack/react-query';
import Tyre from 'api/Tyre';
import { VehicleDetailsActionMenuAtom } from 'components/Vehicle/VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import { FetchVehicleDetailsData } from 'components/Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { TyreQueryKeys } from 'models/Tyre.type';

const tyreAPI = new Tyre();

const MountTyre = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const tyreState = useRecoilValue(TyreStateAtom);
    const mountDate = useRecoilValue(MountDateAtom);
    const { dateTimeFormat, fromTimezoneToUTC } = useConverter();
    const { updateModalState } = useModal(1);
    const setFetchVehicleDetailsData = useSetRecoilState(FetchVehicleDetailsData);
    const vehicleDetailsActionMenuAtom = useRecoilValue(VehicleDetailsActionMenuAtom);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const { mutate } = useMutation(
        [TyreQueryKeys.mountTyre],
        () =>
            tyreAPI.mountTyre({
                date: fromTimezoneToUTC(mountDate),
                wheel: vehicleDetailsActionMenuAtom.data?.wheel.id || 0,
                tyreId: tyreState.newTyre.id
            }),
        {
            onSettled: () => {
                setFetchVehicleDetailsData((prevState) => !prevState);
            }
        }
    );

    useEffect(() => {
        updateModalState({
            buttons: (
                <UiButton
                    id='mount-tyre'
                    skin='success'
                    variant={VARIANT_BUTTON}
                    onClick={() => {
                        mutate();
                        setIsSaving(true);
                    }}
                    loading={isSaving}
                >
                    {translate(`t.${tyreState.state}`)}
                </UiButton>
            )
        });
    }, [tyreState.state]);

    return (
        <TextBox>
            <span>
                {`${translate('p.do_you_really_want_mount_tyre')} `}
                <b>{tyreState.newTyre.tyreSerialNumber}</b>
            </span>
            <span>
                {`${translate('t.mounted_at')} `}
                <b>{mountDate.toFormat(dateTimeFormat('dateTime', false))}</b>
            </span>
        </TextBox>
    );
};

export default MountTyre;
