import React from 'react';
import { TyresSummaryContent } from './TyresSummary.view';
import { Wrapper } from 'helpers/wrapper';
import SensorLog from 'api/SensorLog';
import { SensorLogQueryKeys, TyreDeviation } from 'models/SensorLog.type';
import { DateTimePicker, SelectedVehicle } from 'states/global/Statistics';
import { TyresSummaryProps } from './TyresSummary.type';
import { useRecoilValue } from 'recoil';
import { useQuery } from '@tanstack/react-query';
import useConverter from 'components/CustomHooks/Converter/Converter';

const TyresSummary: React.FC<TyresSummaryProps> = (): JSX.Element => {
    const SensorLogAPI = new SensorLog();
    const selectedVehicle = useRecoilValue(SelectedVehicle);
    const statisticsDateTime = useRecoilValue(DateTimePicker);
    const { fromTimezoneToUTC } = useConverter();

    const { isLoading, data } = useQuery<TyreDeviation>(
        [SensorLogQueryKeys.getTyreDeviationByVehicleId, statisticsDateTime.current, selectedVehicle],
        () =>
            SensorLogAPI.getTyreDeviationByVehicleId(
                selectedVehicle.id,
                fromTimezoneToUTC(statisticsDateTime.current.dateFrom),
                fromTimezoneToUTC(statisticsDateTime.current.dateTo)
            ),
        {
            refetchInterval: false,
            staleTime: 0,
            cacheTime: 0
        }
    );

    return <TyresSummaryContent data-testid='TyresSummary-testid' data={data} isLoading={isLoading} />;
};

export default Wrapper(TyresSummary);
