import styled from 'styled-components';
import { Grid, useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { COLOR_GREY, PRIMARY } from 'components/Ui/colors';
import { applyStyleByMode } from 'helpers';
import { CommonTypeTheme } from 'components/Ui/Theme';
import { SIZE_TEXT_VARIANT } from 'components/Ui/variables';

export const VehicleDetailsWidgetViewContent = styled.div`
    ${applyStyleByMode({
        styleOld: 'display: block;',
        styleJade: 'display: flex;'
    })}
    width: 100%;
`;

export const TextBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
`;

export const Text = styled.div`
    display: flex;
    align-items: center;
    width: 300px;
    justify-content: space-evenly;
    ${applyStyleByMode({
        styleOld: 'font-size: 2.25rem;',
        styleJade: 'font-size: 1.5rem;'
    })}
`;

export const ContentGrid = styled(Grid)`
    padding-bottom: 16px;
    height: 100%;
`;

export const LoadingDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
`;

export const Loading = styled(CircularProgress)({
    color: `${PRIMARY} !important`,
    width: '50px !important',
    height: '50px !important'
});

export const LastHubTransmission = styled.div`
    height: 33px;
    display: flex;
    padding-right: 12px;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: `color: ${COLOR_GREY};`,
            styleJade: `
                font-size: ${common.titleSize};
                @media (max-width: 480px) {
                    width: 150px;
                }
            `
        });
    }}
`;

export const WidgetContent = styled.div``;

export const LegendTable = styled.table`
    ${applyStyleByMode({
        styleJade: `font-size: ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return common.easyMode ? SIZE_TEXT_VARIANT(2) : SIZE_TEXT_VARIANT(1);
        }};`
    })}
`;

export const LegendIcon = styled.i`
    padding-right: 10px;
`;

export const WidgetHeader = styled.div`
    display: flex;
    align-items: center;
`;
