import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMutation } from '@tanstack/react-query';
import CircularProgress from '@mui/material/CircularProgress';
import SettingsIcon from '@mui/icons-material/Settings';
import { UsersAdministrationContent } from './UsersAdministration.view';
import { UserEnabledEnum, UserLockedEnum, UsersAdministrationProps } from './UsersAdministration.type';
import { UserModel } from 'models/User.type';
import { CommonDropDown } from '../GeneralSettingsForm/GeneralSettingsForm.type';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher, ShowPermissionModal } from 'states/global/Modal';
import { SelectedUser } from '../MinesPermissions/Permissions.atom';
import { useTranslation } from 'react-i18next';
import Menu from '../../Ui/Components/UiMenu';
import User from 'api/User';
import Button from '../../Ui/Components/UiButton/UiButton';
import { ROLES } from 'variables';
import { UserInfo } from 'states/global/User';
import UpdateDropDown from '../UpdateDropDown/UpdateDropDown';
import { Success } from '../../Popup/Popup';
import { BLUE_BRIDGESTONE, BRANDIES_BLUE, DARK_STEEL_GREY, ERROR } from '../../Ui/colors';
import { applyStyleByMode } from 'helpers';
import { checkRole } from 'helpers/authentication';
import { Box, FormControl, MenuItem, Select, OutlinedInput, InputLabel } from '@mui/material';
import RolesDropDown from '../RolesDropDown/RolesDropDown';
import { DropDownContainer } from '../RolesDropDown/RolesDropDown.style';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Theme } from 'states/global/Theme';
import { useNavigate } from 'react-router-dom';
import { Column } from 'components/Ui/UiTable/UiTable.type';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';
import useTableFilter from 'components/CustomHooks/TableFilterState/TableFilterState';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

const userApi = new User();

const UsersAdministration: React.FC<UsersAdministrationProps> = (): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const setPermissionsState = useSetRecoilState(SelectedUser);
    const setPermissionModal = useSetRecoilState(ShowPermissionModal);
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const userAdminTableQueryFn = useRecoilValue(QueryFn(`usersAdministrations-QueryFn`));

    const { t: translate } = useTranslation();

    const { fromUTCToTimezone } = useConverter();
    const [showPermissionModal, setShowPermissionModal] = useState<boolean>(false);
    const ThemeMode = useRecoilValue(Theme);
    const navigate = useNavigate();

    const updateModalRemove = () => {
        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...modalAtom,
                buttons: (
                    <Button
                        skin='success'
                        disabled={removeUserLoading}
                        data-testid='UsersAdministration-updateModalRemove-Button-Remove'
                    >
                        {removeUserLoading ? <CircularProgress size='1.75em' /> : translate('t.remove')}
                    </Button>
                )
            }
        });
    };

    const { mutate: removeUserMutate, isLoading: removeUserLoading } = useMutation(userApi.removeUser, {
        onSuccess: () => {
            Success({
                text: translate('t.removed_user')
            });

            userAdminTableQueryFn.refetch &&
                userAdminTableQueryFn.refetch instanceof Function &&
                userAdminTableQueryFn.refetch();
            modalDispach({ type: ModalActionTypesEnum.CLOSE_MODAL });
        }
    });

    const handleModalRemove = (id: number, userName: string) => {
        const contentModalRemove = {
            id: 'UserModalActions',
            content: (
                <Box
                    component='div'
                    textAlign='center'
                    data-testid={`UsersAdministration-handleModalRemove-Box-${id}`}
                    marginBottom='20px'
                    marginTop='20px'
                >
                    {translate('p.are_you_sure_to_delete')}
                </Box>
            ),
            leftTitle: `${translate('t.remove_user')} - ${userName}`,
            width: 400,
            height: 60,
            widthUnit: 'px',
            buttons: (
                <Button
                    skin='success'
                    color='error'
                    variant='contained'
                    testid='Remove-User-Button'
                    data-testid={`UsersAdministration-handleModalRemove-${id}-Button-Remove`}
                    onClick={() => {
                        removeUserMutate(id);
                    }}
                    disabled={removeUserLoading}
                >
                    {removeUserLoading ? <CircularProgress size='1.75em' /> : translate('t.remove')}
                </Button>
            ),
            onClose: () =>
                modalDispach({
                    type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModalRemove,
                        isOpen: false
                    }
                }),
            isOpen: true
        };

        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: contentModalRemove
        });
    };

    const handleModalAdd = () => {
        navigate('/users/add');
    };

    const lockListOptions: CommonDropDown[] = [
        { value: 0, name: translate('t.unlocked') },
        { value: 1, name: translate('t.locked') }
    ];

    const enabledListOptions: CommonDropDown[] = [
        { value: 1, name: translate('t.enabled') },
        { value: 0, name: translate('t.disabled') }
    ];

    const columns: Column<UserModel>[] = [
        {
            Header: translate('t.username'),
            accessor: 'username',
            width: 110,
            Cell: ({ value }) => <>{value || '-'}</>
        },
        {
            Header: translate('t.first_name'),
            accessor: 'firstname',
            width: 110,
            Cell: ({ value }) => <>{value || '-'}</>
        },
        {
            Header: translate('t.last_name'),
            accessor: 'lastname',
            width: 110,
            Cell: ({ value }) => <>{value || '-'}</>
        },
        {
            Header: translate('t.email'),
            accessor: 'email',
            width: 230,
            Cell: ({ value }) => <>{value || '-'}</>
        },
        {
            Header: translate('t.phone_number'),
            accessor: 'phoneNumber',
            width: 125,
            Cell: ({ value }) => <>{value ?? '-'}</>
        },
        {
            Header: translate('t.last_login'),
            accessor: (row: UserModel) => row.lastLogin,
            id: 'lastLogin',
            width: 135,
            disableFilters: true,
            Cell: ({ value }) => <>{fromUTCToTimezone(value, false) ?? '-'}</>
        },
        {
            Header: translate('t.enabled'),
            accessor: 'enabled',
            disableSortBy: true,
            width: 80,
            Cell: (props: { value: boolean; row: { original: UserModel } }) => (
                <UpdateDropDown
                    testid={`UpdateDropDown-Enabled-${props.row.original.id}`}
                    queryKey='usersAdministrations'
                    data-testid={`UsersAdministration-usersAdministrations-${props.row.original.id}-UpdateDropDown`}
                    user={{
                        enabled: props.value ? 1 : 0
                    }}
                    userId={props.row.original.id}
                    propertyName='enabled'
                    title={`${translate('t.enabled')} / ${translate('t.disabled')}`}
                    showLabel={translate(props.value ? 't.enabled' : 't.disabled')}
                    colorTextLabel={
                        props.value
                            ? applyStyleByMode({
                                  styleOld: DARK_STEEL_GREY,
                                  theme: ThemeMode?.mode,
                                  light: BRANDIES_BLUE,
                                  dark: BLUE_BRIDGESTONE
                              })
                            : ERROR
                    }
                    dropDownLabel={translate('t.enabled')}
                    updateDefaultValue={props.value ? 1 : 0}
                    listOptions={enabledListOptions}
                    disabled={checkRole(userInfo.user, [ROLES.ROLE_TECHNICAL_ADMIN])}
                />
            ),
            Filter: ({ column, setFilter, ...props }) => {
                const options = [
                    { label: 'all', value: `${UserEnabledEnum.ALL};${translate('t.all')}` },
                    { label: 'yes', value: `${UserEnabledEnum.ENABLED};${translate('t.yes')}` },
                    { label: 'no', value: `${UserEnabledEnum.DISABLED};${translate('t.no')}` }
                ];

                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: props.applyFiltersFlag,
                    filterId: column.id
                });

                return (
                    <FormControl variant='outlined' size='small' style={{ width: '100%', margin: '4px 0 8px 0' }}>
                        <InputLabel htmlFor='disabled-action' shrink>
                            <>{column.Header}</>
                        </InputLabel>
                        <Select
                            data-testid={`UsersAdministration-${column.id}-Select`}
                            label={<>{column.Header}</>}
                            value={cellValue?.value || ''}
                            onChange={(e) => {
                                const value = e.target.value
                                    ? { name: column.Header, value: e.target.value }
                                    : undefined;
                                setFilterValue(value);
                            }}
                            inputProps={{
                                name: 'enabled',
                                id: 'enabled-native-simple'
                            }}
                        >
                            {options.map((option, index) => (
                                <MenuItem
                                    value={option.value}
                                    key={index}
                                    data-testid={`UsersAdministration-${column.id}-${index}-MenuItem`}
                                >
                                    {translate(`t.${option.label}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }
        },
        {
            Header: translate('t.locked'),
            accessor: 'locked',
            disableSortBy: true,
            width: 80,
            Cell: (props: { value: boolean; row: { original: UserModel } }) => (
                <UpdateDropDown
                    testid={`UpdateDropDown-Locked-${props.row.original.id}`}
                    data-testid={`UsersAdministration-usersAdministrations-${props.row.original.id}-UpdateDropDown`}
                    queryKey='usersAdministrations'
                    user={{
                        locked: props.value ? 1 : 0
                    }}
                    userId={props.row.original.id}
                    propertyName='locked'
                    title={`${translate('t.locked')} / ${translate('t.unlocked')}`}
                    showLabel={translate(props.value ? 't.locked' : 't.unlocked')}
                    colorTextLabel={
                        props.value
                            ? ERROR
                            : applyStyleByMode({
                                  styleOld: DARK_STEEL_GREY,
                                  theme: ThemeMode?.mode,
                                  light: BRANDIES_BLUE,
                                  dark: BLUE_BRIDGESTONE
                              })
                    }
                    dropDownLabel={translate('t.locked')}
                    updateDefaultValue={props.value ? 1 : 0}
                    listOptions={lockListOptions}
                    disabled={checkRole(userInfo.user, [ROLES.ROLE_TECHNICAL_ADMIN])}
                />
            ),
            Filter: ({ column, setFilter, ...props }) => {
                const options = [
                    { label: 'locked', value: `${UserLockedEnum.LOCKED};${translate('t.locked')}` },
                    { label: 'unlocked', value: `${UserLockedEnum.UNLOCKED};${translate('t.unlocked')}` }
                ];

                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: props.applyFiltersFlag,
                    filterId: column.id
                });

                return (
                    <FormControl variant='outlined' size='small' style={{ width: '100%', margin: '4px 0 8px 0' }}>
                        <InputLabel htmlFor='disabled-action' shrink>
                            <>{column.Header}</>
                        </InputLabel>
                        <Select
                            data-testid={`UsersAdministration-${column.id}-Select`}
                            label={<>{column.Header}</>}
                            value={cellValue?.value || ''}
                            onChange={(e) => {
                                const value = e.target.value
                                    ? { name: column.Header, value: e.target.value }
                                    : undefined;
                                setFilterValue(value);
                            }}
                            inputProps={{
                                name: 'locked',
                                id: 'locked-native-simple'
                            }}
                            input={<OutlinedInput notched label={<>{column.Header}</>} />}
                        >
                            {options.map((option, index) => (
                                <MenuItem
                                    value={option.value}
                                    key={index}
                                    data-testid={`UsersAdministration-${column.id}-${index}-MenuItem`}
                                >
                                    {translate(`t.${option.label}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }
        },
        {
            Header: translate('t.role'),
            accessor: (row: UserModel) => row.role.name,
            id: 'role.name',
            width: 100,
            Cell: (props: { value: string }) => <>{props.value.replaceAll(/ROLE_|_/g, ' ')}</>,
            Filter: ({ column, setFilter, ...props }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: translate('t.role'),
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: props.applyFiltersFlag,
                    filterId: 'role.name'
                });

                return (
                    <DropDownContainer>
                        <RolesDropDown
                            data-testid={`UsersAdministration-${column.id}-RolesDropDown`}
                            rawValue={true}
                            defaultValue={column?.filterValue?.value}
                            value={cellValue}
                            getValue={(id, humanValue, rawValue) => {
                                const value = {
                                    name: translate('t.role'),
                                    humanValue: humanValue,
                                    value: rawValue
                                };

                                if (id == null || humanValue == null || rawValue == null) setFilterValue(undefined);
                                else setFilterValue(value);
                            }}
                            error={undefined}
                            xs={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sm={12}
                        />
                    </DropDownContainer>
                );
            }
        },
        {
            Header: translate('t.actions'),
            accessor: 'id',
            width: 60,
            height: 20,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props: { value: number; row: { original: UserModel } }) => (
                <Menu
                    testid={`Menu-Users-${props.row.original.id}`}
                    data-testid={`UsersAdministration-Actions-${props.row.original.id}-Menu`}
                    items={[
                        {
                            title: translate('t.details'),
                            value: props.value,
                            action: () => {
                                navigate(`/profile/${props.row.original.id}`);
                            }
                        },
                        {
                            title: translate('t.site'),
                            value: props.value,
                            none: checkRole(props.row.original, [ROLES.ROLE_SUPER_ADMIN]),
                            action: () => {
                                setPermissionsState(props.row.original);
                                setPermissionModal(true);
                                setShowPermissionModal(true);
                            }
                        },
                        {
                            title: translate('t.remove'),
                            value: props.value,
                            action: () => {
                                handleModalRemove(props.value, props.row.original.username);
                            }
                        }
                    ]}
                    rootEl={
                        <UiIconButton
                            aria-controls='simple-menu'
                            aria-haspopup='true'
                            testid={`UsersAdministration-${props.row.original.id}-ActionButton`}
                        >
                            <SettingsIcon />
                        </UiIconButton>
                    }
                />
            )
        }
    ];

    useEffect(() => {
        updateModalRemove();
    }, [removeUserLoading]);

    return (
        <UsersAdministrationContent
            data-testid={'UsersAdministration-testid'}
            columns={columns}
            handleModalAdd={handleModalAdd}
            setShowPermissionModal={setShowPermissionModal}
            showPermissionModal={showPermissionModal}
        />
    );
};

export default UsersAdministration;
