import React, { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { FilterInput, LegendTable, UiTableFilterCellViewContent } from './UiTableFilterCell.style';
import {
    ParseSignedValueAndAccessorType,
    SIGNS,
    SignedDateTimeIsValid,
    SignedNumberIsValid,
    UiTableFilterCellProps
} from './UiTableFilterCell.type';
import { TableFilters, TableFn, TableAtom } from '../UiTable/TableAtom';
import { InputAdornment, Tooltip, TextField, MenuItem } from '@mui/material';
import { FilterSignedDateTime, FilterSignedValue, FilterType } from '../UiTableHeader/UiTableHeader.type';
import { DateTime } from 'luxon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import Legend from '../Components/UiLegend/UiLegend';
import { getFilterKeyVariation, prepareTableFilterValues } from 'helpers';
import { useTranslation } from 'react-i18next';

const parseSignedDateAndAccessor = (
    value: string,
    filterProps: FilterSignedDateTime['onFilterApply'],
    mainAccessor?: string
) => {
    let splitValue = value
        .trim()
        .replaceAll(' ', '')
        .replace(/[^0-9dmwh><]+/g, '')
        .split(/([0-9]+)/);
    let accessor = '';

    switch (splitValue[0]) {
        case SIGNS.LOWER_THAN: {
            if (filterProps.lowerThan?.accessor) {
                accessor = filterProps.lowerThan.accessor;
            }
            break;
        }
        case SIGNS.HIGHER_THAN: {
            if (filterProps.higherThan?.accessor) {
                accessor = filterProps.higherThan.accessor;
            }
            break;
        }
        default: {
            if (filterProps.fullText) {
                accessor = filterProps.fullText.accessor;
            } else {
                accessor = filterProps.exact?.accessor || mainAccessor || '';
            }
            break;
        }
    }
    return {
        splitValue,
        accessor
    };
};

const parseSignedValueAndAccessor = (
    value: string,
    filterProps: FilterSignedValue['onFilterApply'],
    mainAccessor?: string
): ParseSignedValueAndAccessorType => {
    let splitValue = value
        .trim()
        .replaceAll(' ', '')
        .replace(/([a-z]+)/g, '')
        .split(/([0-9]+)/);
    let accessor = '';
    if (splitValue.length === 1) {
        splitValue = splitValue[0].toLocaleLowerCase().split(/([a-z]+)/);
    }
    switch (splitValue[0]) {
        case SIGNS.LOWER_THAN: {
            if (filterProps.lowerThan?.accessor) {
                accessor = filterProps.lowerThan.accessor;
            }
            break;
        }
        case SIGNS.HIGHER_THAN: {
            if (filterProps.higherThan?.accessor) {
                accessor = filterProps.higherThan.accessor;
            }
            break;
        }
        case SIGNS.FULLTEXT: {
            if (filterProps.fullText?.accessor) {
                accessor = filterProps.fullText.accessor;
            }
            break;
        }
        case SIGNS.EQUAL: {
            if (filterProps.exact?.accessor) {
                accessor = filterProps.exact.accessor;
            }
            break;
        }
        case SIGNS.NEGATE: {
            if (filterProps.negation?.accessor) {
                accessor = filterProps.negation.accessor;
            }
            break;
        }
        default: {
            if (filterProps.fullText) {
                accessor = filterProps.fullText.accessor;
            } else {
                accessor = filterProps.exact?.accessor || mainAccessor || '';
            }
            break;
        }
    }
    return {
        splitValue,
        accessor
    };
};

const filterSignedDatetime = (
    value: string,
    filterProps: FilterSignedDateTime['onFilterApply'],
    mainAccessor: string
): SignedDateTimeIsValid => {
    const timeLegend = {
        d: 'days',
        h: 'hours',
        m: 'minutes',
        w: 'weeks'
    };
    let currentValue = DateTime.local();

    const { accessor, splitValue } = parseSignedDateAndAccessor(value, filterProps, mainAccessor);
    let isValid = true;

    if (splitValue.length !== 3 || isNaN(+splitValue[1]) || splitValue[2] === '') {
        isValid = false;
    } else {
        currentValue = currentValue.minus({ [timeLegend[splitValue[2][0]]]: +splitValue[1] });
    }

    return { isValid, value: currentValue, accessor };
};

// returned accessor is preparation for enhanced filtering like higher lowe etc
const filterVersion = (value: string, accessor: FilterSignedValue['onFilterApply'], defaultAccessor: string) => {
    let versionAccesor: string | undefined;
    let splitValue = value
        .trim()
        .replace(/[^0-9.><!]/g, '')
        .replaceAll(' ', '');

    if (splitValue[0] === SIGNS.HIGHER_THAN) {
        versionAccesor = accessor.higherThan?.accessor;
    }
    if (splitValue[0] === SIGNS.LOWER_THAN) {
        versionAccesor = accessor.lowerThan?.accessor;
    }
    if (splitValue[0] === SIGNS.NEGATE) {
        versionAccesor = accessor.negation?.accessor;
    }

    return {
        isValid: splitValue.match(new RegExp(/^(?!\.)(?!.*\.$)(?!.*\.\.)/)) !== null,
        value: splitValue.replaceAll(/[><!]/g, ''),
        accessor: versionAccesor || accessor.fullText?.accessor || defaultAccessor
    };
};

const filterSignedNumber = (
    value: string,
    filterProps: FilterSignedValue['onFilterApply'],
    mainAccessor: string
): SignedNumberIsValid => {
    const { accessor, splitValue } = parseSignedValueAndAccessor(value, filterProps, mainAccessor);
    let isValid = true;

    if (splitValue.length !== 3 || isNaN(+splitValue[1]) || splitValue[2] !== '') {
        isValid = false;
    }

    return {
        value: splitValue[1],
        accessor: accessor,
        isValid: isValid
    };
};

const filterNegateValue = (value: string, filterProps: FilterSignedValue['onFilterApply'], mainAccessor: string) => {
    let accessor: string | undefined = mainAccessor;
    let customerValue = value;
    if (value[0] === SIGNS.NEGATE) {
        accessor = filterProps.negation?.accessor;
        customerValue = customerValue.slice(1);
    }

    return {
        isValid: true,
        value: customerValue,
        accessor: accessor
    };
};

const UiTableFilterCellContent: React.FC<UiTableFilterCellProps> = (props): JSX.Element => {
    const [tableFilter, setTableFilter] = useRecoilState(TableFilters(`${props.queryKey}-TableFilters`));
    const { fromTimezoneToUTC } = useConverter();
    const setTableState = useSetRecoilState(TableAtom(`${props.queryKey}-Table`));
    const [open, setOpen] = React.useState(false);
    const { t: translate } = useTranslation();
    const tableFns = useRecoilValue(TableFn(`${props.queryKey}-TableFn`));

    const onBlurAction = () => {
        handleTooltipClose();
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const getFilterValue = useMemo(() => {
        switch (props.filterProps?.type) {
            case FilterType.SIGNED_DATETIME:
            case FilterType.NEGATE_VALUE:
            case FilterType.VERSION_VALUE:
            case FilterType.DROPDOWN:
            case FilterType.SIGNED_VALUE: {
                let value = tableFilter[props.accessor]?.value;

                Object.values(props.filterProps.onFilterApply).forEach((filter) => {
                    if (filter?.accessor && tableFilter[filter.accessor]) {
                        value = tableFilter[filter.accessor].humanValue;
                    }
                });
                if (!value && props.filterProps?.type === FilterType.DROPDOWN) {
                    return -1; // default value in dropdown
                }
                return value;
            }
            default:
                return tableFilter[props.accessor]?.value;
        }
    }, [tableFilter, props.filterProps]);

    const FilterLegend = useCallback(() => {
        let legendContent = <></>;

        let tableContent: JSX.Element[] = [];

        if (props.filterProps?.onFilterApply?.lowerThan) {
            tableContent.push(
                <tr>
                    <td>
                        <strong>{'<'}</strong>
                    </td>
                    <td>{'Lower than'}</td>
                    <td>
                        {'(Search for records lower than value '}
                        <strong>{'e.g. <5m'}</strong>
                        {')'}
                    </td>
                </tr>
            );
        }
        if (props.filterProps?.onFilterApply?.higherThan) {
            tableContent.push(
                <tr>
                    <td>
                        <strong>{'>'}</strong>
                    </td>
                    <td>{'Higher than'}</td>
                    <td>
                        {'(Search for records higher than value '} <strong>{'e.g. >5m'}</strong>
                        {')'}
                    </td>
                </tr>
            );
        }
        if (props.filterProps?.onFilterApply?.exact) {
            tableContent.push(
                <tr>
                    <td>
                        <strong>{'='}</strong>
                    </td>
                    <td>{'Exact value'}</td>
                    <td>
                        {'(Search for exact value '}
                        <strong>{'=myValue'}</strong>
                        {')'}
                    </td>
                </tr>
            );
        }
        if (props.filterProps?.onFilterApply?.negation) {
            tableContent.push(
                <tr>
                    <td>
                        <strong>{'!'}</strong>
                    </td>
                    <td>{'Negated value'}</td>
                    <td>
                        {'(Search for records not containing value '}
                        <strong>{'!myValue'}</strong>
                        {')'}
                    </td>
                </tr>
            );
        }

        switch (props.filterProps?.type) {
            case FilterType.VERSION_VALUE:
            case FilterType.SIGNED_VALUE:
            case FilterType.NEGATE_VALUE:
                break;
            case FilterType.SIGNED_DATETIME: {
                tableContent.push(
                    <tr>
                        <td>
                            <strong>{'m'}</strong>
                        </td>
                        <td>{'minutes'}</td>
                    </tr>
                );
                tableContent.push(
                    <tr>
                        <td>
                            <strong>{'d'}</strong>
                        </td>
                        <td>{'days'}</td>
                    </tr>
                );
                tableContent.push(
                    <tr>
                        <td>
                            <strong>{'w'}</strong>
                        </td>
                        <td>{'weeks'}</td>
                    </tr>
                );
                break;
            }
            default:
                return legendContent;
        }
        if (tableContent.length > 0) {
            return <Legend content={<LegendTable>{tableContent}</LegendTable>} title='Filter Legend' />;
        }
        return legendContent;
    }, [props.filterProps]);

    return (
        <UiTableFilterCellViewContent data-testid={`${props.accessor}Filter`}>
            {props.filterProps?.type === FilterType.DROPDOWN && (
                <Tooltip
                    PopperProps={{
                        disablePortal: true
                    }}
                    onClose={onBlurAction}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                        <p style={{ margin: 0, padding: 0, textAlign: 'center', whiteSpace: 'nowrap' }}>
                            {translate('t.press_enter_to_search')}
                        </p>
                    }
                    arrow
                >
                    <TextField
                        id='select-assigned'
                        select
                        size='small'
                        label=''
                        value={getFilterValue}
                        defaultValue='-1'
                        fullWidth
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                const filters = prepareTableFilterValues(tableFilter);
                                tableFns.gotoPage instanceof Function && tableFns.gotoPage(0);
                                setTimeout(() => {
                                    setTableState((current) => ({
                                        ...current,
                                        queryPageFilter: filters,
                                        queryPageIndex: 0
                                    }));
                                }, 200);
                            }
                        }}
                        onChange={(e) => {
                            setTableFilter((current) => {
                                const copyCurrent = { ...current };
                                if (props.filterProps?.onFilterApply.exact || props.accessor) {
                                    getFilterKeyVariation(props.accessor, '').forEach((filterKey) => {
                                        delete copyCurrent[filterKey];
                                    });
                                    copyCurrent[props.filterProps?.onFilterApply?.exact?.accessor || props.accessor] = {
                                        value: e.target.value.toString(),
                                        humanValue: e.target.value.toString()
                                    };
                                    if (+e.target.value < 0) {
                                        delete copyCurrent[
                                            props.filterProps?.onFilterApply?.exact?.accessor || props.accessor
                                        ];
                                    }
                                }
                                return copyCurrent;
                            });
                        }}
                    >
                        {props.filterProps.options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Tooltip>
            )}
            {props.filterProps?.type !== FilterType.DROPDOWN && (
                <Tooltip
                    PopperProps={{
                        disablePortal: true
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                        <p style={{ margin: 0, padding: 0, textAlign: 'center', whiteSpace: 'nowrap' }}>
                            {translate('t.press_enter_to_search')}
                        </p>
                    }
                    arrow
                >
                    <FilterInput
                        id='outlined-basic'
                        size='small'
                        variant='outlined'
                        value={getFilterValue}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                const filters = prepareTableFilterValues(tableFilter);
                                tableFns.gotoPage instanceof Function && tableFns.gotoPage(0);
                                setTimeout(() => {
                                    setTableState((current) => ({
                                        ...current,
                                        queryPageFilter: filters,
                                        queryPageIndex: 0
                                    }));
                                }, 200);
                            }
                        }}
                        onFocus={handleTooltipOpen}
                        onBlur={onBlurAction}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            switch (props.filterProps?.type) {
                                case FilterType.SIGNED_DATETIME: {
                                    const { isValid, accessor, value } = filterSignedDatetime(
                                        e.target.value,
                                        props.filterProps.onFilterApply,
                                        props.accessor
                                    );
                                    setTableFilter((current) => {
                                        const copyCurrent = { ...current };
                                        if (accessor) {
                                            getFilterKeyVariation(props.accessor, accessor).forEach((filterKey) => {
                                                delete copyCurrent[filterKey];
                                            });
                                            copyCurrent[accessor] = {
                                                value: isValid ? fromTimezoneToUTC(value, true, true) : '',
                                                humanValue: e.target.value
                                            };
                                            if (!isValid || !e.target.value) {
                                                delete copyCurrent[accessor];
                                            }
                                        }
                                        return copyCurrent;
                                    });
                                    break;
                                }
                                case FilterType.SIGNED_VALUE: {
                                    const { accessor, value } = filterSignedNumber(
                                        e.target.value,
                                        props.filterProps.onFilterApply,
                                        props.accessor
                                    );

                                    setTableFilter((current) => {
                                        const copyCurrent = { ...current };
                                        getFilterKeyVariation(
                                            props.filterProps?.onFilterApply?.exact?.accessor ||
                                                props.filterProps?.onFilterApply?.fullText?.accessor ||
                                                props.accessor,
                                            accessor
                                        ).forEach((filterKey) => {
                                            delete copyCurrent[filterKey];
                                        });
                                        if (accessor) {
                                            copyCurrent[accessor] = {
                                                value,
                                                humanValue: e.target.value
                                            };

                                            if (!value || !e.target.value) {
                                                delete copyCurrent[accessor];
                                            }
                                        }
                                        return copyCurrent;
                                    });
                                    break;
                                }
                                case FilterType.VERSION_VALUE: {
                                    const { accessor, value } = filterVersion(
                                        e.target.value,
                                        props.filterProps.onFilterApply,
                                        props.filterProps.onFilterApply.fullText?.accessor || props.accessor
                                    );

                                    setTableFilter((current) => {
                                        const copyCurrent = { ...current };
                                        getFilterKeyVariation(
                                            props.filterProps?.onFilterApply?.fullText?.accessor || props.accessor,
                                            accessor || ''
                                        ).forEach((filterKey) => {
                                            delete copyCurrent[filterKey];
                                        });
                                        if (accessor) {
                                            copyCurrent[accessor] = {
                                                value: value,
                                                humanValue: e.target.value
                                            };

                                            if (!value || !e.target.value) {
                                                delete copyCurrent[accessor];
                                            }
                                        }
                                        return copyCurrent;
                                    });
                                    break;
                                }
                                case FilterType.NEGATE_VALUE: {
                                    const { accessor, value } = filterNegateValue(
                                        e.target.value,
                                        props.filterProps.onFilterApply,
                                        props.filterProps.onFilterApply.fullText?.accessor || props.accessor
                                    );

                                    setTableFilter((current) => {
                                        const copyCurrent = { ...current };
                                        getFilterKeyVariation(
                                            props.filterProps?.onFilterApply?.fullText?.accessor || props.accessor,
                                            accessor || ''
                                        ).forEach((filterKey) => {
                                            delete copyCurrent[filterKey];
                                        });
                                        if (accessor) {
                                            copyCurrent[accessor] = {
                                                value,
                                                humanValue: e.target.value
                                            };

                                            if (!value || !e.target.value) {
                                                delete copyCurrent[accessor];
                                            }
                                        }
                                        return copyCurrent;
                                    });
                                    break;
                                }
                                default: {
                                    setTableFilter((current) => {
                                        const copyCurrent = { ...current };
                                        getFilterKeyVariation(props.accessor, '').forEach((filterKey) => {
                                            delete copyCurrent[filterKey];
                                        });
                                        copyCurrent[props.accessor] = {
                                            value: e.target.value,
                                            humanValue: e.target.value
                                        };

                                        if (!e.target.value) {
                                            delete copyCurrent[props.accessor];
                                        }
                                        return copyCurrent;
                                    });
                                    break;
                                }
                            }
                        }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <FilterLegend />
                                </InputAdornment>
                            ),
                            style: {
                                paddingRight: 0
                            }
                        }}
                    />
                </Tooltip>
            )}
        </UiTableFilterCellViewContent>
    );
};

export default UiTableFilterCellContent;
