import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
    AlertsConfigurationFilterInputViewContent,
    ExpandIcon,
    Label,
    SelectConfigurationButton,
    VehicleConfigCover,
    VehicleConfigInput
} from './AlertsConfigurationFilterInput.style';
import { AlertsConfigurationFilterInputProps } from './AlertsConfigurationFilterInput.type';
import { useTranslation } from 'react-i18next';
import { CustomAutocomplete } from 'components/Vehicle/VehicleActions/VehicleActions.style';
import VehicleConfigurationApi from 'api/VehicleConfiguration';
import { AlertVehicleFilters, AlertVehicleFiltersSelector } from '../AlertsFilterVehiclesBy/atom';
import { useQuery } from '@tanstack/react-query';
import { VehicleConfigurationsType } from 'models/VehicleConfiguration.type';
import VehicleSchema from 'components/Vehicle/NewVehicleSchema/VehicheSchema';
import { Box, TextField, Typography, useTheme } from '@mui/material';
import { AlertConfigurationPosition } from './atom';
import { Popper } from '@mui/material';
import { SelectedAvailableFilterIds } from '../AlertsAvailableFilters/atom';
import { VehicleConfigurationQueryKeys } from 'models/VehicleConfiguration.type';

const VehicleConfiguration = new VehicleConfigurationApi();

const AlertsConfigurationFilterInputContent: React.FC<AlertsConfigurationFilterInputProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const [filterVehicleModel, setFilterVehicleModel] = useRecoilState(
        AlertVehicleFiltersSelector(props.filter.tableId)
    );
    const resetFilterVehicleModel = useResetRecoilState(AlertVehicleFiltersSelector(props.filter.tableId));
    const [vehicleFilter, setVehicleFilter] = useRecoilState(AlertVehicleFilters);
    const resetAlertConfigurationPosition = useResetRecoilState(AlertConfigurationPosition);
    const setAlertConfigurationPosition = useSetRecoilState(AlertConfigurationPosition);
    const theme = useTheme();
    const wrapperRef = useRef() as any;
    const setSelectedFilters = useSetRecoilState(SelectedAvailableFilterIds);

    const { data } = useQuery<VehicleConfigurationsType>(
        [VehicleConfigurationQueryKeys.getV2, 'used'],
        () => VehicleConfiguration.get({ version: 'v2', extendUrl: 'used' }),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            staleTime: 0,
            cacheTime: 0
        }
    );

    const toggleOpen = () => {
        setOpen((current) => !current);
    };

    const getConfigurationById = (id: number): VehicleConfigurationsType['codeBook'] | undefined => {
        const configuration = data?.codeBook.filter((config) => config.id === id && config.id !== 9);
        return configuration;
    };

    const presetConfiguration = (): void => {
        if (vehicleFilter.vehicle || vehicleFilter.vehicleModelV2) {
            setOpen(false);
            const newConfiguration = getConfigurationById(
                vehicleFilter?.vehicle?.configurationId || vehicleFilter?.vehicleModelV2?.vehicleConfiguration?.id
            );
            if (newConfiguration) {
                setFilterVehicleModel((current) => ({ ...current, filter: newConfiguration[0] }));
                resetAlertConfigurationPosition();
            }
        }
    };

    useEffect(() => {
        if (vehicleFilter.vehicle === undefined && vehicleFilter.vehicleModelv2 === undefined) {
            setOpen(true);
        }
        presetConfiguration();
    }, [data, vehicleFilter.vehicle, vehicleFilter.vehicleModelV2]);

    useEffect(() => {
        setAlertConfigurationPosition((current) => ({ ...current, color: 'red' }));
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            resetFilterVehicleModel();
            resetAlertConfigurationPosition();
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (wrapperRef && wrapperRef?.current && !wrapperRef?.current.contains(event.target)) {
            toggleOpen();
        }
    };

    return (
        <AlertsConfigurationFilterInputViewContent data-testid='AlertsConfigurationFilterInputContent'>
            <Label theme={theme}>{translate('t.tyre_position')}</Label>
            <CustomAutocomplete
                disableClearable
                id='vehicle-configuration-select'
                data-testid='vehicle-configuration-select'
                size='small'
                open={open}
                PopperComponent={(props) => <Popper {...props} ref={wrapperRef} />}
                options={data?.codeBook.filter((val) => val.id !== 9) || []}
                getOptionLabel={(option: any) => option.distribution ?? ''}
                renderOption={(props, option: any) => {
                    return (
                        <Box component='li' {...props} display={'flex'} justifyContent={'center !important'}>
                            <Box height={100} width={100}>
                                <VehicleSchema schema={option?.distribution} emptySchema={true} />
                            </Box>
                        </Box>
                    );
                }}
                onChange={(event, newValue: any) => {
                    setVehicleFilter((current) => ({ ...current, [props.filter.tableId]: newValue }));
                    setFilterVehicleModel((current) => ({ ...current, filter: newValue }));
                    setSelectedFilters((current) =>
                        current.filter((id) => props.filter.availableWith.includes(id) || id === props.filter.id)
                    );
                    toggleOpen();
                }}
                style={{ width: 200 }}
                renderInput={(params) => (
                    <VehicleConfigCover>
                        <VehicleConfigInput>
                            {filterVehicleModel.filter?.distribution && (
                                <>
                                    <Box height={240} width={200} paddingTop={'10px'}>
                                        <VehicleSchema
                                            schema={filterVehicleModel.filter?.distribution}
                                            emptySchema
                                            allowDisplaySelectedTyre
                                            allowSelectTyre
                                        />
                                    </Box>
                                    {!(vehicleFilter.vehicle || vehicleFilter.vehicleModelV2) && (
                                        <SelectConfigurationButton
                                            variant='contained'
                                            onClick={toggleOpen}
                                            endIcon={<ExpandIcon expand={open} />}
                                        >
                                            <Typography variant='subtitle1' gutterBottom>
                                                {translate('t.select_configuration')}
                                            </Typography>
                                        </SelectConfigurationButton>
                                    )}
                                </>
                            )}
                            {!(vehicleFilter.vehicle || vehicleFilter.vehicleType || vehicleFilter.vehicleModelV2) && (
                                <SelectConfigurationButton
                                    variant='contained'
                                    onClick={toggleOpen}
                                    endIcon={<ExpandIcon expand={open} />}
                                >
                                    <Typography variant='subtitle1' gutterBottom>
                                        {translate('t.select_configuration')}
                                    </Typography>
                                </SelectConfigurationButton>
                            )}
                        </VehicleConfigInput>
                        <TextField
                            {...params}
                            label={translate('t.model')}
                            variant='outlined'
                            size='small'
                            key={params.id}
                            name={params.id}
                            InputLabelProps={{
                                shrink: true
                            }}
                            style={{
                                visibility: 'hidden',
                                position: 'absolute',
                                bottom: 0
                            }}
                            hidden={true}
                        />
                    </VehicleConfigCover>
                )}
            />
        </AlertsConfigurationFilterInputViewContent>
    );
};

export default AlertsConfigurationFilterInputContent;
