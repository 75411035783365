import React from 'react';
import { useRecoilValue } from 'recoil';
import {
    ContainerAutoCompleteReports,
    CustomTextField,
    InitMsg,
    QuicksightReportsContentEl,
    QuicksightReportsViewContent,
    QuicksightReportsWrapper,
    UiCircularProgressCover
} from './QuicksightReports.style';
import { QuicksightReportsViewProps } from './QuicksightReports.type';
import { useTranslation } from 'react-i18next';
import UiWidget from '../../components/Ui/Components/UiWidget/UiWidget';
import { faChartPie } from '@fortawesome/pro-solid-svg-icons';
import { Autocomplete } from '@mui/material';
import UiCircularProgress from '../../components/Ui/Components/UiCircularProgress/UiCircularProgress';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import ReactGA, { GaPageTitle } from 'helpers/ga';
import { ReportType } from 'models/Reporting.type';

const gTrackOnReportChange = (pageTitle: string) => {
    ReactGA.send({
        hitType: 'pageview',
        page: `/quicksight-reports?report_name=${pageTitle}`,
        title:
            GaPageTitle[`/quicksight-reports?report_name=${pageTitle}`] ||
            `/quicksight-reports?report_name=${pageTitle}`
    });
};

export const QuicksightReportsContent: React.FC<QuicksightReportsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <QuicksightReportsViewContent data-testid='QuicksightReportsContent'>
            <UiWidget
                title={`${translate('t.reports')} `}
                avatar={
                    <UiIcon
                        icon={faChartPie}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                $headerActionStartsLeft={true}
                headerAction={
                    <ContainerAutoCompleteReports>
                        <Autocomplete
                            id='vehicle-search'
                            options={props.availableReports || []}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <CustomTextField
                                    {...params}
                                    placeholder={translate('t.select_report')}
                                    variant='outlined'
                                    data-testid='Vehicle-Search-TextField'
                                />
                            )}
                            onChange={(event, value) => {
                                gTrackOnReportChange(value?.reporting.shortName || 'no-report-name-available');
                                value && props.generateEmbeddedUrl(value.reportingId);
                                props.setShowSelectMsg(false);
                                props.setIsPdf(value?.reporting.type === ReportType.MDD);
                            }}
                            style={{ width: '400px' }}
                        />
                    </ContainerAutoCompleteReports>
                }
            >
                <QuicksightReportsWrapper>
                    {props.generateEmbeddedUrlIsLoading && (
                        <UiCircularProgressCover>
                            <UiCircularProgress />
                        </UiCircularProgressCover>
                    )}
                    {props.showSelectMsg && <InitMsg>{translate('t.select_report_to_preview')}</InitMsg>}
                    {props.isPdf ? (
                        <iframe src={props.pdfUrl} title={'pdf-report'} style={{ height: '80vh', width: '100%' }} />
                    ) : (
                        <QuicksightReportsContentEl id='quicksight-content' />
                    )}
                </QuicksightReportsWrapper>
            </UiWidget>
        </QuicksightReportsViewContent>
    );
};
