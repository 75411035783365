import React from 'react';
import {
    ACTUAL_TEMP_COLOR,
    AMBIENT_TEMP_COLOR,
    GraphLegendContent,
    PREDICTED_TEMP_COLOR,
    Table,
    CRITICAL_TEMP_COLOR,
    ACTUAL_TEMP_COLOR_DARK,
    AMBIENT_TEMP_COLOR_DARK,
    CRITICAL_TEMP_COLOR_2
} from '../PredictedGraph.style';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Theme, ThemeMode } from 'states/global/Theme';
import { CustomerSettings } from 'states/global/CustomerSettings';

const GraphLegend = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const theme = useRecoilValue(Theme);
    const customerSettings = useRecoilValue(CustomerSettings);

    const getPreviewForPredTempLine = (): JSX.Element => {
        let dots: JSX.Element[] = [];
        const initX = 2;

        for (let i = 0; i < 18; i++) {
            dots.push(<circle cx={initX + i * 5} cy={6} r={1.5} fill={PREDICTED_TEMP_COLOR} key={i} />);
        }

        return (
            <svg height={10} width={100}>
                {dots.map((dot) => dot)}
            </svg>
        );
    };

    return (
        <GraphLegendContent data-testid='graph-legend'>
            <Table $legend={true}>
                <tbody>
                    <tr>
                        <td>
                            <svg height={10} width={100}>
                                <line
                                    x1={0}
                                    y1={5}
                                    x2={90}
                                    y2={5}
                                    strokeWidth={6}
                                    stroke={theme.mode === ThemeMode.light ? ACTUAL_TEMP_COLOR : ACTUAL_TEMP_COLOR_DARK}
                                />
                            </svg>
                            {translate('t.actual_temp')}
                        </td>
                        <td>
                            <svg height={10} width={100}>
                                <line
                                    x1={0}
                                    y1={5}
                                    x2={90}
                                    y2={5}
                                    strokeWidth={1}
                                    stroke={
                                        theme.mode === ThemeMode.light ? AMBIENT_TEMP_COLOR : AMBIENT_TEMP_COLOR_DARK
                                    }
                                />
                            </svg>
                            {translate('t.ambient_temp')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {getPreviewForPredTempLine()}
                            {translate('t.prediction_temp')}
                        </td>
                        <td>
                            <svg height={10} width={100}>
                                <line x1={0} y1={5} x2={90} y2={5} strokeWidth={1} stroke={CRITICAL_TEMP_COLOR} />
                            </svg>
                            {translate('t.critical_temp_level_3')}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        {customerSettings.temperature_prediction_workflow === 1 && (
                            <td>
                                <svg height={10} width={100}>
                                    <line x1={0} y1={5} x2={90} y2={5} strokeWidth={1} stroke={CRITICAL_TEMP_COLOR_2} />
                                </svg>
                                {`${translate('t.critical_temp_level_2')} - ${translate('t.safety_margin')} 2`}
                            </td>
                        )}
                    </tr>
                </tbody>
            </Table>
        </GraphLegendContent>
    );
};

export default GraphLegend;
