import React from 'react';
import { useSetRecoilState } from 'recoil';
import { RowReportSensor } from './ReportSensor.style';
import { ReportSensorProps } from './ReportSensor.type';
import { useTranslation } from 'react-i18next';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { FaultySensorReporterState } from 'states/component/FaultySensorReporter';
import { VARIANT_BUTTON } from 'components/Ui/variables';
import { Alert } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';

const ReportSensor: React.FC<ReportSensorProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const setFaultySensorReporterState = useSetRecoilState(FaultySensorReporterState);

    return (
        <>
            <RowReportSensor>
                <Alert sx={{ marginBottom: '10px' }} severity='warning'>
                    {translate('p.report_faulty_sensor_before_unmount', {
                        serial_number: props.sensorName
                    })}
                </Alert>
            </RowReportSensor>
            <RowReportSensor>
                <UiButton
                    color='warning'
                    variant={VARIANT_BUTTON}
                    onClick={() => {
                        setFaultySensorReporterState({
                            vehicleId: props.vehicleId,
                            vehicleName: props.vehicleName || '',
                            sensorId: props.sensorId || 0,
                            sensorName: props.sensorName || '',
                            showOnlyGraph: props.showOnlyGraph,
                            wheelPosition: props.wheelPosition,
                            internalOnVehicle: props.internalOnVehicle,
                            isExternal: props.isExternal,
                            allowReportLeakAlert: props.allowReportLeakAlert,
                            afterAction: props.afterAction
                        });
                    }}
                    startIcon={<UiIcon icon={['fas', 'bug']} size={'sm'} />}
                >
                    {translate('t.report_sensor')}
                </UiButton>
            </RowReportSensor>
        </>
    );
};

export default ReportSensor;
