export type RoleModel = {};

export type RoleCodeBook = {
    id: number;
    name: string;
};

export enum RoleQueryKeys {
    getAvailable = 'role-getAvailable',
    getCodebook = 'role-getCodebook'
}
