import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { TempPressAlertLevelTableProps } from './TempPressAlertLevelTable.type';
import { TempPressAlertLevelTableContent } from './TempPressAlertLevelTable.view';
import { BorderDiv, CenterText, TableRows, TableStyles, UnitValue } from './TempPressAlertLevelTable.style';
import Vehicle from 'api/Vehicle';
import { TempPressReportNew, TempPressReportTable, VehicleQueryKeys } from 'models/Vehicle.type';
import { useRecoilValue } from 'recoil';
import { UserInfo } from 'states/global/User';
import { useTranslation } from 'react-i18next';
import { TopBarAtom } from 'components/Mixs/DataExport/TopBar/TopBar.atom';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { PressureUnits } from 'components/CustomHooks/Converter/Converter.type';
import { Paginator } from 'helpers/api/type';
import { Wrapper } from 'helpers/wrapper';

const vehicleAPI = new Vehicle();

const TempPressAlertLevelTable: React.FC<TempPressAlertLevelTableProps> = (): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);

    const { t: translate } = useTranslation();
    const topBarAtom = useRecoilValue(TopBarAtom);
    const { fromServerToUserUnit, displayUserUnits, convertType } = useConverter();
    const decimals = displayUserUnits.pressure.toLowerCase() === PressureUnits.KPA ? 0 : 1;

    const parseTempPressResponseNew = (data: Paginator<TempPressReportNew>): Record<string, unknown>[] => {
        const groupedByVehicleId = data.items.reduce((acc, item) => {
            const { vehicleId, vehicleName, customPosition, type, type2, level, pressureOptimal, limitFrom, limitTo } =
                item;

            const key = `${vehicleId}-${customPosition}`;

            const getPressureOptimal = fromServerToUserUnit({
                type: convertType.pressure,
                value: pressureOptimal || 0,
                displayUnits: false,
                fixed: decimals,
                displayIfEmpty: '-'
            });

            if (!acc[key]) {
                acc[key] = {
                    vehicleId,
                    vehicleName,
                    position: customPosition,
                    low3: { coldPressure: 0, hotPressure: 0, temperature: 0 },
                    low2: { coldPressure: 0, hotPressure: 0, temperature: 0 },
                    low1: { coldPressure: 0, hotPressure: 0, temperature: 0 },
                    optimal: { coldPressure: getPressureOptimal, hotPressure: getPressureOptimal, temperature: 0 },
                    high1: { coldPressure: 0, hotPressure: 0, temperature: 0 },
                    high2: { coldPressure: 0, hotPressure: 0, temperature: 0 },
                    high3: { coldPressure: 0, hotPressure: 0, temperature: 0 }
                };
            }

            const category = `${type2}${level}`;
            if (type === 'pressure') {
                acc[key][category].coldPressure = fromServerToUserUnit({
                    type: convertType.pressure,
                    value: (type2 === 'low' ? limitTo : limitFrom) || 0,
                    displayUnits: false,
                    fixed: decimals,
                    displayIfEmpty: '-'
                });
            } else if (type === 'pressure_hot') {
                acc[key][category].hotPressure = fromServerToUserUnit({
                    type: convertType.pressure,
                    value: (type2 === 'low' ? limitTo : limitFrom) || 0,
                    displayUnits: false,
                    fixed: decimals,
                    displayIfEmpty: '-'
                });
            } else if (type === 'temperature') {
                acc[key][category].temperature = fromServerToUserUnit({
                    type: convertType.temperature,
                    value: (type2 === 'low' ? limitTo : limitFrom) || 0,
                    displayUnits: false,
                    fixed: decimals,
                    displayIfEmpty: '-'
                });
            }

            return acc;
        }, {});

        return Object.values(groupedByVehicleId);
    };

    const parseTempPressData = (data: TempPressReportTable): Record<string, unknown>[] => {
        return parseTempPressResponseNew(data as Paginator<TempPressReportNew>);
    };

    const columns = [
        {
            header: translate('t.vehicle_name'),
            accessor: 'vehicleName',
            parseData: (props) => <CenterText>{props}</CenterText>
        },
        {
            header: translate('t.position'),
            accessor: 'position',
            parseData: (props) => <CenterText>{props}</CenterText>
        },
        {
            header: translate('t.type'),
            accessor: 'vehicleName',
            width: 150,
            parseData: () => (
                <TableRows alertLevel={4} $type>
                    <BorderDiv>
                        {translate('t.cold_pressure')}
                        <UnitValue>({userInfo?.user?.userSetting?.pressureUnit})</UnitValue>
                    </BorderDiv>
                    <BorderDiv>
                        {translate('t.hot_pressure')}
                        <UnitValue>({userInfo?.user?.userSetting?.pressureUnit})</UnitValue>
                    </BorderDiv>
                    <div>
                        {translate('t.temperature')}
                        <UnitValue>(°{userInfo?.user?.userSetting?.temperatureUnit} )</UnitValue>
                    </div>
                </TableRows>
            )
        },
        {
            header: `${translate('t.level')} 3 ${translate('t.low')}`,
            accessor: 'low3',
            parseData: (props) => {
                return (
                    <TableRows alertLevel={3}>
                        <BorderDiv>{props.coldPressure}</BorderDiv>
                        <BorderDiv>-</BorderDiv>
                        <div>-</div>
                    </TableRows>
                );
            }
        },
        {
            header: `${translate('t.level')} 2 ${translate('t.low')}`,
            accessor: 'low2',
            parseData: (props) => (
                <TableRows alertLevel={2}>
                    <BorderDiv>{props.coldPressure}</BorderDiv>
                    <BorderDiv>-</BorderDiv>
                    <div>-</div>
                </TableRows>
            )
        },
        {
            header: `${translate('t.level')} 1 ${translate('t.low')}`,
            accessor: 'low1',
            parseData: (props) => (
                <TableRows alertLevel={1}>
                    <BorderDiv>{props.coldPressure}</BorderDiv>
                    <BorderDiv>-</BorderDiv>
                    <div>-</div>
                </TableRows>
            )
        },
        {
            header: translate('t.optimal'),
            accessor: 'optimal',
            parseData: (props) => {
                return (
                    <TableRows alertLevel={0}>
                        <BorderDiv>{props.coldPressure}</BorderDiv>
                        <BorderDiv>-</BorderDiv>
                        <div>-</div>
                    </TableRows>
                );
            }
        },
        {
            header: `${translate('t.level')} 1 ${translate('t.high')}`,
            accessor: 'high1',
            parseData: (props) => (
                <TableRows alertLevel={1}>
                    <BorderDiv>{props.coldPressure}</BorderDiv>
                    <BorderDiv>{props.hotPressure}</BorderDiv>
                    <div>{props.temperature}</div>
                </TableRows>
            )
        },
        {
            header: `${translate('t.level')} 2 ${translate('t.high')}`,
            accessor: 'high2',
            parseData: (props) => (
                <TableRows alertLevel={2}>
                    <BorderDiv>{props.coldPressure}</BorderDiv>
                    <BorderDiv>{props.hotPressure}</BorderDiv>
                    <div>{props.temperature}</div>
                </TableRows>
            )
        },
        {
            header: `${translate('t.level')} 3 ${translate('t.high')}`,
            accessor: 'high3',
            parseData: (props) => (
                <TableRows alertLevel={3}>
                    <BorderDiv>{props.coldPressure}</BorderDiv>
                    <BorderDiv>{props.hotPressure}</BorderDiv>
                    <div>{props.temperature}</div>
                </TableRows>
            )
        }
    ];

    const { refetch: handleDownloadButton } = useQuery(
        [VehicleQueryKeys.downloadTempPressReportAsCSV, topBarAtom.vehicle.id],
        () => vehicleAPI.downloadTempPressReportAsCSV({ vehicleId: topBarAtom.vehicle.id }),
        {
            retry: false,
            keepPreviousData: true,
            enabled: false,
            refetchOnWindowFocus: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: (dataOnSuccess) => {
                const url = URL.createObjectURL(new Blob([dataOnSuccess]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'temp-press-alert-lvl.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        }
    );

    return (
        <TableStyles>
            <TempPressAlertLevelTableContent
                data-testid={'TempPressAlertLevelTable-testid'}
                columns={columns}
                handleDownloadButton={handleDownloadButton}
                parseTempPressResponse={parseTempPressData}
            />
        </TableStyles>
    );
};

export default Wrapper(TempPressAlertLevelTable);
