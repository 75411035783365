import React from 'react';
import { LifespanReportHistoryViewContent, List, TextNoData, TagFilterContainer } from './LifespanReportHistory.style';
import { LifespanReportHistoryViewProps } from './LifespanReportHistory.type';
import { useTranslation } from 'react-i18next';
import Widget from '../../Ui/Components/UiWidget/UiWidget';
import { faClockRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import Timeline from '@mui/lab/Timeline';
import AlertsLevelPopUpView from './Components/AlertsLevelPopUp/AlertsLevelPopUp.view';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TagFilter from 'components/AlertNotification/TagFilter/TagFilter';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';

export const LifespanReportHistoryContent: React.FC<LifespanReportHistoryViewProps> = ({
    level,
    timeLineCell,
    handleScroll,
    tyreLifeHistory,
    valueIndex,
    isLoading,
    AddFilterAlertByLevel,
    RemoveFilterAlertByLevel
}): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <LifespanReportHistoryViewContent data-testid='LifespanReportHistoryContent'>
            {isLoading ? (
                <UiLoadingPage size='30px' />
            ) : (
                <Widget
                    data-testid={'LifespanReportHistoryContent-Widget'}
                    avatar={<UiIcon icon={faClockRotateLeft} />}
                    title={translate('t.history')}
                    loading={false}
                    height={tyreLifeHistory.length === 0 ? undefined : 860}
                    unit={tyreLifeHistory.length === 0 ? undefined : 'px'}
                    headerAction={
                        <AlertsLevelPopUpView
                            AddFilterAlertByLevel={AddFilterAlertByLevel}
                            data-testid='LifespanReportHistoryContent-AlertsLevelPopUpView'
                        />
                    }
                    content={
                        <>
                            <TagFilterContainer>
                                {level?.map((level, index) => (
                                    <TagFilter
                                        key={level + '' + index}
                                        resetAction={() => RemoveFilterAlertByLevel && RemoveFilterAlertByLevel(level)}
                                        chipText={`${translate('t.level')} ${level}`}
                                    />
                                ))}
                            </TagFilterContainer>
                            {tyreLifeHistory.length == 0 ? (
                                <TextNoData>{translate('t.there_no_data')}</TextNoData>
                            ) : (
                                <>
                                    <List onScroll={handleScroll}>
                                        <Timeline
                                            data-testid='LifespanReportHistoryContent-Timeline'
                                            sx={{
                                                [`& .${timelineOppositeContentClasses.root}`]: {
                                                    flex: 0.2
                                                }
                                            }}
                                        >
                                            {tyreLifeHistory.slice(0, valueIndex).map((value, index) => (
                                                <>
                                                    {timeLineCell(
                                                        index,
                                                        value.date,
                                                        value.type,
                                                        !(tyreLifeHistory.length == index + 1),
                                                        value.type,
                                                        value.additionalData
                                                    )}
                                                </>
                                            ))}
                                        </Timeline>
                                    </List>
                                </>
                            )}
                        </>
                    }
                ></Widget>
            )}
        </LifespanReportHistoryViewContent>
    );
};
