import React, { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ExportedReportDetailContent } from './ExportedReportDetail.view';
import { ExportedReportDetailProps } from './ExportedReportDetail.type';
import { ReportModelDetail, ReportQueryKeys } from 'models/Report.type';
import Report from 'api/Report';
import { cacheTimeToMilliseconds } from 'helpers/cache';

const reportApi = new Report();

const ExportedReportDetail: React.FC<ExportedReportDetailProps> = ({ report, reportStatus }): JSX.Element => {
    const queryClient = useQueryClient();

    const { isFetching: ReportIsFetched, data: ReportData } = useQuery<ReportModelDetail, boolean>(
        [ReportQueryKeys.getDetail, report?.reportId, report?.reportType],
        () => reportApi.getDetail(report?.reportId, report?.reportType),
        {
            enabled: report !== undefined,
            staleTime: cacheTimeToMilliseconds(5, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(5, 'minutes'),
            refetchOnWindowFocus: false
        }
    );

    const dateFormat = (date: string): string => {
        return date.replaceAll('%', '').replace('Y', 'YYYY').replace('m', 'MM').replace('d', 'DD');
    };

    const timeFormat = (time: string): string => {
        return time.replaceAll('%', '').replace('H', 'HH').replace('h', 'hh').replace('i', 'mm');
    };

    const temperatureFormat = (temperature: string): string => {
        return temperature.toUpperCase();
    };

    useEffect(() => {
        queryClient.invalidateQueries([ReportQueryKeys.getDetail, report?.reportId, report?.reportType]);
    }, [queryClient, report]);

    return (
        <ExportedReportDetailContent
            data-testid='ExportedReportDetail-testid'
            loading={ReportIsFetched}
            report={report}
            reportDetail={ReportData}
            reportStatus={reportStatus}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            temperatureFormat={temperatureFormat}
        />
    );
};

export default ExportedReportDetail;
