import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { AlertFontSize, CommonTypeTheme } from '../../Ui/Theme';
import { useTheme } from '@mui/material';
import { FONT_BIG_TEXT_SIZE } from 'components/Ui/variables';

export const UsersSuscribedNotificationsContainer = styled.div`
    ${applyStyleByMode({
        styleJade: 'display: inline-flex;'
    })}
    .MuiIconButton-root {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return `font-size: ${common.easyMode ? FONT_BIG_TEXT_SIZE : AlertFontSize} !important;`;
        }}
        font-weith: bolth;
        text-decoration-line: underline;
    }
    svg {
        margin-left: 5px;
        margin-right: 2px;
    }
`;

export const IconButtonContent = styled.div`
    width: 45px;
`;
