import { Paginator } from './Paginator.type';
import { VehicleModel } from './Vehicle.type';
import { TyreNotificationPreviewModel } from './TyreNotification.type';
import { DateTime } from 'luxon';
import { WheelPrediction } from 'components/Tyre/TemperaturePredictionActionMenu/TemperaturePredictionActionMenu.atom';
import { CounterActionType } from './WheelPrediction.type';

export type TyreModel = {
    id: number;
    tyreSerialNumber: string | null;
    comment: string;
    treadDepth: string | null;
    mileage: string | null;
    operatedHours: string | null;
    inspectedAt: string | null;
    customPosition: number;
    vehicleId: number;
    vehicleName: string;
    maxColdPressure: number | null;
    maxTemperature: number | null;
    maxHotPressure: number | null;
    highestBesSeverityRank: number;
    hasWheel: '0' | '1';
    position: number;
};

export type TyreData = {
    data: {
        tyre: TyreModel;
    };
};

export type TyreWithPaginator = {
    items: TyreModel[];
    paginator: Paginator;
};

export type TyreAdd = {
    tyreSerialNumber: string;
    wheel?: number;
    date: DateTime | string;
    comment?: string;
};

export type TyreUpdateComment = {
    tyreId: number;
    updateData: Partial<TyreModel>;
};

export type Wheels = {
    id?: number;
    notWheel?: boolean;
    customPosition: number;
    coldPressure: number | null;
    hotPressure: number | null;
    temperature?: number | null;
    pressureOptimal?: number;
    hotPressureOptimal?: number | null;
    nonTransmitting?: boolean;
    pressureLevel?: number | null;
    pressureHotLevel?: number | null;
    temperatureLevel?: number | null;
    lastTransmission?: Date | null;
    status?: string | null;
    measuredAt?: Date | null;
    wheelId?: number;
    vehicleId?: number;
    tyreId?: number;
    hotPressureOptimalWet?: number;
    defaultPressureOptimal?: number;
    sensorTypeId?: 1 | 2;
    sensorId?: number | null;
    vehicle?: Pick<VehicleModel, 'id' | 'name' | 'icon'>;
    wheelPrediction?: Partial<WheelPrediction>;
    lastTemperaturePredictedAt: null | string;
};

export type TyreTempPressReport = {
    id: number;
    name: string;
    deviceId: number | null;
    lastTransmission: Date | null;
    internalSensorCount: number | null;
    distanceTraveled: number | null;
    maintenance: number;
    lastTemperaturePredictedAt: string | null;
    outOfService: number;
    wheels: Wheels[];
    maxPosition?: number;
    wheelFilterByAlert?: number;
    countAlertLevel?: number;
    countAlertLevelLow?: number;
    tyreTempPressReport?: TyreTempPressReport[];
    vehicleTypeId?: number;
    counterAction: CounterActionType;
    genericTemperatureAlert: boolean;
    predictionTemperature: boolean;
    vehicleModelId: number;
    predictionTemperatureCriticalThreshold: number | null;
    predictionTemperatureThresholdLevel1: number | null;
    predictionTemperatureThresholdLevel2: number | null;
};

export type TyreTempPressReportData = {
    tyreTempPressReport: TyreTempPressReport[];
};

type movement = {
    action: string;
    wheel: Pick<Wheels, 'id' | 'customPosition' | 'vehicle'>;
};

type odometer = number | string;

type notificationTyre = {
    position: number;
    solved: boolean;
    vehicle: Pick<VehicleModel, 'id' | 'name' | 'icon'>;
    notification: Pick<TyreNotificationPreviewModel, 'type' | 'type2' | 'level'>;
};

export type HistoryLog = {
    date: string;
    type: string;
    additionalData: movement | notificationTyre | odometer;
};

export type TyreLifeHistory = {
    tyre: Partial<TyreModel>;
    tyreLifeHistory: HistoryLog[];
};

export type TyreLifeHistoryResponse = {
    tyreLifeHistory: TyreLifeHistory;
};

type TimeObject = {
    days: number;
    diffInSeconds: number;
    hours: number;
    minutes: number;
    months: number;
    seconds: number;
    years: number;
} | null;

export type TyreLifeDetail = {
    tyre: TyreModel;
    averageDistancePerDay: number | string | null;
    averageOperatingHoursPerDay: number | null;
    born: number | string | null;
    bornDisposeDiff: TimeObject;
    disposed: number | string | null;
    drivenDistance: number | string | null;
    operating: Partial<TimeObject>;
};

export type TyreLifeDetailResponse = {
    tyreLifeDetail: TyreLifeDetail;
};

export type MountTyreProps = {
    tyreId: number;
    date: string;
    wheel: number;
};

export type UnmountTyreProps = {
    tyreId: number;
    date: string;
};

export type ReplaceTyreProps = {
    tyreId: number;
    dateMounted: string;
    dateUnmounted: string;
    newId: number;
};

export enum TyreQueryKeys {
    getAll = 'tyres',
    getTable = 'tyre-getTable',
    removeTyre = 'tyre-removeTyre',
    addTyre = 'tyre-addTyre',
    updateTyreComment = 'tyre-updateTyreComment',
    getTempPressReport = 'tyre-getTempPressReport',
    getUniqueSerialNumber = 'tyre-getUniqueSerialNumber',
    mountTyre = 'tyre-mountTyre',
    unmountTyre = 'tyre-unmountTyre',
    replaceTyre = 'tyre-replaceTyre',
    getTyreLifeHistory = 'tyre-getTyreLifeHistory',
    getTyreLifeDetail = 'tyre-getTyreLifeDetail',
    getLastTyreInspected = 'tyre-getLastTyreInspected',
    getLazyDropdown = 'tyre-getLazyDropdown'
}
