import React, { useMemo } from 'react';
import { UiTableRefreshBtnProps } from './UiTableRefreshBtn.type';
import { useRecoilValue } from 'recoil';
import { QueryFn } from '../UiTable/TableAtom';
import { IconsTypography } from '../UiTable/UiTable.style';
import UiButton from '../Components/UiButton/UiButton';
import { RefreshIcon, UiTableRefreshBtnViewContent } from './UiTableRefreshBtn.style';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material';

const UiTableRefreshBtnContent: React.FC<UiTableRefreshBtnProps> = ({ queryKey, onlyIconBtns }): JSX.Element => {
    const queryFn = useRecoilValue(QueryFn(`${queryKey}-QueryFn`));
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const isXlDevice = useMediaQuery(theme.breakpoints.up(1560));

    const refreshBtn = useMemo(
        () => (
            <>
                {isXlDevice && !onlyIconBtns ? (
                    <UiButton
                        testid={`Refresh-${queryKey}Table-Button`}
                        variant='outlined'
                        size='small'
                        onClick={() => {
                            queryFn.refetch && queryFn.refetch instanceof Function && queryFn.refetch();
                        }}
                    >
                        <RefreshIcon />
                        <IconsTypography> {translate('t.refresh')}</IconsTypography>
                    </UiButton>
                ) : (
                    <Tooltip title={translate('t.refresh')} placement='bottom' arrow disableInteractive>
                        <IconButton
                            data-testid={`Refresh-${queryKey}Table-Button`}
                            onClick={() => {
                                queryFn.refetch && queryFn.refetch instanceof Function && queryFn.refetch();
                            }}
                        >
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </>
        ),
        [isXlDevice, onlyIconBtns]
    );
    return <UiTableRefreshBtnViewContent> {refreshBtn}</UiTableRefreshBtnViewContent>;
};

export default UiTableRefreshBtnContent;
