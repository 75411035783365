import React from 'react';
import { MapLegendInfoPopoverViewProps } from './MapLegendInfoPopover.type';
import { MapLegendInfoPopoverStyle, PopoverContent } from './MapLegendInfoPopover.style';
import { Popover } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';

const MapLegendInfoPopoverView: React.FC<MapLegendInfoPopoverViewProps> = ({
    anchorEl,
    open,
    unreadMessage,
    message,
    handleMouseEnter,
    handleMouseLeave
}) => {
    return (
        <MapLegendInfoPopoverStyle>
            <UiIcon
                data-testid='map-total-number'
                icon={['fas', 'circle-exclamation']}
                size='1x'
                beatFade={unreadMessage}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            <Popover
                open={open}
                sx={{ pointerEvents: 'none' }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                onClose={handleMouseLeave}
                disableRestoreFocus
            >
                <PopoverContent>{message}</PopoverContent>
            </Popover>
        </MapLegendInfoPopoverStyle>
    );
};

export default MapLegendInfoPopoverView;
