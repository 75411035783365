import React from 'react';
import { RemoveSensorContainer } from './RemoveSensor.style';
import { InitialValues, RemoveSensorViewProps } from './RemoveSensor.type';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { Grid } from '@mui/material';
import MuiDialogContent from '@mui/material/DialogContent';
import DropDown from '../../Ui/Components/UiDropDown/UiDropDown';
import * as Yup from 'yup';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { VARIANT_BUTTON } from 'components/Ui/variables';
import { ModalActionTypesEnum } from 'states/global/Modal';
import ReportSensor from '../ReportSensor/ReportSensor';
import { UnmountReason } from 'variables';
import { SensorTypes } from 'models/SensorType.type';
import { CancelButton, ContainerActions, CustomDialogActions } from 'components/StatedModal/Modal/Modal.style';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';
import { useRecoilValue } from 'recoil';

export const RemoveSensorContent: React.FC<RemoveSensorViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const queryTableFn = useRecoilValue(QueryFn(`firstSensorPage-QueryFn`));

    const schemaValidateForm = Yup.object({
        unmountReason: Yup.string().trim().required(translate('p.this_field_is_required'))
    });

    return (
        <RemoveSensorContainer data-testid='AddTyreContent'>
            <Formik
                initialValues={props.initialValues}
                enableReinitialize
                validationSchema={schemaValidateForm}
                onSubmit={(values: InitialValues) => {
                    props.handleSubmit(values);
                }}
            >
                {(propsFormik: FormikProps<InitialValues>) => {
                    const { errors, values, setFieldValue } = propsFormik;
                    const showSensorReport = !props.isFaulty && values.unmountReason === UnmountReason.REMOVING_FAULTY;

                    return (
                        <Form id='sensor-unmount-action-form'>
                            <MuiDialogContent>
                                <Grid container justify-content='flex-start' direction='row' spacing={3}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {translate('p.are_you_sure_remove_sensor_wheel')}
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {props.unmountReasonLoading ? (
                                            <UiLoadingPage size='30px' />
                                        ) : (
                                            <DropDown
                                                options={props.unmountReasonList}
                                                inputName='unmount_reason'
                                                label={translate('t.unmount_reason')}
                                                value={/*values.sensorTypeId ? values.sensorTypeId : */ ' '}
                                                error={errors.unmountReason}
                                                disabled={false}
                                                getValue={(e) => {
                                                    setFieldValue('unmountReason', e ?? null);
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    <Grid md={12} marginTop={1}>
                                        {showSensorReport && (
                                            <ReportSensor
                                                vehicleId={props.sensor.wheel.vehicle?.id || 0}
                                                vehicleName={props.sensor.wheel.vehicle?.name || ''}
                                                sensorId={props.sensor.id || 0}
                                                sensorName={props.sensor.serialNumberHex || ''}
                                                showOnlyGraph={true}
                                                wheelPosition={props.sensor.wheel.position}
                                                internalOnVehicle={
                                                    props.sensor.sensorType?.name == SensorTypes.INTERNAL
                                                }
                                                isExternal={props.sensor.sensorType?.name == SensorTypes.EXTERNAL}
                                                allowReportLeakAlert={true}
                                                afterAction={() =>
                                                    queryTableFn.refetch instanceof Function && queryTableFn.refetch()
                                                }
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </MuiDialogContent>
                            <ContainerActions>
                                <CustomDialogActions>
                                    <UiButton
                                        form='sensor-unmount-action-form'
                                        color='primary'
                                        testid='Remove-sensor-button'
                                        variant={VARIANT_BUTTON}
                                        type='submit'
                                        loading={props.removeSensorLoading}
                                        disabled={showSensorReport}
                                    >
                                        {translate('t.remove')}
                                    </UiButton>
                                    <CancelButton
                                        data-testid='Modal-Cancel-Button'
                                        onClick={() => {
                                            props.modalDispatch({
                                                type: ModalActionTypesEnum.CLOSE_MODAL
                                            });
                                        }}
                                        variant='outlined'
                                        color='primary'
                                        size='small'
                                    >
                                        {translate('t.cancel')}
                                    </CancelButton>
                                </CustomDialogActions>
                            </ContainerActions>
                        </Form>
                    );
                }}
            </Formik>
        </RemoveSensorContainer>
    );
};
