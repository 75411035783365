import React from 'react';
import { LinkContainer } from './SensorItem.style';
import { SensorItemProps } from './SensorItem.type';
import { useRecoilValue } from 'recoil';
import { Theme } from 'states/global/Theme';
import { Sensor } from 'models/Sensors.type';
import { ListItem, ListItemText } from '@mui/material';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import { applyStyleByMode } from 'helpers';
import { COLOR_GREY, WHITE } from 'components/Ui/colors';
import { SensorStatusEnum } from 'variables';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { useTranslation } from 'react-i18next';

const checkLowBattery = (sensor: Sensor) => {
    if (sensor.sensorStats.length && sensor.sensorStats[0].battery && sensor.sensorMake?.criticalVoltage) {
        return sensor.sensorStats[0].battery <= sensor.sensorMake?.criticalVoltage;
    }

    return false;
};

const SensorItem: React.FC<SensorItemProps> = ({ sensor, index }): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);
    const showName = sensor.name ? sensor.name : sensor.wheel?.vehicle?.name;
    const sensorStatus = sensor.status ? sensor.status : sensor.sensorInfo?.status;
    const lowBattery = checkLowBattery(sensor);

    return (
        <ListItem
            component='a'
            key={sensor.sensorSerialNumber}
            sx={{ textAlign: 'center' }}
            data-testid={`Popover-Item-${index}`}
        >
            <LinkContainer>
                <UiLink
                    data-testid={`Popover-Link-${index}`}
                    $padding='0'
                    styleCustom={{ width: '100%', display: 'flex' }}
                    content={
                        <>
                            <ListItemText
                                key={sensor.name}
                                primary={`${sensor.serialNumberHex} - ${translate('t.pos')} ${
                                    sensor.wheel?.customPosition
                                } - ${showName}`}
                            />

                            {sensorStatus === SensorStatusEnum.FAULT || sensorStatus === SensorStatusEnum.SUSPICIOUS ? (
                                <UiIcon transform='down-5' icon={['fas', 'exclamation']} />
                            ) : null}

                            {lowBattery ? (
                                <UiIcon
                                    icon={['fas', 'battery-low']}
                                    size='lg'
                                    transform={'down-2.5 right-4'}
                                    fixedWidth
                                    color={applyStyleByMode({
                                        styleOld: '#333',
                                        theme: ThemeMode?.mode,
                                        light: '#0000008a',
                                        dark: WHITE
                                    })}
                                />
                            ) : null}
                        </>
                    }
                    url={`/vehicle-status?vehicle=${sensor.wheel?.vehicle?.id || sensor.id}`}
                    color={applyStyleByMode({
                        theme: ThemeMode?.mode,
                        light: COLOR_GREY,
                        dark: WHITE
                    })}
                />
            </LinkContainer>
        </ListItem>
    );
};

export default SensorItem;
