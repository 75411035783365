import styled from 'styled-components';
import Box from '@mui/material/Box';
import UiButton from '../../Ui/Components/UiButton';
import { applyStyleByMode } from 'helpers';

export const TemperatureExposureTopbarContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 60px;

    ${applyStyleByMode({
        styleJade: `
            && label {
                background: transparent;
            }
        `
    })}
`;

export const Button = styled(UiButton)`
    &.MuiButton-root {
        margin: 0 3px;
    }
`;
