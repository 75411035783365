import React from 'react';
import { AccelerometerLogViewContent } from './AccelerometerLog.style';
import { useMutation } from '@tanstack/react-query';
import { Success } from 'components/Popup';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { faGaugeHigh } from '@fortawesome/pro-solid-svg-icons';
import UiWidget from 'components/Ui/Components/UiWidget';
import { useTranslation } from 'react-i18next';
import { TopBarAtom } from 'components/Mixs/DataExport/TopBar/TopBar.atom';
import { useRecoilValue } from 'recoil';
import AccelerometerLogAPI from 'api/AccelerometerLog';
import DataExport from 'components/Mixs/DataExport';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Wrapper } from 'helpers/wrapper';

const accelerometerLogAPI = new AccelerometerLogAPI();

const AccelerometerLog: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const topBarAtom = useRecoilValue(TopBarAtom);
    const { fromUTCtoUserTimezone, convertType, fromServerToUserUnit } = useConverter();

    const mutation = useMutation<unknown, unknown, void>(
        () =>
            accelerometerLogAPI.getAccelerometerLogReportsCsv(
                topBarAtom.vehicle.id,
                topBarAtom.dateFrom.toUTC().toISO(),
                topBarAtom.dateTo.toUTC().toISO()
            ),
        {
            onSuccess: () => {
                Success({ text: translate('p.report_success_message') });
            }
        }
    );

    const columns = [
        {
            header: translate('t.vehicle_name'),
            accessor: 'vehicle',
            parseData: (props) => props.name
        },
        {
            header: translate('t.measured_at'),
            accessor: 'measuredAt',
            parseData: (props) => fromUTCtoUserTimezone({ date: props, format: 'dateTime' })
        },
        {
            header: `${translate('t.acceleration')} X`,
            accessor: 'accelX',
            parseData: (props) =>
                fromServerToUserUnit({
                    type: convertType.acceleration,
                    value: props,
                    displayUnits: false,
                    fixed: 2
                })
        },
        {
            header: `${translate('t.acceleration')} Y`,
            accessor: 'accelY',
            parseData: (props) =>
                fromServerToUserUnit({
                    type: convertType.acceleration,
                    value: props,
                    displayUnits: false,
                    fixed: 2
                })
        },
        {
            header: `${translate('t.acceleration')} Z`,
            accessor: 'accelZ',
            parseData: (props) =>
                fromServerToUserUnit({
                    type: convertType.acceleration,
                    value: props,
                    displayUnits: false,
                    fixed: 2
                })
        }
    ];

    return (
        <AccelerometerLogViewContent data-testid={'AccelerometerLog-testid'}>
            <UiWidget
                title={translate('t.accelerometer')}
                id='accelerometer-log-widget'
                avatar={<UiIcon icon={faGaugeHigh} />}
                content={
                    <DataExport
                        columns={columns}
                        fetchFn={accelerometerLogAPI.getAccelerometerLogReports}
                        downloadFn={mutation.mutate}
                        queryKey='AccelerometerLogReports'
                        additionalFetchProps={{
                            vehicleId: topBarAtom.vehicle.id,
                            measuredFrom: topBarAtom.dateFrom.toUTC().toISO(),
                            measuredTo: topBarAtom.dateTo.toUTC().toISO()
                        }}
                    />
                }
            />
        </AccelerometerLogViewContent>
    );
};

export default Wrapper(AccelerometerLog);
