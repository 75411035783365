import { SensorStatusEnum } from 'variables';
import { BasicHeatmapResponse } from './TrackLog.type';
import { DateTime } from 'luxon';
import { VehicleModel } from './Vehicle.type';

export type SensorLogModel = {
    coldPressure: number;
    hotPressure: number;
    id: number;
    measuredAt: string;
    temperature: number;
};

export type Scale = {
    tyreScale: {
        minTemperature: number;
        maxTemperature: number;
        minPressure: number;
        maxPressure: number;
    };
};

export type ScaleParams = {
    measuredFrom?: string;
    measuredTo?: string;
};

export type TempPressSensorParam = {
    allDevices: number;
} & ScaleParams;

export type TempPressDataDiff = {
    diffHotPressure: number;
    diffColdPressure: number;
    timeKey: number;
    diffTemperature: number;
};

export type TempPressureDiff = {
    tyreDifference: TempPressDataDiff[];
};

export type TempPressSensorData = {
    batteryVoltage: number | null;
    createdAt: string;
    device: {
        id: number | null;
        readFromDeviceId: number | null;
    };
    measuredAt: string;
    pressure: {
        absolutePressure: number;
        ambientPressure: number;
        coldPressure: number;
        hotPressure: number;
        pressureStatus: boolean;
    };
    rssi: number | null;
    temperature: {
        sensorTemperature: number;
        temperature: number;
        temperatureStatus: boolean;
    };
    timeKey: number;
    vehicle: Pick<VehicleModel, 'id' | 'name'> & {
        readFromVehicleName: string | null;
    };
};

export type TempPressSensor = {
    tempPressSensor: TempPressSensorData[];
};
export type PassingVehiclesResponse = {
    passingVehicles: PassingVehicleModel[];
};

export type PassingVehicleModel = {
    deviceId: number;
    measuredAt: string;
    name: string;
};

type TempPressData = {
    coldPressure: number;
    hotPressure: number;
    temperature: number;
    timeKey: number;
};

type TempPressObj = {
    sensorSerialNumber: string;
    dateTo: number;
    dateFrom: number;
    data: TempPressData[];
};

export type TempPress = {
    tempPressWheel: TempPressObj[];
};

export type SensorLogTypeModel = {};

export type TyreDeviationModel = {
    wheel_id: number;
    id: number;
    sensorId: number;
    position: number;
    tyreSerialNumber: string;
    sensorSerialNumber: number;
    serialNumberHex: string;
    minPressure: number;
    avgPressure: number;
    maxPressure: number;
    minTemperature: number;
    avgTemperature: number;
    maxTemperature: number;
    measuredAt: Date;
    latestPressure: number;
    latestTemperature: number;
    transmissionsCount: number;
};

export type TyreDeviation = {
    deviations: TyreDeviationModel[];
};

type Sensor = {
    status: SensorStatusEnum;
    id: number;
    priority: number;
    sensorType: number;
    serialNumber: number;
    serialNumberHex: string;
    faulty?: unknown;
};

export type Wheel = {
    currentSensor: Sensor[];
    customPosition: number;
    position: number;
    optimalPressure: number;
    overlapping: number | null;
    overlappingSensor: Sensor[] | null;
    sensor: number[];
    sensorCount: number;
    sensorsInPeriod: Sensor[];
    wheelId: number;
};

export type VehicleSensor = {
    vehicleSensor: Wheel[];
};

export type TempPressReportPreview = {
    wheelReportPreview: {
        id: number;
        measuredAt: string;
        vehicleName: string;
        date: string | null;
        time: string | null;
        customPosition: number;
        hotPressure: number;
        coldPressure: number;
        optimalPressure: number;
        temperature: number;
    }[];
};

export type TemperatureResponse = {
    temperatureHeatmap: (BasicHeatmapResponse & { temperature: number })[];
};

//HEATMAP_API_RESPONSE_TYPE_AUTOMATIC_GENERATOR
export type SensorAroundProps = {
    additionalProps: {
        deviceId: number;
        measuredFrom?: string;
        measuredTo?: string;
    };
};

export type SensorAroundParams = {
    measuredFrom?: string;
    measuredTo?: string;
};

export type SensorAroundType = {
    batteryVoltage: string;
    lastTransmission: string;
    count: string;
    rssi: string;
    sensorSerialNumber: string;
    serialNumberHex: string;
    vehicleId: string | null;
    vehicleName: string | null;
    wheelId: number | null;
    ownSensor: 0 | 1;
};

export type SensorsAroundType = {
    sensors: SensorAroundType[];
};

export type TemperatureExposureBySensor = {
    sensorId: number;
    dateFrom: DateTime;
    dateTo: DateTime;
};

export type TemperatureExposureByTyre = Omit<TemperatureExposureBySensor, 'sensorId'> & {
    tyreId: number;
};

export type TemperatureExposureByWheel = Omit<TemperatureExposureBySensor, 'sensorId'> & {
    wheelId: number;
};

export type TemperatureExposureBySensorResponse = {
    temperatureCategories: {
        [key: number]: {
            value: number;
            label: string;
        };
    };
    temperatureExposureReport: {
        day: string;
        temperatureCategory: string;
        measurements: number;
    }[];
};

export enum SensorLogQueryKeys {
    getLazyDropdown = 'sensorLog-dropdown',
    getTransmissionCSV = 'sensorLog-getTransmissionCSV',
    getVehiclePassing = 'sensorLog-getVehiclePassing',
    getTemperatureExposureByTyre = 'sensorLog-getTemperatureExposureByTyre',
    getTemperatureExposureByWheel = 'sensorLog-getTemperatureExposureByWheel',
    getTemperatureExposureBySensor = 'sensorLog-getTemperatureExposureBySensor',
    getTyreDeviationByVehicleId = 'sensorLog-getTyreDeviationByVehicleId'
}
