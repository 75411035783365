import React, { useEffect, useState } from 'react';
import * as view from './UiSimpleTable.view';
import { Order, UiSimpleTableProps } from './UiSimpleTable.type';
import { useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import { RefreshSimpleTable } from './UiSimpleTable.atom';
import { Loading } from './UiSimpleTable.style';
import UiLoadingPage from '../UiLoadingPage/UiLoadingPage';
import { Wrapper } from 'helpers/wrapper';

// UiSimple table uses FE pagination, not BE
const UiSimpleTable: React.FC<UiSimpleTableProps> = (props): JSX.Element => {
    const [refreshTableAtom, setRefreshTableAtom] = useRecoilState(RefreshSimpleTable);
    const [key, setKey] = useState<string | null>(null);
    const [order, setOrder] = useState<Order>(props.defaultSorting ? props.defaultSorting.order : 'asc');
    const [orderBy, setOrderBy] = useState<string>(props.defaultSorting ? props.defaultSorting.orderBy : 'id');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(props.customRowsPerPage || 25);

    const { isLoading, data, isFetching } = useQuery(
        [`${props.queryKey}-SimpleTableQuery`, refreshTableAtom],
        () =>
            props.fetchFn({
                additionalProps: props.additionalFetchProps
            }),
        {
            refetchInterval: props.refreshInterval,
            retry: false,
            keepPreviousData: true,
            enabled: !refreshTableAtom,
            cacheTime: 0,
            staleTime: 0
        }
    );

    useEffect(() => {
        if (refreshTableAtom) {
            setRefreshTableAtom(false);
        }
    }, [refreshTableAtom]);

    useEffect(() => {
        if (data) {
            if ((data as unknown[]).length === 0) {
                setKey('1');
            } else {
                setKey(Object.keys(data)[0]);
            }
        }
    }, [data]);

    const handleSort = (property: string): void => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    if (isLoading || !data || !key) {
        return (
            <Loading>
                <UiLoadingPage size='30px' />
            </Loading>
        );
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <view.UiSimpleTableContent
            {...props}
            data={key === '1' || key === '0' ? data : data[key]}
            data-testid={'UiSimpleTable-testid'}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            handleSort={handleSort}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isLoading={isFetching || isLoading}
        />
    );
};

export default Wrapper(UiSimpleTable);
