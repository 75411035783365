import { atom } from 'recoil';
import { CRUD } from 'variables';
import { ShiftTimeNameActionsType } from './TeamShiftNameActions.type';

export const ShiftTimeNameActionsState = atom<ShiftTimeNameActionsType>({
    key: 'ShiftTimeNameActionsState',
    default: {
        action: CRUD.EMPTY,
        id: '',
        name: ''
    }
});
