import React from 'react';
import { SIZE_BUTTON, SIZE_BUTTON_BIG_FONT } from '../../variables';
import * as type from './UiIconButton.type';
import { Theme } from 'states/global/Theme';
import { useRecoilValue } from 'recoil';
import { IconButton } from '@mui/material';

const UiIconButton: React.FC<type.UiPassProps> = ({ testid, children, ...props }): JSX.Element => {
    const theme = useRecoilValue(Theme);
    return (
        <IconButton
            {...props}
            size={theme.easyReadingMode ? props.easyReadingValue ?? SIZE_BUTTON_BIG_FONT : props.size ?? SIZE_BUTTON}
            data-testid={`${testid ? testid : 'UiIconButton'}`}
        >
            {children}
        </IconButton>
    );
};

export default UiIconButton;
