import React, { useMemo } from 'react';
import { ColumnIcon, UiTableHideColumnsViewContent } from './UiTableHideColumns.style';
import { UiTableHideColumnsProps } from './UiTableHideColumns.type';
import Legend from '../Components/UiLegend/UiLegend';
import UiTableHideColumnsContentView from '../UiTableHideColumnsContent/UiTableHideColumnsContent';
import { IconsTypography } from '../UiTable/UiTable.style';
import UiButton from '../Components/UiButton/UiButton';
import { useTranslation } from 'react-i18next';
import useUserLocalStorageSettings, {
    useUserLocalStorageSettingsTable
} from 'components/CustomHooks/UserLocalStorageSettings';
import { useRecoilValue } from 'recoil';
import { TableFn } from '../UiTable/TableAtom';
import { IconButton, useMediaQuery, useTheme, Tooltip } from '@mui/material';

const UiTableHideColumnsContent: React.FC<UiTableHideColumnsProps> = ({ queryKey, onlyIconBtns }): JSX.Element => {
    const tableFn = useRecoilValue(TableFn(`${queryKey}-TableFn`));
    const localStorageTableKey = useMemo(() => `table.${queryKey}Table`, [queryKey]);
    const { setUserSettings } = useUserLocalStorageSettings([localStorageTableKey]);
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const isXlDevice = useMediaQuery(theme.breakpoints.up(1560));

    return (
        <UiTableHideColumnsViewContent data-testid={`HideColumnsContent-${queryKey}Table`}>
            <Legend
                data-testid={`HideColumnsContent-${queryKey}Table-Legend`}
                root={
                    isXlDevice && !onlyIconBtns ? (
                        <UiButton variant='outlined' size='small' testid={`HideColumns-show-button`}>
                            <ColumnIcon />
                            <IconsTypography> {translate('t.columns')}</IconsTypography>
                        </UiButton>
                    ) : (
                        <Tooltip title={translate('t.columns')} placement='bottom' arrow disableInteractive>
                            <IconButton data-testid={`HideColumns-show-button`}>
                                <ColumnIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }
                icon={<ColumnIcon />}
                width={400}
                headerElements={
                    <>
                        <UiButton
                            onClick={() => {
                                tableFn.resetHiddenColumns instanceof Function && tableFn.resetHiddenColumns();

                                setUserSettings(localStorageTableKey, {
                                    ...getUserSettingTable(localStorageTableKey),
                                    userHiddenColumns: []
                                });
                            }}
                            testid={`HideColumns-${queryKey}Table-Reset-Button`}
                            title={translate('p.reset_column_tooltip')}
                        >
                            {translate('t.reset')}
                        </UiButton>
                    </>
                }
            >
                <UiTableHideColumnsContentView queryKey={queryKey} />
            </Legend>
        </UiTableHideColumnsViewContent>
    );
};

export default UiTableHideColumnsContent;
