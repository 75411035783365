import { Paginator } from 'helpers/api/type';

export type ExecutableCommandsResponse = {
    deviceCommand: ExecutableCommands[];
};

export type ExecutableCommands = {
    command: number;
    name: string;
    hasValue: boolean;
    value:
        | {
              shell: number;
              value: number;
          }
        | number;
};

export type ExecutedDeviceCommandsTable = Paginator<ExecutedDeviceCommands>;

export type ExecutedDeviceCommands = {
    id: number;
    command: number;
    commandType: number;
    createdAt: string;
    name: string;
    content: string;
    result: string;
    submittedAt: string;
    executedAt: string;
    executed: true;
};

export type DeviceCommandModel = {
    command: number;
    name: string;
    hasValue: boolean;
    value?: ValueModel;
};

export type ValueModel = {
    shell: number;
    value?: number;
};

export type DeviceCommandList = {
    deviceCommand: DeviceCommandModel[];
};

export type DeviceCommandPending = {
    isPending: boolean;
};

export type DeviceCommandListWithPaginator = {
    items: DeviceCommandModel[];
    paginator?: Paginator<DeviceCommandModel>;
};

export enum DeviceCommandQueryKeys {
    getCommandPending = 'deviceCommand-getCommandPending',
    getRecentSplitHistoryDeviceCommand = 'deviceCommand-getRecentSplitHistoryDeviceCommand',
    getAvailableDeviceCommand = 'deviceCommand-getAvailableDeviceCommand',
    getLastDeviceCommand = 'deviceCommand-getLastDeviceCommand'
}
