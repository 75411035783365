import { TimezoneModel } from './TimeZone.type';
import { ROLES } from 'variables';
import { Paginator } from './Paginator.type';
import { CustomerExtendedModel, CustomerAccess } from './Customer.type';
import {
    AccelerationUnits,
    AltitudeUnits,
    DistanceUnits,
    PressureUnits,
    SpeedUnits,
    TemperatureUnits
} from '../components/CustomHooks/Converter/Converter.type';

export type UserData = {
    user: UserModel;
};

export type UserDataResponse = {
    data: { user: UserModel };
};

export type UserModel = {
    createdAt: string | null;
    customer: CustomerExtendedModel;
    email: string | null;
    firstname: string;
    id: number;
    lastname: string;
    phoneNumber: string | null;
    locked: boolean;
    enabled: boolean;
    removed: boolean;
    role: Role;
    roles: Array<string> | null; // obsolete
    stockRole: StockRole;
    userPermissions: Array<string>;
    userLocationAccess: Array<object>;
    userCustomerAccess: CustomerAccess[];
    userSetting: UserSetting;
    username: string;
    lastLogin: string | null;
    customerToken: string;
    internal: boolean;
    data?: { user: UserModel };
    version?: number;
    passwordExpireDate: string | null;
};

export type UserWithPaginator = {
    items: UserModel[];
    paginator: Paginator;
};

export type UserSetAccount = {
    userId: number;
    updateData: UserAccount;
};

export type AccountModelResponse = {
    user: UserModel;
};

export type PatchVehicleModel = Partial<UserModel>;

export type PatchCustomerUserResponse = {
    user: Pick<UserModel, 'customer' | 'customerToken' | 'id'>;
};

export type Role = {
    id: number;
    name: ROLES;
};

export type StockRole = {
    createdAt: string;
    id: number;
    name: string;
    updatedAt: string | null;
};

export type UserSetting = {
    accelerationUnit: AccelerationUnits;
    adminTableRows: number;
    alertReminderLevel1: number | null;
    alertReminderLevel2: number | null;
    alertReminderLevel3: number | null;
    alertsBreakdownPeriod: number;
    alertsByVehiclePeriod: number;
    allHubsChecked: boolean;
    altitudeUnit: AltitudeUnits;
    coldPressure: number; // should be boolean
    showColdPressureForExternalSensors: boolean;
    datatablesLengthDesktop: boolean;
    datatablesLengthPhone: boolean;
    datatablesLengthTablet: boolean;
    dateFormat: string;
    desktopNotification: boolean;
    distanceUnit: DistanceUnits;
    id: number;
    language: string;
    layoutGridDesktop: string | null;
    layoutGridPhone: null | string;
    layoutGridTablet: null | boolean | string;
    layoutStyleDesktop: null | boolean | string;
    layoutStylePhone: null | boolean | string;
    layoutStyleTablet: null | boolean | string;
    layoutWidgetDesktop: null | string;
    layoutWidgetPhone: null | boolean | string;
    layoutWidgetTablet: null | boolean | string;
    mapCenter: null | boolean | string;
    mapJourneyReport: null | number;
    mapType: null | string;
    mapVehicleLabel: null | number;
    mapZoom: null | number | string;
    notificationPeriod: null | number;
    pressureUnit: PressureUnits;
    selectedCommandType: null | number | string;
    selectedMonitorDb: null | number | string;
    showOutOfService: boolean;
    speedUnit: SpeedUnits;
    statisticsValue: string;
    systemTotalsStage: number;
    targetPressure: boolean;
    temperatureUnit: TemperatureUnits;
    theme: null | string;
    muteNotification: boolean;
    showTemperatureForExternalSensors: boolean;
    showColdPressureInTyreSummary: boolean;
    timeFormat: string;
    timeOutPeriod: number;
    timezone: TimezoneModel;
    customerTimezone?: TimezoneModel;
    timezoneOffset: null | string;
    trackingColor: string;
    useMineTimezone: boolean;
    useZoomInStatistics: boolean;
    vehicleIconsOnMap: number;
    vehicleStatsPeriod: number;
    vehicleStatusSorting: string;
};

export type UserAlerts = {
    alertReminderLevel1: string | number | null;
    alertReminderLevel2: string | number | null;
    alertReminderLevel3: string | number | null;
    notificationPeriod: string | number | null;
    desktopNotification?: boolean | undefined;
    showOutOfService?: boolean | undefined;
};

export type UserAlertsData = {
    userSetting: UserSetting;
};

export type UserTracking = {
    mapType: string | null;
    mapVehicleLabel: number | null;
    vehicleIconsOnMap?: number | undefined;
    mapJourneyReport?: number | null | undefined;
    trackingColor?: string | undefined;
    useZoomInStatistics?: boolean | undefined;
};

export type UserUnits = {
    pressureUnit: string | null;
    temperatureUnit: string | null;
    speedUnit: string;
    accelerationUnit: string;
    altitudeUnit: string;
    targetPressure?: boolean;
    coldPressure?: number;
    statisticsValue?: string;
};

export type UserGeneralSettings = {
    language: string;
    dateFormat: string;
    timeFormat: string;
    timeOutPeriod?: string | number | undefined;
    timezone?: string | number | undefined;
    useMineTimezone?: boolean | undefined;
};

export type UserAccount = {
    username?: string;
    email?: string;
    role?: string | number;
    phoneNumber?: string;
    firstname?: string;
    lastname?: string;
    avatar?: string | ArrayBuffer;
    locked?: number;
    enabled?: number;
};

export type UserAdd = {
    username: string;
    password: string;
    firstname: string;
    lastname: string;
    phoneNumber: string;
    email: string;
    role: number;
    resetPassword: number;
    userSetting: {
        statisticsValue: string;
        mapType: string;
        language: string;
        timezone: number;
        useMineTimezone?: boolean;
    };
};

export type UserAvatar = {
    userId: number;
    image: string | ArrayBuffer | undefined;
};

export type UserLenguage = {
    language: string;
};

export enum UserQueryKeys {
    getLazyDropdown = 'user-dropdown',
    getNotification = 'user-getNotification',
    getUserById = 'user-getUserById',
    getAvatar = 'user-getAvatar'
}
