import { HeaderProps, Renderer } from 'react-table';
import { RecoilState, SetterOrUpdater, atom } from 'recoil';
import { columnVisibilityType } from '../UiTableInstance/UiTableInstance.type';
import { FilterProps, SortByProps } from './UiTable.type';

let tableAtoms = {};
let queryFnAtoms = {};
let tableFnAtoms = {};
let tableFiltersAtoms = {};
let columnNameIdsAtoms = {};
let columnVisibilityAtoms = {};
let selectRowAtoms = {};
let tableHiddenColumnsAtoms = {};

export type TableInitStateProps = {
    queryPageIndex: number;
    queryPageSize: number;
    totalCount: number;
    queryPageFilter: FilterProps[] | [];
    queryPageSortBy: SortByProps[] | [];
    tableKey: string;
};

export const TableAtom = (key?: string): RecoilState<TableInitStateProps> => {
    if (key && !tableAtoms[key]) {
        tableAtoms[key] = atom<TableInitStateProps>({
            key: key,
            default: {
                queryPageIndex: 0,
                queryPageSize: 10,
                totalCount: 0,
                queryPageSortBy: [],
                queryPageFilter: [],
                tableKey: ''
                // ready: false
            }
        });
    } else if (!key && !tableAtoms['Table']) {
        tableAtoms['Table'] = atom<TableInitStateProps>({
            key: 'Table',
            default: {
                queryPageIndex: 0,
                queryPageSize: 10,
                totalCount: 0,
                queryPageSortBy: [],
                queryPageFilter: [],
                tableKey: ''
                // ready: false
            }
        });
    }

    return tableAtoms[key ?? 'Table'];
};

export type TableFiltersType = {
    [key: string]: {
        value: string | number;
        humanValue: string;
    };
};

export const TableFilters = (key?: string): RecoilState<TableFiltersType> => {
    if (key && !tableFiltersAtoms[key]) {
        tableFiltersAtoms[key] = atom<TableFiltersType>({
            key: key,
            default: {}
        });
    } else if (!key && !tableFiltersAtoms['TableFilters']) {
        tableFiltersAtoms['TableFilters'] = atom<TableFiltersType>({
            key: 'TableFilters',
            default: {}
        });
    }

    return tableFiltersAtoms[key ?? 'TableFilters'];
};

export type ColumnNameIdType = {
    id: string;
    name?: Renderer<HeaderProps<object>>;
};

export const ColumnNamesIds = (key?: string): RecoilState<ColumnNameIdType[]> => {
    if (key && !columnNameIdsAtoms[key]) {
        columnNameIdsAtoms[key] = atom<ColumnNameIdType[]>({
            key: key,
            default: []
        });
    } else if (!key && !columnNameIdsAtoms['ColumnNamesIds']) {
        columnNameIdsAtoms['ColumnNamesIds'] = atom<ColumnNameIdType[]>({
            key: 'ColumnNamesIds',
            default: []
        });
    }

    return columnNameIdsAtoms[key ?? 'ColumnNamesIds'];
};

// could be implemeted later to replace localStorage hiddenColumns
export const HiddenColumnsIds = (key?: string): RecoilState<string[]> => {
    if (key && !tableHiddenColumnsAtoms[key]) {
        tableHiddenColumnsAtoms[key] = atom<string[]>({
            key: key,
            default: []
        });
    } else if (!key && !tableHiddenColumnsAtoms['HiddenColumnsIds']) {
        tableHiddenColumnsAtoms['HiddenColumnsIds'] = atom<string[]>({
            key: 'HiddenColumnsIds',
            default: []
        });
    }

    return tableHiddenColumnsAtoms[key ?? 'Table'];
};

export const ColumnVisibility = (key?: string): RecoilState<columnVisibilityType> => {
    if (key && !columnVisibilityAtoms[key]) {
        columnVisibilityAtoms[key] = atom<columnVisibilityType>({
            key: key,
            default: {}
        });
    } else if (!key && !columnVisibilityAtoms['ColumnVisibility']) {
        columnVisibilityAtoms['ColumnVisibility'] = atom<columnVisibilityType>({
            key: 'ColumnVisibility',
            default: {}
        });
    }

    return columnVisibilityAtoms[key ?? 'ColumnVisibility'];
};

type TableFnType = Record<string, unknown>;

export const TableFn = (key?: string): RecoilState<TableFnType> => {
    if (key && !tableFnAtoms[key]) {
        tableFnAtoms[key] = atom<TableFnType>({
            key: key,
            default: {}
        });
    } else if (!key && !tableFnAtoms['TableFn']) {
        tableFnAtoms['TableFn'] = atom<TableFnType>({
            key: 'TableFn',
            default: {}
        });
    }

    return tableFnAtoms[key ?? 'TableFn'];
};

export const QueryFn = (key?: string): RecoilState<TableFnType> => {
    if (key && !queryFnAtoms[key]) {
        queryFnAtoms[key] = atom<TableFnType>({
            key: key,
            default: {}
        });
    } else if (!key && !queryFnAtoms['QueryFn']) {
        queryFnAtoms['QueryFn'] = atom<TableFnType>({
            key: 'QueryFn',
            default: {}
        });
    }

    return queryFnAtoms[key ?? 'QueryFn'];
};

export const SelectAll = atom<boolean>({
    key: 'SelectAll',
    default: false
});

export enum SelectedRowEnum {
    SET_SELECT_ALL = 'SET_SELECT_ALL',
    SET_SELECT = 'SET_SELECT'
}

type toggleSelectRow = {
    type: SelectedRowEnum.SET_SELECT_ALL;
    payload: boolean;
};

type selectRow = {
    type: SelectedRowEnum.SET_SELECT;
    payload: {
        rowData: Record<string, unknown>;
        added: boolean;
    };
};

type SelectedRowAction = toggleSelectRow | selectRow;

type SelectedRowProps = {
    selectedAll: boolean;
    selectedRows: Record<string, unknown>[];
};

export const SelectRow = (key?: string): RecoilState<SelectedRowProps> => {
    if (key && !selectRowAtoms[key]) {
        selectRowAtoms[key] = atom<SelectedRowProps>({
            key: key,
            default: {
                selectedAll: false,
                selectedRows: []
            }
        });
    } else if (!key && !selectRowAtoms['SelectRow']) {
        selectRowAtoms['SelectRow'] = atom<SelectedRowProps>({
            key: 'SelectRow',
            default: {
                selectedAll: false,
                selectedRows: []
            }
        });
    }

    return selectRowAtoms[key ?? 'SelectRow'];
};

export const ShowScroll = atom<boolean>({
    key: 'ShowScrollAtom',
    default: true
});

export const FilterBtnStates = atom<{ anchorEl: HTMLButtonElement | null; open: boolean }>({
    key: 'FilterBtnStatesAtom',
    default: { anchorEl: null, open: false }
});

export const SelectedRowDispatcher =
    (previousState: SelectedRowProps, setState: SetterOrUpdater<SelectedRowProps>) => (action: SelectedRowAction) => {
        switch (action.type) {
            case SelectedRowEnum.SET_SELECT_ALL:
                return setState({
                    ...previousState,
                    selectedRows: [],
                    selectedAll: action.payload
                });
            case SelectedRowEnum.SET_SELECT: {
                let copySelectedRows = [...previousState.selectedRows];
                if (action.payload.added) {
                    copySelectedRows.push(action.payload.rowData);
                } else {
                    copySelectedRows = copySelectedRows.filter((row) => row.id !== action.payload.rowData.id);
                }
                return setState({
                    ...previousState,
                    selectedRows: copySelectedRows
                });
            }
            default:
                return setState(previousState);
        }
    };
