import React from 'react';
import { useRecoilValue } from 'recoil';
import { ContainerExtraFilter, TyreTableViewContent } from './TyreTable.style';
import { TyreTableViewProps } from './TyreTable.type';
import { useTranslation } from 'react-i18next';
import Button from 'components/Ui/Components/UiButton/UiButton';
import Widget from 'components/Ui/Components/UiWidget';
import UiTable from 'components/Ui/UiTable/UiTable';
import Tyre from 'api/Tyre';
import { checkRole } from 'helpers/authentication';
import { UserInfo } from 'states/global/User';
import { ROLES } from 'variables';
import { applyStyleByMode } from 'helpers';
import { SIZE_BUTTON, VARIANT_BUTTON } from 'components/Ui/variables';
import { CustomerSettings } from 'states/global/CustomerSettings';
import ExtraFilter from 'components/Ui/UiTableExtraFilter';
import AddIcon from '@mui/icons-material/Add';
import { faTire } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import { TyreQueryKeys } from 'models/Tyre.type';

const tyreApi = new Tyre();

export const TyreTableContent: React.FC<TyreTableViewProps> = ({ columns, handleModalAdd }): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const customerSettings = useRecoilValue(CustomerSettings);
    const ThemeMode = useRecoilValue(Theme);

    return (
        <TyreTableViewContent data-testid='TyreTableContent'>
            <Widget
                title={translate('t.tyres')}
                loading={false}
                avatar={
                    <UiIcon
                        icon={faTire}
                        size='lg'
                        fixedWidth
                        testid='TyresTable-headerWidget-icon'
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            dark: WHITE
                        })}
                    />
                }
                headerAction={
                    <ContainerExtraFilter>
                        <ExtraFilter
                            color='primary'
                            tableKey='tyres'
                            extraFilter={{
                                id: 'hasWheel',
                                value: {
                                    name: translate('t.assigned_to_wheels'),
                                    value: '1',
                                    humanValue: 'True'
                                }
                            }}
                        />
                    </ContainerExtraFilter>
                }
                content={
                    <>
                        <UiTable
                            columns={columns}
                            fetchFn={tyreApi.getAll}
                            exportFn={tyreApi.getExport}
                            exportName={'Tyres'}
                            defaultSortBy={{
                                id: 'tyreSerialNumber',
                                desc: false
                            }}
                            defaultFilter={{
                                id: 'hasWheel',
                                value: {
                                    name: translate('t.assigned_to_wheels'),
                                    value: '1',
                                    humanValue: 'True'
                                }
                            }}
                            rightActionBtns={() => (
                                <>
                                    {!checkRole(userInfo.user, [
                                        ROLES.ROLE_ENGINEERING,
                                        ROLES.ROLE_BASE_USER,
                                        ROLES.ROLE_TECHNICAL_USER
                                    ]) && (
                                        <Button
                                            onClick={() => handleModalAdd()}
                                            size={SIZE_BUTTON}
                                            skin='success'
                                            variant={VARIANT_BUTTON}
                                            startIcon={<AddIcon />}
                                            testid='Add-Tyre-Button'
                                        >
                                            {translate('t.add_tyre')}
                                        </Button>
                                    )}
                                </>
                            )}
                            queryKey={TyreQueryKeys.getAll}
                            hiddenColumns={
                                customerSettings.mdd_bes ? ['hasWheel'] : ['hasWheel', 'highestBesSeverityRank']
                            }
                        />
                    </>
                }
            />
        </TyreTableViewContent>
    );
};
