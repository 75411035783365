import React, { useMemo } from 'react';
import { ShiftTimeNameTableContent } from './ShiftTimeNameTable.view';
import { ShiftTimeNameTableProps } from './ShiftTimeNameTable.type';
import { Wrapper } from 'helpers/wrapper';
import { useTranslation } from 'react-i18next';
import UiMenu from 'components/Ui/Components/UiMenu/UiMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { useSetRecoilState } from 'recoil';
import { ShowTeamShiftNameActionsModal } from 'states/global/Modal';
import { ShiftTimeNameActionsState } from '../TeamShiftNameActions/TeamShiftNameActions.atom';
import { CRUD } from 'variables';

const ShiftTimeNameTable: React.FC<ShiftTimeNameTableProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const openShowTeamShiftNameActionsModal = useSetRecoilState(ShowTeamShiftNameActionsModal);
    const shiftTimeNameActionsState = useSetRecoilState(ShiftTimeNameActionsState);

    const columns = useMemo(
        () => [
            {
                Header: translate('t.team'),
                accessor: 'name',
                width: 100,
                height: 20,
                disableFilters: true,
                disableSortBy: true,
                Cell: (props) => props.value
            },
            {
                Header: translate('t.actions'),
                accessor: 'id',
                width: 50,
                height: 20,
                disableFilters: true,
                disableSortBy: true,
                Cell: (props) => (
                    <UiMenu
                        data-testid='ShiftTimeNameTable-menu-actions'
                        items={[
                            {
                                title: translate('t.edit'),
                                value: props.value,
                                action: () => {
                                    shiftTimeNameActionsState({
                                        action: CRUD.EDIT,
                                        id: props.value,
                                        name: props.row.original.name
                                    });
                                    openShowTeamShiftNameActionsModal(true);
                                }
                            },
                            {
                                title: translate('t.remove'),
                                value: props.value,
                                action: () => {
                                    shiftTimeNameActionsState({
                                        action: CRUD.REMOVE,
                                        id: props.value,
                                        name: props.row.original.name
                                    });
                                    openShowTeamShiftNameActionsModal(true);
                                }
                            }
                        ]}
                        rootEl={
                            <UiButton
                                data-testid='ShiftTimeNameTable-button-actions'
                                aria-controls='simple-menu'
                                aria-haspopup='true'
                                style={{ minWidth: '20px', maxWidth: '20px' }}
                            >
                                <SettingsIcon />
                            </UiButton>
                        }
                    />
                )
            }
        ],
        [translate]
    );

    return <ShiftTimeNameTableContent data-testid='ShiftTimeName-testid' columns={columns} />;
};

export default Wrapper(ShiftTimeNameTable);
