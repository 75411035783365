import React from 'react';
import { DeviceDetailsTableContent } from './DeviceDetailsTable.view';
import { DeviceDetailsTableProps } from './DeviceDetailsTable.type';
import { useQuery } from '@tanstack/react-query';
import { Wrapper } from 'helpers/wrapper';
import Device from 'api/Device';
import { useSetRecoilState } from 'recoil';
import { ActiveDeviceDetailsTab } from './DeviceDetailsTable.state';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { DeviceQueryKeys } from 'models/Device.type';
import { DeviceAppRegionAtom } from 'components/Hub/DeviceCommandsTable/atom';

const deviceApi = new Device();

const DeviceDetailsTable: React.FC<DeviceDetailsTableProps> = (props): JSX.Element => {
    const setActiveTab = useSetRecoilState(ActiveDeviceDetailsTab);
    const setDeviceAppRegion = useSetRecoilState(DeviceAppRegionAtom);

    const { data, isLoading, isRefetching } = useQuery(
        [DeviceQueryKeys.getDetails, props.deviceId],
        () => deviceApi.getDetails(props.deviceId),
        {
            retry: false,
            staleTime: cacheTimeToMilliseconds(15, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(15, 'minutes'),
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            onSuccess: (data1) => setDeviceAppRegion(data1.device.appRegion.name)
        }
    );

    const handleTabChange = (event, newTabIndex: number): void => {
        setActiveTab({ details: newTabIndex });
    };

    return (
        <DeviceDetailsTableContent
            data-testid='DeviceDetailsTable-testid'
            handleTabChange={handleTabChange}
            deviceId={props.deviceId}
            data={data}
            isLoading={isLoading || isRefetching}
        />
    );
};

export default Wrapper(DeviceDetailsTable);
