import React from 'react';
import { TeamShiftTimeActionsContainer } from './TeamShiftTimeActions.style';
import { TeamShiftTimeActionsViewProps } from './TeamShiftTimeActions.type';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { TeamShiftTimeForm } from './TeamShiftTimeForm';
import Modal from 'components/Modal/Modal';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { ShowTeamShiftTimeActionsModal } from 'states/global/Modal';
import { CRUD } from 'variables';
import { TeamShiftTimeActionsState } from './TeamShiftTimeActions.atom';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { Box } from '@mui/material';

export const TeamShiftTimeActionsContent: React.FC<TeamShiftTimeActionsViewProps> = ({
    addTeamShiftTimeLoading,
    handleSubmit,
    initialValues,
    teamList
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const teamShiftTimeActionsState = useRecoilValue(TeamShiftTimeActionsState);
    const resetTeamShiftTimeActionsState = useResetRecoilState(TeamShiftTimeActionsState);
    const closeAnnouncementsActionModal = useResetRecoilState(ShowTeamShiftTimeActionsModal);

    const button = (): JSX.Element => {
        const textButton: string = teamShiftTimeActionsState.action === CRUD.EDIT ? 'save' : 'add';

        return addTeamShiftTimeLoading ? (
            <Box sx={{ marginTop: '-20px' }}>
                <UiLoadingPage size='25px' />
            </Box>
        ) : (
            <UiButton variant='contained' form='teamShiftTime-form' type='submit' color='primary' skin='success'>
                {translate(`t.${textButton}`)}
            </UiButton>
        );
    };

    return (
        <TeamShiftTimeActionsContainer data-testid='teamShiftTimeActionsContent'>
            <Modal
                testid='TeamShiftTimeActions-actions-modal'
                type='ShowTeamShiftTimeActionsModal'
                leftTitle={`${translate(`t.${teamShiftTimeActionsState.action}`)} ${translate(`t.team_shift_time`)}`}
                height={270}
                content={
                    <TeamShiftTimeForm initialValues={initialValues} handleSubmit={handleSubmit} teamList={teamList} />
                }
                onClose={() => {
                    closeAnnouncementsActionModal();
                    resetTeamShiftTimeActionsState();
                }}
                buttons={button()}
                width={390}
                id='teamShiftTimeActions-actions-modal'
            />
        </TeamShiftTimeActionsContainer>
    );
};
