import React, { useEffect, useRef, useState } from 'react';
import { DashboardMapProps } from './DashboardMap.type';
import { DashboardMapContainer } from './DashboardMap.style';
import { DashboardMapContent as DashboardMapView } from './DashboardMap.view';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { MapAction } from 'states/global/Map';
import { MapActionsEnum } from 'components/Map/Map.type';
import { Wrapper } from 'helpers/wrapper';
import VehicleApi from 'api/Vehicle';
import { SelectedVehicleIds } from 'states/global/TrackingMap';
import { useQuery } from '@tanstack/react-query';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { VehicleQueryKeys } from 'models/Vehicle.type';

const Vehicle = new VehicleApi();

const DashboardMapContent: React.FC<DashboardMapProps> = (props): JSX.Element => {
    const setMapAction = useSetRecoilState(MapAction);

    const vehicleIntervalRef: { current: NodeJS.Timeout | null } = useRef(null);
    const [selectedVehicleIds, setSelectedVehicleIds] = useRecoilState(SelectedVehicleIds);
    const [redrawAllVehicles, setRedrawAllVehicles] = useState<boolean>(false);

    const { refetch: vehiclePosition } = useQuery([VehicleQueryKeys.getLastPosition], () => Vehicle.getLastPosition(), {
        enabled: false,
        staleTime: 0,
        cacheTime: 0,
        onSuccess: (dataOnSuccess) =>
            setMapAction({
                action: MapActionsEnum.DRAW_VEHICLES_POSITION,
                params: {
                    vehicles: dataOnSuccess,
                    redrawAllVehicles
                }
            })
    });

    const loadVehiclesPosition = (redrawAllVehicles: boolean): void => {
        setRedrawAllVehicles(redrawAllVehicles);
        vehiclePosition();
    };

    const loadVehiclesPeriodicaly = (): void => {
        loadVehiclesPosition(true);
        const interval = setInterval(() => {
            !document.hidden && loadVehiclesPosition(false);
        }, 15000);
        vehicleIntervalRef.current = interval;
    };

    useEffect(() => {
        if (selectedVehicleIds.length > 0) {
            clearInterval(vehicleIntervalRef.current as NodeJS.Timeout);
            vehicleIntervalRef.current = null;
        } else {
            !vehicleIntervalRef.current && !props.singleVehicleMode && loadVehiclesPeriodicaly();
        }
    }, [selectedVehicleIds, props.singleVehicleMode]);

    useEffect(() => {
        return () => {
            setSelectedVehicleIds([]);
            clearInterval(vehicleIntervalRef.current as NodeJS.Timeout);
            vehicleIntervalRef.current = null;
        };
    }, []);

    return (
        <DashboardMapContainer data-testid='DashboardMapContent-style'>
            <DashboardMapView {...props} data-testid={'DashboardMapContent-view'} />
        </DashboardMapContainer>
    );
};

export default Wrapper(DashboardMapContent);
