import React from 'react';
import { CustomerReportWidgetFormikProps } from './CustomerReportWidget.type';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@mui/material';
import { SUCCESS } from 'components/Ui/colors';
import UiButton from 'components/Ui/Components/UiButton';

const CustomerReportWidgetFormik: React.FC<CustomerReportWidgetFormikProps> = ({ data, mutation }) => {
    const { t: translate } = useTranslation();

    return (
        <Formik
            initialValues={data}
            enableReinitialize={true}
            onSubmit={(values) => {
                mutation.mutate(values);
            }}
        >
            {(propsFormik) => (
                <Form id='CustomerReportForm'>
                    <Grid data-testid='CustomerReportFormContainer' container spacing={2} marginBottom={1}>
                        <Grid item xs={12} margin='6px' marginTop='16px' marginBottom='0px'>
                            <TextField
                                data-testid='MinimumActiveDeviceTrigger'
                                label={translate('t.minimum_active_hub')}
                                variant='outlined'
                                name='minimumActiveDeviceTrigger'
                                size='small'
                                value={propsFormik.values.minimumActiveDeviceTrigger}
                                type='number'
                                InputLabelProps={{
                                    shrink: true
                                }}
                                inputProps={{
                                    min: 0
                                }}
                                onChange={propsFormik.handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <UiButton
                                data-testid='CustomerReportFormSave'
                                skin={SUCCESS}
                                type='submit'
                                color='primary'
                                variant='contained'
                                loading={mutation.isLoading}
                            >
                                {translate('t.save')}
                            </UiButton>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default CustomerReportWidgetFormik;
