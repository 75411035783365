import React from 'react';
import { DeviceDiagnoseContent } from './DeviceDiagnose.view';
import { DeviceDiagnoseProps } from './DeviceDiagnose.type';
import DeviceDiagnoseApi from 'api/DeviceDiagnose';
import { Wrapper } from 'helpers/wrapper';
import { useQuery } from '@tanstack/react-query';
import { DeviceDiagnoseQueryKeys } from 'models/DeviceDiagnose.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';

const deviceDiagnoseAPI = new DeviceDiagnoseApi();

const DeviceDiagnose: React.FC<DeviceDiagnoseProps> = (props): JSX.Element => {
    const { data, refetch, isFetching, isFetched } = useQuery(
        [DeviceDiagnoseQueryKeys.getById, props.id],
        () =>
            deviceDiagnoseAPI.getById({
                id: props.id
            }),
        {
            refetchOnWindowFocus: false,
            enabled: true,
            retry: false,
            cacheTime: cacheTimeToMilliseconds(5, 'minutes'),
            staleTime: cacheTimeToMilliseconds(5, 'minutes')
        }
    );

    return (
        <DeviceDiagnoseContent
            data={data}
            isFetched={isFetched}
            refetch={refetch}
            isLoading={isFetching}
            data-testid='DeviceDiagnoseTable-testid'
        />
    );
};

export default Wrapper(DeviceDiagnose);
