import React from 'react';
import { AlertsBreakdownLevels, AlertsBreakdownTableBodyProps } from './AlertsBreakdownTableBody.type';
import { AlertsBreakdownTableBodyContent } from './AlertsBreakdownTableBody.view';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { AlertsBreakdownPeriod, AlertsBreakdownVehicle } from '../state';
import {
    AlertsBreakDownResponse,
    NotificationEventQueryKeys,
    NotificationType2Enum,
    NotificationTypeEnum
} from 'models/NotificationEvent.type';
import NotificationEvent from 'api/NotificationEvent';
import { Wrapper } from 'helpers/wrapper';
import { UserInfo } from 'states/global/User';

const notificationEventAPI = new NotificationEvent();

const AlertsBreakdownTableBody: React.FC<AlertsBreakdownTableBodyProps> = (): JSX.Element => {
    const selectedPeriod = useRecoilValue(AlertsBreakdownPeriod);
    const selectedVehicle = useRecoilValue(AlertsBreakdownVehicle);
    const userInfo = useRecoilValue(UserInfo);

    const parseData = (
        data: AlertsBreakDownResponse['alertsBreakDown'],
        type: 'last' | 'previous',
        { highPressure, lowPressure, highTemperature, temperaturePrediction }: AlertsBreakdownLevels
    ) => {
        const periodData = data[`${type}Period`];

        for (let i = 0, length = periodData.length; i < length; i++) {
            if (periodData[i].notificationType2 === NotificationType2Enum.HIGH) {
                if (periodData[i].notificationType === NotificationTypeEnum.PRESSURE) {
                    highPressure[periodData[i].notificationLevel][type] = periodData[i].alertsCount;
                }
                if (periodData[i].notificationType === NotificationTypeEnum.TEMPERATURE) {
                    highTemperature[periodData[i].notificationLevel][type] = periodData[i].alertsCount;
                }
                if (periodData[i].notificationType === NotificationTypeEnum.TEMPERATURE_PREDICTION) {
                    temperaturePrediction[periodData[i].notificationLevel][type] = periodData[i].alertsCount;
                }
            }
            if (
                periodData[i].notificationType2 === NotificationType2Enum.LOW &&
                periodData[i].notificationType === NotificationTypeEnum.PRESSURE
            ) {
                lowPressure[periodData[i].notificationLevel][type] = periodData[i].alertsCount;
            }
        }
        return {
            highPressure,
            lowPressure,
            highTemperature
        };
    };

    const fetchAlertsBreakdown = async (): Promise<AlertsBreakdownLevels> => {
        const data = await notificationEventAPI.getAlertsBreakDown({
            period: selectedPeriod,
            vehicleId: selectedVehicle.value
        });
        let parsedData: AlertsBreakdownLevels = {
            highPressure: {
                '': { last: undefined, previous: undefined },
                1: { last: undefined, previous: undefined },
                2: { last: undefined, previous: undefined },
                3: { last: undefined, previous: undefined }
            },
            lowPressure: {
                '': { last: undefined, previous: undefined },
                1: { last: undefined, previous: undefined },
                2: { last: undefined, previous: undefined },
                3: { last: undefined, previous: undefined }
            },
            highTemperature: {
                '': { last: undefined, previous: undefined },
                1: { last: undefined, previous: undefined },
                2: { last: undefined, previous: undefined },
                3: { last: undefined, previous: undefined }
            },
            temperaturePrediction: {
                '': { last: undefined, previous: undefined },
                1: { last: undefined, previous: undefined },
                2: { last: undefined, previous: undefined },
                3: { last: undefined, previous: undefined }
            }
        };
        await parseData(data.alertsBreakDown, 'previous', parsedData);
        await parseData(data.alertsBreakDown, 'last', parsedData);

        return parsedData;
    };

    const { data, isLoading } = useQuery(
        [
            NotificationEventQueryKeys.getAlertsBreakdown,
            userInfo.user?.customer.id,
            { selectedPeriod, selectedVehicle }
        ],
        () => fetchAlertsBreakdown(),
        {
            refetchInterval: 30000,
            staleTime: 0,
            cacheTime: 0
        }
    );

    return (
        <AlertsBreakdownTableBodyContent
            data={data}
            isLoading={isLoading}
            data-testid='AlertsBreakdownTableBody-testid'
        />
    );
};

export default Wrapper(AlertsBreakdownTableBody);
