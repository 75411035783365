import PortalApi from 'helpers/api/PortalApiClient';
import { TeamShiftModel, TeamShiftPayload } from '../models/TeamShift.type';

export default class TeamShift extends PortalApi<TeamShiftModel> {
    route = 'team-shift';

    addTeamShift = async (data: TeamShiftPayload): Promise<TeamShiftModel> => {
        return await this.post({ data: data });
    };

    removeTeamShift = async (id: number): Promise<void | unknown> => {
        return await this.delete({ id: id });
    };

    getTeamShiftByShiftDate = async (date: string): Promise<void> => {
        const criteria = { shiftDate: date };

        return this.getByCriteria({
            criteria: criteria
        });
    };
}
