import styled from 'styled-components';
import { Tooltip, useTheme } from '@mui/material';
import { ThemeMode } from 'states/global/Theme';
import UiIconButton from '../Components/UiIconButton/UiIconButton';

export const CollapseSidebarButton = styled(UiIconButton)<{ $collapsedSidebar?: boolean }>`
    ${() => {
        const theme = useTheme();
        return `
            color: ${
                theme.palette.mode === ThemeMode.light ? theme.palette.background.paper : theme.palette.text.primary
            } !important;
            background: ${theme.palette.primary.main} !important;
        `;
    }}
    height: 34px;
    width: 34px;
    margin-top: 1% !important;
    margin-left: ${({ $collapsedSidebar }) => ($collapsedSidebar ? '17px' : '13px')};

    &:hover {
        color: #fff !important;
        svg {
            color: #fff !important;
        }
    }
`;

export const CollapseMenuButtonContainer = styled.div<{ $collapsedSidebar?: boolean }>`
    text-align: ${({ $collapsedSidebar }) => ($collapsedSidebar ? 'right' : 'center')};
`;

export const CollapseSidebarButtonTooltip = styled(Tooltip)`
    &&& {
        margin: 0;
    }
`;
