import React from 'react';
import { ShiftTimeNameTableContainer } from './ShiftTimeNameTable.style';
import { ShiftTimeNameTableViewProps } from './ShiftTimeNameTable.type';
import { useTranslation } from 'react-i18next';
import UiTable from 'components/Ui/UiTable/UiTable';
import TeamShiftName from 'api/TeamShiftName';
import TeamShiftNameActions from '../TeamShiftNameActions/TeamShiftNameActions';
import { useSetRecoilState } from 'recoil';
import { ShowTeamShiftNameActionsModal } from 'states/global/Modal';
import { ShiftTimeNameActionsState } from '../TeamShiftNameActions/TeamShiftNameActions.atom';
import { CRUD } from 'variables';
import UiButton from 'components/Ui/Components/UiButton/UiButton';

const teamShiftNameApi = new TeamShiftName();

export const ShiftTimeNameTableContent: React.FC<ShiftTimeNameTableViewProps> = ({ columns }): JSX.Element => {
    const { t: translate } = useTranslation();
    const showShowTeamShiftNameActionsModal = useSetRecoilState(ShowTeamShiftNameActionsModal);
    const shiftTimeNameActionsState = useSetRecoilState(ShiftTimeNameActionsState);

    return (
        <>
            <ShiftTimeNameTableContainer data-testid='ShiftTimeNameTableContent'>
                <UiTable
                    data-testid='ShiftTimeNameListContent-uiTable'
                    fetchFn={teamShiftNameApi.getAll}
                    columns={columns}
                    refresh={false}
                    queryKey='shiftTimeName'
                    hideFilterAction
                    onlyIconsForDefActionBtns
                    rightActionBtns={() => (
                        <UiButton
                            testid='Add-shiftTimeName-button'
                            color='primary'
                            variant='contained'
                            style={{ color: '#fff' }}
                            onClick={() => {
                                shiftTimeNameActionsState({
                                    action: CRUD.ADD
                                });
                                showShowTeamShiftNameActionsModal(true);
                            }}
                        >
                            {translate('t.add')}
                        </UiButton>
                    )}
                />
            </ShiftTimeNameTableContainer>
            <TeamShiftNameActions />
        </>
    );
};
