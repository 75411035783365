import React, { useEffect } from 'react';
import { CustomerSettingsQueryType } from './CustomerSettings.type';
import CustomerSettingsAPI from 'api/CustomerSettings';
import { CustomerSettingsAll, CustomerSettingsQueryKeys } from 'models/CustomerSettings.type';
import { useQuery } from '@tanstack/react-query';
import { useSetRecoilState } from 'recoil';
import { CustomerSettings as CustomerSettingsAtom } from 'states/global/CustomerSettings';
import { GlobalUserInfo } from 'states/global/User';
import { useLocation } from 'react-router-dom';
import { UiFullScreenLoaderAtom } from 'components/Ui/Components/UiFullScreenLoader/UiFullScreenLoader.atom';

const customerSettingsAPI = new CustomerSettingsAPI();

const CustomerSettings = ({ options, children }: CustomerSettingsQueryType) => {
    const setCustomerSettings = useSetRecoilState(CustomerSettingsAtom);
    const location = useLocation();
    const setFullScreenLoaderState = useSetRecoilState(UiFullScreenLoaderAtom);

    const { isFetching } = useQuery<CustomerSettingsAll>(
        [CustomerSettingsQueryKeys.getAll, GlobalUserInfo.user],
        () => customerSettingsAPI.getAll(),
        {
            ...options,
            enabled:
                !!GlobalUserInfo.user &&
                location.pathname !== '/customer-redirection' &&
                !GlobalUserInfo.user?.customer.disabledOrActiveTerminatedAt,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: (data) => {
                setCustomerSettings({
                    ...data.settings,
                    ready_to_use_data: true
                });
            }
        }
    );

    useEffect(() => {
        setFullScreenLoaderState({ open: isFetching });
    }, [isFetching]);

    return <>{children}</>;
};

export default CustomerSettings;
