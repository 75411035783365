import { Box, Theme } from '@mui/material';
import { styled as styleMui } from '@mui/system';
import { DARK_STEEL_GREY, BACKGROUND_PAPER_DARK_MODE, WHITE } from 'components/Ui/colors';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';
import { ThemeMode } from 'states/global/Theme';

export const AlertsFilterVehicleInputContainerViewContent = styleMui(Box)({
    position: 'relative',
    display: 'inline-block',
    marginRight: 15
});

export const CloseButton = styleMui(UiIconButton)((props: { theme: Theme }) => ({
    position: 'absolute',
    top: 0,
    right: -8,
    background: props.theme.palette.mode === ThemeMode.dark ? BACKGROUND_PAPER_DARK_MODE : WHITE,
    border: 'solid thin #aeacac',
    height: '18px',
    width: '18px',
    fontSize: '0.8rem',
    '&.MuiIconButton-root:hover': {
        background: props.theme.palette.mode === ThemeMode.dark ? '#28313a' : 'rgb(245,245,245)',
        color: DARK_STEEL_GREY
    }
}));
