import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { RolesCodebookQueryType } from './Roles.type';
import RoleClient from 'api/Role';
import { RoleCodeBook, RoleQueryKeys } from 'models/Role.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { useRecoilValue } from 'recoil';
import { UserInfo } from 'states/global/User';

const roleClient = new RoleClient();

export const GetRolesCodebookQuery: React.FC<RolesCodebookQueryType> = ({ children }): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const useQueryProperties = useQuery<RoleCodeBook[], Error, RoleCodeBook[]>(
        [RoleQueryKeys.getCodebook, userInfo.user?.customer.id],
        () => roleClient.getCodeBook(),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours')
        }
    );
    return <>{children(useQueryProperties)}</>;
};

export const GetRolesAvailableCodebookQuery: React.FC<RolesCodebookQueryType> = ({ children }): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const useQueryProperties = useQuery<RoleCodeBook[], Error, RoleCodeBook[]>(
        [RoleQueryKeys.getAvailable, userInfo.user?.customer.id],
        () => roleClient.getAvailable(),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(60, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(65, 'minutes')
        }
    );
    return <>{children(useQueryProperties)}</>;
};
