export type WheelPredictionModel = {};

export type WheelPredictionGraphData = {
    tyreTempPredictionGraph: {
        vehicleId: number;
        wheelId: number;
        position: number;
        criticalTemperature: number;
        criticalTemperatureAt: string;
        measuredAt: string;
        alertStartAt: string;
        actualTemperature: number;
        lastHubTransmission: string;
        counterAction: CounterActionType | null;
        remainingHours: string;
        notificationLimits: NotificationLimit[];
    } & DataForGraph;
};

export type NotificationLimit = {
    criticalTemperature: number;
    level: number;
    notificationId: number;
};

export type CounterActionType = {
    counterActionIds: number[];
    expiresAt: string;
    vehicleId: number;
};

export type DataForGraph = {
    weatherData: { actualTemperature: number; timeKey: number }[];
    forecastData: { forecastTemperature: number; timeKey: number }[];
    sensorData: { temperature: number; timeKey: number }[];
    predictionData: { predictedTemperature: number; timeKey: number }[];
};

export type GraphData = {
    timeKey: number;
    timeForTooltip?: string;
    actualTemperature?: number;
    forecastTemperature?: number;
    temperature?: number | string;
    predictedTemperature?: number;
    criticalTemperature?: number;
};

export type TemperaturePredictionPreview = {
    temperatureKpi: TemperaturePredictionPreviewData[];
};

export type TemperaturePredictionPreviewData = {
    detectedAt: string;
    processedAt: string;
    vehicleId: number;
    position: number;
    realChamberTemperatureMovingAverage: number;
    ambientTemperature: number;
    predictedRemainMinutes: number;
    tgPerMin: number;
    criticalTemperature: number;
    timeAfter1Hour: string;
    predictedTemperature1Hour: number;
    realTemperature1Hour: number;
    predictedAmbientTemperature1Hour: number;
    realAmbient1Hour: number;
    timeAfter2Hour: string;
    predictedTemperature2Hour: number;
    realTemperature2Hour: number;
    predictedAmbientTemperature2Hour: number;
    realAmbient2Hour: number;
    timeAfter3Hour: string;
    predictedTemperature3Hour: number;
    realTemperature3Hour: number;
    predictedAmbientTemperature3Hour: number;
    realAmbient3Hour: number;
    counterAction: null;
    slopeMoveAverageValue: number;
    customerSchema: string;
    customerName: string;
};

export enum WheelPredictionQueryKeys {
    getGraphData = 'wheelPrediction-getGraphData'
}
