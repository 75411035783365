import React from 'react';
import { ShiftTimeNameContainer } from './ShiftTimeName.style';
import { ShiftTimeNameViewProps } from './ShiftTimeName.type';
import { useTranslation } from 'react-i18next';
import UiWidget from 'components/Ui/Components/UiWidget/UiWidget';
import ShiftTimeNameTable from 'components/Customer/ShiftTimeNameTable/ShiftTimeNameTable';

export const ShiftTimeNameContent: React.FC<ShiftTimeNameViewProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <ShiftTimeNameContainer data-testid='ShiftTimeNameContent'>
            <UiWidget
                testid='shitTeamName-widget'
                title={translate('t.team_list')}
                id={'shitTeamName-table'}
                collapse={false}
                content={<ShiftTimeNameTable />}
            />
        </ShiftTimeNameContainer>
    );
};
