import React from 'react';
import { WheelHistoryTableContainer } from './WheelHistoryTable.style';
import { WheelHistoryTableViewProps } from './WheelHistoryTable.type';
import UiTable from 'components/Ui/UiTable/UiTable';
import SensorHistory from 'api/SensorHistory';

const sensorHistoryApi = new SensorHistory();

export const WheelHistoryTableContent: React.FC<WheelHistoryTableViewProps> = (props): JSX.Element => {
    return (
        <WheelHistoryTableContainer data-testid='WheelHistoryTableContent'>
            <UiTable
                columns={props.columns}
                fetchFn={sensorHistoryApi.getSensorsHistory}
                refresh={false}
                maxHeight={490}
                onlyIconsForDefActionBtns
                exportFn={sensorHistoryApi.exportSensorWheelHistory}
                exportName={'SensorsWheelHistory'}
                additionalProps={{ sensorId: props.sensorId }}
                hideFilterAction
                hiddenColumns={['inactive']}
                queryKey={`wheelHistory-${props.sensorId}`}
                defaultSortBy={{
                    id: 'createdAt',
                    desc: true
                }}
            />
        </WheelHistoryTableContainer>
    );
};
