import React, { useMemo } from 'react';
import UiWidgetTab from 'components/Ui/Components/UiWidgetTab/UiWidgetTab';
import AlertRefresh from './AlertRefresh.view';
import AlertsPopUpStateView from './AlertsPopUpState.view';
import AlertsList from '../AlertsList/AlertsList';
import { useTranslation } from 'react-i18next';
import TpmsAlertsQuery from '../../Queries/TpmsAlertQuery/TpmsAlertQuery';
import GpsAlertQuery from '../../Queries/GpsAlertQuery/GpsAlertQuery';
import VehicleEventQuery from 'components/Queries/VehicleEvent';
import { useRecoilValue } from 'recoil';
import { faSquareTerminal } from '@fortawesome/pro-solid-svg-icons';
import { AlertsByVehicleContainer } from './AlertsListTabManager.style';
import { applyStyleByMode } from 'helpers';
import { faBook, faMapMarker, faTirePressureWarning, faTruck } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { WHITE } from 'components/Ui/colors';
import { AlertsListViewProps, TabNumber, typeAlert, typeFilter } from './AlertsListTabManager.type';
import { Theme } from 'states/global/Theme';
import { ModelTpmsAlerts, TpmsAlertsQueryKeys } from 'models/TpmsAlerts.type';
import { UserInfo } from 'states/global/User';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { CustomerSettings } from 'states/global/CustomerSettings';

export const AlertsListTabManagerContent: React.FC<AlertsListViewProps> = (props): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();
    const { fromTimezoneToUTC } = useConverter();
    const customerSettings = useRecoilValue(CustomerSettings);
    const ThemeMode = useRecoilValue(Theme);
    let contentHeight = !props.displayRefresh ? 473 : 465;

    const tabHeaders = useMemo(() => {
        const headers = [
            {
                label: translate('t.tpms_alerts'),
                icon: (
                    <UiIcon
                        icon={faTirePressureWarning}
                        size='1x'
                        transform='left-4'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#333',
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                )
            },
            {
                label: `GPS ${translate('t.alerts')}`,
                icon: (
                    <UiIcon
                        icon={faMapMarker}
                        size='1x'
                        transform='left-4'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#333',
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                )
            },
            {
                label: translate('t.system_alerts'),
                icon: (
                    <UiIcon
                        icon={['fas', 'triangle-exclamation']}
                        size='1x'
                        transform='left-4'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#333',
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                )
            },
            {
                label: translate('t.historical_log'),
                icon: (
                    <UiIcon
                        icon={faBook}
                        size='1x'
                        transform='left-4'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#333',
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                )
            }
        ];

        if (customerSettings.vehicle_alerts_enabled) {
            headers.splice(1, 0, {
                label: translate('t.vehicles_alerts'),
                icon: (
                    <UiIcon
                        icon={faTruck}
                        size='1x'
                        transform='left-4'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#333',
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                )
            });
        }

        return headers;
    }, [customerSettings]);

    const tabContent = useMemo(() => {
        const contentTab = [
            {
                id: 0,
                body: (
                    <TpmsAlertsQuery
                        alertFilters={props.alertFilters}
                        customConfig={props.customConfigReactQuery.TpmsQuery}
                        currentPage={1}
                        limit={150}
                        tab={'tpms'}
                        solved={false}
                        measuredFrom={fromTimezoneToUTC(DateTime.local().minus({ days: 30 }))}
                    >
                        {({ data, isLoading }) => (
                            <AlertsList
                                {...props}
                                data={data?.items.reduce((acc: ModelTpmsAlerts[], current) => {
                                    current.notification.type !== typeFilter.BATTERY && acc.push(current);
                                    return acc;
                                }, [])}
                                alertKey='_alertTPMS'
                                displayAlertActions={true}
                                typeAlert={typeAlert.TPMS}
                                isLoading={isLoading}
                                showSolveButton={props.showSolveButton}
                                showValidateButton={props.showValidateButton}
                                tabNumber={0}
                                displayBody={true}
                            />
                        )}
                    </TpmsAlertsQuery>
                )
            },
            {
                id: 1,
                body: (
                    <GpsAlertQuery customConfig={props.customConfigReactQuery.GpsQuery} page={1} limit={150}>
                        {({ data, isLoading }) => (
                            <AlertsList
                                {...props}
                                data={data?.items}
                                testId='dashboard-GPS'
                                alertKey={'_alertGPS'}
                                displayAlertActions={false}
                                typeAlert={typeAlert.GPS}
                                isLoading={isLoading}
                                tabNumber={2}
                                displayBody={true}
                            />
                        )}
                    </GpsAlertQuery>
                )
            },
            {
                id: 2,
                body: (
                    <TpmsAlertsQuery
                        measuredFrom={fromTimezoneToUTC(DateTime.local().minus({ days: 30 }))}
                        customConfig={props.customConfigReactQuery.TpmsQuery}
                        currentPage={1}
                        limit={150}
                        solved={false}
                        tab={'system'}
                        keyQuery={TpmsAlertsQueryKeys.getNotificationSensorSolved}
                    >
                        {({ data, isLoading }) => (
                            <AlertsList
                                data={data?.items}
                                alertKey={'_alertSensor'}
                                displayAlertActions={false}
                                showSolveButton={false}
                                typeAlert={typeAlert.Sensor}
                                isLoading={isLoading}
                                tabNumber={0}
                                displayBody={false}
                            />
                        )}
                    </TpmsAlertsQuery>
                )
            },
            {
                id: 3,
                body: (
                    <TpmsAlertsQuery
                        alertFilters={props.alertFilters}
                        customConfig={props.customConfigReactQuery.TpmsQuery}
                        currentPage={1}
                        limit={150}
                        solved={true}
                        tab={'log'}
                        keyQuery={TpmsAlertsQueryKeys.getNotificationSolved}
                    >
                        {({ data, isLoading }) => (
                            <AlertsList
                                {...props}
                                data={
                                    checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN])
                                        ? data?.items
                                        : data?.items.reduce((acc: ModelTpmsAlerts[], current) => {
                                              current.notification.type !== typeFilter.BATTERY && acc.push(current);
                                              return acc;
                                          }, [])
                                }
                                testId='dashboard-HistoryLog'
                                alertKey={'_alertHistoryLog'}
                                displayAlertActions={false}
                                typeAlert={typeAlert.HistoricalLog}
                                isLoading={isLoading}
                                tabNumber={3}
                                displayBody={true}
                            />
                        )}
                    </TpmsAlertsQuery>
                )
            }
        ];

        if (customerSettings.vehicle_alerts_enabled) {
            contentTab.splice(1, 0, {
                id: 1,
                body: (
                    <VehicleEventQuery customConfig={props.customConfigReactQuery.TpmsQuery} page={1} limit={150}>
                        {({ data, isLoading }) => (
                            <AlertsList
                                {...props}
                                data={data?.items}
                                alertKey={'_alertVehicleEvent'}
                                displayAlertActions={false}
                                typeAlert={typeAlert.VehicleEvent}
                                isLoading={isLoading}
                                showSolveButton={props.showSolveButton}
                                showValidateButton={false}
                                tabNumber={TabNumber.VehiclesAlerts}
                                displayBody={true}
                                gridNumber={3}
                            />
                        )}
                    </VehicleEventQuery>
                )
            });
        }

        return contentTab;
    }, [customerSettings, props.alertFilters]);

    return (
        <AlertsByVehicleContainer>
            <UiWidgetTab
                title={translate('t.alerts_by_vehicle')}
                $contentHeight={contentHeight}
                height={512}
                actionScroll={props.actionScroll}
                testid='Widget-Alerts'
                unit='px'
                setNumberTab={props.setNumberTab}
                tab={{
                    headers: tabHeaders,
                    constents: tabContent
                }}
                headerAction={
                    props.tabNumber === TabNumber.TpmsAlert || props.tabNumber === TabNumber.HistoricalLog ? (
                        <AlertsPopUpStateView
                            AddFilterAlertByLevel={props.AddFilterAlertByLevel}
                            setFilterByType={props.setFilterByType}
                        />
                    ) : (
                        <></>
                    )
                }
            />
            <AlertRefresh
                setEnabledQuery={props.setEnabledQuery}
                enabledQuery={props.enabledQuery}
                displayRefresh={!!props.displayRefresh}
            />
        </AlertsByVehicleContainer>
    );
};
