import styled from 'styled-components';
import Alert from '@mui/material/Alert';
import { Autocomplete, Grid, TextField, useTheme } from '@mui/material';
import UiButton from 'components/Ui/Components/UiButton';
import { applyStyleByMode } from 'helpers';
import { CommonTypeTheme } from 'components/Ui/Theme';
import { FONT_BIG_TEXT_SIZE } from 'components/Ui/variables';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

export const UiDateRangePicker2Container = styled.div`
    top: -8.5px;
    position: relative;

    && .MuiInputBase-root {
        width: 200px;
    }
`;

export const DataExportTopBarView = styled.div`
    ${applyStyleByMode({
        styleOld: `
            padding: 10px 10px 0px;
            width: 100%;
        `
    })}
`;

export const WarningMessage = styled(Alert)`
    margin: 0 0 12px 0;
`;

export const WheelPositionTextField = styled(TextField)`
    & .MuiOutlinedInput-root {
        width: 200px;
    }
`;

export const AreaTextField = styled(TextField)`
    & .MuiOutlinedInput-root {
        width: 200px;
    }
`;

export const StyledAutocomplete = styled(Autocomplete)`
    & .MuiFormControl-root {
        width: 200px;
    }
`;

export const DownloadButton = styled(UiButton)`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return common.easyMode
            ? ` 
    height: 40px;
    display: flex;
    justify-self: start;
    & .MuiTypography-root { 
    font-size: 15px; 
    }
    `
            : `
    height: 35px;
    & .MuiTypography-root { 
    font-size: 12px; 
    }`;
    }}
`;

export const RefreshButton = styled(UiIconButton)`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return common.easyMode ? `display: flex; align-self: start; height: 50px;  ` : '';
    }}
`;

export const ButtonsGrid = styled(Grid)`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return common.easyMode ? 'display: inline-flex;' : '';
    }}
`;

export const WrapperAutocompleteVehicle = styled.div`
    top: -8.5px;
    position: relative;
`;
