import React, { useEffect, useState } from 'react';
import * as view from './AddVehicleType.view';
import * as type from './AddVehicleType.type';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { VehicleTypeAddState } from 'states/component/VehicleType';
import VehicleTypeAPI from 'api/VehicleType';
import { Success } from 'components/Popup';
import { FormProps, RequestData } from './AddVehicleType.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
    DefaultRequestPropsType,
    PatchPutByIdType,
    RequestPropsWithData,
    WithRequiredProperty
} from 'helpers/api/type';
import { useTranslation } from 'react-i18next';
import { VehicleTypeQueryKeys } from 'models/VehicleType.type';
import { removeAllQueryKeysFromModel } from 'helpers/cache';
import { Wrapper } from 'helpers/wrapper';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';

const VehicleTypeApi = new VehicleTypeAPI();

const DEFAULT_ICON = `mining/48x48/adt_Yellow.png`;
const DEFAULT_VALUES = {
    type: '',
    maxSpeed: 0,
    icon: DEFAULT_ICON,
    maxSpeedTemperatureL1: 0,
    maxSpeedTemperatureL2: 0,
    maxSpeedTemperatureL3: 0,
    enableTemperatureCheckbox: false
};

const AddVehicleType: React.FC<type.AddVehicleTypeProps> = (): JSX.Element => {
    const queryClient = useQueryClient();
    const [icon, setIcon] = useState<string>(DEFAULT_ICON);
    const [forceClose, setForceClose] = useState<boolean>(false);
    const vehicleTypeAddState = useRecoilValue(VehicleTypeAddState);
    const resetVehicleTypeAddState = useResetRecoilState(VehicleTypeAddState);
    const vehicleTypesTableQueryFn = useRecoilValue(QueryFn(`VehicleTypes-QueryFn`));
    const [initValues, setInitValues] = useState<FormProps>(DEFAULT_VALUES);
    const { fromUserToServerUnit, fromServerToUserUnit, convertType } = useConverter();
    const { t: translate } = useTranslation();

    const finishAction = () => {
        removeAllQueryKeysFromModel(VehicleTypeQueryKeys, queryClient);
        vehicleTypesTableQueryFn.refetch instanceof Function && vehicleTypesTableQueryFn.refetch();
        setForceClose(true);
    };

    const { mutate: VehicleTypePatchById, isLoading: VehicleTypePatchLoading } = useMutation<
        DefaultRequestPropsType,
        unknown,
        PatchPutByIdType<RequestData, DefaultRequestPropsType>
    >(VehicleTypeApi.patchById, {
        onSuccess: () => {
            finishAction();
            Success({ text: `${translate('t.vehicle_type')} ${translate('p.has_been_edited')}` });
        }
    });

    const { mutate: VehicleTypePost, isLoading: VehicleTypePostLoading } = useMutation<
        DefaultRequestPropsType,
        unknown,
        RequestPropsWithData<RequestData>
    >(VehicleTypeApi.post, {
        onSuccess: () => {
            finishAction();
            Success({ text: `${translate('t.vehicle_type')} ${translate('p.has_been_added')}` });
        }
    });

    const { mutate: VehicleTypeDelete, isLoading: VehicleTypeDeleteLoading } = useMutation<
        DefaultRequestPropsType,
        unknown,
        WithRequiredProperty<DefaultRequestPropsType, 'id'>
    >(VehicleTypeApi.delete, {
        onSuccess: () => {
            finishAction();
            Success({ text: `${translate('t.vehicle_type')} ${translate('p.has_been_deleted')}` });
        }
    });

    const trimSlashesFromImgSource = (imgSource: string): string => {
        let url = imgSource;
        while (url.charAt(0) === '/') {
            url = url.substring(1);
        }
        return url;
    };

    const sendData = (formData: FormProps): void => {
        let data: RequestData = {
            type: formData.type,
            icon: trimSlashesFromImgSource(icon),
            maxSpeed: fromUserToServerUnit({
                type: convertType.speed,
                value: formData.maxSpeed,
                fixed: 1
            })
        };

        if (vehicleTypeAddState.action === 'edit') {
            data.temperatureBasedMaxSpeed = formData.enableTemperatureCheckbox;
            if (formData.enableTemperatureCheckbox) {
                data.maxSpeedTemperatureL1 = fromUserToServerUnit({
                    type: convertType.speed,
                    value: formData.maxSpeedTemperatureL1 ?? 0,
                    fixed: 1
                });
                data.maxSpeedTemperatureL2 = fromUserToServerUnit({
                    type: convertType.speed,
                    value: formData.maxSpeedTemperatureL2 ?? 0,
                    fixed: 1
                });
                data.maxSpeedTemperatureL3 = fromUserToServerUnit({
                    type: convertType.speed,
                    value: formData.maxSpeedTemperatureL3 ?? 0,
                    fixed: 1
                });
            }
            VehicleTypePatchById({
                id: vehicleTypeAddState.id ?? NaN,
                data: data
            });
        } else {
            VehicleTypePost({ data: data });
        }
    };

    const removeVehicleType = (): void => {
        VehicleTypeDelete({ id: vehicleTypeAddState.id ?? NaN });
    };

    const resetValues = (): void => {
        resetVehicleTypeAddState();
        setInitValues(DEFAULT_VALUES);
    };

    useEffect(() => {
        vehicleTypeAddState.action === 'edit' &&
            setInitValues({
                type: vehicleTypeAddState.type ?? '',
                maxSpeed: +fromServerToUserUnit({
                    type: convertType.speed,
                    value: vehicleTypeAddState.maxSpeed ?? 0,
                    fixed: 1
                }),
                maxSpeedTemperatureL1: +fromServerToUserUnit({
                    type: convertType.speed,
                    value: vehicleTypeAddState.maxSpeedTemperatureL1 ?? 0,
                    fixed: 1
                }),
                maxSpeedTemperatureL2: +fromServerToUserUnit({
                    type: convertType.speed,
                    value: vehicleTypeAddState.maxSpeedTemperatureL2 ?? 0,
                    fixed: 1
                }),
                maxSpeedTemperatureL3: +fromServerToUserUnit({
                    type: convertType.speed,
                    value: vehicleTypeAddState.maxSpeedTemperatureL3 ?? 0,
                    fixed: 1
                }),
                icon: vehicleTypeAddState.icon || '',
                enableTemperatureCheckbox: vehicleTypeAddState.enableTemperatureCheckbox ?? false
            });
    }, [vehicleTypeAddState.action]);

    useEffect(() => {
        resetValues();
        setForceClose(false);
    }, [forceClose]);

    return (
        <view.AddVehicleTypeContent
            data-testid={'AddVehicleType-testid'}
            setIcon={setIcon}
            icon={icon}
            resetValues={resetValues}
            initValues={initValues}
            sendData={sendData}
            removeVehicleType={removeVehicleType}
            forceCLose={forceClose}
            isLoading={VehicleTypePatchLoading || VehicleTypePostLoading || VehicleTypeDeleteLoading}
        />
    );
};

export default Wrapper(AddVehicleType);
