import React from 'react';
import { ShiftTimeTabManagerContainer } from './ShiftTimeTabManager.style';
import { ShiftTimeTabManagerViewProps } from './ShiftTimeTabManager.type';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import UiWidgetTab from 'components/Ui/Components/UiWidgetTab/UiWidgetTab';
import ShiftTimeCalendar from '../ShiftTimeCalendar/ShiftTimeCalendar';
import ShiftTimeName from 'pages/ShiftTimeName/ShiftTimeName';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import TeamShiftTime from '../TeamShiftTime/TeamShiftTime';

export const ShiftTimeTabManagerContent: React.FC<ShiftTimeTabManagerViewProps> = (): JSX.Element => {
    const ThemeMode = useRecoilValue(Theme);
    const { t: translate } = useTranslation();

    return (
        <ShiftTimeTabManagerContainer data-testid='ShiftTimeTabManagerContent'>
            <UiWidgetTab
                title={translate('t.alerts_by_vehicle')}
                unit='px'
                height={800}
                tab={{
                    headers: [
                        {
                            label: translate('t.shift'),
                            icon: (
                                <UiIcon
                                    icon={['fas', 'calendar']}
                                    size='1x'
                                    transform='left-4'
                                    testid='ShiftTimeTabManager-tab-shift-icon'
                                    fixedWidth
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        dark: WHITE
                                    })}
                                />
                            )
                        },
                        {
                            label: translate('t.shift_time'),
                            icon: (
                                <UiIcon
                                    icon={['fas', 'calendar-clock']}
                                    size='1x'
                                    transform='left-4'
                                    fixedWidth
                                    testid='ShiftTimeTabManager-tab-shiftTime-icon'
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        dark: WHITE
                                    })}
                                />
                            )
                        },
                        {
                            label: translate('t.team'),
                            icon: (
                                <UiIcon
                                    icon={faUsers}
                                    size='1x'
                                    testid='ShiftTimeTabManager-tab-team-icon'
                                    transform='left-4'
                                    fixedWidth
                                />
                            )
                        }
                    ],
                    constents: [
                        {
                            id: 0,
                            body: (
                                <div style={{ padding: 10 }}>
                                    <ShiftTimeCalendar />
                                </div>
                            )
                        },
                        {
                            id: 1,
                            body: (
                                <div style={{ padding: 10 }}>
                                    <TeamShiftTime />
                                </div>
                            )
                        },
                        {
                            id: 2,
                            body: (
                                <div style={{ padding: 10 }}>
                                    <ShiftTimeName />
                                </div>
                            )
                        }
                    ]
                }}
            />
        </ShiftTimeTabManagerContainer>
    );
};
