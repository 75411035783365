import React from 'react';
import { SystemNotificationMessageViewProps } from './SystemNotificationMessage.type';
import {
    CloseButton,
    SystemNotificationMessageContainer,
    WarningMessage,
    Content,
    WarningMessageCover,
    WhatsNewAnnoucement,
    Stars,
    WhatsNewCloseBtn
} from './SystemNotificationMessage.style';
import CloseIcon from '@mui/icons-material/Close';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStars, faTimes } from '@fortawesome/pro-solid-svg-icons';
import useModal from 'components/CustomHooks/ModalHook/ModalHook';
import { useTranslation } from 'react-i18next';
import WhatsNewAnnouncement from 'components/Mixs/WhatsNewAnnouncement';

export const SystemNotificationMessageContent: React.FC<SystemNotificationMessageViewProps> = (props): JSX.Element => {
    const { setModalState } = useModal(0);
    const { t: translate } = useTranslation();
    const helpjuiceName = props.whatsNewNotification?.knowledgebaseUrl?.split('/').pop();

    const openWhatsNewModal = () => {
        if (props.whatsNewNotification?.helpjuiceId) {
            const contentModalKnowledgebase = {
                id: 'WhatsNewContent',
                leftTitle: `${translate('p.whats_new_in_itrack')} - ${helpjuiceName}`,
                content: <WhatsNewAnnouncement articleId={props.whatsNewNotification.helpjuiceId} />,
                height: 690,
                width: 1200,
                isOpen: true
            };

            setModalState({ ...contentModalKnowledgebase });
        }
    };

    return (
        <>
            {props.whatsNewNotification && (
                <WhatsNewAnnoucement>
                    <Grid container>
                        <Grid item xs={1} md={1} lg={1} onClick={openWhatsNewModal}>
                            <Typography variant='h5'>
                                <Stars>
                                    <FontAwesomeIcon icon={faStars} />
                                </Stars>
                            </Typography>
                        </Grid>
                        <Grid item xs={10} md={10} lg={10} onClick={openWhatsNewModal} style={{ height: '100%' }}>
                            <Typography variant='h5'>{translate('p.whats_new_in_itrack')}...</Typography>
                            <Typography margin={'5px 0'} height={'100%'}>
                                {translate('p.explore_functionalities_fixes_rolled_out')}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} md={1} lg={1}>
                            <Tooltip title={translate('t.dismiss')}>
                                <WhatsNewCloseBtn
                                    size='small'
                                    onClick={() =>
                                        props.whatsNewNotification &&
                                        props.handleCloseNotification(props.whatsNewNotification)
                                    }
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </WhatsNewCloseBtn>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </WhatsNewAnnoucement>
            )}
            <SystemNotificationMessageContainer
                data-testid='SystemNotificationMessageContent'
                hide={props.displayedNotification === null}
            >
                {props.displayedNotification?.map((notification) => {
                    return (
                        <WarningMessage severity='warning' key={notification.id}>
                            <Content style={{ display: 'flex', alignItems: 'center' }}>
                                <WarningMessageCover>
                                    {notification && props.createMessage(notification)}
                                </WarningMessageCover>
                                <CloseButton>
                                    <UiIconButton onClick={() => props.handleCloseNotification(notification)}>
                                        <CloseIcon />
                                    </UiIconButton>
                                </CloseButton>
                            </Content>
                        </WarningMessage>
                    );
                })}
            </SystemNotificationMessageContainer>
        </>
    );
};
