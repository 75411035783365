import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { RegionModelExtended, RegionQueryKeys } from 'models/Region.type';
import Region from 'api/Region';
import { RegionsCodebookQueryType } from './Regions.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { useRecoilValue } from 'recoil';
import { UserInfo } from 'states/global/User';

const regionAPI = new Region();

export const GetRegionsCodebookQuery: React.FC<RegionsCodebookQueryType> = ({ children }): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const useQueryProperties = useQuery<RegionModelExtended[]>(
        [RegionQueryKeys.getAllAsCodebook, userInfo.user?.customer.id],
        () => regionAPI.getAllAsCodebook(),
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours')
        }
    );

    return <>{children(useQueryProperties)}</>;
};
