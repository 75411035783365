import React, { useEffect } from 'react';
import { VehicleDetailsWidgetProps } from './VehicleDetailsWidget.type';
import { VehicleDetailsWidgetContent } from './VehicleDetailsWidget.view';
import Vehicle from 'api/Vehicle';
import { VehicleModel, VehicleQueryKeys } from 'models/Vehicle.type';
import { useQuery } from '@tanstack/react-query';
import Wheel from 'api/Wheel';
import { TyreDetail, TyreDetailResponse, WheelQueryKeys } from 'models/Wheel.type';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { VehicleDetailsWidgetAtom, HasInternalSensorAtom, FetchVehicleDetailsData } from './VehicleDetailsWidget.atom';
import { useMediaQuery, useTheme } from '@mui/material';
import useModal from 'components/CustomHooks/ModalHook';
import { VehicleDetailsActionMenuAtom } from 'components/Vehicle/VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import { Success } from 'components/Popup/Popup';
import { useTranslation } from 'react-i18next';
import { cacheTimeToMilliseconds } from 'helpers/cache';

const vehicleAPI = new Vehicle();
const wheelAPI = new Wheel();

const VehicleDetailsWidget: React.FC<VehicleDetailsWidgetProps> = (props): JSX.Element => {
    const setVehicleDetailsWidgetAtom = useSetRecoilState(VehicleDetailsWidgetAtom());
    const setHasInternalSensorAtom = useSetRecoilState(HasInternalSensorAtom());
    const fetchVehicleDetailsData = useRecoilValue(FetchVehicleDetailsData);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const { closeModal: closeOriginalModal } = useModal();
    const { closeModal } = useModal(1);
    const closeActionMenu = useResetRecoilState(VehicleDetailsActionMenuAtom);
    const { t: translate } = useTranslation();

    const {
        isLoading: vehicleDetailsLoading,
        data: vehicleData,
        refetch: vehicleDetailsRefetch
    } = useQuery<VehicleModel>(
        [VehicleQueryKeys.getVehicleById, props.vehicleId],
        () => vehicleAPI.getVehicleById(props.vehicleId),
        {
            enabled: props.vehicleId > 0,
            refetchInterval: 20000,
            staleTime: cacheTimeToMilliseconds(1, 'hours'),
            cacheTime: cacheTimeToMilliseconds(1, 'hours')
        }
    );

    const {
        isLoading: tyresDetailsLoading,
        data: tyreData,
        refetch: tyreDetailsRefetch
    } = useQuery<TyreDetailResponse>(
        [WheelQueryKeys.getTyresDetail, props.vehicleId],
        () => wheelAPI.getTyresDetail(props.vehicleId),
        {
            enabled: props.vehicleId > 0,
            refetchInterval: 20000,
            staleTime: cacheTimeToMilliseconds(15, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(15, 'minutes')
        }
    );

    useEffect(() => {
        if (props.vehicleId > 0) {
            vehicleDetailsRefetch().then(() =>
                tyreDetailsRefetch().then(() => {
                    closeModal();
                    closeOriginalModal();
                    closeActionMenu();
                    Success({ text: translate('t.changes_have_been_saved_successfully') });
                })
            );
        }
    }, [fetchVehicleDetailsData]);

    const sortTyresAndSensors = (data: TyreDetail[]): TyreDetail[] => {
        let dataToStore: TyreDetail[] = [];
        data.forEach((tyre) => {
            const newTyre: TyreDetail = { ...tyre };
            if (newTyre.hasInternalSensor) setHasInternalSensorAtom(true);
            newTyre.attachedSensors = newTyre.attachedSensors
                .slice()
                .sort((a, b) => (a.sensor.priority > b.sensor.priority ? 1 : -1));
            dataToStore.push(newTyre);
        });

        return dataToStore.sort((a, b) => a.wheel.customPosition - b.wheel.customPosition);
    };

    const parseVehicleInfoDisplay = (data: VehicleModel): VehicleModel => {
        let dataToStore: VehicleModel = { ...data };
        if (typeof dataToStore.vehicleInfo.display === 'string') {
            dataToStore.vehicleInfo.display = JSON.parse(dataToStore.vehicleInfo.display);
        }
        return dataToStore;
    };

    useEffect(() => {
        if ((props.vehicleId > 0 && !vehicleData) || (props.vehicleId > 0 && !tyreData)) {
            setVehicleDetailsWidgetAtom({
                isLoadingVehicle: true
            });
        }

        if (vehicleData && tyreData) {
            setVehicleDetailsWidgetAtom({
                vehicleDetails: parseVehicleInfoDisplay(vehicleData),
                tyreDetails: sortTyresAndSensors(tyreData),
                isLoadingVehicle: false
            });
        }
    }, [vehicleData, tyreData]);

    return (
        <VehicleDetailsWidgetContent
            data-testid={'VehicleDetailsWidget-testid'}
            loadingData={tyresDetailsLoading || vehicleDetailsLoading}
            matches={matches}
            {...props}
        />
    );
};

export default VehicleDetailsWidget;
