import styled from 'styled-components';

export const UiLoadingPageViewContent = styled.div<{ $marginTop?: string; $height?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ $height }) => `height: ${$height || 'auto'};`}
    ${({ $marginTop }) => `margin-top: ${$marginTop || '20px'};`}
`;
