import React from 'react';
import {
    CollapseTable,
    CustomText,
    CustomerSelectorContainer,
    CustomerSelectorPopover,
    ListItemTextUI,
    LoadingContent,
    LocationMenuItem,
    RegionMenuItem,
    SearchMenuItem,
    SearchTextField
} from './CustomerSelectorCheckbox.style';
import { CustomerSelectorCheckboxViewProps } from './CustomerSelectorCheckbox.type';
import { useTranslation } from 'react-i18next';
import UiScroll from 'components/Ui/Components/UiScroll/UiScroll';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { Box, Checkbox, ListItemIcon, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';

export const CustomerSelectorCheckboxContent: React.FC<CustomerSelectorCheckboxViewProps> = ({
    selectorState,
    handleOnClosePopover,
    searchCustomer,
    onInputChange,
    spaceToBottom,
    customersDataLoading,
    regionsData,
    openRegion,
    handleRegionCheckboxChange,
    handleCustomerCheckboxChange,
    selectedCustomers,
    customerData,
    clearSelectedCustomers
}): JSX.Element => {
    const { t: translate } = useTranslation();

    const generateContent = () => {
        if (customersDataLoading) {
            return (
                <LoadingContent>
                    <UiLoadingPage size='25px' />
                </LoadingContent>
            );
        } else {
            if (regionsData.length) {
                return regionsData.map((value) => [
                    <>
                        <RegionMenuItem
                            key={`${value.id}-region`}
                            onClick={() => {
                                openRegion(value?.id ?? null);
                            }}
                            selected={value.selected}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge='start'
                                    checked={value.customers.every((customer) => customer.selected)}
                                    tabIndex={-1}
                                    disableRipple
                                    onChange={(e) => {
                                        value.id && handleRegionCheckboxChange(value.id, e.target.checked);
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemTextUI
                                primary={
                                    <>
                                        <UiIcon icon={['fas', 'globe-europe']} />{' '}
                                        {translate(`t.${value.translationString}`)}
                                    </>
                                }
                            />
                            <span key={`${value.id}-icon`}>
                                {value.open ? (
                                    <UiIcon icon={['fas', 'chevron-up']} size='xs' />
                                ) : (
                                    <UiIcon icon={['fas', 'chevron-down']} size='xs' />
                                )}
                            </span>
                        </RegionMenuItem>

                        <CollapseTable key={`${value.id}-collapse`} in={value.open} timeout='auto' unmountOnExit>
                            {value.customers.map((data, key) => (
                                <LocationMenuItem
                                    key={`${key}-customer`}
                                    selected={data.selected}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleCustomerCheckboxChange(data);
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge='start'
                                            checked={data.selected}
                                            tabIndex={-1}
                                            disableRipple
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleCustomerCheckboxChange(data);
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemTextUI primary={data.name} />
                                </LocationMenuItem>
                            ))}
                        </CollapseTable>
                    </>
                ]);
            }

            return <CustomText>{translate('t.there_no_data')}</CustomText>;
        }
    };

    return (
        <CustomerSelectorContainer>
            <CustomerSelectorPopover
                className='popover-customer-selector'
                id='popover-customer-selector'
                open={selectorState.isOpen}
                onClose={handleOnClosePopover}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'center', horizontal: 'left' }}
                anchorEl={document.getElementById('customer')}
            >
                <>
                    <SearchMenuItem key='input-search' disableRipple={true} disableGutters={true}>
                        <SearchTextField
                            label={translate('t.customer')}
                            value={searchCustomer}
                            onChange={onInputChange}
                            style={{ width: '300px' }}
                            size='small'
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant='outlined'
                            onKeyDown={(e) => {
                                e.stopPropagation();
                            }}
                        />
                    </SearchMenuItem>
                    {customerData && customerData.length > 1 ? (
                        <Box display='flex' alignItems='center'>
                            <Typography sx={{ pt: 2, pb: 2, ml: 1 }} variant='body1'>
                                {translate('t.selected_customers')}: {selectedCustomers?.length || '0'}
                            </Typography>
                            {selectedCustomers && selectedCustomers.length ? (
                                <Tooltip title={translate('t.delete')} placement='right' arrow disableInteractive>
                                    <UiIconButton
                                        sx={{ ml: 1 }}
                                        onClick={() => clearSelectedCustomers()}
                                        testid='EnableOrDisableNotifications-Button'
                                    >
                                        <DeleteIcon color='error' />
                                    </UiIconButton>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                        </Box>
                    ) : (
                        <></>
                    )}
                    <UiScroll maxHeight={`${spaceToBottom && spaceToBottom > 240 ? spaceToBottom + 60 : '300'}px`}>
                        {generateContent()}
                    </UiScroll>
                </>
            </CustomerSelectorPopover>
        </CustomerSelectorContainer>
    );
};
