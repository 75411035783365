import React, { useMemo } from 'react';
import { TableProps, TableRowsProps } from '../SystemTotals.type';
import { useTranslation } from 'react-i18next';
import UiCircularGraph from 'components/Ui/Components/UiCircularGraph/UiCircularGraph';
import { TableBody } from '@mui/material';
import { CustomRow, GraphCell, SimpleCell } from '../SystemTotals.style';
import { getPercentage, getColorOfChartForSystemTotals } from 'helpers';

const TableRow = ({ value, label, percentage }: TableRowsProps): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <CustomRow>
            <SimpleCell align='center' id='default'>
                {translate(`t.${label}`)}
            </SimpleCell>
            <SimpleCell align='center'>{value}</SimpleCell>
            {percentage !== null && percentage !== undefined && (
                <GraphCell rowSpan={2} customColor={getColorOfChartForSystemTotals(percentage)}>
                    <UiCircularGraph value={percentage} color='inherit' />
                </GraphCell>
            )}
        </CustomRow>
    );
};

const SystemTotalsSimpleTable: React.FC<TableProps> = ({ data }) => {
    const dataToDisplay = useMemo(() => {
        return {
            transmittingVehicle:
                (data?.vehicles?.transmitting?.maintenance ?? 0) + (data?.vehicles?.transmitting?.operating ?? 0),
            notTransmittingVehicles:
                (data?.vehicles?.notTransmitting?.maintenance ?? 0) + (data?.vehicles?.notTransmitting?.operating ?? 0),
            transmittingSensors:
                (data?.internal?.transmitting?.maintenance ?? 0) +
                (data?.internal?.transmitting?.operating ?? 0) +
                (data?.external?.transmitting?.maintenance ?? 0) +
                (data?.external?.transmitting?.operating ?? 0),
            notTransmittingSensors:
                (data?.internal?.notTransmitting?.maintenance ?? 0) +
                (data?.internal?.notTransmitting?.operating ?? 0) +
                (data?.external?.notTransmitting?.maintenance ?? 0) +
                (data?.external?.notTransmitting?.operating ?? 0)
        };
    }, [data]);

    return (
        <TableBody data-testid='SystemTotal-SimpleTable'>
            <TableRow
                label='total_vehicles'
                value={dataToDisplay.transmittingVehicle + dataToDisplay.notTransmittingVehicles}
                percentage={getPercentage(
                    dataToDisplay.transmittingVehicle,
                    dataToDisplay.transmittingVehicle + dataToDisplay.notTransmittingVehicles
                )}
            />
            <TableRow label='transmitting_vehicles' value={dataToDisplay.transmittingVehicle} />
            <TableRow
                label='fitted_sensors'
                value={dataToDisplay.transmittingSensors + dataToDisplay.notTransmittingSensors}
                percentage={getPercentage(
                    dataToDisplay.transmittingSensors,
                    dataToDisplay.transmittingSensors + dataToDisplay.notTransmittingSensors
                )}
            />
            <TableRow label='transmitting_sensors' value={dataToDisplay.transmittingSensors} />
        </TableBody>
    );
};

export default SystemTotalsSimpleTable;
