import React, { useMemo } from 'react';
import {
    ContainerSchemmaVehicleStatus,
    SchemaMapTabs,
    IconLabel,
    SchemaMapTab
} from './VehicleGeneralDetailsTab.style';
import { VehicleGeneralDetailsTabProps } from './VehicleGeneralDetailsTab.type';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { applyStyleByMode, isVehicleTyreBayHub } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon';
import { faLocationDot, faCarSide } from '@fortawesome/pro-solid-svg-icons';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import VehicleDetailsSchema from '../VehicleDetailsSchema/VehicleDetailsSchema';
import TabContent from 'components/Ui/Components/UiTab/UiTab';
import VehicleDetailsMap from '../VehicleDetailsMap/VehicleDetailsMap';
import { Wrapper } from 'helpers/wrapper';
import StatisticsButton from '../StatisticsButton/StatisticsButton';
import { VehicleDetailsWidgetAtom } from '../VehicleDetailsWidget/VehicleDetailsWidget.atom';

export const VehicleGeneralDetailsTab: React.FC<VehicleGeneralDetailsTabProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [activeTab, setActiveTab] = React.useState(0);
    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());
    const isVehicleTyreBayHubVerified = useMemo(
        () => isVehicleTyreBayHub(vehicleDetailsWidgetAtom?.vehicleDetails?.vehicleConfigurationV2?.id || 0),
        [vehicleDetailsWidgetAtom.vehicleDetails]
    );
    const ThemeMode = useRecoilValue(Theme);
    const handleTabChange = (event, newTabIndex: number): void => setActiveTab(newTabIndex);

    return (
        <ContainerSchemmaVehicleStatus>
            <>
                <SchemaMapTabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor='primary'
                    textColor='primary'
                    variant='scrollable'
                    scrollButtons='auto'
                >
                    {!isVehicleTyreBayHubVerified && (
                        <SchemaMapTab
                            label={
                                <IconLabel>
                                    <UiIcon
                                        icon={faCarSide}
                                        fontSize={17}
                                        fixedWidth
                                        color={applyStyleByMode({
                                            styleOld: '#3f51b5',
                                            theme: ThemeMode?.mode,
                                            light: '#0000008a',
                                            dark: WHITE
                                        })}
                                        transform='up-1 left-2'
                                    />
                                    <>{translate('t.vehicle_schema')}</>
                                </IconLabel>
                            }
                            data-testid='VehicleStatus-vehicle-schema-Button'
                        />
                    )}
                    <SchemaMapTab
                        label={
                            <IconLabel>
                                <UiIcon
                                    icon={faLocationDot}
                                    fontSize={15}
                                    fixedWidth
                                    color={applyStyleByMode({
                                        styleOld: '#777',
                                        theme: ThemeMode?.mode,
                                        light: '#0000008a',
                                        dark: WHITE
                                    })}
                                    transform='up-1 left-3'
                                />
                                <>{translate('t.location')}</>
                            </IconLabel>
                        }
                        data-testid='VehicleStatus-current-location-Button'
                    />
                </SchemaMapTabs>
                {!isVehicleTyreBayHubVerified && (
                    <TabContent value={activeTab} index={0} testId='VehicleDetailsSchema'>
                        <VehicleDetailsSchema {...props} />
                    </TabContent>
                )}
                <TabContent
                    value={activeTab}
                    index={isVehicleTyreBayHubVerified ? 0 : 1}
                    testId='VehicleDetailsLocation'
                    padding='14px 0'
                >
                    <VehicleDetailsMap vehicleId={props.vehicleId} />
                </TabContent>
            </>
            <StatisticsButton vehicleId={props.vehicleId} />
        </ContainerSchemmaVehicleStatus>
    );
};

export default Wrapper(VehicleGeneralDetailsTab);
