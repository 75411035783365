export type AppRegionModel = {
    id: number;
    name: string;
    provider: string;
};

export type ChangeAppRegion = {
    appRegionId?: number;
};

export enum AppRegionQueryKeys {
    getAllAsCodebook = 'appRegion-getAllAsCodebook'
}
