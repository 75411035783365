import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { HubsDeviceViewContent, OptionDevice, Text } from './HubsDevice.style';
import { HubsDeviceViewProps } from './HubsDevice.type';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import DeviceCommandsTable from '../../components/Hub/DeviceCommandsTable/DeviceCommandsTable';
import DeviceDetailsTable from '../../components/Hub/DeviceDetailsTable/DeviceDetailsTable';
import DeviceStatisticsTable from '../../components/Hub/DeviceStatisticsTable/DeviceStatisticsTable';
import HistoryPlacementTable from '../../components/Hub/HistoryPlacementTable/HistoryPlacementTable';
import { checkRole } from 'helpers/authentication';
import { UserInfo } from '../../states/global/User';
import { ROLES } from 'variables';
import LazyloadingAutocomplete from '../../components/Mixs/LazyloadingAutocomplete';
import Device from '../../api/Device';
import { DeviceQueryKeys } from 'models/Device.type';
import { DeviceModel } from 'models/Device.type';

const deviceApi = new Device();

export const HubsDeviceContent: React.FC<HubsDeviceViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);

    useEffect(() => {
        document.title = `${translate('t.hubs')} - ${props.deviceId}`;
    }, [props.deviceId]);

    if (props.isError) {
        return (
            <HubsDeviceViewContent data-testid='HubsDeviceContent'>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <Text variant='h3'>
                            {translate('t.error_requesting')} device/{props.deviceId}
                        </Text>
                    </Grid>
                </Grid>
            </HubsDeviceViewContent>
        );
    }

    return (
        <HubsDeviceViewContent data-testid='HubsDeviceContent'>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <LazyloadingAutocomplete
                        id='hubs-device'
                        queryId={DeviceQueryKeys.getLazyDropdown}
                        optionKey='id'
                        optionValue='id'
                        width={150}
                        size='small'
                        customMargin='0px 0px 5px 0px'
                        query={deviceApi.getLazyLoadingDropdown}
                        label='Hubs'
                        onValueChange={(value) => {
                            if (value && value !== props.deviceId) {
                                props.changePage(value);
                            }
                        }}
                        getOptionLabel={(option) => {
                            const optionDevice = option as DeviceModel;
                            return {
                                html: (
                                    <OptionDevice $outOfService={optionDevice.vehicle?.outOfService}>
                                        {optionDevice.id}
                                    </OptionDevice>
                                ),
                                text: null
                            };
                        }}
                        preselectedValue={{
                            value: props.deviceId || 0,
                            label: `${props.deviceId}` || '',
                            origItem: {}
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                    {!checkRole(userInfo.user, [ROLES.ROLE_TECHNICAL_USER]) && (
                        <DeviceCommandsTable deviceId={props.deviceId ?? 0} />
                    )}
                    {checkRole(userInfo.user, [
                        ROLES.ROLE_SUPER_ADMIN,
                        ROLES.ROLE_ADMIN,
                        ROLES.ROLE_TECHNICAL_ADMIN,
                        ROLES.ROLE_TECHNICAL_USER
                    ]) && <HistoryPlacementTable deviceId={props.deviceId ?? 0} />}
                </Grid>

                <Grid item xs={12} md={6} lg={6} xl={6}>
                    <DeviceDetailsTable deviceId={props.deviceId ?? 0} />
                    {!props.isLoadingCustomerId && (
                        <DeviceStatisticsTable
                            deviceId={props.deviceId ?? 0}
                            customerId={props.customerId}
                            lastTimeConnected={props.lastTimeConnected}
                        />
                    )}
                </Grid>
            </Grid>
        </HubsDeviceViewContent>
    );
};
