export type VehicleConfigurationModel = {
    axles: number;
    chasis: string;
    distribution: string;
    id: number;
    wheels: number;
};

export type VehicleConfigurationsType = {
    codeBook: ConfigurationV2[];
};

export type ConfigurationV2 = {
    active: boolean;
    axles: number;
    distribution: string;
    id: number;
    wheels: number;
};

export enum VehicleConfigurationQueryKeys {
    getV2 = 'vehicleConfiguration-getV2'
}
