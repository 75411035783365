import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { DeviceStatisticsTableContent } from './DeviceStatisticsTable.view';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DeviceStatisticsTableProps } from './DeviceStatisticsTable.type';
import { Grid } from '@mui/material';
import Device from 'api/Device';
import TrackLog from 'api/TrackLog';
import { useTranslation } from 'react-i18next';
import { Wrapper } from 'helpers/wrapper';
import { GpsStatisticsGrid, TextModal } from './DeviceStatisticsTable.style';
import DeviceBatteryVoltageGraph from '../DeviceBatteryVoltageGraph/DeviceBatteryVoltageGraph';
import { UserInfo } from 'states/global/User';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { DateTime } from 'luxon';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { DeviceQueryKeys } from 'models/Device.type';
import { TrackLogQueryKeys } from 'models/TrackLog.type';

const deviceApi = new Device();
const tracklogApi = new TrackLog();

const DeviceStatisticsTable: React.FC<DeviceStatisticsTableProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const userInfo = useRecoilValue(UserInfo);
    const [distinctCustomer, setDistinctCustomer] = useState<boolean>(false);

    const { data: statisticsData, isLoading: statisticsLoading } = useQuery(
        [DeviceQueryKeys.getStatistics, props.deviceId],
        () => deviceApi.getStatistics(props.deviceId),
        {
            staleTime: cacheTimeToMilliseconds(5, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(5, 'minutes'),
            retry: false,
            keepPreviousData: true,
            refetchOnWindowFocus: false
        }
    );

    const {
        data: gpsStatisticsData,
        isLoading: gpsStatisticsLoading,
        refetch: refetchGpsStatistics
    } = useQuery(
        [TrackLogQueryKeys.getGPSStatistics, props.deviceId],
        () => tracklogApi.getGPSStatistics(props.deviceId),
        {
            enabled: false,
            retry: false,
            staleTime: cacheTimeToMilliseconds(5, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(5, 'minutes'),
            keepPreviousData: true,
            refetchOnWindowFocus: false
        }
    );

    const handleStatisticsModal = (contentModal) => {
        modalDispach({
            type: ModalActionTypesEnum.SET_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModal,
                isOpen: true
            }
        });
    };

    const handleModalGps = () => {
        updateModalGps(true);
    };

    const updateModalGps = (isOpen?: boolean) => {
        const contentModal = {
            id: 'gpsStaticstics',
            leftTitle: `${translate('t.gps_statistics')} ${translate('t.graph')}`,
            content: (
                <>
                    {gpsStatisticsLoading ? (
                        <UiLoadingPage size='25px' />
                    ) : (
                        <GpsStatisticsGrid container>
                            <Grid item xs={5} md={5}>
                                <TextModal>{translate('t.visible_satelites_average')}:</TextModal>
                            </Grid>
                            <Grid item xs={7} md={7}>
                                <TextModal>
                                    {gpsStatisticsData?.averageSatellites
                                        ? Math.round(
                                              typeof gpsStatisticsData.averageSatellites === 'string'
                                                  ? parseFloat(gpsStatisticsData.averageSatellites)
                                                  : gpsStatisticsData.averageSatellites
                                          )
                                        : '-'}
                                </TextModal>
                            </Grid>
                            <Grid item xs={5} md={5}>
                                <TextModal>{translate('t.gps_records')}:</TextModal>
                            </Grid>
                            <Grid item xs={7} md={7}>
                                <TextModal>{gpsStatisticsData?.logsInPeriod ?? '-'}</TextModal>
                            </Grid>
                            <Grid item xs={5} md={5}>
                                <TextModal>{translate('t.gps_records_no_satelites')}:</TextModal>
                            </Grid>
                            <Grid item xs={7} md={7}>
                                <TextModal>{gpsStatisticsData?.logsInPeriodNoSatellites ?? '-'}</TextModal>
                            </Grid>
                        </GpsStatisticsGrid>
                    )}
                </>
            ),
            buttons: <></>,
            width: 600,
            height: 150,
            widthUnit: 'px',
            onClose: () =>
                modalDispach({
                    type: ModalActionTypesEnum.SET_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModal,
                        isOpen: false
                    }
                })
        };

        modalDispach({
            type: ModalActionTypesEnum.SET_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModal,
                isOpen: typeof isOpen === 'undefined' ? modalAtom.isOpen : isOpen
            }
        });
    };

    const handleModalBattery = () => {
        const contentModal = {
            id: 'batteryVoltage',
            leftTitle: `${translate('t.battery_voltage')}`,
            content: (
                <>
                    <DeviceBatteryVoltageGraph deviceId={props.deviceId} />
                </>
            ),
            buttons: <></>,
            width: 600,
            height: 500,
            widthUnit: 'px',
            onClose: () =>
                modalDispach({
                    type: ModalActionTypesEnum.SET_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModal,
                        isOpen: false
                    }
                })
        };
        handleStatisticsModal(contentModal);
    };

    useEffect(() => {
        updateModalGps();
    }, [gpsStatisticsLoading]);

    useEffect(() => {
        if (props.customerId != 0 && userInfo.user?.customer && userInfo.user?.customer.id != props.customerId)
            setDistinctCustomer(true);

        return () => {
            setDistinctCustomer(false);
        };
    }, [userInfo.user?.customer]);

    const getDiffFromLastTransmitted = (lastTransmitted?: string | null): string => {
        if (!lastTransmitted) return '';

        const lastTransmittedDate = DateTime.fromISO(lastTransmitted, { setZone: 'utc' });
        const current = DateTime.local().toUTC();

        const diffInSeconds: number = Math.floor(current.diff(lastTransmittedDate, ['seconds']).values.seconds);

        if (diffInSeconds < 60) return `(${diffInSeconds} ${translate('t.seconds')})`;
        if (diffInSeconds < 7200) return `(${Math.floor(diffInSeconds / 60)} ${translate('t.minutes')})`;

        if (diffInSeconds < 172800) return `(${Math.floor(diffInSeconds / 3600)} ${translate('t.hours')})`;

        return `(${Math.floor(diffInSeconds / 86400)} ${translate('t.days')})`;
    };

    return (
        <DeviceStatisticsTableContent
            statisticsData={statisticsData ?? undefined}
            statisticsLoading={statisticsLoading}
            data-testid='DeviceStatisticsTable-testid'
            handleModalGps={handleModalGps}
            refetchGps={refetchGpsStatistics}
            handleModalBattery={handleModalBattery}
            distinctCustomer={distinctCustomer}
            deviceId={props.deviceId}
            lastTimeConnected={props.lastTimeConnected}
            getDiffFromLastTransmitted={getDiffFromLastTransmitted}
        />
    );
};

export default Wrapper(DeviceStatisticsTable);
