import { BLUE, ERROR, PRIMARY, SUCCESS } from '../../Ui/colors';
import { AlertFontSize } from '../../Ui/Theme';
import styled from 'styled-components';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

export const Title = styled.div`
    font-weight: bold;
`;

export const NewAlertIcon = styled(UiIconButton)<{
    $pulse?: boolean;
    $oldPortalMode?: boolean;
    $statusNotification?: boolean;
}>`
    @keyframes opacityChange {
        0% {
            background: ${PRIMARY};
        }
        50% {
            background: ${SUCCESS};
        }
        100% {
            background: ${PRIMARY};
        }
    }
    padding: 7px !important;
    ${(props) =>
        props.$statusNotification && !props.$oldPortalMode
            ? `
            color: ${ERROR} !important;
        `
            : ''}
    ${(props) =>
        props.$oldPortalMode &&
        `
            color: #3a3633 !important;
            background-color: rgb(242 242 242) !important;
            border-radius: 2px !important;
            border: thin solid #bfbfbf !important;
            height: 30px !important;
            width: 30px !important;
            top: 10px !important;
            && .MuiBadge-badge{
                font-size: ${AlertFontSize} !important;
            }
        `}
    && .MuiBadge-badge {
        border-radius: 4px !important;
        ${(props) =>
            props.$pulse
                ? `
                animation-name: opacityChange;
                animation-duration: 1.5s;
                animation-iteration-count: infinite;`
                : `background-color: ${BLUE} !important;`}
    }
`;
