import React from 'react';
import { SpinnerContainer, WhatsNewAnnouncementContainer } from './WhatsNewAnnouncement.style';
import { WhatsNewAnnouncementProps } from './WhatsNewAnnouncement.type';
import HelpjuiceApi from 'api/Helpjuice';
import { useQuery } from '@tanstack/react-query';
import { Box, CircularProgress } from '@mui/material';

const Helpjuice = new HelpjuiceApi();

const WhatsNewAnnouncementContent: React.FC<WhatsNewAnnouncementProps> = ({ articleId }): JSX.Element => {
    const { isLoading: articlesLoading, data: articleData } = useQuery(
        ['article'],
        () => Helpjuice.getArticle(articleId),
        {
            refetchOnWindowFocus: false
        }
    );

    return (
        <WhatsNewAnnouncementContainer data-testid='WhatsNewAnnouncementContent'>
            {articlesLoading ? (
                <SpinnerContainer data-testid='ArticleSpinnerContainer'>
                    <CircularProgress data-testid='ArticleSpinner' />
                </SpinnerContainer>
            ) : (
                <Box dangerouslySetInnerHTML={{ __html: articleData?.article.answer.body || <></> }} />
            )}
        </WhatsNewAnnouncementContainer>
    );
};

export default WhatsNewAnnouncementContent;
