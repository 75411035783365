import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { HubsDeviceContent } from './HubsDevice.view';
import { HubsDeviceProps } from './HubsDevice.type';
import { DeviceQueryKeys } from 'models/Device.type';
import Device from 'api/Device';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from 'helpers/wrapper';

const deviceApi = new Device();

const HubsDevice: React.FC<HubsDeviceProps> = (): JSX.Element => {
    const navigate = useNavigate();

    const changePage = (id: string | number | null): void => {
        if (id) {
            navigate(`/device/${id}`);
        }
    };

    const getDeviceId = (): number | undefined => {
        let destructUrl: string[];
        let positionId: number;

        destructUrl = document.location.pathname.split('/');
        positionId = process.env.REACT_APP_REDIRECT_MODE === 'TRUE' ? 3 : 2;

        if (destructUrl.length > 0) {
            return +destructUrl[positionId];
        }

        return undefined;
    };
    const deviceId: number | undefined = getDeviceId();

    const {
        isError,
        data: deviceData,
        isLoading: isLoadingDeviceDetails
    } = useQuery([DeviceQueryKeys.getDetails, deviceId], () => deviceApi.getDetails(deviceId), {
        retry: false,
        refetchOnWindowFocus: false,
        staleTime: cacheTimeToMilliseconds(15, 'minutes'),
        cacheTime: cacheTimeToMilliseconds(15, 'minutes')
    });

    return (
        <HubsDeviceContent
            data-testid='HubsDevice-testid'
            deviceId={deviceId}
            isError={isError}
            customerId={deviceData?.device.customer?.id ?? 0}
            lastTimeConnected={deviceData?.device.deviceInfo.lastTransmission || null}
            isLoadingCustomerId={isLoadingDeviceDetails}
            changePage={changePage}
        />
    );
};

export default Wrapper(HubsDevice);
