import React, { useEffect } from 'react';
import { TextBox } from '../TyreManager.style';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { TyreStateAtom, UnmountDateAtom } from '../TyreManager.atom';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { VARIANT_BUTTON } from 'components/Ui/variables';
import useModal from 'components/CustomHooks/ModalHook';
import UiButton from 'components/Ui/Components/UiButton';
import Tyre from 'api/Tyre';
import { useMutation } from '@tanstack/react-query';
import { FetchVehicleDetailsData } from 'components/Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { TyreQueryKeys } from 'models/Tyre.type';

const tyreAPI = new Tyre();

const UnmountTyre = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const tyreState = useRecoilValue(TyreStateAtom);
    const unmountDate = useRecoilValue(UnmountDateAtom);
    const { dateTimeFormat, fromTimezoneToUTC } = useConverter();
    const { updateModalState } = useModal(1);
    const setFetchVehicleDetailsData = useSetRecoilState(FetchVehicleDetailsData);

    const { mutate } = useMutation(
        [TyreQueryKeys.unmountTyre],
        () =>
            tyreAPI.unmountTyre({
                date: fromTimezoneToUTC(unmountDate),
                tyreId: tyreState.originalTyre.id
            }),
        {
            onSettled: () => {
                setFetchVehicleDetailsData((prevState) => !prevState);
            }
        }
    );

    useEffect(() => {
        updateModalState({
            buttons: (
                <UiButton id='unmount-tyre' skin='error' variant={VARIANT_BUTTON} onClick={() => mutate()}>
                    {translate(`t.${tyreState.state}`)}
                </UiButton>
            )
        });
    }, [tyreState.state]);

    return (
        <TextBox>
            <span>
                {`${translate('p.do_you_really_want_unmount_tyre')} `}
                <b>{tyreState.newTyre.tyreSerialNumber}</b>
            </span>
            <span>
                {`${translate('t.unmounted_at')} `}
                <b>{unmountDate.toFormat(dateTimeFormat('dateTime', false))}</b>
            </span>
        </TextBox>
    );
};

export default UnmountTyre;
