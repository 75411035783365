import { JiraIssueProjectModel } from './JiraIssueProject.type';

export type JiraModel = {
    id: number;
    description: string;
    translationKey: string;
    jiraIssueProject: JiraIssueProjectModel;
    jiraIssue: {
        jiraPriorityId: number;
    };
};

export enum JiraQueryKeys {
    getLazyDropdown = 'jira-getLazyDropdown',
    getLazyDropdownIssueType = 'jira-getLazyDropdownIssueType'
}
