import { Paginator } from './Paginator.type';

export type NotificationModel = {
    id: number;
    type: string;
    type2: string;
    level?: number;
    assignation?: {
        portal: number;
        email: number;
        mobile: number;
        whatsapp: number;
    };
};

export enum TypeEnum {
    AREA = 'area',
    PRESSURE = 'pressure',
    PRESSURE_HOT = 'pressure_hot',
    PRESSURE_LEAK = 'pressure_leak',
    TEMPERATURE = 'temperature',
    TERMPERATURE_PREDICTION = 'temperature_prediction',
    MDD_BES_TYRE_LOG = 'mdd_bes_tyre_log'
}

export enum Type2Enum {
    MAINTENANCE = 'maintenance',
    HIGH = 'high',
    LOW = 'low',
    NORMAL = 'normal',
    FAST = 'fast',
    SLOW = 'slow',
    AMBIENT = 'ambient'
}

export type NotificationsSettings = {
    email: NotificationSeetingValue;
    level: null | 1 | 2 | 3;
    mobile: NotificationSeetingValue;
    portal: NotificationSeetingValue;
    type: TypeEnum;
    type2: Type2Enum;
    whatsapp: NotificationSeetingValue;
    id: number;
};

export type NotificationSettingsPatch = {
    email: number;
    mobile: number;
    portal: number;
    whatsapp: number;
    id: number;
};

//will be removed
export type TyreNotificationsResponse = {
    tyreTotalAlerts: TyreNotification[];
};

export type TyreNotification = {
    type: string;
    level: number;
    count: number;
    hours: number;
};

export type NotificationsSettingsData = {
    area: NotificationsSettings[];
    pressure: NotificationsSettings[];
    pressure_hot: NotificationsSettings[];
    pressure_leak: NotificationsSettings[];
    speed: NotificationsSettings[];
    temperature: NotificationsSettings[];
};

export type NotificationSeetingValue = {
    allowed: boolean;
    value: boolean;
};

export type NotificationsWithPaginator = {
    items: NotificationModel[];
    paginator: Paginator;
};

export type UsersSubscribed = {
    subscribed: {
        user: [
            {
                id: number;
                username: string;
                firstname: string;
                lastname: string;
            }
        ];
        role: [
            {
                id: number;
                name: string;
            }
        ];
    };
};

export enum NotificationType {
    PORTAL = 'portal',
    EMAIL = 'email',
    MOBILE = 'mobile',
    WHATSAPP = 'whatsapp'
}

export type SubscribedParams = {
    notificationId: number;
    type: NotificationType;
};

export enum NotificationQueryKeys {
    getNotificationCodebook = 'notification-getNotificationCodebook',
    getSubscribed = 'notification-getSubscribed'
}
