export type SensorMovements = {
    id: number;
    fittingReason: string | null;
    removalReason: string | null;
};

export enum SensorMovementQueryKeys {
    getSensorsUnmountReason = 'sensorMovement-getSensorsUnmountReason',
    getSensorsFittingReason = 'sensorMovement-getSensorsFittingReason'
}
