import React, { useMemo, useState } from 'react';
import { TestResultTableProps } from './NewHubsTestResultTable.type';
import { NewHubsTestResultTableContent } from './NewHubsTestResultTable.view';
import { useMutation, useQuery } from '@tanstack/react-query';
import DeviceCommand from 'api/DeviceCommand';
import { DeviceCommandQueryKeys, ExecutedDeviceCommandsTable } from 'models/DeviceCommand.type';
import DeviceDiagnose from 'api/DeviceDiagnose';
import { DeviceDiagnoseModel, DeviceDiagnoseQueryKeys, DiagnoseSensor } from 'models/DeviceDiagnose.type';
import { checkErrorsInCMD30 } from 'helpers';
import { DiagnoseTable } from '../DeviceDiagnoseTable/DeviceDiagnoseTable.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';

const deviceCommandAPI = new DeviceCommand();
const deviceDiagnoseAPI = new DeviceDiagnose();

const NewHubsTestResultTable: React.FC<TestResultTableProps> = ({ hubData }): JSX.Element => {
    const [testResultId, setTestResultId] = useState<number>(0);
    const [loadingTestResults, setLoadingTestResults] = useState<boolean>(false);

    const enableTests = useMemo<boolean>(() => {
        const hubService = hubData?.deviceInfo.service;
        if (hubService?.length) {
            if (Number(hubService[0]) > 1) {
                return true;
            }
            const hubServiceVersion = +hubService?.slice(0, 4);
            if (isNaN(hubServiceVersion)) {
                return false;
            }
            return hubServiceVersion >= 1.15;
        }
        return false;
    }, [hubData]);

    const { isLoading: isLoadingList, data: listData } = useQuery(
        [DeviceDiagnoseQueryKeys.getList, hubData.id, loadingTestResults],
        () => deviceDiagnoseAPI.getList(hubData.id),
        {
            enabled: !loadingTestResults && enableTests,
            refetchOnWindowFocus: true,
            keepPreviousData: true,
            staleTime: cacheTimeToMilliseconds(5, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(5, 'minutes'),
            onSuccess: (data) => {
                if (data.items.length) {
                    setTestResultId(data.items[0].id);
                }
            }
        }
    );

    const mutation = useMutation<unknown, unknown, void>({
        mutationFn: () =>
            deviceCommandAPI.post({
                data: {
                    commandName: 'self_diagnostics',
                    value: null,
                    deviceIds: [hubData.id]
                }
            }),
        onSuccess: () => {
            setLoadingTestResults(true);
            setTestResultId(0);
        }
    });

    useQuery<ExecutedDeviceCommandsTable>(
        [DeviceCommandQueryKeys.getLastDeviceCommand, hubData.id],
        () => deviceCommandAPI.getLastDeviceCommand(hubData.id),
        {
            enabled: loadingTestResults,
            refetchInterval: 10000,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: (data: ExecutedDeviceCommandsTable) => {
                if (data.items.length > 0 && data.items[0].executed) {
                    refetchLastCMD().then(() => setLoadingTestResults(false));
                }
            }
        }
    );

    const { isLoading: isLoadingFromList, data: dataFromList } = useQuery(
        [DeviceDiagnoseQueryKeys.getOne, hubData.id, testResultId],
        () => deviceDiagnoseAPI.getOne(testResultId),
        {
            enabled: testResultId > 0,
            staleTime: 0,
            cacheTime: 0,
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false
        }
    );

    const {
        isFetching: isFetchingCMD,
        data: lastCMDData,
        refetch: refetchLastCMD
    } = useQuery<DeviceDiagnoseModel>(
        [DeviceDiagnoseQueryKeys.getById, hubData.id],
        () =>
            deviceDiagnoseAPI.getById({
                id: hubData.id
            }),
        {
            cacheTime: cacheTimeToMilliseconds(5, 'minutes'),
            staleTime: cacheTimeToMilliseconds(5, 'minutes'),
            refetchOnWindowFocus: false,
            refetchInterval: false,
            retry: false,
            enabled: false
        }
    );

    const getValuesWithAlertFirst = (
        data: DeviceDiagnoseModel
    ): [string, string | number | DiagnoseSensor[] | null][] => {
        let entries = Object.entries(data);
        let entriesWithAlert: [string, string | number | DiagnoseSensor[] | null][] = [];
        let entriesWithoutAlert: [string, string | number | DiagnoseSensor[] | null][] = [];

        for (let i = 0; i < entries.length; i++) {
            const value: [string, string | number | DiagnoseSensor[] | null] = entries[i];
            if (checkErrorsInCMD30(value[0] as DiagnoseTable, value[1], data.deviceDiagnoseSensor.length)) {
                entriesWithAlert.push(value);
            } else {
                entriesWithoutAlert.push(value);
            }
        }

        return entriesWithAlert.concat(entriesWithoutAlert);
    };

    return (
        <NewHubsTestResultTableContent
            data={testResultId > 0 ? dataFromList?.deviceDiagnose : lastCMDData}
            listData={listData?.items}
            isLoading={isFetchingCMD || isLoadingFromList || loadingTestResults}
            isLoadingList={isLoadingList}
            testResultId={testResultId}
            mutation={mutation}
            setTestResultId={setTestResultId}
            enableTests={enableTests}
            getValuesWithAlertFirst={getValuesWithAlertFirst}
        />
    );
};

export default NewHubsTestResultTable;
