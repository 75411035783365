import React from 'react';
import { Row, RowData, RowName, StyledDiv, Table, TableColumn, TableRow } from '../PredictedGraph.style';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { faFilter, faStopwatch, faThermometer, faTire } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { InformationTableProps } from '../PredictedGraph.type';
import useConverter from '../../../CustomHooks/Converter/Converter';
import { Grid } from '@mui/material';

const InformationTable = (props: InformationTableProps): JSX.Element => {
    const { t: translate } = useTranslation();
    const { convertType, fromServerToUserUnit, fromUTCtoUserTimezone } = useConverter();

    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={8}>
                <StyledDiv>
                    <Table>
                        <tbody>
                            <TableRow>
                                <TableColumn>
                                    <Row>
                                        <UiIcon icon={['fas', 'truck']} />
                                        <RowName>{translate('t.vehicle_name')}:</RowName>
                                        <RowData>{props.vehicleName}</RowData>
                                    </Row>
                                </TableColumn>
                                <TableColumn>
                                    <Row>
                                        <UiIcon icon={faTire} />
                                        <RowName>{translate('t.wheel_position')}:</RowName>
                                        <RowData>{props.wheelPosition}</RowData>
                                    </Row>
                                </TableColumn>
                            </TableRow>
                            <TableRow>
                                <TableColumn>
                                    <Row>
                                        <UiIcon icon={faThermometer} />
                                        <RowName>{translate('t.tyre_temperature')}:</RowName>
                                        <RowData>
                                            {fromServerToUserUnit({
                                                displayIfEmpty: '-',
                                                displayUnits: true,
                                                fixed: 2,
                                                type: convertType.temperature,
                                                value: props.tyreTemperature
                                            })}
                                        </RowData>
                                    </Row>
                                </TableColumn>
                                <TableColumn>
                                    <Row>
                                        <UiIcon icon={faStopwatch} />
                                        <RowName>{translate('t.measured_at')}:</RowName>
                                        <RowData>
                                            {fromUTCtoUserTimezone({
                                                displayIfEmpty: '-',
                                                date: props.measuredAt,
                                                displaySeconds: false,
                                                format: 'dateTime'
                                            })}
                                        </RowData>
                                    </Row>
                                </TableColumn>
                            </TableRow>
                            <TableRow>
                                <TableColumn>
                                    <Row>
                                        <UiIcon icon={faStopwatch} />
                                        <RowName>{translate('t.remaining_time')}:</RowName>
                                        <RowData>{props.remainingHours}</RowData>
                                    </Row>
                                </TableColumn>
                                <TableColumn>
                                    <Row>
                                        <UiIcon icon={faFilter} />
                                        <RowName>{translate('t.last_hub_transmission')}:</RowName>
                                        <RowData>
                                            {fromUTCtoUserTimezone({
                                                displayIfEmpty: '-',
                                                date: props.lastHubTransmission,
                                                displaySeconds: false,
                                                format: 'dateTime'
                                            })}
                                        </RowData>
                                    </Row>
                                </TableColumn>
                            </TableRow>
                            <TableRow>
                                <TableColumn colSpan={2}>
                                    <Row>
                                        <UiIcon icon={faStopwatch} />
                                        <RowName>
                                            {translate('t.time_to_reach')}(
                                            {fromServerToUserUnit({
                                                type: convertType.temperature,
                                                value: props.criticalTemperature,
                                                displayUnits: true,
                                                fixed: 1
                                            })}
                                            )
                                        </RowName>
                                        <RowData>
                                            {fromUTCtoUserTimezone({
                                                displayIfEmpty: '-',
                                                date: props.criticalTemperatureAt,
                                                displaySeconds: false,
                                                format: 'dateTime'
                                            })}
                                        </RowData>
                                    </Row>
                                </TableColumn>
                            </TableRow>
                        </tbody>
                    </Table>
                </StyledDiv>
            </Grid>
        </Grid>
    );
};

export default InformationTable;
