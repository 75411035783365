import React, { useEffect, useMemo } from 'react';
import { Wrapper } from 'helpers/wrapper';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { TopBarAtom } from 'components/Mixs/DataExport/TopBar/TopBar.atom';
import UiWidget from 'components/Ui/Components/UiWidget';
import { TemperatureNotificationsContainer } from './TemperatureNotifications.style';
import TyreNotification from 'api/TyreNotification';
import DataExport from 'components/Mixs/DataExport';
import { useQuery } from '@tanstack/react-query';
import { Success } from 'components/Popup';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { applyStyleByMode } from 'helpers';
import { faBullhorn } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import NotificationEvent from 'api/NotificationEvent';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { NotificationEventQueryKeys } from 'models/NotificationEvent.type';

//todo: Check with Marek if we can move csv report to notification event so will have everything together
const tyreNotificationsAPI = new TyreNotification();
const notificationEventAPI = new NotificationEvent();

const TemperatureNotifications: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const topBarAtom = useRecoilValue(TopBarAtom);
    const ThemeMode = useRecoilValue(Theme);
    const { fromUTCtoUserTimezone, convertType, fromServerToUserUnit } = useConverter();
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    const { refetch } = useQuery(
        [NotificationEventQueryKeys.getTempTyreNotificationsReportsCsv],
        () =>
            tyreNotificationsAPI.getTempTyreNotificationsReportsCsv(
                topBarAtom.vehicle.id,
                topBarAtom.dateFrom.toUTC().toISO(),
                topBarAtom.dateTo.toUTC().toISO()
            ),
        {
            enabled: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: () => Success({ text: translate('p.report_success_message') })
        }
    );

    const getColumns = useMemo(() => {
        return [
            {
                header: translate('t.vehicle_name'),
                accessor: 'vehicleName'
            },
            {
                header: translate('t.measured_at'),
                accessor: 'measuredAt',
                parseData: (props) => fromUTCtoUserTimezone({ date: props, format: 'dateTime' })
            },
            {
                header: translate('t.wheel'),
                accessor: 'customPosition'
            },
            {
                header: translate('t.level'),
                accessor: 'level'
            },
            {
                header: translate('t.limit_temperature'),
                accessor: 'limit_from',
                parseData: (props) =>
                    fromServerToUserUnit({
                        type: convertType.temperature,
                        value: props,
                        displayUnits: true,
                        fixed: 1
                    })
            }
        ];
    }, [translate, fromUTCtoUserTimezone, fromServerToUserUnit, convertType.temperature]);

    useEffect(() => {
        setPageHelpButton({ article: 'data-exports-tab' });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return (
        <TemperatureNotificationsContainer data-testid='TemperatureNotifications-testid'>
            <UiWidget
                title={translate('t.temperature_notifications')}
                id='temperature-notifications'
                avatar={
                    <UiIcon
                        icon={faBullhorn}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                content={
                    <DataExport
                        columns={getColumns}
                        fetchFn={notificationEventAPI.getTempPreview}
                        downloadFn={refetch}
                        queryKey='TemperatureTyreNotification'
                        additionalFetchProps={{
                            vehicleId: topBarAtom.vehicle.id,
                            measuredFrom: topBarAtom.dateFrom.toUTC().toISO(),
                            measuredTo: topBarAtom.dateTo.toUTC().toISO()
                        }}
                    />
                }
            />
        </TemperatureNotificationsContainer>
    );
};

export default Wrapper(TemperatureNotifications);
