import styled from 'styled-components';
import { AlertFontSize } from '../../Ui/Theme';
import { BACKGROUND_BLUE, PRIMARY } from '../../Ui/colors';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

export const LogoutButton = styled(UiIconButton)`
    ${applyStyleByMode({
        styleOld: `
            color: #3a3633 !important;
            background-color: rgb(242 242 242) !important;
            border-radius: 2px !important;
            box-shadow: none !important;
            border: thin solid #bfbfbf !important;
            height: 30px !important;
            width: 30px !important;
            top: 10px !important;
            && .MuiBadge-badge{
                font-size: ${AlertFontSize} !important;
            }
        `
    })}
`;

export const LogoutButtonNavbar = styled(Button)<{ $collapsedSidebar?: boolean }>`
    background: ${() => useTheme().palette.background.paper} !important;
    color: ${() => useTheme().palette.text.primary} !important;
    text-transform: capitalize !important;
    font-weight: bold !important;
    margin-top: 10px !important;

    & .MuiSvgIcon-root {
        margin-right: ${({ $collapsedSidebar }) => ($collapsedSidebar ? '17px' : '14px')};
        font-size: 1.4rem;
        margin-left: auto;
    }

    &:hover {
        background: ${() => useTheme().palette.background.paper} !important;
        color: ${BACKGROUND_BLUE} !important;
        & .MuiListItemIcon-root {
            color: ${BACKGROUND_BLUE} !important;
        }
    }
`;

export const ModalTitle = styled.div`
    font-size: ${AlertFontSize};
    font-weight: bold;
    text-align: left;
`;

export const ModalMute = styled.p`
    margin-top: 20px;
    padding-right: 12px;
    text-align: right;

    & .MuiFormControlLabel-label {
        font-size: ${AlertFontSize} !important;
    }
`;

export const ModalContent = styled.div`
    margin: 10px 0px;
    font-size: ${AlertFontSize};
    text-align: center;
    ${applyStyleByMode({
        styleOld: `
            color: ${PRIMARY} !important;
            font-size: ${AlertFontSize};
        `
    })}
`;
