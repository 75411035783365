import React from 'react';
import { useQuery } from '@tanstack/react-query';
import CounterActionAPI from 'api/CounterAction';
import { CounterActionsProps } from './Tpms.type';
import { CounterActionQueryKeys } from 'models/CounterAction.type';
import { UserInfo } from 'states/global/User';
import { useRecoilValue } from 'recoil';

const counterActionsAPI = new CounterActionAPI();

export const GetCounterActions: React.FC<CounterActionsProps> = ({ children, onSuccess }): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const useQueryProperties = useQuery(
        [CounterActionQueryKeys.getOptions, userInfo.user?.customer.id],
        () => counterActionsAPI.getOptions(),
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess
        }
    );

    return <>{children(useQueryProperties)}</>;
};
