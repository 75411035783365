import Account from 'api/Account';
import React from 'react';
import * as view from './ChangePasswordForm.view';
import * as type from './ChangePasswordForm.type';
import { useTranslation } from 'react-i18next';
import { Success } from 'components/Popup/Popup';
import User from 'api/User';
import { Wrapper } from 'helpers/wrapper';

const AccountApi = new Account();
const UserApi = new User();

const ChangePasswordForm: React.FC<type.ChangePasswordFormProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const handleSubmit = async (
        changePasswordFormData,
        resetForm,
        setSubmitting: (isSubmitting: boolean) => void
    ): Promise<void> => {
        delete changePasswordFormData.newPasswordAgain;

        if (!props.isCurrentUser) {
            delete changePasswordFormData.currentPassword;
        }

        try {
            const GeneralPatch = props.isCurrentUser
                ? AccountApi.changePassword(changePasswordFormData)
                : UserApi.changePassword(props.userId as number, changePasswordFormData);

            const { data }: type.UserDataPost = await GeneralPatch;

            Success({
                text: translate(data.user ? 't.password_changed_successfully' : 't.fail_to_change_password')
            });
            resetForm({});

            if (props.isCurrentUser) {
                setTimeout(() => {
                    props.handleChangeTab(0);
                }, 2000);
            }
        } catch (error) {
            setSubmitting(false);
        }
    };

    return (
        <view.ChangePasswordFormContent
            data-testid={'ChangePasswordForm-testid'}
            handleSubmit={handleSubmit}
            isCurrentUser={props.isCurrentUser}
        />
    );
};

export default Wrapper(ChangePasswordForm);
