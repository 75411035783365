import React from 'react';
import { UploadedFilesProps } from './DragAndDrop.type';
import { UploadedFilesView, RemoveFileButton, FileName } from './DragAndDrop.style';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { faFile } from '@fortawesome/pro-light-svg-icons';

export const UploadedFiles: React.FC<UploadedFilesProps> = (props): JSX.Element => {
    return (
        <UploadedFilesView>
            <UiIcon icon={faFile} />
            <FileName>{props.file.name}</FileName>
            {props.showFileSize(props.file.size)}
            <RemoveFileButton onClick={() => props.removeFile(props.file.name)}>&times;</RemoveFileButton>
        </UploadedFilesView>
    );
};
