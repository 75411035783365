import React from 'react';
import { ShiftTimeTabManagerContent } from './ShiftTimeTabManager.view';
import { ShiftTimeTabManagerProps } from './ShiftTimeTabManager.type';
import { Wrapper } from 'helpers/wrapper';

const ShiftTimeTabManager: React.FC<ShiftTimeTabManagerProps> = (): JSX.Element => {
    return <ShiftTimeTabManagerContent data-testid='ShiftTimeTabManager-testid' />;
};

export default Wrapper(ShiftTimeTabManager);
