import React from 'react';
import UiWidget from '../../components/Ui/Components/UiWidget';
import UserPermissionsTable from '../../components/User/UserPermissionsTable/UserPermissionsTable';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { faUserCog } from '@fortawesome/pro-solid-svg-icons';
import { PermissionContent } from './Permission.style';
import { GetAvailableCustomersCodebookQuery } from '../../components/Queries/Customers';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import { Wrapper } from 'helpers/wrapper';

const Permission: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <PermissionContent>
            <GetAvailableCustomersCodebookQuery>
                {({ data }) => (
                    <UiWidget
                        data-testid='UserMinesPermissionsContent'
                        id='UserMinesPermissionsWidget'
                        title={translate('t.users')}
                        avatar={
                            <UiIcon
                                icon={faUserCog}
                                size='lg'
                                fixedWidth
                                color={applyStyleByMode({
                                    styleOld: '#fff',
                                    theme: ThemeMode?.mode,
                                    light: '#0000008a',
                                    dark: WHITE
                                })}
                            />
                        }
                        content={<UserPermissionsTable customers={data} />}
                    />
                )}
            </GetAvailableCustomersCodebookQuery>
        </PermissionContent>
    );
};

export default Wrapper(Permission);
