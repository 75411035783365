import React from 'react';
import { useRecoilState } from 'recoil';
import { EasyButton } from './EasyReadingButton.style';
import { EasyReadingButtonProps } from './EasyReadingButton.type';
import { Theme } from '../../../states/global/Theme';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const EasyReadingButton: React.FC<EasyReadingButtonProps> = (): JSX.Element => {
    const [theme, setTheme] = useRecoilState(Theme);
    const { t: translate } = useTranslation();
    const isFirefox: boolean = navigator.userAgent.includes('Firefox');

    const toggleEasyMode = () => {
        /*if (document.body) {
            const checkZoom = (document.body.style as CSSStyleDeclaration & { zoom: unknown }).zoom === '120%';

            if (checkZoom) {
                document.body.setAttribute('style', 'zoom: 100%');
            } else {
                document.body.setAttribute('style', 'zoom: 120%');

                pending, delete bigFont !!!!!!
            }
        }*/

        setTheme({
            ...theme,
            easyReadingMode: !theme.easyReadingMode
        });
    };

    return isFirefox ? (
        <></>
    ) : (
        <Tooltip
            title={translate(!theme.easyReadingMode ? 't.activate_big_fonts' : 't.deactivate_big_fonts')}
            placement='bottom'
            arrow
        >
            <EasyButton
                $active={theme.easyReadingMode}
                onClick={() => toggleEasyMode()}
                size='small'
                testid='EnableOrDisableBigFonts-Button'
            >
                <FormatSizeIcon fontSize='inherit' />
            </EasyButton>
        </Tooltip>
    );
};

export default EasyReadingButton;
