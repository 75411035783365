import React, { useEffect, useState } from 'react';
import * as view from './NotificationsSettingsForm.view';
import { useTranslation } from 'react-i18next';
import Account from 'api/Account';
import User from 'api/User';
import { useQuery } from '@tanstack/react-query';
import { Errors, Success } from 'components/Popup/Popup';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { NotificationSettingsPatch, NotificationsSettings, Type2Enum, TypeEnum } from 'models/Notification.type';
import { GroupedData, NotificationsSettingsFormProps } from './NotificationsSettingsForm.type';
import Box from '@mui/material/Box';
import { UserQueryKeys } from 'models/User.type';
import { AccountQueryKeys } from 'models/Account.type';
import { Wrapper } from 'helpers/wrapper';

const ApiAccount = new Account();
const ApiUser = new User();

const NotificationsSettingsForm: React.FC<NotificationsSettingsFormProps> = ({
    checkPermissionsUser,
    isCurrentUser,
    userId,
    setReloadPage,
    userData
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const [reloadForm, setReloadForm] = useState<boolean>(false);
    const [groupedData, setGroupedData] = useState<GroupedData>({});

    const {
        isLoading: notificationsLoading,

        isFetching: notificationsisFetching
    } = useQuery<NotificationsSettings[], Error, NotificationsSettings[]>(
        [isCurrentUser ? UserQueryKeys.getNotification : AccountQueryKeys.getNotification, isCurrentUser],
        () => (isCurrentUser ? ApiAccount.getNotification() : ApiUser.getNotification(userId as number)),
        {
            refetchOnWindowFocus: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess(data) {
                const groupedData: GroupedData = {};
                for (let i = 0, length = data.length; i < length; i++) {
                    let type = data[i].type;
                    if (!groupedData[type]) {
                        groupedData[type] = {};
                    }
                    if (!groupedData[type][data[i].type2]) {
                        groupedData[type][data[i].type2] = [];
                    }
                    groupedData[type][data[i].type2].push(data[i]);
                }

                setGroupedData(groupedData);
            }
        }
    );

    const notificationLabel = (type: string, type2: string): string => {
        const normalLabel = {
            temperature: 't.Within_optimal_range',
            pressure: 't.as_recommended_cold',
            pressure_hot: 't.as_recommended_hot'
        };
        if (type2 === Type2Enum.NORMAL) {
            return normalLabel[type];
        }
        if (type === TypeEnum.PRESSURE_LEAK) {
            return `t.${type2}_leak`;
        }
        if (type2 === Type2Enum.MAINTENANCE) {
            return 't.maintenance_zone_in_out';
        }
        if (type === TypeEnum.PRESSURE) {
            return `t.${type2}_cold_pressure`;
        }
        return `t.${type2}_${type}`;
    };

    const getNotificationData = (): NotificationSettingsPatch[] => {
        let notifications: NotificationSettingsPatch[] = [];
        Object.values(groupedData).forEach((group) => {
            Object.values(group).forEach((groupSettings) => {
                const data = groupSettings.map((d) => {
                    return {
                        id: d.id,
                        email: +d.email.value,
                        portal: +d.portal.value,
                        mobile: +d.mobile.value,
                        whatsapp: +d.whatsapp.value
                    };
                });
                notifications = [...notifications, ...data];
            });
        });
        return notifications;
    };

    const handleSubmit = async (): Promise<void> => {
        try {
            const settings = getNotificationData();
            const NotificationPatch = isCurrentUser
                ? ApiAccount.patchNotification({ notifications: settings })
                : ApiUser.patchNotification(userId as number, { notifications: settings });
            const notifications: NotificationsSettings[] = await NotificationPatch;
            if (notifications) {
                Success({ text: translate('t.notification_settings_modified_successfully') });
                setReloadPage(true);
            } else {
                throw new Error(notifications);
            }
        } catch (error) {
            Errors({ text: translate('t.error_modifying_notification_settings') });
        }
    };

    useEffect(() => {
        setReloadForm(false);
    }, [reloadForm]);

    return !notificationsLoading && !notificationsisFetching ? (
        <view.NotificationsSettingsFormContent
            data-testid={'NotificationsSettingsForm-testid'}
            handleSubmit={handleSubmit}
            isCurrentUser={isCurrentUser}
            userData={userData}
            data={groupedData}
            setData={setGroupedData}
            notificationLabel={notificationLabel}
            checkPermissionsUser={checkPermissionsUser}
        />
    ) : (
        <Box height={1500}>
            <UiLoadingPage size='30px' />
        </Box>
    );
};

export default Wrapper(NotificationsSettingsForm);
