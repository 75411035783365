import React from 'react';
import { UiPassProps } from './UiButton.type';
import { UiCustomButton } from './UiButton.style';
import { SIZE_BUTTON } from 'components/Ui/variables';
// import module

const UiButton: React.FC<UiPassProps> = ({ testid, ...newProps }) => {
    return <UiCustomButton {...newProps} size={SIZE_BUTTON} data-testid={testid || 'UiButtonContents'} />;
};

export default UiButton;
