import React, { useEffect, useCallback } from 'react';
import { TyreLifespanComparisonContent } from './TyreLifespanComparison.view';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { HandleTyreChangeProps, TyreLifespanComparisonProps } from './TyreLifespanComparison.type';
import { Wrapper } from 'helpers/wrapper';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Tyre from 'api/Tyre';
import { DateTime, Interval, Settings } from 'luxon';
import { BesRankTimeline, BesRankTicks } from '../SeverityRank/atom';
import { TyreLifeSpanDataAtom, TyreLifespanDataDefaultValue } from './TyreLifespanComparison.atom';
import { TyreLifeDetailResponse, TyreQueryKeys } from 'models/Tyre.type';
import { cacheTimeToMilliseconds, getCache, isCacheAvailable } from 'helpers/cache';

const tyreApi = new Tyre();

const TyreLifespanComparison: React.FC<TyreLifespanComparisonProps> = (): JSX.Element => {
    const [firstTyreData, setFirstTyreData] = useRecoilState(TyreLifeSpanDataAtom('TyreLifespanData-1'));
    const [secondTyreData, setSecondTyreData] = useRecoilState(TyreLifeSpanDataAtom('TyreLifespanData-2'));
    const queryCache = useQueryClient().getQueryCache();
    const setRankTimeline = useSetRecoilState(BesRankTimeline);
    const setRankTicks = useSetRecoilState(BesRankTicks);

    const generateBESTimeline = useCallback((): void => {
        let timeline = {};
        Interval.fromDateTimes(
            DateTime.local().minus({ days: 30 }).toUTC().startOf('day'),
            DateTime.local().toUTC().plus({ days: 1 }).startOf('day')
        )
            .splitBy({ day: 1 })
            .map((d) => d.start)
            .forEach((element) => {
                const timeKey = element.valueOf() / 1000;
                timeline[timeKey] = { timeKey };
            });

        setRankTimeline(timeline);
        const ticks = Interval.fromDateTimes(
            DateTime.local().minus({ days: 30 }).toUTC().startOf('day'),
            DateTime.local().toUTC().plus({ days: 1 }).startOf('day')
        )
            .splitBy({ day: 1 })
            .map((d) => d.start)
            .filter((element) => {
                return element.weekday === 1;
            })
            .map((element) => element.valueOf() / 1000);

        setRankTicks(ticks);
    }, [Settings.defaultZone]);

    const { refetch: refetchTyreDetailsFirst } = useQuery(
        [TyreQueryKeys.getTyreLifeDetail, firstTyreData.tyreId],
        () => tyreApi.getTyreLifeDetail(firstTyreData.tyreId),
        {
            enabled: false,
            retry: false,
            staleTime: cacheTimeToMilliseconds(15, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(15, 'minutes'),
            refetchOnWindowFocus: false,
            onSuccess: (dataOnSuccess) => {
                setFirstTyreData({
                    ...firstTyreData,
                    tyreSerialNumber: dataOnSuccess.tyreLifeDetail.tyre.tyreSerialNumber ?? '',
                    customPosition: dataOnSuccess.tyreLifeDetail.tyre.customPosition ?? NaN,
                    vehicleId: dataOnSuccess.tyreLifeDetail.tyre.vehicleId
                });
                generateBESTimeline();
            },
            onError: () => {
                setFirstTyreData(TyreLifespanDataDefaultValue);
            }
        }
    );

    const { refetch: refetchTyreDetailsSecond } = useQuery(
        [TyreQueryKeys.getTyreLifeDetail, secondTyreData.tyreId],
        () => tyreApi.getTyreLifeDetail(secondTyreData.tyreId),
        {
            enabled: false,
            retry: false,
            staleTime: cacheTimeToMilliseconds(15, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(15, 'minutes'),
            refetchOnWindowFocus: false,
            onSuccess: (dataOnSuccess) => {
                setSecondTyreData({
                    ...secondTyreData,
                    tyreSerialNumber: dataOnSuccess.tyreLifeDetail.tyre.tyreSerialNumber ?? '',
                    customPosition: dataOnSuccess.tyreLifeDetail.tyre.customPosition ?? NaN,
                    vehicleId: dataOnSuccess.tyreLifeDetail.tyre.vehicleId
                });
                generateBESTimeline();
            },
            onError: () => {
                setSecondTyreData(TyreLifespanDataDefaultValue);
            }
        }
    );

    const handleTyreChange = ({ refetch, setTyre, tyreData }: HandleTyreChangeProps): void => {
        if (
            !isNaN(tyreData.tyreId) &&
            !isCacheAvailable([TyreQueryKeys.getTyreLifeDetail, tyreData.tyreId], queryCache)
        ) {
            refetch();
        } else if (
            !isNaN(tyreData.tyreId) &&
            isCacheAvailable([TyreQueryKeys.getTyreLifeDetail, tyreData.tyreId], queryCache)
        ) {
            const cacheValue = getCache([TyreQueryKeys.getTyreLifeDetail, tyreData.tyreId], queryCache)[0]?.state
                ?.data as TyreLifeDetailResponse;
            setTyre({
                ...tyreData,
                tyreSerialNumber: cacheValue.tyreLifeDetail.tyre.tyreSerialNumber ?? '',
                customPosition: cacheValue.tyreLifeDetail.tyre.customPosition ?? NaN,
                vehicleId: cacheValue.tyreLifeDetail.tyre.vehicleId
            });
        }
    };

    useEffect(() => {
        return () => {
            setSecondTyreData(TyreLifespanDataDefaultValue);
            setFirstTyreData(TyreLifespanDataDefaultValue);
        };
    }, []);

    useEffect(
        () =>
            handleTyreChange({ refetch: refetchTyreDetailsFirst, setTyre: setFirstTyreData, tyreData: firstTyreData }),
        [firstTyreData.tyreId]
    );

    useEffect(
        () =>
            handleTyreChange({
                refetch: refetchTyreDetailsSecond,
                setTyre: setSecondTyreData,
                tyreData: secondTyreData
            }),
        [secondTyreData.tyreId]
    );

    return (
        <>
            <TyreLifespanComparisonContent data-testid={'TyreLifespanComparison-testid'} />
        </>
    );
};

export default Wrapper(TyreLifespanComparison);
