import React from 'react';
import { AnnouncementsTableContainer } from './AnnouncementsTable.style';
import { AnnouncementsTableViewProps } from './AnnouncementsTable.type';
import UiTable from 'components/Ui/UiTable/UiTable';
import AnnouncementsActions from '../AnnouncementsActions/AnnouncementsActions';
import SystemNotification from 'api/SystemNotification';
import { AddButton } from 'pages/Announcements/AddButton';

export const AnnouncementsTableContent: React.FC<AnnouncementsTableViewProps> = (props): JSX.Element => {
    const systemNotificationAPI = new SystemNotification();

    return (
        <>
            <AnnouncementsTableContainer data-testid='AnnouncementsTableContent'>
                <UiTable
                    columns={props.columns}
                    hiddenColumns={[]}
                    fetchFn={systemNotificationAPI.getAll}
                    hideFilterAction
                    queryKey='announcements'
                    rightActionBtns={() => <AddButton />}
                />
            </AnnouncementsTableContainer>
            <AnnouncementsActions />
        </>
    );
};
