import React, { useEffect, useState } from 'react';
import { FormikTypes, VehicleDetailsSettingsProps, VehicleSettingsValues } from './VehicleDetailsSettings.type';
import { VehicleDetailsSettingsContent } from './VehicleDetailsSettings.view';
import { Success } from 'components/Popup/Popup';
import { useTranslation } from 'react-i18next';
import Vehicle from 'api/Vehicle';
import Device from 'api/Device';
import DeviceCommand from 'api/DeviceCommand';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { useMutation, useQuery } from '@tanstack/react-query';
import { VehicleModel } from 'models/Vehicle.type';
import { DeviceModel } from 'models/Device.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { VehicleQueryKeys } from 'models/Vehicle.type';
import { DeviceQueryKeys } from 'models/Device.type';
import { DeviceCommandQueryKeys } from 'models/DeviceCommand.type';

const vehicleAPI = new Vehicle();
const DeviceApi = new Device();
const DeviceCommandApi = new DeviceCommand();

const VehicleDetailsSettings: React.FC<VehicleDetailsSettingsProps> = (props): JSX.Element => {
    const [vehicleData, setVehicleData] = useState<VehicleModel | undefined>(undefined);
    const [deviceSeq, setDeviceSeq] = useState<unknown | null>(null);
    const [deviceCommandPending, setDeviceCommandPending] = useState<DeviceModel | null>(null);
    const [switchOutOfService, setSwitchOutOfService] = useState<boolean>(false);
    const [switchMaintenance, setSwitchMaintenance] = useState<boolean>(false);
    const [sensor3Checkbox, setSensor3Checkbox] = useState<boolean>(false);
    const [sensorSttCheckbox, setSensorSttCheckbox] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [initialFormValues, setInitialFormValues] = useState<FormikTypes>({
        maxSpeedLevel: 0,
        maxLongitudinalAcceleration: 0,
        maxLateralAcceleration: 0,
        maxVerticalAcceleration: 0
    });
    const { t: translate } = useTranslation();

    const { fromServerToUserUnit, fromUserToServerUnit, convertType } = useConverter();

    const { isLoading: vehicleDataLoading } = useQuery(
        [VehicleQueryKeys.getVehicleById, props.vehicleId],
        () => vehicleAPI.getVehicleById(props.vehicleId),
        {
            refetchInterval: false,
            enabled: !!props.vehicleId,
            staleTime: cacheTimeToMilliseconds(1, 'hours'),
            cacheTime: cacheTimeToMilliseconds(1, 'hours'),
            onSuccess: (vehicleData) => setVehicleData(vehicleData)
        }
    );

    const { isLoading: deviceSeqLoading } = useQuery(
        [DeviceQueryKeys.getDeviceById, props.vehicleDetails?.device?.id],
        () => DeviceApi.getDeviceById(props.vehicleDetails?.device?.id as number),
        {
            refetchInterval: false,
            enabled: !!props.vehicleDetails?.device?.id,
            staleTime: cacheTimeToMilliseconds(15, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(15, 'minutes'),
            onSuccess: (vehicleData) => setDeviceCommandPending(vehicleData as DeviceModel)
        }
    );

    useQuery(
        [DeviceCommandQueryKeys.getCommandPending, props.vehicleDetails?.device?.id],
        () => DeviceCommandApi.getCommandPending(15, props.vehicleDetails?.device?.id as number),
        {
            refetchInterval: false,
            enabled: !!props.vehicleDetails?.device?.id,
            staleTime: cacheTimeToMilliseconds(1, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(1, 'minutes'),
            onSuccess: (commandStatus) => {
                setPending(commandStatus.isPending);
            }
        }
    );

    const processData = (data, sequence): void => {
        let processedData = {
            pending: false,
            operationLimit1: null,
            iTrackII: false,
            hasDevice: false
        };
        if (sequence && sequence.deviceInfo) {
            let parsedSequence = parseSequence(sequence.deviceInfo?.seq || '');
            setSensorSttCheckbox(parsedSequence.iTrackII);
            setSensor3Checkbox(parsedSequence.sensor3);
            processedData.hasDevice = true;
        }
        if (data && !!Object.keys(data).length) {
            setInitialFormValues({
                maxSpeedLevel: data.maxSpeed
                    ? +fromServerToUserUnit({
                          type: convertType.speed,
                          value: data.maxSpeed ?? 0,
                          fixed: 1
                      })
                    : undefined,
                maxLateralAcceleration: data.yAccel
                    ? parseInt(
                          fromServerToUserUnit({
                              type: convertType.acceleration,
                              value: data.yAccel
                          })
                      )
                    : undefined,
                maxVerticalAcceleration: data.zAccel
                    ? parseInt(
                          fromServerToUserUnit({
                              type: convertType.acceleration,
                              value: data.zAccel
                          })
                      )
                    : undefined,
                maxLongitudinalAcceleration: data.xAccel
                    ? parseInt(
                          fromServerToUserUnit({
                              type: convertType.acceleration,
                              value: data.xAccel
                          })
                      )
                    : undefined
            });
        }
    };

    const parseSequence = (sequence): { iTrackII: boolean; sensor3: boolean } => {
        let values = {
            iTrackII: false,
            sensor3: false
        };

        if (sequence.length === 0) {
            return values;
        }

        let count = parseInt(sequence[0]);
        for (let i = 1; i <= count; i++) {
            switch (sequence[i]) {
                case '2':
                    values.iTrackII = true;
                    break;
                case '5':
                    values.sensor3 = true;
            }
        }

        return values;
    };

    const handleChangeSwitch = async (checked: boolean, type: 'outOfService' | 'maintenance'): Promise<void> => {
        let dataToSend = {};
        let message = '';
        if (type === 'outOfService') {
            setSwitchOutOfService(checked);
            dataToSend = { outOfService: checked ? 1 : 0 };
            message = checked
                ? 't.vehicle_settings_service_off_success_message'
                : 't.vehicle_settings_service_on_success_message';
        } else {
            setSwitchMaintenance(checked);
            dataToSend = { maintenance: checked ? 1 : 0 };
            message = checked
                ? 't.vehicle_settings_maintenance_on_success_message'
                : 't.vehicle_settings_maintenance_off_success_message';
        }
        try {
            await vehicleAPI.patchById({ id: props.vehicleId, data: dataToSend });
            Success({ text: translate(message) });
        } catch (e) {
            false;
        }
    };

    useEffect(() => {
        return () => {
            setVehicleData(undefined);
            setDeviceSeq(null);
            setSwitchOutOfService(false);
            setSwitchMaintenance(false);
            setSensor3Checkbox(false);
            setSensorSttCheckbox(false);
            setPending(false);
            setInitialFormValues({
                maxSpeedLevel: 0,
                maxLongitudinalAcceleration: 0,
                maxLateralAcceleration: 0,
                maxVerticalAcceleration: 0
            });
        };
    }, []);

    useEffect(() => {
        if (vehicleData && deviceCommandPending) {
            processData(vehicleData, deviceCommandPending);
        }
    }, [vehicleData, deviceCommandPending]);

    useEffect(() => {
        setSwitchOutOfService(props.vehicleDetails?.outOfService);
        setSwitchMaintenance(props.vehicleDetails?.maintenance);
    }, [props.vehicleDetails]);

    const { mutate: setSubmit } = useMutation(
        (formValues: FormikTypes) => vehicleAPI.setVehicleSettings({ ...getValueVehicleSettings(formValues) }),
        {
            onSuccess: () => {
                Success({ text: `${translate('t.settings_saved')}` });
            }
        }
    );

    const getValueVehicleSettings = (formValues: FormikTypes): VehicleSettingsValues => {
        return {
            id: props.vehicleId,
            maxSpeed: +fromUserToServerUnit({
                type: convertType.speed,
                value: formValues.maxSpeedLevel,
                fixed: 1
            }),
            xAccel: formValues.maxLongitudinalAcceleration
                ? fromUserToServerUnit({
                      type: convertType.acceleration,
                      value: formValues.maxLongitudinalAcceleration,
                      fixed: 0
                  })
                : undefined,
            yAccel: formValues.maxLateralAcceleration
                ? fromUserToServerUnit({
                      type: convertType.acceleration,
                      value: formValues.maxLateralAcceleration,
                      fixed: 0
                  })
                : undefined,
            zAccel: formValues.maxVerticalAcceleration
                ? fromUserToServerUnit({
                      type: convertType.acceleration,
                      value: formValues.maxVerticalAcceleration,
                      fixed: 0
                  })
                : undefined
        };
    };

    const { mutate: setSensorTypes } = useMutation(
        () =>
            DeviceCommandApi.setSensorTypes({
                deviceIds: [props.vehicleDetails.device?.id],
                value: `{"seq":"${getValueSensorTypes()}"}`,
                commandName: 'set_sensors'
            }),
        {
            onSuccess: () => {
                Success({ text: 'Sensor Types were successfully set' });
                setPending(true);
            }
        }
    );

    const getValueSensorTypes = (): string => {
        let sequence = '';
        if (props.vehicleDetails.device && props.vehicleDetails.device.id) {
            let numberOfSensors = (sensorSttCheckbox ? 1 : 0) + (sensor3Checkbox ? 1 : 0);
            if (sensor3Checkbox) {
                numberOfSensors += 3;
            }
            sequence += numberOfSensors;
            if (sensorSttCheckbox) {
                sequence += '2';
            }
            if (sensor3Checkbox) {
                sequence += '5555';
            }
            while (sequence.length < 9) {
                sequence += '0';
            }
        }

        return sequence;
    };

    return (
        <VehicleDetailsSettingsContent
            data-testid={'VehicleDetailsSettings-testid'}
            {...props}
            vehicleData={vehicleData}
            deviceSeq={deviceSeq}
            initialFormValues={initialFormValues}
            handleChangeSwitch={handleChangeSwitch}
            switchOutOfService={switchOutOfService}
            switchMaintenance={switchMaintenance}
            handleSubmit={setSubmit}
            pending={pending}
            sensor3Checkbox={sensor3Checkbox}
            sensorSttCheckbox={sensorSttCheckbox}
            setSensor3Checkbox={setSensor3Checkbox}
            setSensorSttCheckbox={setSensorSttCheckbox}
            handleSetSensorTypes={setSensorTypes}
            isLoading={
                (!!props.vehicleDetails?.device?.id && deviceSeqLoading) || (!!props.vehicleId && vehicleDataLoading)
            }
        />
    );
};

export default VehicleDetailsSettings;
