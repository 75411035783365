import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { SpeedIncidentPopUpContent } from './SpeedIncidentPopUp.view';
import { SpeedIncidentPopUpProps } from './SpeedIncidentPopUp.type';
import { Wrapper } from 'helpers/wrapper';
import SpeedEventApi from 'api/SpeedEvent';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SpeedEventQueryKeys, SpeedIncidentMap } from 'models/SpeedEvent.type';
import { MapActionData } from 'states/global/Map';

const SpeedEvent = new SpeedEventApi();

const SpeedIncidentPopUp: React.FC<SpeedIncidentPopUpProps> = (): JSX.Element => {
    const mapActionData = useRecoilValue(MapActionData);
    const queryClient = useQueryClient();

    const { isFetching: SpeedIncidentFetched, data: SpeedIncidentData } = useQuery<
        SpeedIncidentMap | undefined,
        boolean
    >(
        [SpeedEventQueryKeys.getSpeedIncidentById, mapActionData.clickedSpeedIncident],
        () => {
            if (mapActionData.clickedSpeedIncident) {
                return SpeedEvent.getSpeedIncidentById(mapActionData.clickedSpeedIncident);
            }
        },
        {
            enabled: !!mapActionData.clickedSpeedIncident,
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false
        }
    );

    useEffect(() => {
        queryClient.invalidateQueries([SpeedEventQueryKeys.getSpeedIncidentById, mapActionData.clickedSpeedIncident]);
    }, [queryClient, mapActionData.clickedSpeedIncident]);

    return (
        <SpeedIncidentPopUpContent
            data-testid='SpeedIncidentPopUp-testid'
            speedIncidentFetched={SpeedIncidentFetched}
            speedIncidentData={SpeedIncidentData}
        />
    );
};

export default Wrapper(SpeedIncidentPopUp);
