import React, { useState } from 'react';
import { AlertNotificationManagmentTopbarContainer } from './AlertNotificationManagmentTopbar.style';
import { AlertNotificationManagmentTopbarProps } from './AlertNotificationManagmentTopbar.type';
import UiDateRangePicker2 from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Fab, Grid, Tooltip } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { WHITE } from 'components/Ui/colors';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { DateRangeData } from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2.type';
import { AlertNotificationManagmentState } from 'states/component/AlertNotificationManagment';
import AddAlertNotificationManagment from '../AddAlertNotificationManagment/AddAlertNotificationManagment';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { DateTime } from 'luxon';
import { Wrapper } from 'helpers/wrapper';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';

const AlertNotificationManagmentTopbar: React.FC<AlertNotificationManagmentTopbarProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const setAlertNotificationManagmentState = useSetRecoilState(AlertNotificationManagmentState);
    const [dateRange, setDateRange] = useState<DateRangeData>({
        dateFrom: DateTime.local(),
        dateTo: DateTime.local().plus({ days: 7 }),
        isValidRange: true
    });
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispatch = ModalDispatcher(modalAtom, setStateModalAtom);
    const queryTableFn = useRecoilValue(QueryFn(`alertNotificationManagment-QueryFn`));

    const handleModalAdd = () => {
        const contentModalAdd = {
            id: 'AlertNotificationManagmentModalActionsAdd',
            customContent: <AddAlertNotificationManagment />,
            leftTitle: translate('t.add_alert_notification_management'),
            width: 750,
            height: 470,
            widthUnit: 'px',
            heightUnit: 'px',
            onClose: () =>
                modalDispatch({
                    type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModalAdd,
                        isOpen: false
                    }
                })
        };

        modalDispatch({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalAdd,
                isOpen: true
            }
        });
    };

    return (
        <AlertNotificationManagmentTopbarContainer data-testid='AlertNotificationManagmentTopbarContent'>
            <Grid container spacing={1} justifyContent='center'>
                <Grid item>
                    <UiDateRangePicker2
                        onlyDate
                        validFutureDate
                        adjustToPeriod={{ period: 7, periodType: 'days' }}
                        dateFrom={dateRange?.dateFrom}
                        dateTo={dateRange?.dateTo}
                        dateFromLabel={translate('t.date_from')}
                        dateToLabel={translate('t.date_to')}
                        onDateChange={(dateRange, invalid) => {
                            if (dateRange)
                                setDateRange({
                                    dateFrom: dateRange[0],
                                    dateTo: dateRange[1],
                                    isValidRange: !invalid
                                });
                        }}
                    />
                </Grid>
                <Grid item>
                    <UiButton
                        data-testid={`AlertNotificationManagmentTableContent-actionsButton-Button`}
                        variant='contained'
                        skin='success'
                        testid='AlertNotificationManagmentTopbarContent-ApplyRange-Button'
                        disabled={!dateRange.isValidRange}
                        onClick={() => {
                            if (dateRange.dateFrom && dateRange.dateTo) {
                                setAlertNotificationManagmentState(dateRange);
                            }
                            queryTableFn.refetch && queryTableFn.refetch instanceof Function && queryTableFn.refetch();
                        }}
                    >
                        {translate('t.apply')}
                    </UiButton>
                    <Tooltip title={translate('t.add_new')} placement='bottom' arrow disableInteractive>
                        <Fab
                            size='small'
                            color='primary'
                            aria-label='Go Back'
                            onClick={() => handleModalAdd()}
                            data-testid='AlertNotificationManagmentTopbarContent-AddNew-Button'
                        >
                            <UiIcon icon={['fas', 'plus']} size='lg' fixedWidth color={WHITE} />
                        </Fab>
                    </Tooltip>
                </Grid>
            </Grid>
        </AlertNotificationManagmentTopbarContainer>
    );
};

export default Wrapper(AlertNotificationManagmentTopbar);
