import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AdminHubsViewContent } from './AdminHubs.style';
import { AdminHubsViewProps } from './AdminHubs.type';
import { useTranslation } from 'react-i18next';
import Hub from 'api/Device';
import UiTable from 'components/Ui/UiTable/UiTable';
import UiButton from 'components/Ui/Components/UiButton';
import { COLOR_GREY, GRAY, SUCCESS, TEXT_BLACK_OLD, WHITE } from 'components/Ui/colors';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import { applyStyleByMode } from 'helpers';
import { Theme } from 'states/global/Theme';
import useModal from 'components/CustomHooks/ModalHook';
import { GetParamsReturnProps } from 'models/Table.type';
import TopBarTerminal from 'components/Hub/DeviceCommandsTable/Components/TopBarTerminal/TopBarTerminal';
import { FilterType } from 'components/Ui/UiTableHeader/UiTableHeader.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DeviceTable } from 'models/Device.type';
import { Tooltip } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon';
import { faHdd } from '@fortawesome/pro-solid-svg-icons';
import UiWidget from 'components/Ui/Components/UiWidget/UiWidget';
import ReuseHub from 'components/Hub/ReuseHub/ReuseHub';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';

const hubApi = new Hub();

// check if GetParamsReturnProps is correct there
const getTitle = (
    selectedAll: boolean,
    selectedRows: (unknown & { id: string | number })[],
    filters: GetParamsReturnProps,
    totalCount: number,
    translate: any
): string => {
    let title = '';
    let total = totalCount;
    if (selectedAll && selectedRows.length) {
        total = totalCount - selectedRows.length;
    }
    if (!selectedAll && selectedRows.length) {
        total = selectedRows.length;
    }

    if (total === 1) {
        if (selectedRows.length === 1) {
            title = `${translate('t.hub')} ${selectedRows[0].id}`;
        } else {
            title = translate('t.filtered_hubs', { number: 1 });
        }
    } else {
        title = translate('t.filtered_hubs', { number: total });
    }
    return title;
};

export const AdminHubsContent: React.FC<AdminHubsViewProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);
    const { setModalState } = useModal();
    const { fromUTCToTimezone } = useConverter();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);

    const showReuseHubModal = (hub: any) => {
        const newModal = {
            customContent: <ReuseHub hub={hub} />,
            content: undefined,
            leftTitle: translate('t.reuse_hub'),
            rightTitle: `${translate('t.hub')} ${hub.id}`,
            cancelColor: GRAY,
            width: 450,
            height: 90
        };
        modalDispach({
            type: ModalActionTypesEnum.SET_MODAL_STATE,
            ModalPropsPayload: {
                ...newModal,
                onClose: () =>
                    modalDispach({
                        type: ModalActionTypesEnum.SET_MODAL_STATE,
                        ModalPropsPayload: {
                            isOpen: false,
                            ...newModal
                        }
                    }),
                isOpen: true
            }
        });
    };

    // Chacer after unifying Juan's assignment
    const columns2: any = useMemo(() => {
        const a: any = [
            {
                accessor: 'id',
                Header: 'ID',
                width: 150,
                filterProps: {
                    type: FilterType.SIGNED_VALUE,
                    onFilterApply: {
                        exact: {
                            accessor: 'id'
                        },
                        fullText: {
                            accessor: 'idFullText'
                        },
                        negation: {
                            accessor: 'notIdFullText'
                        }
                    }
                }
            },
            {
                accessor: 'customer.name',
                Header: translate('t.customer'),
                filterProps: {
                    type: FilterType.NEGATE_VALUE,
                    onFilterApply: {
                        fullText: {
                            accessor: 'customerName'
                        },
                        negation: {
                            accessor: 'notCustomerName'
                        }
                    }
                }
            },
            {
                accessor: 'deviceInfo.lastTransmission',
                Header: translate('t.last_transmission'),
                filterProps: {
                    type: FilterType.SIGNED_DATETIME,
                    onFilterApply: {
                        lowerThan: {
                            accessor: 'lastTransmissionDateTimeTo'
                        },
                        higherThan: {
                            accessor: 'lastTransmissionDateTimeFrom'
                        }
                    }
                },
                width: 200,
                Cell: (props: { row: { original: DeviceTable['items'][0] } }) =>
                    fromUTCToTimezone(props.row?.original?.deviceInfo?.lastTransmission || '', true) ?? '-'
            },
            {
                accessor: 'deviceInfo.powerSource',
                Header: translate('t.power_supply'),
                filterProps: {
                    type: FilterType.SIGNED_VALUE,
                    onFilterApply: {
                        lowerThan: {
                            accessor: 'powerSourceTo'
                        },
                        higherThan: {
                            accessor: 'powerSourceFrom'
                        },
                        fullText: {
                            accessor: 'powerSourceFullText'
                        },
                        exact: {
                            accessor: 'powerSource'
                        },
                        negation: {
                            accessor: 'notPowerSourceFullText'
                        }
                    }
                }
            },
            {
                accessor: 'deviceInfo.fmw',
                Header: translate('t.firmware'),
                Cell: (props: { row: { original: DeviceTable['items'][0] } }) => (
                    <Tooltip title={props.row?.original?.deviceInfo?.fmw}>
                        <span>{props.row?.original?.deviceInfo?.fmw}</span>
                    </Tooltip>
                ),
                filterProps: {
                    type: FilterType.NEGATE_VALUE,
                    onFilterApply: {
                        fullText: {
                            accessor: 'firmware'
                        },
                        negation: {
                            accessor: 'notFirmware'
                        }
                    }
                }
            },
            {
                accessor: 'deviceInfo.service',
                Header: translate('t.service'),
                Cell: (props: { row: { original: DeviceTable['items'][0] } }) => (
                    <Tooltip title={props.row?.original?.deviceInfo?.service}>
                        <span>{props.row?.original?.deviceInfo?.service}</span>
                    </Tooltip>
                ),
                filterProps: {
                    type: FilterType.VERSION_VALUE,
                    onFilterApply: {
                        fullText: {
                            accessor: 'service'
                        },
                        negation: {
                            accessor: 'notService'
                        },
                        lowerThan: {
                            accessor: 'serviceTo'
                        },
                        higherThan: {
                            accessor: 'serviceFrom'
                        }
                    }
                }
            },
            {
                accessor: 'deviceInfo.tools',
                Header: translate('t.tools'),
                Cell: (props: { row: { original: DeviceTable['items'][0] } }) => (
                    <Tooltip title={props.row?.original?.deviceInfo?.tools}>
                        <span>{props.row?.original?.deviceInfo?.tools}</span>
                    </Tooltip>
                ),
                filterProps: {
                    type: FilterType.VERSION_VALUE,
                    onFilterApply: {
                        fullText: {
                            accessor: 'tools'
                        },
                        negation: {
                            accessor: 'notTools'
                        },
                        lowerThan: {
                            accessor: 'toolsTo'
                        },
                        higherThan: {
                            accessor: 'toolsFrom'
                        }
                    }
                }
            },
            {
                accessor: 'deviceRevision.description',
                Header: translate('t.revision'),
                filterProps: {
                    type: FilterType.NEGATE_VALUE,
                    onFilterApply: {
                        fullText: {
                            accessor: 'deviceRevision'
                        },
                        negation: {
                            accessor: 'notDeviceRevision'
                        }
                    }
                }
            },
            {
                accessor: 'deviceInfo.battery',
                Header: translate('t.battery'),
                filterProps: {
                    type: FilterType.SIGNED_VALUE,
                    onFilterApply: {
                        lowerThan: {
                            accessor: 'batteryTo'
                        },
                        higherThan: {
                            accessor: 'batteryFrom'
                        },
                        fullText: {
                            accessor: 'batteryFullText'
                        },
                        negation: {
                            accessor: 'notBatteryFullText'
                        },
                        exact: {
                            accessor: 'battery'
                        }
                    }
                }
            },
            {
                accessor: 'deviceInfo.mount',
                Header: translate('t.mount'),
                filterProps: {
                    type: FilterType.NEGATE_VALUE,
                    onFilterApply: {
                        fullText: {
                            accessor: 'mount'
                        },
                        negation: {
                            accessor: 'notMount'
                        }
                    }
                }
            },
            {
                accessor: 'deviceInfo.exchange',
                Header: translate('t.exchange'),
                Cell: (props: { row: { original: DeviceTable['items'][0] } }) => (
                    <Tooltip title={props.row?.original?.deviceInfo?.exchange}>
                        <span>{props.row?.original?.deviceInfo?.exchange}</span>
                    </Tooltip>
                ),
                filterProps: {
                    type: FilterType.VERSION_VALUE,
                    onFilterApply: {
                        fullText: {
                            accessor: 'exchange'
                        },
                        negation: {
                            accessor: 'notExchange'
                        },
                        lowerThan: {
                            accessor: 'exchangeTo'
                        },
                        higherThan: {
                            accessor: 'exchageFrom'
                        }
                    }
                }
            },
            {
                accessor: 'deviceInfo.pingReboot',
                Header: translate('t.ping_reboot'),
                Cell: (props: { row: { original: DeviceTable['items'][0] } }) => (
                    <Tooltip title={props.row?.original?.deviceInfo?.pingReboot}>
                        <span>{props.row?.original?.deviceInfo?.pingReboot}</span>
                    </Tooltip>
                ),
                filterProps: {
                    type: FilterType.VERSION_VALUE,
                    onFilterApply: {
                        fullText: {
                            accessor: 'pingReboot'
                        },
                        negation: {
                            accessor: 'notPingReboot'
                        },
                        lowerThan: {
                            accessor: 'pingRebootTo'
                        },
                        higherThan: {
                            accessor: 'pingRebootFrom'
                        }
                    }
                }
            },
            {
                accessor: 'deviceInfo.sshtunneld',
                Header: translate('t.sshtunneld'),
                Cell: (props: { row: { original: DeviceTable['items'][0] } }) => (
                    <Tooltip title={props.row?.original?.deviceInfo?.sshtunneld}>
                        <span>{props.row?.original?.deviceInfo?.sshtunneld}</span>
                    </Tooltip>
                ),
                filterProps: {
                    type: FilterType.VERSION_VALUE,
                    onFilterApply: {
                        fullText: {
                            accessor: 'sshTunnel'
                        },
                        negation: {
                            accessor: 'notSshTunnel'
                        },
                        lowerThan: {
                            accessor: 'sshTunnelTo'
                        },
                        higherThan: {
                            accessor: 'sshTunnelFrom'
                        }
                    }
                }
            },
            {
                accessor: 'deviceInfo.statusMonitor',
                Header: translate('t.status_monitor'),
                Cell: (props: { row: { original: DeviceTable['items'][0] } }) => (
                    <Tooltip title={props?.row.original?.deviceInfo?.statusMonitor}>
                        <span>{props.row?.original?.deviceInfo?.statusMonitor}</span>
                    </Tooltip>
                ),
                filterProps: {
                    type: FilterType.VERSION_VALUE,
                    onFilterApply: {
                        fullText: {
                            accessor: 'statusMonitor'
                        },
                        negation: {
                            accessor: 'notStatusMonitor'
                        },
                        lowerThan: {
                            accessor: 'statusMonitorTo'
                        },
                        higherThan: {
                            accessor: 'statusMonitorFrom'
                        }
                    }
                }
            },
            {
                accessor: 'deviceInfo.usb',
                Header: translate('t.usb'),
                Cell: (props: { row: { original: DeviceTable['items'][0] } }) => (
                    <Tooltip title={props.row?.original?.deviceInfo?.usb}>
                        <span>{props.row?.original?.deviceInfo?.usb}</span>
                    </Tooltip>
                ),
                filterProps: {
                    type: FilterType.VERSION_VALUE,
                    onFilterApply: {
                        fullText: {
                            accessor: 'usb'
                        },
                        negation: {
                            accessor: 'notUsb'
                        },
                        lowerThan: {
                            accessor: 'usbTo'
                        },
                        higherThan: {
                            accessor: 'usbFrom'
                        }
                    }
                }
            },
            {
                accessor: 'deviceInfo.watchdog',
                Header: translate('t.watchdog'),
                Cell: (props: { row: { original: DeviceTable['items'][0] } }) => (
                    <Tooltip title={props.row?.original?.deviceInfo?.watchdog}>
                        <span>{props.row?.original?.deviceInfo?.watchdog}</span>
                    </Tooltip>
                ),
                filterProps: {
                    type: FilterType.VERSION_VALUE,
                    onFilterApply: {
                        fullText: {
                            accessor: 'watchdog'
                        },
                        negation: {
                            accessor: 'notWatchdog'
                        },
                        lowerThan: {
                            accessor: 'watchdogTo'
                        },
                        higherThan: {
                            accessor: 'watchdogFrom'
                        }
                    }
                }
            },
            {
                accessor: 'comment',
                Header: translate('t.comment'),
                Cell: (props: { row: { original: DeviceTable['items'][0] } }) => (
                    <Tooltip title={props.row?.original?.comment}>
                        <span>{props.row?.original?.comment}</span>
                    </Tooltip>
                ),
                filterProps: {
                    type: FilterType.NEGATE_VALUE,
                    onFilterApply: {
                        fullText: {
                            accessor: 'comment'
                        },
                        negation: {
                            accessor: 'notComment'
                        }
                    }
                }
            },
            {
                accessor: 'disabled',
                Header: translate('t.assigned'),
                filterProps: {
                    type: FilterType.DROPDOWN,
                    options: [
                        {
                            label: translate('t.all'),
                            value: -1
                        },
                        {
                            label: translate('t.assigned'),
                            value: 0
                        },
                        {
                            label: translate('t.unassigned'),
                            value: 1
                        }
                    ],
                    onFilterApply: {
                        exact: {
                            accessor: 'disabled'
                        }
                    }
                },
                Cell: (props: { value: number }) => (
                    <>{translate(`t.${props.value === 0 ? 'assigned' : 'unassigned'}`)}</>
                )
            }
        ];
        return a;
    }, []);

    return (
        <AdminHubsViewContent data-testid='AdminHubsContent'>
            <UiWidget
                title={translate('t.hubs')}
                avatar={
                    <UiIcon
                        icon={faHdd}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                content={
                    <UiTable
                        columns={columns2}
                        useColumnFiltering
                        useColumnAction={{ position: 0 }}
                        fetchFn={hubApi.getGlobalHubs}
                        exportFn={hubApi.getExportAll}
                        queryKey='adminHubs'
                        exportName='adminHubs'
                        refresh={false}
                        useColumnCheckbox
                        rightActionBtns={(selectedAll, selectedRows, filters, totalCount) => {
                            return (
                                <UiButton
                                    skin={SUCCESS}
                                    variant='contained'
                                    testid='SendCmdToHubsButton'
                                    disabled={
                                        (!selectedAll && selectedRows.length === 0) ||
                                        (selectedAll && totalCount - selectedRows.length === 0)
                                    }
                                    onClick={() => {
                                        setModalState({
                                            isOpen: true,
                                            height: 200,
                                            width: 700,
                                            content: (
                                                <TopBarTerminal
                                                    deviceId={1}
                                                    sendCommandProps={{
                                                        filters: selectedAll ? filters : undefined,
                                                        selectedRows: selectedRows.map(
                                                            (selectedRow) => selectedRow.id
                                                        ) as number[]
                                                    }}
                                                />
                                            ),
                                            leftTitle: translate('t.send_command_to'),
                                            rightTitle: getTitle(
                                                selectedAll,
                                                selectedRows as { id: string | number }[],
                                                filters,
                                                totalCount,
                                                translate
                                            )
                                        });
                                    }}
                                >
                                    {translate('t.send_cmd_to_selected_hubs')}
                                </UiButton>
                            );
                        }}
                        rowActionBtns={(rowData) => {
                            const actions = [
                                {
                                    title: (
                                        <UiLink
                                            data-testid={`LinkDevice${rowData.id}`}
                                            $padding='0'
                                            content={translate('t.hub_detail')}
                                            url={`/device/${rowData.id}`}
                                            color={applyStyleByMode({
                                                styleOld: TEXT_BLACK_OLD,
                                                theme: ThemeMode?.mode,
                                                light: COLOR_GREY,
                                                dark: WHITE
                                            })}
                                        />
                                    ),
                                    value: rowData.id
                                },
                                {
                                    title: (
                                        <span data-testid={`SendCmdModal${rowData.id}`}>{translate('t.send_cmd')}</span>
                                    ),
                                    value: rowData.id,
                                    action: () => {
                                        setModalState({
                                            isOpen: true,
                                            height: 200,
                                            width: 700,
                                            content: (
                                                <TopBarTerminal
                                                    deviceId={1}
                                                    sendCommandProps={{
                                                        selectedRows: [rowData.id]
                                                    }}
                                                />
                                            ),
                                            leftTitle: translate('t.send_command_to'),
                                            rightTitle: `${translate('t.hub')} ${rowData.id}`
                                        });
                                    }
                                }
                            ];

                            if (!rowData.customer) {
                                actions.push({
                                    title: (
                                        <span data-testid={`Reuse-hub-${rowData.id}`}>{translate('t.reuse_hub')}</span>
                                    ),
                                    value: rowData.id,
                                    action: () => {
                                        showReuseHubModal(rowData);
                                    }
                                });
                            }

                            return actions;
                        }}
                    />
                }
            />
        </AdminHubsViewContent>
    );
};
