import styled from 'styled-components';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { ReactComponent as ExternalIcon } from '../../../assets/svg/externalSensorIcon.svg';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from '../../../states/global/Theme';
import { ALERT_LEVEL_3_TEMP_AND_PRESS, BACKGROUND_BLUE, BACKGROUND_TABLE, COLOR_GREY, WHITE } from '../../Ui/colors';
import { SIZE_TEXT_VARIANT } from '../../Ui/variables';
import { useTheme } from '@mui/material';

export const TyreDetailsViewContent = styled.div`
    ${() => {
        return `
            border-bottom: 1px solid #c5c5c5;
            cursor: pointer;
            padding: 3px 0px;
            color: ${useTheme().palette.mode === ThemeMode.light ? COLOR_GREY : WHITE};
            & a{
                color: ${useTheme().palette.mode === ThemeMode.light ? BACKGROUND_BLUE : BACKGROUND_BLUE}
            }
        `;
    }}
    display: block;
    width: 100%;
    margin: 5px 0;

    text-decoration: none;

    &:hover {
        ${() => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                light: 'background-color: #fddfb3;',
                dark: 'background-color: #383e46;'
            });
        }}
        transition: background-color 500ms linear, border-color 500ms linear;
    }
    &.hovered {
        ${() => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                light: 'background-color: #fddfb3;',
                dark: 'background-color: #383e46;'
            });
        }}
        transition: background-color 500ms linear, border-color 500ms linear;
    }
`;

export const DetailContent = styled.div<{ width: number; $firstColumn?: boolean }>`
    display: inline-block;
    vertical-align: top;
    width: ${(props) => props.width}%;
    ${({ $firstColumn }) => {
        return `
            padding-right: 13px;
            text-align: left;
            font-size: ${SIZE_TEXT_VARIANT(1)};
            ${
                $firstColumn &&
                `
                padding-left: 10px;
            `
            }
        `;
    }}
`;

export const DetailContentLine = styled.div`
    & span {
        font-size: ${SIZE_TEXT_VARIANT(1)} !important;
    }
`;

export const DetailContentLinePressure = styled.div`
    display: flex;
    align-items: center;
`;

export const SensorLabel = styled.span`
    cursor: pointer;
    display: inline-block;
    ${() => {
        return applyStyleByMode({
            styleJade: `
                border-radius: 4px;
                padding: 0px 3px;
                margin: 1px 0px; 
            `,
            theme: useTheme().palette.mode,
            light: `background: ${BACKGROUND_TABLE};`,
            dark: `background: ${useTheme().palette.background.default};`
        });
    }}
`;

export const Alert = styled(SensorLabel)<{ alertLevel: number }>`
    color: ${(props) => {
        const alertColors = {
            0: useTheme().palette.mode === ThemeMode.dark ? WHITE : COLOR_GREY,
            1: '#aab31a',
            2: '#de9d0d',
            3: '#de4f0d'
        };
        return alertColors[props.alertLevel];
    }};
`;

export const SensorHexNumber = styled('small')<{ $isSelected: boolean }>`
    ${(props) => props.$isSelected && 'text-decoration: underline; text-decoration-color: #3a3633;'}
`;

export const Value = styled.span<{ alertLevel?: number; $threshold?: boolean }>`
    ${(props) =>
        props.$threshold
            ? `
        animation: blink 1s infinite alternate;
        
        @keyframes blink {       
            from {
                text-shadow: 0 0 0px red;
            }
            to {
                text-shadow: 0 0 20px red;
            }
        }   
    `
            : ''}
    font-weight: 600;
    ${({ alertLevel }) => {
        const alertColorsOld = {
            0: '#35ef1d',
            1: '#aab31a',
            2: '#de9d0d',
            3: ALERT_LEVEL_3_TEMP_AND_PRESS
        };

        return `color: ${
            alertLevel !== undefined
                ? alertColorsOld[alertLevel]
                : useTheme().palette.mode === ThemeMode.dark
                ? WHITE
                : COLOR_GREY
        };`;
    }}
`;

export const Label = styled.h5`
    margin: 0;
    font-size: 15px;
    text-align: center;
`;

export const TargetedPressure = styled.div`
    font-weight: 600;
    color: ${() => useTheme().palette.primary.main};
    padding-left: 5px;
`;

export const Content = styled.div`
    display: flex;
    position: relative;
    align-items: center;
`;

export const PressureIcon = styled(UiIcon)<{ $hotPressureIcon: boolean }>`
    color: ${(props) => (props.$hotPressureIcon ? 'red' : '#0069FF')};
    margin-left: ${(props) => (props.$hotPressureIcon ? '-1px' : '0px')};
    margin-right: 3px;
`;

export const ExternalSensorIcon = styled(ExternalIcon)`
    width: 16px;
    height: 11px;
    padding-left: 5px;
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: 'color: #0000008a;',
            dark: `color: ${WHITE};`
        });
    }}
`;

export const SensorBatteryStatusIcon = styled(UiIcon)<{ $exclamation?: boolean }>`
    padding-left: 5px;
    padding-right: 5px;
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: 'color: #0000008a;',
            dark: `color: ${WHITE};`
        });
    }}
`;

export const SensorFaultyStatusIcon = styled('span')`
    padding: 0 5px;
    font-weight: 600;
    color: red;
    text-decoration: none !important;
`;

export const FontAwesomeIconWithPadding = styled(UiIcon)<{ paddingRight: number }>`
    padding-right: ${(props) => props.paddingRight}px;
    color: ${() => useTheme().palette.primary.main};
`;

export const TextGreen = styled.span`
    color: ${() => useTheme().palette.primary.main};
`;
