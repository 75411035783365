import React, { useReducer } from 'react';
import { useRecoilValue } from 'recoil';
import { useMutation } from '@tanstack/react-query';
import { PropertyPositionPopover, UpdateDropDownActions, UpdateDropDownProps } from './UpdateDropDown.type';
import { UpdateDropDownContent } from './UpdateDropDown.view';
import { UpdateDropDownReducer } from './UpdateDropDown.reducer';
import { useTranslation } from 'react-i18next';
import { UserData, UserSetAccount } from 'models/User.type';
import User from 'api/User';
import { Success } from 'components/Popup/Popup';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';

const UpdateDropDown: React.FC<UpdateDropDownProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const userApi = new User();
    const queryTableFn = useRecoilValue(QueryFn(`${props.queryKey}-QueryFn`));
    const [state, dispatch] = useReducer(UpdateDropDownReducer, {
        updateValue: props.updateDefaultValue
    });

    const anchorOriginCustom: PropertyPositionPopover = {
        vertical: 'top',
        horizontal: 'center'
    };

    const transformOriginCustom: PropertyPositionPopover = {
        vertical: 'bottom',
        horizontal: 'right'
    };

    const setUpdateValue = (value: number) => {
        dispatch({
            type: UpdateDropDownActions.SET_UPDATE_VALUE,
            payload: value
        });
    };

    const { mutate: setAccountMutation, isLoading: setAccountMutationLoading } = useMutation<
        UserData,
        unknown,
        UserSetAccount
    >(userApi.patchAccount, {
        onSuccess: () => {
            queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            Success({
                text: translate('t.user_modified_success')
            });
        }
    });

    return (
        <UpdateDropDownContent
            data-testid={props.testid || 'UpdateDropDown-testid'}
            {...props}
            updateValue={state.updateValue}
            anchorOriginCustom={anchorOriginCustom}
            transformOriginCustom={transformOriginCustom}
            setUpdateValue={setUpdateValue}
            loading={setAccountMutationLoading}
            setAccountUser={setAccountMutation}
        />
    );
};

export default UpdateDropDown;
