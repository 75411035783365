import React, { useState } from 'react';
import { QuicksightReportsContent } from './QuicksightReports.view';
import { QuicksightReportsProps } from './QuicksightReports.type';
import { Wrapper } from 'helpers/wrapper';
import ReportingApi from 'api/Reporting';
import { useMutation, useQuery } from '@tanstack/react-query';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { ReportingQueryKeys } from 'models/Reporting.type';

const Reporting = new ReportingApi();

//Done quickly, needs to be checked again
const QuicksightReports: React.FC<QuicksightReportsProps> = (): JSX.Element => {
    const [showSelectMsg, setShowSelectMsg] = useState<boolean>(true);
    const [isPdf, setIsPdf] = useState<boolean>(false);
    const [pdfUrl, setPdfUrl] = useState<string>();
    const { isLoading, data: availableReports } = useQuery(
        [ReportingQueryKeys.getAvailableQuicksightReports],
        Reporting.getAvailableQuicksightReports,
        {
            staleTime: cacheTimeToMilliseconds(15, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(15, 'minutes')
        }
    );
    const { mutate: generateEmbeddedUrl, isLoading: generateEmbeddedUrlIsLoading } = useMutation(
        (reportingId: number) => Reporting.generateEmbeddedUrl({ reportingId }),
        {
            onSuccess: (data) => {
                if (data.data) {
                    loadQuicksight(data.data);
                }
            },
            onMutate: () => {
                clearEmbededElement();
            }
        }
    );

    const clearEmbededElement = () => {
        const embeddedEl = document.getElementById('quicksight-content');
        embeddedEl?.childNodes[0] && embeddedEl?.removeChild(embeddedEl.childNodes[0]);
    };

    const loadQuicksight = (data) => {
        setPdfUrl(data.embeddedUrl);

        const options = {
            url: data.embeddedUrl,
            container: '#quicksight-content',
            scrolling: 'no',
            height: '100%',
            width: '100%',
            footerPaddingEnabled: true,
            printEnabled: true
        };
        const contentOptions = {
            toolbarOptions: {
                export: true
            }
        };
        embedDashboard(options, contentOptions);
    };

    return (
        <QuicksightReportsContent
            availableReports={availableReports}
            isLoading={isLoading}
            generateEmbeddedUrl={generateEmbeddedUrl}
            generateEmbeddedUrlIsLoading={generateEmbeddedUrlIsLoading}
            showSelectMsg={showSelectMsg}
            setShowSelectMsg={setShowSelectMsg}
            data-testid={'QuicksightReports-testid'}
            isPdf={isPdf}
            setIsPdf={setIsPdf}
            pdfUrl={pdfUrl}
        />
    );
};

export default Wrapper(QuicksightReports);
