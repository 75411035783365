import React, { useState } from 'react';
import { AlertsTabProps } from './AlertsTab.type';
import { typeAlert } from '../AlertsListTabManager/AlertsListTabManager.type';
import AlertsTabContent from './AlertsTab.view';

const AlertsTab: React.FC<AlertsTabProps> = ({ vehicleId, showTab }):JSX.Element => {
    const [activeTab, setActiveTab] = useState<number>(showTab);
    const [tpmsAlerts, setTPMSAlerts] = useState<number>(0);
    const [gpsAlerts, setGPSAlerts] = useState<number>(0);
    const [historicalLogAlerts, setHistoricalLogAlerts] = useState<number>(0);
    const [systemAlerts, setSystemAlerts] = useState<number>(0);
    const [vehicleAlerts, setVehicleAlerts] = useState<number>(0);

    const handleTabChange = (event, newTabIndex: number): void => {
        setActiveTab(newTabIndex)
    };

    const changeNumberOfAlerts = (type: typeAlert, value: number) => {
        switch (type) {
            case typeAlert.TPMS:
                setTPMSAlerts(value);
                break;
            case typeAlert.GPS:
                setGPSAlerts(value);
                break;
            case typeAlert.HistoricalLog:
                setHistoricalLogAlerts(value);
                break;
            case typeAlert.Sensor:
                setSystemAlerts(value);
                break;
            case typeAlert.VehicleEvent:
                setVehicleAlerts(value);
                break;
        }
    };

    return (
        <AlertsTabContent 
            data-testid="AlertsTabContent"
            vehicleId={vehicleId}
            activeTab={activeTab}
            tpmsAlerts={tpmsAlerts}
            gpsAlerts={gpsAlerts}
            historicalLogAlerts={historicalLogAlerts}
            systemAlerts={systemAlerts}
            vehicleAlerts={vehicleAlerts}
            handleTabChange={handleTabChange}
            changeNumberOfAlerts={changeNumberOfAlerts}
        />
    );
};

export default AlertsTab;