import { UserModel } from './User.type';
import { NotificationModel } from './Notification.type';
import { Paginator } from 'helpers/api/type';
import { VehicleModel } from './Vehicle.type';
import Wheel from './Wheel.type';

export enum RULE_TYPE {
    DEFAULT = 1,
    WIZARD = 2,
    CUSTOM = 3
}

type RuleType = {
    description: 'default' | 'wizard' | 'custom';
    id: number;
};

type RuleLimit = {
    createdAt: string | Date;
    id: number;
    legacyOlHistoryId: number;
    limitFrom: number;
    limitTo: number;
    notification: Pick<NotificationModel, 'id' | 'level' | 'type' | 'type2'>;
};

export type RuleModel = {
    active: boolean;
    createdAt: string | Date;
    id: number;
    optimalColdPressure: number;
    position: number | null;
    priority: number;
    ruleLimit: RuleLimit[];
    ruleType: RuleType;
    tyreManufacturerId: number | null;
    tyreModelId: number | null;
    updatedAt: string | Date | null;
    user: Pick<UserModel, 'id' | 'firstname' | 'lastname'>;
    validTill: null;
    vehicle: null;
    vehicleModelId: number | null;
    wheel: Pick<Wheel, 'id'> | null;
};

export type RuleModelWheel = {
    rule: RuleModel;
};

export type RuleModelPost = Partial<
    Pick<RuleModel, 'optimalColdPressure' | 'vehicle'> & {
        priority: number;
        ruleLimit: (Pick<RuleLimit, 'limitFrom' | 'limitTo'> & { notification: number })[];
        ruleType: RULE_TYPE;
        vehicleModelId: number;
        overrideExistingRules: boolean;
        position: null | number[] | number;
        wheel: number;
    }
>;

export type DefaultVehicle = Pick<VehicleModel, 'id' | 'name' | 'vehicleConfigurationV2'> & {
    wheel: (Pick<Wheel, 'id' | 'position'> & {
        rule: Pick<RuleModel, 'id'> & {
            ruleType: Pick<RuleType, 'id'>;
        };
    })[];
};

export type DefaultVehiclesWithPaginator = Paginator<DefaultVehicle>;

export type DefaultVehicles = {
    page: number;
    limit: number;
    name: string;
    wheelRuleRuleType: 1;
};

export enum RuleQueryKeys {
    getDefaultVehicles = 'rule-getDefaultVehicles',
    getWheel = 'rule-getWheel',
    getDefault = 'rule-getDefault'
}
