import React, { useState, useEffect } from 'react';
import { ReportIssueContent } from './ReportIssue.view';
import { ReportIssueProps } from './ReportIssue.type';
import { useMutation, useQuery } from '@tanstack/react-query';
import Account from 'api/Account';
import { BugReportModel } from 'models/BugReport.type';
import BugReport from 'api/BugReport';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { FormikValues } from 'formik';
import { Success } from 'components/Popup/Popup';
import { useNavigate } from 'react-router-dom';
import { getUrlSafeData } from 'helpers/encrypt';
import { authToken } from 'helpers/authentication';
import { User } from 'states/global/User';
import { UserModel } from 'models/User.type';
import { BugReportQueryKeys } from 'models/BugReport.type';
import { Wrapper } from 'helpers/wrapper';

const apiAccount = new Account();
const bugReportAPI = new BugReport();
const ReportIssue: React.FC<ReportIssueProps> = (): JSX.Element => {
    const [hasAttachmentError, setHasAttachmentError] = useState<boolean>(false);
    const [attachments, setAttachments] = useState<File[]>();
    const [urlSafeData, setUrlSafeData] = useState<string | undefined>();
    const { t: translate } = useTranslation();
    const setUser = useSetRecoilState(User);
    const navigate = useNavigate();

    const {
        refetch: verifyAccount,
        isLoading: isLoadingAccountVerification,
        isError: isErrorAccountVerification
    } = useQuery([BugReportQueryKeys.bugReporterAccount], () => apiAccount.getParsed(), {
        enabled: false,
        staleTime: 0,
        cacheTime: 0,
        onSuccess: (dataOnSuccess: UserModel) => {
            setUser(dataOnSuccess);
            setUrlSafeData(undefined);
        },
        onError: () => {
            location.href = `https://jira-helpdesk.itrack.bridgestone/?id=${urlSafeData}`;
        }
    });

    const mutation = useMutation<BugReportModel, unknown, FormData>(
        (form) =>
            bugReportAPI.post({
                apiProject: undefined,
                data: form
            }),
        {
            onSuccess: () => {
                Success({ text: translate('p.ticket_created') });
                setTimeout(() => {
                    navigate('/');
                }, 2000);
            }
        }
    );

    const setError = (error: boolean): void => {
        setHasAttachmentError(error);
    };

    const getUploadedAttachments = (files: File[]): void => {
        setAttachments(files);
    };

    const handleRaiseRequest = async (formikValues: FormikValues): Promise<void> => {
        let formData = new FormData();
        formData.append('page', formikValues.page.value);
        formData.append('title', formikValues.title);
        formData.append('message', formikValues.description);
        formData.append('priority', formikValues.jiraPriority?.jiraIssue.jiraPriorityId);
        formData.append('projectId', formikValues.jiraProject.id);
        formData.append('typeId', formikValues.jiraPriority.id);
        if (attachments?.length) {
            for (let i = 0, length = attachments.length; i < length; i++) {
                formData.append('attachments[]', attachments[i]);
            }
        }
        mutation.mutate(formData);
    };

    useEffect(() => {
        if (authToken() !== '') {
            getUrlSafeData().then((result) => {
                setUrlSafeData(result);
                verifyAccount();
            });
        } else {
            navigate('/login');
        }
    }, []);

    return (
        <>
            {!isLoadingAccountVerification && !isErrorAccountVerification && (
                <ReportIssueContent
                    data-testid={'ReportIssue-testid'}
                    setError={setError}
                    getUploadedAttachments={getUploadedAttachments}
                    handleRaiseRequest={handleRaiseRequest}
                    hasAttachmentError={hasAttachmentError}
                    isProcessing={mutation.isLoading}
                />
            )}
        </>
    );
};

export default Wrapper(ReportIssue);
