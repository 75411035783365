import React from 'react';
import { ButtonShowNotificationsViewProps } from './ButtonShowNotifications.type';
import { Title, NewAlertIcon } from './ButtonShowNotifications.style';
import { Badge, Tooltip } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import UiLegend from '../../Ui/Components/UiLegend/UiLegend';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AlertsList from '../../AlertNotification/AlertsList/AlertsList';
import { typeAlert } from '../../AlertNotification/AlertsListTabManager/AlertsListTabManager.type';
import FaultySensorReportModal from 'components/Mixs/FaultySensorReportModal/FaultySensorReportModal';
import { typeFilter } from '../../AlertNotification/AlertsListTabManager/AlertsListTabManager.type';
import { ModelTpmsAlerts } from 'models/TpmsAlerts.type';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import UiScroll from '../../Ui/Components/UiScroll/UiScroll';
import { UserInfo } from 'states/global/User';

export const ButtonShowNotificationsContent: React.FC<ButtonShowNotificationsViewProps> = ({
    alertsData,
    alertsIsLoading,
    muteNotification,
    notificationPulse,
    setNotificationPulse
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);

    return (
        <>
            <UiLegend
                icon={<Title>{translate('t.alerts')}</Title>}
                mode={'light'}
                onEnter={() => setNotificationPulse(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                content={
                    <UiScroll
                        height='400px'
                        content={
                            <AlertsList
                                data={
                                    checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN])
                                        ? alertsData?.items
                                        : alertsData?.items.reduce((acc: ModelTpmsAlerts[], current) => {
                                              current.notification.type !== typeFilter.BATTERY && acc.push(current);
                                              return acc;
                                          }, [])
                                }
                                testId='topbar'
                                paginator={alertsData?.paginator}
                                alertKey='_alertTopBarTPMS'
                                displayAlertActions={true}
                                typeAlert={typeAlert.TPMS}
                                isLoading={alertsIsLoading}
                                showSolveButton={true}
                                showValidateButton={true}
                                tabNumber={0}
                                displayBody={true}
                                sizeAlert='sm'
                            />
                        }
                    />
                }
                width={350}
                root={
                    <Tooltip title={translate('t.show_notifications')} placement='bottom' arrow disableInteractive>
                        <NewAlertIcon
                            $statusNotification={muteNotification}
                            $oldPortalMode={false}
                            $pulse={notificationPulse}
                        >
                            <Badge
                                badgeContent={
                                    checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN])
                                        ? alertsData?.items.length
                                        : alertsData?.items.reduce((acc: ModelTpmsAlerts[], current) => {
                                              current.notification.type !== typeFilter.BATTERY && acc.push(current);
                                              return acc;
                                          }, []).length
                                }
                                color='primary'
                                data-testid='ShowNotifications-Button'
                            >
                                <UiIcon size='sm' icon={['far', 'bullhorn']} />
                            </Badge>
                        </NewAlertIcon>
                    </Tooltip>
                }
            />
            <FaultySensorReportModal />
        </>
    );
};
