import React, { useCallback, useEffect, useMemo } from 'react';
import { Wrapper } from 'helpers/wrapper';
import { useQuery } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { QueryFn, TableAtom } from './TableAtom';
import { DefaultRequestPropsType, Paginator } from 'helpers/api/type';
import useUserLocalStorageSettings from 'components/CustomHooks/UserLocalStorageSettings';
import { FilterProps } from './UiTable.type';
import { DateTime } from 'luxon';
import { UiTableContent } from '../UiTable/UiTable.view';
import { UserInfo } from 'states/global/User';

const UiTable = <
    T extends (props: DefaultRequestPropsType['criteria']) => Promise<Paginator<T, unknown>>,
    K extends ({ ...args }) => ReturnType<K>
>(
    props
): JSX.Element => {
    const [tableState, setTableState] = useRecoilState(TableAtom(`${props.queryKey}-Table`));
    const userInfo = useRecoilValue(UserInfo);
    const tableSettingsKey = useMemo(() => `${props.queryKey}Table`, [props.queryKey]);
    const setQueryFn = useSetRecoilState(QueryFn(`${props.queryKey}-QueryFn`));
    const resetTableState = useResetRecoilState(TableAtom(`${props.queryKey}-Table`));
    const { setUserSettings, userLSSettings: tableSettings } = useUserLocalStorageSettings([
        `table.${tableSettingsKey}`
    ]);

    const checkEnabled = useCallback((): boolean => {
        return tableState.tableKey === props.queryKey || !!props.useColumnFiltering;
    }, [tableState.tableKey, props.queryKey, props.useColumnFiltering]);

    const { data, isLoading, isFetching, refetch } = useQuery(
        [
            `${props.queryKey}-TableQuery`,
            tableState.queryPageIndex,
            tableState.queryPageSize,
            tableState.queryPageSortBy,
            tableState.queryPageFilter,
            props.additionalProps,
            userInfo.user?.customer.id
        ],
        () => props.fetchFn({ ...tableState }, props.additionalProps),
        {
            refetchInterval: props.refresh ?? 50000,
            keepPreviousData: true,
            enabled: checkEnabled(),
            refetchOnWindowFocus: false,
            onSuccess(data) {
                setTableState((current) => ({ ...current, totalCount: data.paginator.totalCount }));
            }
        }
    );

    useEffect(() => {
        if (tableSettings && tableSettings[`${tableSettingsKey}`] && props.hiddenColumns) {
            setUserSettings(`table.${tableSettingsKey}`, {
                ...tableSettings[tableSettingsKey],
                defaultHiddenColumns: [...props.hiddenColumns]
            });
        }
    }, [props.hiddenColumns, tableSettings]);

    useEffect(() => {
        if (!props.useColumnFiltering && tableSettings && tableSettings[tableSettingsKey]) {
            let filtersState: (FilterProps & Record<string, unknown>)[] | [] =
                tableSettings[tableSettingsKey].filter || [];
            if (filtersState && !!filtersState.length) {
                filtersState = filtersState.map((filter) => {
                    let newFilter: FilterProps & Record<string, unknown> = filter;
                    const date = DateTime.fromISO(filter.value.value, { zone: 'utc' });
                    if (date.isValid && isNaN(filter.value.value)) {
                        newFilter.value.value = date;
                        return newFilter;
                    }
                    return filter;
                });
            }
            setTableState({
                ...tableState,
                queryPageSortBy: tableSettings[tableSettingsKey] ? tableSettings[tableSettingsKey].orderBy : [],
                queryPageSize: tableSettings[tableSettingsKey].pageSize || 10,
                queryPageFilter: filtersState,
                tableKey: props.queryKey
            });
        } else {
            if (!props.useColumnFiltering && tableSettings && !tableSettings[tableSettingsKey]) {
                setTableState({
                    ...tableState,
                    queryPageIndex: 0,
                    queryPageSize: 10,
                    queryPageSortBy: props.defaultSortBy ? [props.defaultSortBy] : [],
                    queryPageFilter: props.defaultFilter ? [props.defaultFilter] : [],
                    tableKey: props.queryKey
                });
                setUserSettings(`table.${tableSettingsKey}`, {
                    filter: props.defaultFilter ? [props.defaultFilter] : [],
                    orderBy: props.defaultSortBy ? [props.defaultSortBy] : [],
                    pageSize: 10,
                    defaultHiddenColumns: props.hiddenColumns ? [...props.hiddenColumns] : []
                });
            }
        }

        return () => {
            resetTableState();
        };
    }, [tableSettings, props.queryKey]);

    const defineGlobalQueryFn = useCallback(() => {
        setQueryFn((current) => ({ ...current, refetch }));
    }, [refetch]);

    useEffect(() => {
        defineGlobalQueryFn();
    }, []);

    return (
        <UiTableContent
            {...props}
            useColumnFiltering={props.useColumnFiltering}
            useColumnAction={props.useColumnAction}
            queryKey={props.queryKey}
            useColumnCheckbox={props.useColumnCheckbox}
            data={data?.items}
            pageCount={data?.paginator?.pageCount}
            paginator={data?.paginator}
            isLoadingFetching={isLoading || isFetching}
            isLoading={isLoading}
            rowActionBtns={props.rowActionBtns}
            expandableContent={props.expandableContent}
            isExpandable={props.isExpandable}
        />
    );
};

export default Wrapper(UiTable);
