import React from 'react';
import * as view from './RolesDropDown.view';
import * as type from './RolesDropDown.type';
import Role from 'api/Role';
import { Wrapper } from 'helpers/wrapper';
import { useQuery } from '@tanstack/react-query';
import CircularProgress from '@mui/material/CircularProgress';
import { RoleCodeBook, RoleQueryKeys } from 'models/Role.type';
import { toCamelCase_H } from 'helpers';
import { GridFieldFormik } from '../ProfileTabManager/ProfileTabManager.style';
import { cacheTimeToMilliseconds } from 'helpers/cache';

const ApiRole = new Role();

const RolesDropDown: React.FC<type.RolesDropDownProps> = (props): JSX.Element => {
    const {
        isLoading: rolesLoading,

        data: rolesData
    } = useQuery<RoleCodeBook[], Error, { codeBook: RoleCodeBook[] }>(
        [RoleQueryKeys.getAvailable],
        () => ApiRole.getAvailable(),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours')
        }
    );

    const FormatText = (valueText: string, subStringText: string) => {
        return toCamelCase_H(valueText.replace(subStringText, ''));
    };

    return !rolesLoading ? (
        <view.RolesDropDownContent
            data-testid={props.testId || 'RolesDropDown'}
            {...props}
            roles={rolesData?.codeBook ? rolesData.codeBook : []}
            formatText={FormatText}
        />
    ) : (
        <GridFieldFormik item xl={props.xl} lg={props.lg} md={props.md} sm={props.sm} xs={props.xs}>
            <CircularProgress />
        </GridFieldFormik>
    );
};

export default Wrapper(RolesDropDown);
