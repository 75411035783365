import { typeFilter } from 'components/AlertNotification/AlertsListTabManager/AlertsListTabManager.type';
import { Paginator } from './Paginator.type';
import { VehicleStatus2Data } from './Vehicle.type';
import { VehicleInfoModel } from './VehicleInfo.type';

export type TrackLogModel = {};

export type SpeedParams = {
    measuredFrom?: string;
    measuredTo?: string;
};

export type SpeedData = {
    timeKey: number;
    speedMax: number;
    speedMin: number;
    speedAvg: number;
    measuredAt: string;
};

export type SpeedResponse = {
    vehicleSpeed: SpeedData[];
};

export type VehicleStatsModel = {
    vehicleStats: VehicleStatsData[];
};

export type VehicleStatsData = {
    id: number;
    name: string;
    icon: string;
    alert: number;
    operationTime: number;
    odometer: number;
    averageSpeed: number;
    outOfService: number;
    maintenance: number | null;
    deviceId: number | null;
    vehicleModelId: number | null;
    parkingTime: number;
    shutdown: boolean;
    network: string | null;
    maxBesSeverityRankTotal: number | null;
    vehicleTypeId?: number;
    predictionTemperature: boolean;
    genericTemperatureAlert: boolean;
    vehicleInfo: Pick<VehicleInfoModel, 'measuredAt' | 'battery' | 'powerSource' | 'lastGpsMeasuredAt'>;
    notifications:
        | {
              count: number;
              isNotificationAlert: number;
              type: typeFilter;
              type2: string;
              level: number;
              timeType: string;
          }[]
        | [];
    vehicleStats?: VehicleStatsData;
    lastTemperaturePredictedAt: null | string;
    wheels: VehicleStatus2Data['vehicle']['wheels'];
    iconAlerts: {
        type: typeFilter;
        type2: string;
        level: number;
    }[];
};

export type TrackLogReportTable = {
    items: TrackLogReport[];
    paginator: Paginator;
};

export type TrackLogReport = {
    id: string;
    latitude: number;
    longitude: number;
    speed: number;
    altitude: number;
    ascend: number;
    descend: number;
    heading: number;
    sat: boolean;
    odometer: number;
    acceleration: number;
    measuredAt: string;
    createdAt: string;
    requestId: string;
    vehicle: {
        id: number;
    };
};

export type SpeedHeatmap = {
    speed: number;
    latitude: number;
    longitude: number;
};

export type SpeedHeatmapResponse = {
    speedHeatmap: SpeedHeatmap[];
};

export type HeatmapProps = {
    measuredFrom: string;
    measuredTo: string;
    vehicleIds?: string;
    position?: [number, number, number, number];
    limit?: number;
    zoomLevel: number;
    area?: [number, number, number, number] | string;
    //todo: temporarly, will be removed after testing ask @David
    odometerSum?: number;
    satellites?: number;
};

export type SpeedHeatmapProps = Omit<HeatmapProps, 'vehicleIds'> & {
    vehicleIds?: string | number[];
};

export type ElevationProps = Omit<HeatmapProps, 'position'>;

export type BasicHeatmapProps = {
    measuredFrom: string;
    measuredTo: string;
    vehicleIds?: string;
    zoomLevel: number;
    area?: [number, number, number, number] | string;
    limit?: number;
};

export type BasicHeatmapResponse = {
    latitude: number;
    longitude: number;
    vehicleId: number;
};

export type Elevation = {
    elevation: number;
} & BasicHeatmapResponse;

export type ElevationResponse = {
    heatmapElevation: Elevation[];
};

export type GpsResponse = {
    heatmapGps: (Omit<BasicHeatmapResponse, 'vehicleId'> & { satellite: number })[];
};

export type GpsStatistics = {
    averageSatellites?: number;
    logsInPeriod?: number;
    logsInPeriodNoSatellites?: number;
};

export type AccelerationResponse = {
    accelerationHeatmap: (BasicHeatmapResponse & { acceleration: number })[];
};

export type SlopeInclinationHeatmapResponse = {
    slopeInclinationHeatmap: SlopeInclinationHeatmapData[];
};

export type SlopeInclinationHeatmapData = {
    accelerometerSumX: number;
    accelerometerSumY: number;
    accelerometerSumZ: number;
    altPercentage: number;
    altSlopeAngleDegree: number;
    altitudeFirst: number;
    altitudeLast: number;
    inclinationDegree: number;
    inclinationPercentage: number;
    lastMeasuredAt: string;
    latitude: number;
    latitudeFirst: number;
    longitude: number;
    longitudeFirst: number;
    odometerSum: number;
    speedAvg: number;
    speedFirst: number;
    speedLast: number;
};

export type GetSlopeInclinationDataParams = {
    vehicleId: string;
    measuredFrom: string;
    measuredTo: string;
    odometerSum: number;
    satellites: number;
};

export enum TrackLogQueryKeys {
    getWebtrackVehicleStats = 'trackLog-getWebtrackVehicleStats',
    getVehicleStatusDetail = 'trackLog-getVehicleStatusDetail',
    getGPSStatistics = 'trackLog-getGPSStatistics'
}

//HEATMAP_API_RESPONSE_TYPE_AUTOMATIC_GENERATOR

export default TrackLogModel;
