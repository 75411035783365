import { UserModel } from 'models/User.type';

export type AccountSettingsFormProps = {
    userData: UserModel | null;
    isCurrentUser?: boolean;
    userId?: number;
    setReloadPage: (reload: boolean) => void;
};

export type UserData = {
    user: UserModel;
};

export type InicialvaluesForm = {
    username: string;
    email: string;
    role: string | number;
    phoneNumber: string;
    firstname: string;
    lastname: string;
    avatar?: string | ArrayBuffer;
};

type AccountSettingsPass = {
    inicialvaluesFormik: InicialvaluesForm;
    handleSubmit: (e: InicialvaluesForm, setSubmitting: (isSubmitting: boolean) => void) => void;
    handleCapture: (e: React.ChangeEvent) => void;
    selectedFile: string | ArrayBuffer | null;
    previewImg: string;
    setPreviewImg: (img: string) => void;
    userData: UserModel | null;
    isCurrentUser?: boolean;
    sendform: boolean;
};

export type AccountSettingsFormViewProps = {
    existsUserName: (userName: string, currentUserName?: string) => Promise<boolean>;
} & AccountSettingsPass;

export enum TypeRole {
    ROLE_SUPER_ADMIN = 1,
    ROLE_ADMIN = 2
}

export type FormikValidationViewProps = {} & AccountSettingsFormViewProps;

export type AvatarModel = {
    avatar: HTMLImageElement;
};
