import React, { useCallback } from 'react';
import { ParsedData, TemperatureExposureReportProps } from './TemperatureExposureReport.type';
import {
    BarChartTooltip,
    BarChartTooltipLine,
    TemperatureExposureReportContainer,
    TooltipContentEl
} from './TemperatureExposureReport.style';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { EXPOSURE_1, EXPOSURE_2, EXPOSURE_3, EXPOSURE_4, EXPOSURE_5, EXPOSURE_6, EXPOSURE_7 } from '../../Ui/colors';
import useConverter from '../../CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { ConverterTypeEnum } from '../../CustomHooks/Converter/Converter.type';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { TemperatureExposureDate } from '../TemperatureExposureTopbar/TemperatureExposureTopbar.atom';
import { Theme, ThemeMode } from '../../../states/global/Theme';
import { Wrapper } from 'helpers/wrapper';

const TooltipContent: React.FC<
    {
        labels: () => void;
        payload?: ({ payload: { name: string }; name: string; color: string } & Record<string, unknown>)[];
        active?: unknown;
    } & Record<string, unknown>
> = (props): JSX.Element | null => {
    const { t: translate } = useTranslation();
    const labels = props.labels();
    if (props.active && props.payload && props.payload.length) {
        return (
            <TooltipContentEl>
                <p>{props.payload[0].payload.name}</p>
                <BarChartTooltip>
                    {props.payload.map((data, index) => {
                        return (
                            <BarChartTooltipLine color={data.color} key={index}>
                                [{labels[data.name] || translate('t.unknown')}] - {data.value}
                            </BarChartTooltipLine>
                        );
                    })}
                </BarChartTooltip>
            </TooltipContentEl>
        );
    }

    return null;
};

const TemperatureExposureReport: React.FC<TemperatureExposureReportProps> = (props): JSX.Element => {
    const { fromServerToUserUnit, dateTimeFormat } = useConverter();
    const dateRange = useRecoilValue(TemperatureExposureDate);
    const ThemeModeValue = useRecoilValue(Theme);
    const lineColor: string = ThemeModeValue?.mode === ThemeMode.light ? '#000' : '#fff';

    const getParsedData = useCallback(() => {
        let numberOfDays = Math.ceil(dateRange.appliedDateTo.diff(dateRange.appliedDateFrom, ['days']).toObject().days);
        let parsedData: ParsedData = {};
        let startDate = DateTime.fromISO(dateRange.appliedDateFrom);

        while (numberOfDays > 0) {
            parsedData[startDate.toFormat(dateTimeFormat('date', false))] = {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0
            };
            startDate = startDate.plus({ days: 1 });
            numberOfDays--;
        }

        if (props.data) {
            const length = props.data?.temperatureExposureReport?.length || 0;
            for (let i = 0; i < length; i++) {
                const date = DateTime.fromISO(props.data?.temperatureExposureReport[i]?.day ?? '').toFormat(
                    dateTimeFormat('date', false)
                );
                if (!parsedData[date]) {
                    parsedData[date] = {
                        1: 0,
                        2: 0,
                        3: 0,
                        4: 0,
                        5: 0,
                        6: 0,
                        7: 0
                    };
                }
                parsedData[date][props.data.temperatureExposureReport[i].temperatureCategory] =
                    props.data.temperatureExposureReport[i].measurements;
            }
        }
        return Object.entries(parsedData).map((value) => {
            return { ...value[1], name: value[0] };
        });
    }, [props.data?.temperatureExposureReport, dateRange.appliedDateFrom, dateRange.appliedDateTo]);

    const getLabels = useCallback(() => {
        let labels = {};
        let minLimit = 0;
        if (props.data?.temperatureCategories) {
            for (const [key, value] of Object.entries(props.data?.temperatureCategories)) {
                labels[key] = `${fromServerToUserUnit({
                    type: ConverterTypeEnum.TEMPERATURE,
                    value: minLimit,
                    displayUnits: true
                })} ${
                    value.value === 1000
                        ? '+'
                        : ` - ${fromServerToUserUnit({
                              type: ConverterTypeEnum.TEMPERATURE,
                              value: value.value,
                              displayUnits: true
                          })}`
                }`;
                minLimit = value.value;
            }
        }
        return labels;
    }, [props.data?.temperatureCategories]);

    return (
        <TemperatureExposureReportContainer data-testid='TemperatureExposureReport-testid'>
            <ResponsiveContainer width='100%' height={260} minHeight={270}>
                <BarChart data={getParsedData()} width={500} barGap={0} barSize={100} syncId='temp-exposure'>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis
                        dataKey='name'
                        domain={['']}
                        stroke={lineColor}
                        tickFormatter={(option) => {
                            return option;
                        }}
                        padding={{ left: 50, right: 50 }}
                    />
                    <YAxis
                        domain={[0, 24]}
                        tickFormatter={(value) => `${value}h`}
                        stroke={lineColor}
                        label={{
                            fill: lineColor
                        }}
                    />
                    <Tooltip content={<TooltipContent labels={getLabels} />} cursor={{ fill: 'transparent' }} />
                    <Legend
                        wrapperStyle={{ fontSize: '13px' }}
                        formatter={(value) => {
                            return <span style={{ color: lineColor }}>{getLabels()[value] ?? '-'}</span>;
                        }}
                    />
                    <Bar dataKey='1' stackId='a' fill={EXPOSURE_1} stroke='rgba(0,0,0,.2)' />
                    <Bar dataKey='2' stackId='a' fill={EXPOSURE_2} stroke='rgba(0,0,0,.2)' />
                    <Bar dataKey='3' stackId='a' fill={EXPOSURE_3} stroke='rgba(0,0,0,.2)' />
                    <Bar dataKey='4' stackId='a' fill={EXPOSURE_4} stroke='rgba(0,0,0,.2)' />
                    <Bar dataKey='5' stackId='a' fill={EXPOSURE_5} stroke='rgba(0,0,0,.2)' />
                    <Bar dataKey='6' stackId='a' fill={EXPOSURE_6} stroke='rgba(0,0,0,.2)' />
                    <Bar dataKey='7' stackId='a' fill={EXPOSURE_7} stroke='rgba(0,0,0,.2)' />
                </BarChart>
            </ResponsiveContainer>
        </TemperatureExposureReportContainer>
    );
};

export default Wrapper(TemperatureExposureReport);
