import React from 'react';
import { ContainerForm, Header, ShiftTimeCalendarActionContainer, Titile } from './ShiftTimeCalendarAction.style';
import { ShiftTimeCalendarActionViewProps } from './ShiftTimeCalendarAction.type';
import { useTranslation } from 'react-i18next';
import { ShiftTimeCalendarForm } from './ShiftTimeCalendarForm';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { Box } from '@mui/material';

export const ShiftTimeCalendarActionContent: React.FC<ShiftTimeCalendarActionViewProps> = ({
    handleSubmit,
    inicialvaluesFormik,
    teamShiftTimeListsDataLoading,
    scheduler,
    teamShiftTimeData,
    loading
}): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <ShiftTimeCalendarActionContainer data-testid='ShiftTimeCalendarActionContent'>
            <Header>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', pr: 1.5 }}>
                    <Titile>{translate(scheduler.edited ? 't.edit_shift' : 't.add_shift')}</Titile>

                    {scheduler.edited && scheduler.edited?.team ? (
                        <Titile>
                            {translate('t.team')}: {scheduler.edited.team?.name}
                        </Titile>
                    ) : (
                        <></>
                    )}
                </Box>
            </Header>
            <ContainerForm>
                {teamShiftTimeListsDataLoading ? (
                    <UiLoadingPage size='25px' data-testid='ShiftTimeCalendarAction-uiLoadingPage' />
                ) : (
                    <ShiftTimeCalendarForm
                        handleSubmit={handleSubmit}
                        inicialvaluesFormik={inicialvaluesFormik}
                        scheduler={scheduler}
                        teamShiftTimeData={teamShiftTimeData}
                        loading={loading}
                    />
                )}
            </ContainerForm>
        </ShiftTimeCalendarActionContainer>
    );
};
