import React from 'react';
import Widget from '../Ui/Components/UiWidget';
import TrackingSummary from './TrackingSummary';
import HistoricalLog from './HistoricalLog';
import TyresSummary from './TyresSummary';
import { DateTimePicker } from '../../states/global/Statistics';
import { useRecoilValue } from 'recoil';
import Grid from '@mui/material/Grid';
import UiLegend from '../Ui/Components/UiLegend';
import { useTranslation } from 'react-i18next';
import { LegendLeftText, LegendRightText, LegendTable, StatisticsSummaryContainer } from './StatisticsSummary.style';
import { StatisticsSummaryViewProps } from './StatisticsSummary.type';
import { UserInfo } from '../../states/global/User';
import { ROLES } from 'variables';
import useConverter from '../CustomHooks/Converter/Converter';
import { applyStyleByMode } from 'helpers';
import { Theme } from '../../states/global/Theme';
import { BACKGROUND_DARK_MODE, WHITE } from '../Ui/colors';
import UiIcon from '../Ui/Components/UiIcon/UiIcon';

export const StatisticsSummaryContent: React.FC<StatisticsSummaryViewProps> = (props): JSX.Element => {
    const statisticsDateTime = useRecoilValue(DateTimePicker);
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const { fromUTCToTimezone } = useConverter();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <StatisticsSummaryContainer data-testid='StatisticsSummaryContent'>
            <Widget
                id={'statistics_summary'}
                title={`${translate('t.summary_for_period')}:
                    ${fromUTCToTimezone(statisticsDateTime.current.dateFrom, false)} -
                    ${fromUTCToTimezone(statisticsDateTime.current.dateTo, false)}                   
                `}
                headerColor={applyStyleByMode({
                    theme: ThemeMode?.mode,
                    light: '#e9e9e9;',
                    dark: BACKGROUND_DARK_MODE
                })}
                avatar={
                    <UiIcon
                        icon={['fas', 'book']}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                collapseButton={true}
                content={
                    <Grid container spacing={3} sx={{ pt: 1 }}>
                        <Grid item xs={12} md={5} lg={5}>
                            <TrackingSummary {...props} />
                        </Grid>
                        <Grid item xs={12} md={7} lg={7}>
                            <HistoricalLog {...props} />
                        </Grid>
                    </Grid>
                }
                headerAction={
                    <UiLegend
                        title={translate('t.legend')}
                        content={
                            <LegendTable>
                                <tr>
                                    <LegendLeftText>{translate('t.average_speed')}</LegendLeftText>
                                    <td>
                                        <LegendRightText>
                                            {translate('t.average_speed')} - {translate('p.average_traveling_speed')}
                                        </LegendRightText>
                                    </td>
                                </tr>
                                <tr>
                                    <LegendLeftText>{translate('t.gross_average_speed')}</LegendLeftText>
                                    <td>
                                        <LegendRightText>
                                            {translate('t.gross_average_speed')} -{' '}
                                            {translate('p.distance_travelled_in_period_time')}
                                        </LegendRightText>
                                    </td>
                                </tr>
                                <tr>
                                    <LegendLeftText>{translate('t.hub_operating_time')}</LegendLeftText>
                                    <td>
                                        <LegendRightText>{translate('p.hub_powered_total_time')}</LegendRightText>
                                    </td>
                                </tr>
                                {userInfo.user?.role.name === ROLES.ROLE_SUPER_ADMIN && (
                                    <tr>
                                        <LegendLeftText>{translate('t.vehicle_idling_time')}</LegendLeftText>
                                        <td>
                                            <LegendRightText>
                                                {translate('p.vehicle_total_idling_time')}
                                            </LegendRightText>
                                        </td>
                                    </tr>
                                )}
                            </LegendTable>
                        }
                    />
                }
            />
            <TyresSummary {...props} />
        </StatisticsSummaryContainer>
    );
};
