import React from 'react';
import { VehicleStatusIconsContent } from './VehicleStatusIcons.view';
import { VehicleStatusIconsProps } from './VehicleStatusIcons.type';
import { Wrapper } from 'helpers/wrapper';
import { useNavigate } from 'react-router-dom';

const VehicleStatusIcons: React.FC<VehicleStatusIconsProps> = (props): JSX.Element => {
    const navigate = useNavigate();

    const setVehicleSelected = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, showTab: number) => {
        if (!props.isSelected) {
            const url: string = location.href;
            if(!props.isLoadingVehicle) {
                props.setVehicleId(props.data.vehicle.id);
            }

            url.includes('vehicle-status')
                ? navigate(`?id=${props.data.vehicle.id}&tab=${showTab}`, { replace: true })
                : navigate(`vehicle-status?id=${props.data.vehicle.id}&tab=${showTab}`, { replace: true });
        }
        props.handleShowTab(showTab);
        event.stopPropagation();
    }

    return <VehicleStatusIconsContent data-testid='VehicleStatusIcons-testid' {...props} setVehicleSelected={setVehicleSelected} />;
};

export default Wrapper(VehicleStatusIcons);
