import { Paginator } from 'helpers/api/type';

export type VehicleCounterActionsModel = {};

export type VehicleCounterActionsType = {
    counterActionIds: number[];
    expiresAt: string;
    createdAt: string;
};

export type VehicleCounterActionsTableModel = Paginator<VehicleCounterActionsType[]>;

export enum VehicleCounterActionsQueryKeys {
    getVehicleCounterAction = 'vehicleCounterAction-get'
}
