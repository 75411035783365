import React from 'react';
import { IconButtonContent, ListContainer, RowIconButton, UserListContainer } from './UserList.style';
import { UserListProps } from './UserList.type';
import UiLegend from 'components/Ui/Components/UiLegend/UiLegend';
import { useTranslation } from 'react-i18next';
import { PositionType } from 'components/Ui/Components/UiLegend/UiLegend.type';
import { ListItem, ListItemText } from '@mui/material';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';

const UserList: React.FC<UserListProps> = ({ id, alertNotificationCallsUser }): JSX.Element => {
    const { t: translate } = useTranslation();

    const anchorOriginCustom: PositionType = {
        vertical: 'top',
        horizontal: 'center'
    };
    const transformOriginCustom: PositionType = {
        vertical: 'center',
        horizontal: 'center'
    };

    return (
        <UserListContainer data-testid='UserListContent'>
            <UiLegend
                title={translate('t.users')}
                content={
                    <ListContainer>
                        {alertNotificationCallsUser.map((alertNotificationUser) => {
                            return (
                                <ListItem component='p' key={`${id}-${alertNotificationUser.user.id}`}>
                                    <ListItemText
                                        key={alertNotificationUser.user.id}
                                        primary={`${alertNotificationUser.user.firstname} ${alertNotificationUser.user.lastname} | ${alertNotificationUser.user.username}`}
                                    />
                                </ListItem>
                            );
                        })}
                    </ListContainer>
                }
                anchorOrigin={anchorOriginCustom}
                transformOrigin={transformOriginCustom}
                root={
                    <IconButtonContent>
                        <RowIconButton
                            aria-label='delete'
                            color='primary'
                            size='small'
                            testid={`Notification-Managment-Users-${id}`}
                        >
                            <>
                                <UiIcon icon={faUsers} size='1x' />
                                {alertNotificationCallsUser.length}
                            </>
                        </RowIconButton>
                    </IconButtonContent>
                }
            />
        </UserListContainer>
    );
};

export default UserList;
