import React from 'react';
import { VehicleEventType } from './VehicleEvent.type';
import VehicleEventAPI from 'api/VehicleEvent';
import { useQuery } from '@tanstack/react-query';
import { VehicleEventModelAlerts } from 'models/VehicleEvent.type';
import { useRecoilValue } from 'recoil';
import { UserInfo } from 'states/global/User';
import { VehicleQueryKeys } from 'models/Vehicle.type';

const vehicleEventAPI = new VehicleEventAPI();

const GetVehicleEventQuery: React.FC<VehicleEventType> = ({
    children,
    queryKey,
    vehicleId,
    measuredFrom,
    limit,
    page,
    customConfig
}) => {
    const userInfo = useRecoilValue(UserInfo);
    const useQueryProperties = useQuery<VehicleEventModelAlerts>(
        [queryKey || VehicleQueryKeys.getAlerts, userInfo.user?.customer.id],
        () => vehicleEventAPI.getAlerts({ limit, page, vehicleId, measuredFrom }),
        customConfig
    );

    return <>{children(useQueryProperties)}</>;
};

export default GetVehicleEventQuery;
