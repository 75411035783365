import styled from 'styled-components';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { SECONDARY } from '../../Ui/colors';
import { useTheme } from '@mui/material';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const VehicleDetailsActionsViewContent = styled.div``;

export const Icon = styled(UiIcon)`
    padding-right: 8px;
`;

export const ActionItem = styled.div`
    display: flex;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.textSize};`;
    }}
`;

export const Header = styled.div`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `
            color: ${useTheme().palette.primary.main};
            font-size: ${common.titleSize};
            font-weight: 600;
            padding: 10px 0px;
            margin : 0 auto;
            width: 93%;
            border-bottom: 1px solid ${SECONDARY};
        `;
    }}
`;

export const CircularProgressCover = styled.div`
    width: 187px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
