import React, { useEffect } from 'react';
import { CustomerSettingsContent } from './CustomerSettings.view';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { Wrapper } from 'helpers/wrapper';

const CustomerSettings: React.FC = (): JSX.Element => {
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    useEffect(() => {
        setPageHelpButton({ article: 'administration-tab' });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return <CustomerSettingsContent data-testid='CustomerSettings-testid' />;
};

export default Wrapper(CustomerSettings);
