import React from 'react';
import { RankVehicleDropdownViewProps } from './RankVehicleDropdown.type';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete/LazyloadingAutocomplete';
import VehicleApi from 'api/Vehicle';
import { useTranslation } from 'react-i18next';
import { VehicleQueryKeys } from 'models/Vehicle.type';

const Vehicle = new VehicleApi();

export const RankVehicleDropdownContent: React.FC<RankVehicleDropdownViewProps> = ({
    selectedVehicle,
    handleVehicleChange
}): JSX.Element => {
    const { t: translate } = useTranslation();
    return (
        <>
            {selectedVehicle.id && (
                <LazyloadingAutocomplete
                    id='severity-rank-topbar-lazyloading'
                    queryId={VehicleQueryKeys.getLazyDropdown}
                    optionKey='name'
                    optionValue='id'
                    size='small'
                    query={Vehicle.getTable}
                    label={translate('t.vehicle')}
                    onValueChange={handleVehicleChange}
                    preselectedValue={{
                        value: selectedVehicle.id,
                        label: selectedVehicle.name
                    }}
                    customMargin='0'
                    querySortBy={{
                        desc: false,
                        id: 'vehicleName'
                    }}
                />
            )}
        </>
    );
};
