import React, { useEffect } from 'react';
import { CustomDateTimePicker, UiDatePickerContent as UiDatePickerContentEl } from './UiDatePicker.style';
import { UiDatePickerProps } from './UiDatePicker.type';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useRecoilValue } from 'recoil';
import { Is12HrsFormat } from 'helpers/Converters';
import { useTranslation } from 'react-i18next';
import useConverter from '../../../CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { VARIANT_BUTTON } from '../../variables';
import { firstCapital, snakeToCamel } from 'helpers/converter/text';
import HelperText from '../../../Mixs/HelperText';
import DateTimeDialogSelector from './Components/DateTimeDialogSelector/DateTimeDialogSelector';
import UiButton from '../UiButton/UiButton';

const UiDatePickerContent: React.FC<UiDatePickerProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [openDate, setOpenDate] = React.useState(false);
    const [dateTimeError, setDateTimeError] = React.useState(false);
    const is12HrsFormat = useRecoilValue(Is12HrsFormat);
    const { dateTimeFormat } = useConverter();
    const [dateValue, setDateValue] = React.useState<DateTime | null>(
        props.allowEmpty
            ? null
            : (typeof props.value === 'string' ? DateTime.fromISO(props.value) : props.value) || DateTime.local()
    );
    const [dateValueStaticDatePicker, setDateValueStaticDatePicker] = React.useState<DateTime | null>(dateValue);

    useEffect(() => {
        props.value && setDateValue(DateTime.fromISO(props.value));
    }, [props.value]);

    useEffect(() => {
        dateValue && setDateValueStaticDatePicker(dateValue);
    }, [dateValue]);

    useEffect(() => {
        return () => {
            setDateValue(null);
            setDateValueStaticDatePicker(null);
        };
    }, []);

    return (
        <UiDatePickerContentEl data-testid={props.testId || 'UiDatePickerCustomer'} fullWidth={props.fullWidth}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <CustomDateTimePicker
                    format={dateTimeFormat(props.onlyDate ? 'date' : props.inputFormat || 'dateTime', false)}
                    ampm={is12HrsFormat}
                    label={props.label || translate('t.date')}
                    value={dateValue}
                    disabled={props.disabled}
                    open={false}
                    onOpen={() => {
                        if (dateTimeError) {
                            setDateValue(DateTime.local());
                            setDateTimeError(false);
                        }
                        setOpenDate(true);
                        (dateValueStaticDatePicker === null || dateValueStaticDatePicker?.invalid) &&
                            setDateValueStaticDatePicker(DateTime.local());
                    }}
                    onChange={(newDate: DateTime, validationError): void => {
                        if (newDate == null || validationError?.validationError) {
                            setDateValue(DateTime.fromISO(props.value));
                            props.onDateChange && props.onDateChange(null);
                        } else {
                            if (!newDate.invalid) {
                                setDateValue(newDate);
                                props.onDateChange && props.onDateChange(newDate);
                            }
                        }
                        setDateTimeError(!!validationError?.validationError);
                    }}
                    slots={{ textField: TextField }}
                    slotProps={{
                        textField: (params) => {
                            return {
                                margin: 'normal',
                                id: `UiDatePicker${Math.ceil(Math.random() * 1000000000000000000)}`,
                                size: 'small',
                                variant: 'outlined',
                                sx: { margin: '0 0 25px 0 !important' },
                                helperText: (
                                    <HelperText
                                        data-testid={`error$${
                                            props.testId ||
                                            firstCapital(
                                                snakeToCamel((props.label || translate('t.date')).replaceAll(' ', '_'))
                                            )
                                        }`}
                                        error={dateTimeError && props.allowEmpty ? false : dateTimeError}
                                        text={translate('t.the_date_format_is_invalid')}
                                    />
                                ),
                                error:
                                    params.value &&
                                    typeof params.value.invalid !== 'undefined' &&
                                    params.value.invalid !== null &&
                                    props.allowEmpty
                                        ? false
                                        : props.error || params.error,

                                InputLabelProps: {
                                    shrink: true
                                },
                                inputProps: {
                                    ...params.inputProps,
                                    placeholder: `${dateTimeFormat('date', false).toUpperCase()} ${
                                        !props.onlyDate ||
                                        props.inputFormat === 'dateTime' ||
                                        props.inputFormat === 'time'
                                            ? dateTimeFormat('time', false)
                                            : ''
                                    }`,
                                    style: { width: '100%' }
                                },
                                fullWidth: props.fullWidth,
                                onFocus: () => props.onFocus && props.onFocus(),
                                onBlur: () => props.onBlur && props.onBlur()
                            };
                        }
                    }}
                    $noMargin={props.noMargin}
                />

                <Dialog open={openDate} onClose={() => setOpenDate(false)}>
                    <DialogContent>
                        <DateTimeDialogSelector
                            dateValueStaticDatePicker={dateValueStaticDatePicker}
                            setDateValueStaticDatePicker={setDateValueStaticDatePicker}
                            onlyDate={props.onlyDate}
                        />
                    </DialogContent>
                    <DialogActions>
                        <UiButton
                            color='primary'
                            testid={`${props.testId || ''}-UiDatePicker-cancel-button`}
                            variant='outlined'
                            onClick={() => setOpenDate(false)}
                            size='small'
                        >
                            {translate('t.cancel')}
                        </UiButton>
                        <UiButton
                            color='primary'
                            testid={`${props.testId || ''}-UiDatePicker-ok-button`}
                            variant={VARIANT_BUTTON}
                            size='small'
                            onClick={() => {
                                if (!dateValueStaticDatePicker.invalid) {
                                    setDateValue(dateValueStaticDatePicker);
                                    props.onDateChange && props.onDateChange(dateValueStaticDatePicker);
                                }
                                setOpenDate(false);
                            }}
                        >
                            OK
                        </UiButton>
                    </DialogActions>
                </Dialog>
            </LocalizationProvider>
        </UiDatePickerContentEl>
    );
};

export default UiDatePickerContent;
