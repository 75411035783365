import React, { useCallback } from 'react';
import * as type from './HubBatteryIndicator.type';
import * as view from './HubBatteryIndicator.view';
import { Wrapper } from 'helpers/wrapper';
import { useTranslation } from 'react-i18next';

const HubBatteryIndicator: React.FC<type.HubBatteryIndicatorProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const getBatteryInfo = useCallback((): type.BatteryInfo => {
        if (props.battery === null || props.battery < 6500) {
            return {
                title: translate('t.empty_battery'),
                icon: 'battery-empty'
            };
        } else if (props.battery < 7000) {
            return {
                title: `${translate('t.battery_is_below')} 7 V`,
                icon: 'battery-quarter'
            };
        } else if (props.battery < 7500) {
            return {
                title: `${translate('t.battery_is_below')} 7.5 V`,
                icon: 'battery-half'
            };
        } else if (props.battery < 8000) {
            return {
                title: `${translate('t.battery_is_below')} 8 V`,
                icon: 'battery-three-quarters'
            };
        } else {
            return {
                title: translate('t.battery_charged'),
                icon: 'battery-full'
            };
        }
    }, [props.battery]) ;
    if (!props.powerSource || (props.powerSource && (props?.powerSource > 999 || props?.powerSource <= 0))) {
        return <></>;
    }

    return (
        <view.HubBatteryIndicatorContent
            data-testid='HubBatteryIndicator-testid'
            battery={props.battery}
            getBatteryInfo={getBatteryInfo}
        />
    );
};

export default Wrapper(HubBatteryIndicator);
