import React from 'react';
import { IconButtonContent, UsersSuscribedNotificationsContainer } from './UsersSuscribedNotifications.style';
import { PropertyPositionPopover, UsersSuscribedNotificationsProps } from './UsersSuscribedNotifications.type';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import UiLegend from '../../Ui/Components/UiLegend';
import { useTranslation } from 'react-i18next';
import ListSubscribedUsers from './ListSubscribedUsers/ListSubscribedUsers';
import { NotificationType } from '../../../models/Notification.type';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

export const UsersSuscribedNotificationsContent: React.FC<UsersSuscribedNotificationsProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const msgType: string = props.data.type === 'leak' ? 'pressure_leak' : props.data.type;
    const anchorOriginCustom: PropertyPositionPopover = {
        vertical: 'top',
        horizontal: 'center'
    };
    const transformOriginCustom: PropertyPositionPopover = {
        vertical: 'center',
        horizontal: 'center'
    };

    if (!props.data?.assignation) {
        return <></>;
    }

    return (
        <UsersSuscribedNotificationsContainer data-testid='UsersSuscribedNotificationsContent'>
            <UiLegend
                title={translate(`t.${props.data.type2}_${msgType}`) + ' - ' + translate('t.portal')}
                content={
                    <ListSubscribedUsers NotificationId={props.data.id} NotificationType={NotificationType.PORTAL} />
                }
                anchorOrigin={anchorOriginCustom}
                transformOrigin={transformOriginCustom}
                root={
                    <IconButtonContent>
                        <UiIconButton aria-label='delete' color='primary' testid={`PortalRow${props.rowId}`}>
                            <>
                                <UiIcon icon={['fas', 'laptop']} />
                                {props.data.assignation.portal}
                            </>
                        </UiIconButton>
                    </IconButtonContent>
                }
            />

            <UiLegend
                title={translate(`t.${props.data.type2}_${msgType}`) + ' - ' + translate('t.email')}
                content={
                    <ListSubscribedUsers NotificationId={props.data.id} NotificationType={NotificationType.EMAIL} />
                }
                anchorOrigin={anchorOriginCustom}
                transformOrigin={transformOriginCustom}
                root={
                    <IconButtonContent>
                        <UiIconButton
                            aria-label='delete'
                            color='primary'
                            size='small'
                            testid={`EmailRow${props.rowId}`}
                        >
                            <>
                                {' '}
                                <UiIcon icon={['fas', 'envelope']} /> {props.data.assignation.email}
                            </>
                        </UiIconButton>
                    </IconButtonContent>
                }
            />

            <UiLegend
                title={translate(`t.${props.data.type2}_${msgType}`) + ' - WhatsApp'}
                content={
                    <ListSubscribedUsers NotificationId={props.data.id} NotificationType={NotificationType.WHATSAPP} />
                }
                anchorOrigin={anchorOriginCustom}
                transformOrigin={transformOriginCustom}
                root={
                    <IconButtonContent>
                        <UiIconButton
                            aria-label='delete'
                            color='primary'
                            size='small'
                            testid={`WhatsappRow${props.rowId}`}
                        >
                            <>
                                <UiIcon icon={['fas', 'mobile']} /> {props.data.assignation?.whatsapp}
                            </>
                        </UiIconButton>
                    </IconButtonContent>
                }
            />

            <UiLegend
                title={translate(`t.${props.data.type2}_${msgType}`) + ' - SMS'}
                content={
                    <ListSubscribedUsers NotificationId={props.data.id} NotificationType={NotificationType.MOBILE} />
                }
                anchorOrigin={anchorOriginCustom}
                transformOrigin={transformOriginCustom}
                root={
                    <IconButtonContent>
                        <UiIconButton
                            aria-label='delete'
                            size='small'
                            color='primary'
                            testid={`MobileRow${props.rowId}`}
                        >
                            <>
                                <UiIcon icon={['fas', 'message-sms']} /> {props.data.assignation?.mobile}
                            </>
                        </UiIconButton>
                    </IconButtonContent>
                }
            />
        </UsersSuscribedNotificationsContainer>
    );
};
