import { FilterContent, UseTableFilterReturnType, UseTableFilterType } from './TableFilterState.type';
import { useEffect, useState } from 'react';

const useTableFilter: UseTableFilterType = ({
    filterContent,
    setFilter,
    applyFiltersFlag,
    filterId
}): UseTableFilterReturnType => {
    const [cellValue, setCellValue] = useState<FilterContent>(filterContent);

    const setFilterValue = (newValue) => {
        setCellValue(newValue);
    };

    useEffect(() => {
        if (applyFiltersFlag) {
            if (applyFiltersFlag?.value === 'reset') {
                setCellValue({ name: cellValue?.name as string, value: undefined, humanValue: undefined });
            } else {
                if (filterId && cellValue?.name) {
                    setFilter(filterId, cellValue);
                } else if (cellValue?.name) {
                    setFilter(cellValue);
                } else {
                    if (filterId) {
                        setFilter(filterId, undefined);
                    } else {
                        setFilter(undefined);
                    }
                }
            }
        }

        return () => setCellValue(undefined);
    }, [applyFiltersFlag]);

    return {
        cellValue,
        setFilterValue
    };
};

export default useTableFilter;
