import { atom } from 'recoil';
import { CRUD } from 'variables';
import { TeamShiftTimeActionsType } from './TeamShiftTimeActions.type';

export const TeamShiftTimeActionsState = atom<TeamShiftTimeActionsType>({
    key: 'TeamShiftTimeActionsState',
    default: {
        action: CRUD.EMPTY,
        id: '',
        values: undefined
    }
});
