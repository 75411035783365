import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { BarGroup } from '../TopBar.style';

export const ChooseLanguageButtonViewContent = styled.div``;

export const BarGroupLanguage = styled(BarGroup)`
    margin-right: 0px;

    ${() => {
        return `min-width: 133px ;`;
    }}

    ${() => useTheme().breakpoints.between('xs', 'sm')} {
        min-width: auto;

        & .MuiButton-root {
            min-width: 45px !important;
        }
    }
`;
