export type ITimeZone = {
    id: number;
    timezoneName: string;
};

export type ITimeZoneFormatted = ITimeZone & {
    timezoneFormatted: string;
    offset: number;
};

export type TimezoneModel = ITimeZone & {
    timezoneOffset: string;
};

export type TimeZoneList = {
    codeBook: ITimeZone[];
};

export enum TimeZoneQueryKeys {
    getCodebook = 'timeZone-AllTimeZone'
}
