import { useCallback } from 'react';
import { DateTime } from 'luxon';
import TeamShift from 'api/TeamShift';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { TeamShiftModel } from 'models/TeamShift.type';
import { TimeRange } from '../ShiftTimeCalendarAction/ShiftTimeCalendarAction.type';

const useFreeShiftChecker = () => {
    const teamShiftApi = new TeamShift();
    const queryClient = useQueryClient();

    const { refetch } = useQuery(['GetTeamShiftListByShiftDate'], () => teamShiftApi.getTeamShiftByShiftDate(''), {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: (dataOnSuccess: { teamShift: TeamShiftModel[] }) => {}
    });

    const getTeamShiftData = async (dateFilter: string): Promise<{ teamShift: TeamShiftModel[] }> => {
        return await queryClient.fetchQuery(['GetTeamShiftListByShiftDate', dateFilter], () =>
            teamShiftApi.getTeamShiftByShiftDate(dateFilter)
        );
    };

    const addOneSecond = useCallback((time: string): string => {
        const [hours, minutes, seconds] = time.split(':').map(Number);
        const date = new Date();
        date.setHours(hours, minutes, seconds + 1);
        return date.toTimeString().split(' ')[0];
    }, []);

    const subtractOneSecond = useCallback((time: string): string => {
        const [hours, minutes, seconds] = time.split(':').map(Number);
        const date = new Date();
        date.setHours(hours, minutes, seconds - 1);
        return date.toTimeString().split(' ')[0];
    }, []);

    const generateEmptyRanges = useCallback(
        (timeSlots: string[]): TimeRange[] => {
            const emptyRanges: TimeRange[] = [];
            let lastEndTime: string | null = '00:00:00';

            for (let i = 0; i < timeSlots.length; i++) {
                const slot = timeSlots[i];

                if (slot === '') {
                    let startTime = lastEndTime!;

                    let j = i;
                    while (j < timeSlots.length && timeSlots[j] === '') {
                        j++;
                    }

                    if (j < timeSlots.length) {
                        const [nextStart] = timeSlots[j].split(' - ');
                        const endTime = subtractOneSecond(nextStart);

                        emptyRanges.push({
                            startTime: addOneSecond(startTime),
                            endTime
                        });

                        i = j - 1;
                    } else {
                        emptyRanges.push({
                            startTime: addOneSecond(startTime),
                            endTime: '23:59:59'
                        });
                        break;
                    }
                } else {
                    const [, end] = slot.split(' - ');
                    lastEndTime = end;
                }
            }

            return emptyRanges;
        },
        [addOneSecond, subtractOneSecond]
    );

    const canFitInEmptyRange = useCallback((newRange: TimeRange, emptyRanges: TimeRange[]): boolean => {
        return emptyRanges.some(
            (emptyRange) => newRange.startTime >= emptyRange.startTime && newRange.endTime <= emptyRange.endTime
        );
    }, []);

    const getFreeHours = useCallback(
        (teamShiftList: TeamShiftModel[], timeRange: TimeRange): boolean => {
            const freeHours: string[] = Array(24).fill('');

            teamShiftList.forEach((teamShift) => {
                const { shiftTime } = teamShift;
                const startTimeUTC = DateTime.fromISO(shiftTime.startTime).setZone('utc');
                const endTimeUTC = DateTime.fromISO(shiftTime.endTime).setZone('utc');

                for (let i = Number(startTimeUTC.toFormat('HH')); i <= Number(endTimeUTC.toFormat('HH')); i++) {
                    freeHours[i] = `${startTimeUTC.toFormat('HH:mm:ss')} - ${endTimeUTC.toFormat('HH:mm:ss')}`;
                }
            });

            const emptyRanges = generateEmptyRanges(freeHours);
            return canFitInEmptyRange(timeRange, emptyRanges);
        },
        [generateEmptyRanges, canFitInEmptyRange]
    );

    const isRangeFreeTime = useCallback(
        async (dateFilter: string, shiftId: number, timeRange: TimeRange): Promise<boolean> => {
            const { teamShift } = await getTeamShiftData(dateFilter);

            if (teamShift && teamShift?.length) {
                if (shiftId) {
                    return getFreeHours(
                        teamShift.filter((shift) => shift.id !== shiftId),
                        timeRange
                    );
                }
                return getFreeHours(teamShift, timeRange);
            } else {
                return true;
            }
        },
        [getFreeHours]
    );

    return {
        isRangeFreeTime
    };
};

export default useFreeShiftChecker;
