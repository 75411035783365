import React from 'react';
import { ActionModalContentContainer } from './ActionModalContent.style';
import { ActionModalContentProps } from './ActionModalContent.type';
import MuiDialogContent from '@mui/material/DialogContent';
import { CancelButton, ContainerActions, CustomDialogActions } from 'components/StatedModal/Modal/Modal.style';
import AlertNotification from 'api/AlertNotification';
import { useMutation } from '@tanstack/react-query';
import { Success } from 'components/Popup/Popup';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';

const alertNotificationApi = new AlertNotification();

const ActionModalContent: React.FC<ActionModalContentProps> = ({ content, typeAction, id }): JSX.Element => {
    const { t: translate } = useTranslation();
    const queryTableFn = useRecoilValue(QueryFn(`alertNotificationManagment-QueryFn`));

    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispatch = ModalDispatcher(modalAtom, setStateModalAtom);

    const { mutate: deleteSnoozeSchedulerMutate, isLoading: deleteSnoozeSchedulerLoading } = useMutation(
        alertNotificationApi.deleteSnoozeScheduler,
        {
            onSuccess: (data) => {
                if (data) {
                    Success({
                        text: translate('t.deleted_alert_notification_management')
                    });
                    queryTableFn.refetch && queryTableFn.refetch instanceof Function && queryTableFn.refetch();
                    modalDispatch({ type: ModalActionTypesEnum.CLOSE_MODAL });
                }
            }
        }
    );

    const { mutate: cancelSnoozeSchedulerMutate, isLoading: cancelSnoozeSchedulerLoading } = useMutation(
        alertNotificationApi.cancelSnoozeScheduler,
        {
            onSuccess: (data) => {
                if (data.alertNotificationCallsScheduler) {
                    Success({
                        text: `Snooze ${translate('t.canceled')}`
                    });
                    queryTableFn.refetch && queryTableFn.refetch instanceof Function && queryTableFn.refetch();
                    modalDispatch({ type: ModalActionTypesEnum.CLOSE_MODAL });
                }
            }
        }
    );

    const getButtonAction = (): JSX.Element => {
        switch (typeAction) {
            case 'cancel':
                return (
                    <UiButton
                        variant='contained'
                        skin='success'
                        color='primary'
                        testid='AlertNotificationManagment-Modal-Cancel-Button'
                        onClick={() => cancelSnoozeSchedulerMutate(id)}
                        loading={cancelSnoozeSchedulerLoading}
                    >
                        {translate('t.yes')}
                    </UiButton>
                );
                break;
            case 'delete':
                return (
                    <UiButton
                        variant='contained'
                        skin='error'
                        color='error'
                        testid='AlertNotificationManagment-Modal-Delete-Button'
                        onClick={() => deleteSnoozeSchedulerMutate(id)}
                        loading={deleteSnoozeSchedulerLoading}
                    >
                        {translate('t.delete')}
                    </UiButton>
                );
                break;
        }
    };

    return (
        <>
            <MuiDialogContent>
                <ActionModalContentContainer data-testid='ActionModalContentContent'>
                    {content}
                </ActionModalContentContainer>
            </MuiDialogContent>
            <ContainerActions>
                <CustomDialogActions>
                    {getButtonAction()}
                    <CancelButton
                        variant='outlined'
                        color='primary'
                        size='small'
                        data-testid='Modal-Cancel-Button'
                        onClick={() => {
                            modalDispatch({
                                type: ModalActionTypesEnum.CLOSE_MODAL
                            });
                        }}
                    >
                        {translate('t.cancel')}
                    </CancelButton>
                </CustomDialogActions>
            </ContainerActions>
        </>
    );
};

export default ActionModalContent;
