import React from 'react';
import * as type from './VehicleTable.type';
import Widget from 'components/Ui/Components/UiWidget';
import Legend from 'components/Ui/Components/UiLegend';
import { VehicleTableContentView } from './VehicleTable.style';
import Button from 'components/Ui/Components/UiButton';
import { VehicleActionAtom } from 'states/component/Vehicle';
import { ShowVehicleModal } from 'states/global/Modal';
import VehicleActions from 'components/Vehicle/VehicleActions';
import { CRUD } from 'variables';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import UiTable from 'components/Ui/UiTable/UiTable';
import VehicleAPI from 'api/Vehicle';
import { UserInfo } from 'states/global/User';
import { checkRole } from 'helpers/authentication';
import { useTranslation } from 'react-i18next';
import { ROLES } from 'variables';
import AddIcon from '@mui/icons-material/Add';
import { applyStyleByMode } from 'helpers';
import { SIZE_BUTTON } from 'components/Ui/variables';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { SECONDARY_DARK_MODE, WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import ReloadHubs from 'components/Hub/ReloadHubs/ReloadHubs';

export const VehicleTableContent: React.FC<type.VehicleTableViewProps> = (props): JSX.Element => {
    const setVehicleModal = useSetRecoilState(ShowVehicleModal);
    const setVehicleActionAtom = useSetRecoilState(VehicleActionAtom);

    const vehicleAPI = new VehicleAPI();
    const userInfo = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    const hidden: string[] = [
        'deviceLastTransmissionFrom',
        'deviceLastTransmissionTo',
        'createdAtFrom',
        'createdAtTo',
        'measuredAtFrom',
        'measuredAtTo',
        'displayLastTransmissionFrom',
        'displayLastTransmissionTo'
    ];

    if (checkRole(userInfo.user, [ROLES.ROLE_TECHNICAL_USER])) {
        hidden.push('id');
        props.columns.pop();
    }

    const LegendContent: React.FC = (): JSX.Element => (
        <table>
            <tbody>
                <tr>
                    <td>
                        <UiIcon icon={['fas', 'square']} size='lg' fixedWidth color='#0945ad' />
                    </td>
                    <td>{translate('t.last_transmission_first')}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon icon={['fas', 'square']} size='lg' fixedWidth color='#ff6f00' />
                    </td>
                    <td>{translate('t.last_transmission_second')}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon icon={['fas', 'square']} size='lg' fixedWidth color='#ff0000' />
                    </td>
                    <td>{translate('t.last_transmission_third')}</td>
                </tr>
            </tbody>
        </table>
    );

    return (
        <VehicleTableContentView data-testid='VehicleTableContent'>
            <Widget
                testid='vehicle-widget'
                title={translate('t.vehicles')}
                avatar={
                    <UiIcon
                        icon={['fas', 'truck-container']}
                        size='sm'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: SECONDARY_DARK_MODE,
                            dark: WHITE
                        })}
                    />
                }
                headerAction={<Legend title={translate('t.legend')} content={<LegendContent />} />}
                content={
                    <UiTable
                        fetchFn={vehicleAPI.getVehicleTable}
                        exportFn={vehicleAPI.getExport}
                        exportName={'Vehicles'}
                        columns={props.columns}
                        hiddenColumns={hidden}
                        rightActionBtns={() => (
                            <>
                                <ReloadHubs />

                                {userInfo.user?.role.name !== ROLES.ROLE_TECHNICAL_USER ? (
                                    <Button
                                        testid='vehicle-add-button'
                                        variant='contained'
                                        skin='success'
                                        style={{ color: '#fff' }}
                                        size={SIZE_BUTTON}
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                            setVehicleModal(true);
                                            setVehicleActionAtom({ action: CRUD.ADD });
                                        }}
                                    >
                                        {translate('t.add_vehicle')}
                                    </Button>
                                ) : (
                                    ''
                                )}
                            </>
                        )}
                        defaultSortBy={{
                            id: 'name',
                            desc: false
                        }}
                        defaultFilter={
                            !userInfo.user?.userSetting.showOutOfService
                                ? {
                                      id: 'outOfService',
                                      value: {
                                          value: '0',
                                          humanValue: translate('t.vehicle_settings_service_on_success_title'),
                                          name: translate('t.status')
                                      }
                                  }
                                : undefined
                        }
                        queryKey='Vehicles'
                    />
                }
            />
            <VehicleActions queryKey='Vehicles' />
        </VehicleTableContentView>
    );
};
