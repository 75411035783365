import React, { useState } from 'react';
import {
    AlertChangesInfoContainer,
    MainAffectedVehiclesBox,
    ModalContent,
    ModalWarning,
    SaveAlertsSettingsButtonContainer
} from './SaveAlertsSettingsButton.style';
import { SaveAlertsSettingsButtonProps } from './SaveAlertsSettingsButton.type';
import useModal from 'components/CustomHooks/ModalHook/ModalHook';
import { AffectedVehiclesCount } from '../AlertsAffectedVehicles/atom';
import { useRecoilValue } from 'recoil';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { useTranslation } from 'react-i18next';
import { Box, Collapse, Typography } from '@mui/material';
import AlertSettingsWizardButtons from '../AlertSettingsWizardButtons/AlertSettingsWizardButtons';
import { RULE_TYPE } from 'models/Rule.type';
import { AppliedFilters } from '../AlertsFilterVehiclesBy/atom';
import AlertSettingsChangeOverviewTable from '../AlertSettingsChangeOverviewTable';
import AlertsAffectedVehiclesList from '../AlertsAffectedVehiclesList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

const AffectedVehiclesPreview: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [collapsed, setCollapsed] = useState<boolean>(false);
    return (
        <>
            <MainAffectedVehiclesBox>
                <Typography gutterBottom variant='body1'>
                    {translate('t.show_affected_vehicles')}
                </Typography>
                <UiIconButton onClick={() => setCollapsed((current) => !current)}>
                    <ExpandMoreIcon />
                </UiIconButton>
            </MainAffectedVehiclesBox>
            <Box width={'100%'}>
                <Collapse in={collapsed} timeout='auto' unmountOnExit>
                    <AlertsAffectedVehiclesList height={330} />
                </Collapse>
            </Box>
        </>
    );
};

const SaveAlertsSettingsButtonContent: React.FC<SaveAlertsSettingsButtonProps> = (props): JSX.Element => {
    const { updateModalState } = useModal();
    const { t: translate } = useTranslation();
    const affectedVehiclesCount = useRecoilValue(AffectedVehiclesCount);
    const filters = useRecoilValue(AppliedFilters);

    return (
        <SaveAlertsSettingsButtonContainer data-testid='SaveAlertsSettingsButtonContent'>
            <UiButton
                disabled={props.ruleType === RULE_TYPE.WIZARD && Object.keys(filters).length === 0}
                variant='contained'
                onClick={() => {
                    updateModalState({
                        id: 'set-alerts',
                        isOpen: true,
                        content: (
                            <ModalContent>
                                {props.ruleType === RULE_TYPE.DEFAULT && (
                                    <ModalWarning severity='info'>
                                        {translate('p.settings_set_as_default_template_all_vehicles')}
                                    </ModalWarning>
                                )}
                                <Typography gutterBottom variant='body1'>
                                    {translate('t.alert_settings')}
                                </Typography>
                                <AlertSettingsChangeOverviewTable />
                                <AffectedVehiclesPreview />
                                <AlertChangesInfoContainer>
                                    <Typography gutterBottom>
                                        {translate('p.warning_save_alerts_settings', {
                                            numberOfVehicles: affectedVehiclesCount.current
                                        })}
                                    </Typography>
                                </AlertChangesInfoContainer>
                            </ModalContent>
                        ),
                        leftTitle: translate('t.save_alert_settings'),
                        rightTitle: '',
                        height: 690,
                        width: 800,
                        buttons: <AlertSettingsWizardButtons ruleType={props.ruleType} />
                    });
                }}
            >
                {translate('t.save')}
            </UiButton>
        </SaveAlertsSettingsButtonContainer>
    );
};

export default SaveAlertsSettingsButtonContent;
