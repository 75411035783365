import React from 'react';
import { UsersAdministrationStyle } from './UsersAdministration.style';
import Widget from 'components/Ui/Components/UiWidget';
import { UsersAdministrationViewProps } from './UsersAdministration.type';
import User from 'api/User';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import UiTable from 'components/Ui/UiTable/UiTable';
import MinesPermissions from '../MinesPermissions/MinesPermissions';
import Button from 'components/Ui/Components/UiButton/UiButton';
import { ROLES } from 'variables';
import { UserInfo } from 'states/global/User';
import { checkRole } from 'helpers/authentication';
import { applyStyleByMode } from 'helpers';
import { Theme } from 'states/global/Theme';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { WHITE } from 'components/Ui/colors';
import { SIZE_BUTTON } from 'components/Ui/variables';
import AddIcon from '@mui/icons-material/Add';

const userApi = new User();

export const UsersAdministrationContent: React.FC<UsersAdministrationViewProps> = ({
    columns,
    handleModalAdd,
    setShowPermissionModal,
    showPermissionModal
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const ThemeMode = useRecoilValue(Theme);

    return (
        <UsersAdministrationStyle data-testid='UsersAdministrationContent'>
            <Widget
                title={translate('t.users')}
                data-testid={`UsersAdministrationContent-Widget`}
                avatar={
                    <UiIcon
                        icon={['fas', 'user']}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            dark: WHITE
                        })}
                    />
                }
                loading={false}
                content={
                    <>
                        <UiTable
                            data-testid={`UsersAdministrationContent-UiTable`}
                            columns={columns}
                            fetchFn={userApi.getUserTable}
                            exportFn={userApi.getExport}
                            exportName={'Users Administration'}
                            hiddenColumns={checkRole(userInfo.user, [ROLES.ROLE_TECHNICAL_ADMIN]) ? ['id'] : []}
                            defaultSortBy={{
                                id: 'username',
                                desc: false
                            }}
                            defaultFilter={{
                                id: 'enabled',
                                value: {
                                    name: translate('t.enabled'),
                                    value: `all;${translate('t.all')}`
                                }
                            }}
                            rightActionBtns={() =>
                                !checkRole(userInfo.user, [ROLES.ROLE_TECHNICAL_ADMIN]) ? (
                                    <Button
                                        data-testid={`UsersAdministrationContent-actionsButton-Button`}
                                        variant='contained'
                                        skin='success'
                                        style={{ color: '#fff' }}
                                        size={SIZE_BUTTON}
                                        startIcon={<AddIcon />}
                                        testid='Add-User-Button'
                                        onClick={() => {
                                            handleModalAdd();
                                        }}
                                    >
                                        {translate('t.add_user')}
                                    </Button>
                                ) : (
                                    <></>
                                )
                            }
                            queryKey={'usersAdministrations'}
                            additionalProps={{
                                customer: userInfo?.user?.customer.id
                            }}
                        />
                    </>
                }
            />
            {showPermissionModal && (
                <MinesPermissions
                    queryKey='usersAdministrations'
                    data-testid={`UsersAdministrationContent-MinesPermissions`}
                    setShowPermissionModal={setShowPermissionModal}
                />
            )}
        </UsersAdministrationStyle>
    );
};
