import Wheel, { SensorAlert } from './Wheel.type';
import { Paginator as GenericPaginator } from 'helpers/api/type';
import { UserModel } from './User.type';
import { SensorLogModel } from './SensorLog.type';
import { SensorInfo } from './SensorInfo.type';
import { SensorMake } from './SensorMake.type';
import { SensorMovements } from './SensorMovement.type';
import { SensorType } from './SensorType.type';
import { SensorStats } from './SensorStats.type';
import { InitialValues } from '../components/Sensor/ReplaceSensor/ReplaceSensor.type';
import { Paginator } from './Paginator.type';
import { UnmountReason, FittingReason } from '../variables';

export type SensorsModel = {}; // after removing dependencies can be removed

export type Sensor = {
    analysis: string | null;
    comment: string | null;
    createdAt: string;
    id: number;
    lifetime: lifetime | null;
    pressureStatus: boolean;
    priority: number;
    removedAt: string | null;
    removedBy: Pick<UserModel, 'id' | 'firstname' | 'lastname' | 'removed'> | null;
    sensorInfo: SensorInfo | null;
    sensorMake: SensorMake | null;
    sensorMovements: SensorMovements[];
    lastMovement: SensorMovements | null;
    sensorSerialNumber: string;
    sensorStats: SensorStats[];
    sensorType: SensorType | null;
    serialNumberHex: string;
    temperatureStatus: boolean;
    wheel: Wheel | null;
    name?: string;
    status?: 'removed' | 'fault' | 'suspicious' | 'active' | 'inactive' | 'test';
    measuredAt?: string;
    createdAtFrom?: string;
    createdAtTo?: string;
    measuredAtFrom?: string;
    measuredAtTo?: string;
    removedAtTo?: string;
    sensorBatteryVoltageFrom?: string;
    sensorBatteryVoltageTo?: string;
    removedAtFrom?: string;
    battery_low?: string;
    inactive?: '0' | '1';
    isWheel?: '0' | '1';
    sensorStatsBatteryTo?: '0' | '1'; // Check it
    sensorInfoStatus?: string;
};

type lifetime = {
    days: number;
    diffInSeconds: number;
    hours: number;
    minutes: number;
    months: number;
    seconds: number;
    years: number;
};

// TEMPORALY
// discussion needed
/*type SensorTable2 = Omit<Sensor, 'priority' | 'wheel' |'sensorMake' | 'sensorStats'> & {
    wheel: Pick<Wheel, 'customPosition' | 'position'> & {
        vehicle: Pick<VehicleModel, 'name'> | null;
    } | null;
} & {
    sensorMake: Omit<SensorMake, 'id'> | null
} & {
    sensorStats: Omit<SensorStats, 'id'>[]
}*/

export type SensorTable = Omit<Sensor, 'priority' | 'wheel' | 'sensorMake' | 'sensorStats'> & {
    wheel: Pick<NonNullable<Sensor['wheel']>, 'customPosition' | 'position'> & {
        vehicle: Pick<NonNullable<Pick<NonNullable<Sensor['wheel']>, 'vehicle'>['vehicle']>, 'id' | 'name'> | null;
    };
} & {
    sensorMake: Omit<NonNullable<Sensor['sensorMake']>, 'id'> | null;
} & {
    sensorStats: Omit<Sensor['sensorStats'][0], 'id'>[];
};

export type SensorTableResponse = GenericPaginator<SensorTable>;

export type AttachedSensor = {
    alertHotPressure: number | null;
    alertPressure: number | null;
    alertTemperature: number | null;
    sensor: Sensor;
    sensorAlert: number | null;
    sensorAlerts: SensorAlert[];
    sensorLog: SensorLogModel | null; // TODO: check what is sensor log mode, maybe make pick
    targetPressure: TargetPressure;
    isTyreLifespan: boolean; //todo: this needs to be fixed with src/components/Tyre/LifespanReportHistory/Components/VehicleSchemaContainer line: 30
};

type TargetPressure = {
    cold: number;
    hot: number;
};

export type SystemTotalModel = {
    external: Transmitting;
    internal: Transmitting;
    vehicles: Transmitting;
};

export type GetSystemTotalModel = {
    systemTotals: SystemTotalModel;
};

export type SystemTotalDetailParams = {
    type?: string;
    status?: string;
    transmitting?: number;
};

export type SystemTotalDetailGet = {
    systemTotalsDetail: Sensor[];
};

export type SensorsWithPaginator = GenericPaginator<Sensor>;

export type Transmitting = {
    notTransmitting: Transmission;
    transmitting: Transmission;
};

export type Transmission = {
    maintenance: number;
    operating: number;
    outOfService: number;
};

export type TransmissionsStatisticsReponse = {
    sensorTransmissions: TransmissionsStatistics[];
};

type StatisticsValues = {
    measuredAt: string;
    timeKey?: number | undefined;
};

export type TransmissionsStatistics = {
    transmissions: number;
} & StatisticsValues;

export type RssiStatisticsReponse = {
    sensorRssi: RssiStatistics[];
};

export type RssiStatistics = {
    avgRssi: number;
} & StatisticsValues;

export type BatteryStatisticsReponse = {
    sensorBatteryVoltage: BatteryStatistics[];
};

export type BatteryStatistics = {
    avgBattery: number;
} & StatisticsValues;

export type replaceSensorOnWheelProps = InitialValues & {
    oldSensorId: number;
};

export type SwitchPrioritiesReturnType = {
    items: SwitchPrioritiesItemType[];
};

export type SwitchPrioritiesItemType = {
    id: number;
    sensorSerialNumber: string;
    serialNumberHex: string;
    priority: number;
};

export type UnmountSensorProps = {
    sensorId: number;
    unmountReason: UnmountReason;
};

export type MountSensorProps = {
    fittingReason: FittingReason;
    sensorId: number;
    sensorTypeId: number;
    wheelId: number;
};

export type ReplaceSensorProps = {
    newSensorId: number;
    newSensorTypeId: number;
    oldSensorId: number;
    unmountReason: UnmountReason;
};

export type SensorAlertTable = {
    items: SensorAlertResponse[]; //SensorAlert
    paginator: Paginator;
};

export type SensorAlertResponse = {
    id: number;
    sensorSerialNumber: string;
    serialNumberHex: string;
    pressureStatus: number;
    temperatureStatus: number;
    createdAt: string;
    comment: string | null;
    analysis: string | null;
    wheel: {
        position: number;
        customPosition: number;
        vehicle: {
            name: string;
        };
    } | null;
    sensorMake: {
        description: string;
        fullVoltage: number;
        criticalVoltage: number;
    } | null;
    sensorType: {
        id: number;
        name: string;
    };
    sensorInfo: {
        id: number;
        measuredAt: string;
        sensorStatus: {
            id: number;
            name: string;
        };
    };
    sensorStats: SensorStats[];
};

export type InspectionParams = {
    granularity: number;
    measuredFrom?: string;
    measuredTo?: string;
    sensorId: number;
};

export type vehicleInspection = {
    id: number;
    name: string;
    icon: string;
};

export type Inspection = {
    measuredAt: string;
    timeKey: number;
    latitude: number;
    longitude: number;
    vehicles: vehicleInspection[];
};

export type InspectionResponse = {
    measurements: Inspection[];
};

export type getAttachedSensorVehicleResponse = {
    id: number;
    mountPolicy: null | string;
    priority: number;
    sensorHealth: null | string;
    sensorSerialNumber: string;
    serialNumberHex: string;
}[];

export enum SensorQueryKeys {
    getLazyDropdown = 'sensor-dropdown',
    getTransmissionCSV = 'sensor-getTransmissionCSV',
    getSystemTotalsNew = 'sensor-getSystemTotalsNew'
}
