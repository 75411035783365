import React, { useState, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
    TitleContainer,
    PercentageTyreGraphContent,
    Graph,
    GraphTitle,
    SubTitle,
    SubTitleContainer,
    DataText
} from './PercentageTyreGraph.style';
import { PercentageTyreGraphProps, VehicleInstalations } from './PercentageTyreGraph.type';
import { useTranslation } from 'react-i18next';
import { BACKGROUND_BLUE, ERROR } from 'components/Ui/colors';
import Tyre from 'api/Tyre';
import NotificationEvent from 'api/NotificationEvent';
import { TyreLifeDetailResponse, TyreLifeHistoryResponse, TyreQueryKeys } from 'models/Tyre.type';
import { cacheTimeToMilliseconds, getCache, isCacheAvailable } from 'helpers/cache';
import { NotificationEventQueryKeys } from 'models/NotificationEvent.type';
import { TyreNotificationsResponse } from 'models/Notification.type';
import { Wrapper } from 'helpers/wrapper';

const apiTyre = new Tyre();
const notificationEvent = new NotificationEvent();

export const PercentageTyreGraph: React.FC<PercentageTyreGraphProps> = ({ tyreId }): JSX.Element => {
    const { t: translate } = useTranslation();
    const queryCache = useQueryClient().getQueryCache();
    const [mountedVehicles, setMountedVehicles] = useState<VehicleInstalations>({
        counter: 0,
        vehicle: []
    });
    const [avgOperatingHoursPerDay, setAvgOperatingHoursPerDay] = useState<number>();
    const [alertHours, setAlertHours] = useState<number>();
    //cacheValue?.tyreLifeDetail?.tyre?.id
    const { refetch: refetchLifeHistoryQuery } = useQuery(
        [TyreQueryKeys.getTyreLifeHistory, tyreId],
        () => apiTyre.getTyreLifeHistory(tyreId),
        {
            enabled: false,
            retry: false,
            refetchOnWindowFocus: false,
            refetchInterval: false,
            staleTime: cacheTimeToMilliseconds(5, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(5, 'minutes'),
            onSuccess: (result) => setMountedVehicles(countMountedVehicles(result.tyreLifeHistory.tyreLifeHistory)),
            onError: () => {
                setMountedVehicles({
                    counter: 0,
                    vehicle: []
                });
                //invalidate query?
            }
        }
    );

    const { refetch: refetchMainDetailsQuery } = useQuery(
        [TyreQueryKeys.getTyreLifeDetail, tyreId],
        () => apiTyre.getTyreLifeDetail(tyreId),
        {
            enabled: false,
            retry: false,
            refetchOnWindowFocus: false,
            refetchInterval: false,
            staleTime: cacheTimeToMilliseconds(5, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(5, 'minutes'),
            onSuccess: (result) =>
                setAvgOperatingHoursPerDay(
                    (result.tyreLifeDetail.bornDisposeDiff?.diffInSeconds as number) / 3600 ?? 0
                ),
            onError: () => setAvgOperatingHoursPerDay(undefined)
        }
    );

    const { refetch: refetchTyreAlertsNotificationQuery } = useQuery(
        [NotificationEventQueryKeys.getTotalAlertsByTyre, tyreId],
        () => notificationEvent.getTotalAlertsByTyre(tyreId),
        {
            enabled: false,
            retry: false,
            refetchOnWindowFocus: false,
            refetchInterval: false,
            staleTime: cacheTimeToMilliseconds(5, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(5, 'minutes'),
            onSuccess: (successData) => setAlertHours(alertHoursCounter(successData?.tyreTotalAlerts)),
            onError: () => setAlertHours(undefined)
        }
    );

    useEffect(() => {
        if (!isNaN(tyreId)) {
            if (!isCacheAvailable([TyreQueryKeys.getTyreLifeHistory, tyreId], queryCache)) {
                refetchLifeHistoryQuery();
            } else {
                setMountedVehicles(
                    countMountedVehicles(
                        (
                            getCache([TyreQueryKeys.getTyreLifeHistory, tyreId], queryCache)[0]?.state
                                ?.data as TyreLifeHistoryResponse
                        ).tyreLifeHistory
                    )
                );
            }

            if (!isCacheAvailable([TyreQueryKeys.getTyreLifeDetail, tyreId], queryCache)) {
                refetchMainDetailsQuery();
            } else {
                setAvgOperatingHoursPerDay(
                    ((
                        getCache([TyreQueryKeys.getTyreLifeDetail, tyreId], queryCache)[0]?.state
                            ?.data as TyreLifeDetailResponse
                    ).tyreLifeDetail.bornDisposeDiff?.diffInSeconds as number) / 3600 ?? 0
                );
            }

            if (!isCacheAvailable([NotificationEventQueryKeys.getTotalAlertsByTyre, tyreId], queryCache)) {
                refetchTyreAlertsNotificationQuery();
            } else {
                setAlertHours(
                    alertHoursCounter(
                        (
                            getCache([NotificationEventQueryKeys.getTotalAlertsByTyre, tyreId], queryCache)[0]?.state
                                ?.data as TyreNotificationsResponse
                        ).tyreTotalAlerts
                    )
                );
            }
        }
    }, [tyreId]);

    const alertHoursCounter = (alertsData) => {
        let alertsCount = 0;

        if (alertsData)
            alertsData.map((val) => {
                alertsCount = alertsCount + val.hours;
            });

        return alertsCount;
    };

    const countPercentagesOperatingAlerts = (avgOperatingHoursPerDay, alertsCount) => {
        let total = 0;
        total = alertsCount + avgOperatingHoursPerDay;

        return [
            total > 0 ? ((avgOperatingHoursPerDay / total) * 100).toFixed(2) : 100,
            total > 1 ? ((alertsCount / total) * 100).toFixed(2) : 0
        ];
    };

    const countMountedVehicles = (tyreData): VehicleInstalations => {
        let counter = 0;
        let vehicleData: string[] = [];
        if (tyreData && tyreData.length > 0) {
            tyreData.forEach((val) => {
                if (val.type == 'movement') {
                    if (val.additionalData?.movement.action && val.additionalData?.movement.action == 'mounted') {
                        counter++;

                        counter < 5 &&
                            val?.additionalData?.movement?.wheel &&
                            vehicleData.push(
                                `${val?.additionalData?.movement?.wheel?.vehicle?.name} - Pos ${val?.additionalData?.movement?.wheel?.customPosition}`
                            );
                    }
                }
            });
        }

        return {
            counter: counter,
            vehicle: vehicleData
        };
    };

    const parsePercentageData = (percentage) => {
        let color = BACKGROUND_BLUE;
        percentage = percentage ? percentage : 0;

        return `linear-gradient(to right, ${color}
            ${percentage}%, ${ERROR} 0%, ${ERROR}
            ${100 - percentage - 1}%)`;
    };

    return (
        <PercentageTyreGraphContent data-testid='AlertsTableContent'>
            {avgOperatingHoursPerDay == undefined || alertHours == undefined ? (
                <>
                    <TitleContainer className='main'>
                        {translate('t.percentage_working_optimal_alerts_conditions')}:
                    </TitleContainer>
                    <TitleContainer className='sub'>{translate('t.there_no_data')}</TitleContainer>
                </>
            ) : (
                <>
                    <TitleContainer className='main'>
                        {translate('t.percentage_working_optimal_alerts_conditions')}:
                    </TitleContainer>
                    <Graph
                        data-testid={`PercentageTyreGraph-Graph`}
                        style={{
                            backgroundImage: parsePercentageData(
                                countPercentagesOperatingAlerts(avgOperatingHoursPerDay, alertHours)[0]
                            )
                        }}
                    >
                        <GraphTitle className='left-position'>
                            {`${countPercentagesOperatingAlerts(avgOperatingHoursPerDay, alertHours)[0]}% ${translate(
                                't.had_no_alerts'
                            )}`}
                        </GraphTitle>

                        <GraphTitle className='right-position'>
                            {`${countPercentagesOperatingAlerts(avgOperatingHoursPerDay, alertHours)[1]}% ${translate(
                                't.had_alerts'
                            )}`}
                        </GraphTitle>
                    </Graph>

                    <SubTitleContainer>
                        <SubTitle className='left-title'>
                            <>{translate('t.wheels_instalations')}:</>
                            <DataText variant='body2' data-testid='PercentageTyreGraph-DataText'>{`${
                                mountedVehicles.counter
                            } ${translate('t.times_installed')}`}</DataText>
                        </SubTitle>

                        <SubTitle className='right-title'>
                            <>{translate('t.in_vehicles_positions')}:</>

                            {mountedVehicles.vehicle.map((val, key) => (
                                <DataText
                                    variant='body2'
                                    key={key}
                                    data-testid={`PercentageTyreGraph-DataText-${key}-${val}`}
                                >
                                    {val}
                                </DataText>
                            ))}
                            {mountedVehicles.vehicle.length == 4 && (
                                <DataText variant='body2'>{translate('t.and_more')}...</DataText>
                            )}
                        </SubTitle>
                    </SubTitleContainer>
                </>
            )}
        </PercentageTyreGraphContent>
    );
};

export default Wrapper(PercentageTyreGraph);
