import React, { useEffect, useState } from 'react';
import { TopBarTerminalProps, CommandPropsToSubmit } from './TopBarTerminal.type';
import { TopBarHistoryContent } from './TopBarTerminal.view';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Success } from 'components/Popup/Popup';
import DeviceCommand from 'api/DeviceCommand';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { optionsCodeBook } from 'components/Ui/Components/UiDropDown/UiDropDown.type';
import { UserInfo } from 'states/global/User';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import DeviceApi from 'api/Device';
import { cacheTimeToMilliseconds, isCacheAvailable } from 'helpers/cache';
import { DeviceCommandQueryKeys } from 'models/DeviceCommand.type';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';

const deviceCommandApi = new DeviceCommand();
const Device = new DeviceApi();

const TopBarTerminal: React.FC<TopBarTerminalProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const queryTableFn = useRecoilValue(QueryFn(`deviceCommandsTableTerminal-QueryFn`));
    const [commandsCodebook, setCommandsCodebook] = useState<optionsCodeBook[]>();
    const [commandsAvailable, setCommandsAvailable] = useState<boolean>(true);
    const queryCache = useQueryClient().getQueryCache();

    const parseData = (data): optionsCodeBook[] => {
        return data.deviceCommand.reduce((acc: optionsCodeBook[], curr) => {
            acc.push({
                id: curr.name,
                name: `${translate(`device.cmd.${curr.name}`)} (CMD ${curr?.command})`
            });
            return acc;
        }, []);
    };

    const handleSubmit = (id: string, value?: string) => {
        let commandProps: CommandPropsToSubmit = {
            ...commandsData?.deviceCommand.find((element) => element.name === id),
            value: null
        };
        if (value) {
            commandProps.value = value;
        }

        if (props.sendCommandProps?.filters || props.sendCommandProps?.selectedRows) {
            if (props.sendCommandProps?.selectedRows) {
                commandProps = { ...commandProps, deviceIds: props.sendCommandProps?.selectedRows };
            }
            executeAdminCommandMutation({ ...commandProps, criteria: props.sendCommandProps?.filters });
        } else {
            executeCommandMutation({
                ...commandProps,
                deviceIds: [props.deviceId]
            });
        }
    };

    const {
        data: commandsData,
        isLoading: commandsLoading,
        isFetching: commandsFetching,
        refetch: refetchDeviceCommands
    } = useQuery(
        [DeviceCommandQueryKeys.getAvailableDeviceCommand],
        () => deviceCommandApi.getAvailableDeviceCommand(),
        {
            enabled: false,
            retry: false,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours'),
            onSuccess: (dataOnSuccess) => {
                setCommandsCodebook(parseData(dataOnSuccess));
            }
        }
    );

    useEffect(() => {
        isCacheAvailable([DeviceCommandQueryKeys.getAvailableDeviceCommand], queryCache) &&
            setCommandsCodebook(parseData(commandsData));
    }, []);

    const { mutate: executeAdminCommandMutation } = useMutation(
        ['ExecuteAdminCommandMutation'],
        Device.sendGlobalHubCommand,
        {
            onSuccess() {
                Success({
                    text: translate('p.command_sent'),
                    customSetting: {
                        position: 'bottom-right',
                        duration: 3000
                    }
                });
            }
        }
    );

    const { mutate: executeCommandMutation } = useMutation(
        ['ExecuteCommandMutation'],
        deviceCommandApi.postExcuteCommand,
        {
            onSuccess: () => {
                Success({
                    text: translate('p.command_sent'),
                    customSetting: {
                        position: 'bottom-right',
                        duration: 3000
                    }
                });
            },
            onSettled: () => {
                queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            }
        }
    );

    useEffect(() => {
        if (checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_TECHNICAL_ADMIN])) {
            refetchDeviceCommands();
        } else {
            setCommandsAvailable(true);
        }
    }, [userInfo.user]);

    useEffect(() => {
        if (commandsFetching || commandsLoading) setCommandsAvailable(true);
        else if (!commandsFetching && !commandsLoading) setCommandsAvailable(false);
    }, [commandsFetching, commandsLoading]);

    return (
        <TopBarHistoryContent
            {...props}
            commandsCodebook={commandsCodebook ?? []}
            commandsRaw={commandsData?.deviceCommand ?? []}
            data-testid='TopBarTerminal-testid'
            handleSubmit={handleSubmit}
            commandsAvailable={commandsAvailable}
        />
    );
};

export default TopBarTerminal;
