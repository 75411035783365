import { Paginator } from 'helpers/api/type';

export type DeviceDiagnoseModel = {
    accelAddr: string;
    accelChipId: string;
    accelPort: string;
    accelVibrations: number;
    accelX: number;
    accelY: number;
    accelZ: number;
    baroPressure: number;
    baroTemperature: number;
    createdAt: string;
    deviceDiagnoseSensor: DiagnoseSensor[];
    gpsAlt: number;
    gpsCourse: number;
    gpsLat: number;
    gpsLon: number;
    gpsSatellites: number;
    gpsSpeed: number;
    gpsState: string;
    gpsTime: string;
    gsm: string;
    id: number;
    region: number | null;
    requestId: number;
    sensorAvgRssi: number;
    sensorFirmware: string;
    voltageBattery: number;
    voltageTemperature: number;
    voltageVehicle: number;
};

export type OneDeviceDiagnose = {
    deviceDiagnose: DeviceDiagnoseModel;
};

export type DiagnoseSensor = {
    alertCount: number | null;
    id: number;
    pos: number;
    psi: number;
    rssi: number;
    rssiSecondaryAntenna: number;
    serialNumberHex: string;
    temperature: number;
};

export type DeviceDiagnoseList = Paginator<{
    id: number;
    createdAt: string;
}>;

export enum DeviceDiagnoseQueryKeys {
    getById = 'deviceDiagnose-getById',
    getList = 'deviceDiagnose-getList',
    getOne = 'deviceDiagnose-getOne'
}
