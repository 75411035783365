import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import {
    DeviceCommandsTab,
    DeviceCommandsTableViewContent,
    DeviceCommandsTabs,
    DeviceCommandsTabsContainer,
    TabContentHistory,
    TabContentTerminal,
    TextRegion
} from './DeviceCommandsTable.style';
import { DeviceCommandsTableViewProps } from './DeviceCommandsTable.type';
import { useTranslation } from 'react-i18next';
import Widget from 'components/Ui/Components/UiWidget/UiWidget';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { ActiveDeviceCommandsTab } from './DeviceCommandsTable.state';
import TabContent from 'components/Ui/Components/UiTab/UiTab';
import UiTable from 'components/Ui/UiTable/UiTable';
import TopBarTerminal from './Components/TopBarTerminal/TopBarTerminal';
import DeviceCommand from 'api/DeviceCommand';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import { UserInfo } from 'states/global/User';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import { DeviceAppRegionAtom } from './atom';
import Menu from 'components/Ui/Components/UiMenu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

const deviceCommandApi = new DeviceCommand();

export const DeviceCommandsTableContent: React.FC<DeviceCommandsTableViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const activeTabAtom = useRecoilValue(ActiveDeviceCommandsTab);
    const activeTab = activeTabAtom.details;
    const historycolumns = [...props.columns];
    historycolumns.pop();
    const userInfo = useRecoilValue(UserInfo);
    const isAdmin = useMemo(() => {
        return checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN]);
    }, [userInfo.user]);
    const [region, setRegion] = useState<string>('eu');
    const hidden: string[] = ['commandNameFilter'];
    const ThemeMode = useRecoilValue(Theme);
    const hubRegion = useRecoilValue(DeviceAppRegionAtom);

    useEffect(() => {
        const apiURL = process.env.REACT_APP_API_URL || 'https://webtrack.atmstechnology.com/api/';
        switch (apiURL) {
            case 'https://freeport.atmstechnology.com/api':
                setRegion('fcx');
                break;
            case 'https://au.itrack.bridgestone/api':
                setRegion('au');
                break;
            case 'https://sa.itrack.bridgestone/api':
                setRegion('sa');
                break;
            case 'https://na.itrack.bridgestone/api':
                setRegion('na');
                break;
            default:
                setRegion('eu');
                break;
        }
    }, []);

    const DeviceCommandsTable = useCallback(() => {
        return (
            <UiTable
                maxHeight={450}
                onlyIconsForDefActionBtns
                columns={props.columns}
                fetchFn={deviceCommandApi.getRecentPlusHistoryDeviceCommand}
                additionalProps={{ id: props.deviceId, region: region }}
                refresh={false}
                hiddenColumns={hidden}
                queryKey={'deviceCommandsTableTerminal'}
                hideFilterAction
            />
        );
    }, [props.deviceId, isAdmin, region]);

    useEffect(() => {
        if (hubRegion.includes('eu')) setRegion('eu');
        if (hubRegion.includes('fcx')) setRegion('fcx');
        if (hubRegion.includes('sa')) setRegion('sa');
        if (hubRegion.includes('na')) setRegion('na');
    }, [hubRegion]);

    return (
        <DeviceCommandsTableViewContent data-testid='DeviceCommandsTableContent'>
            <Widget
                data-testid='AdministrationTableContent'
                avatar={
                    <UiIcon
                        icon={faChevronRight}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#333',
                            theme: ThemeMode?.mode,
                            dark: WHITE
                        })}
                    />
                }
                headerAction={
                    isAdmin ? (
                        <Menu
                            rootEl={
                                <Tooltip title={translate('p.select_region')} placement='left' arrow disableInteractive>
                                    <Button
                                        color='secondary'
                                        variant='text'
                                        data-testid='AlertsBreakdown-period-button'
                                    >
                                        <TextRegion>{region}</TextRegion>
                                        <ExpandMoreIcon />
                                    </Button>
                                </Tooltip>
                            }
                            items={[
                                {
                                    title: 'EU',
                                    value: 'eu',
                                    action: () => setRegion('eu')
                                },
                                {
                                    title: 'FCX',
                                    value: 'fcx',
                                    action: () => setRegion('fcx')
                                },
                                {
                                    title: 'AU',
                                    value: 'au',
                                    action: () => setRegion('au')
                                },
                                {
                                    title: 'SA',
                                    value: 'sa',
                                    action: () => setRegion('sa')
                                },
                                {
                                    title: 'NA',
                                    value: 'na',
                                    action: () => setRegion('na')
                                }
                            ]}
                        />
                    ) : undefined
                }
                title={translate('t.hub_commands')}
                loading={false}
                content={
                    <>
                        <DeviceCommandsTabsContainer>
                            <DeviceCommandsTabs
                                value={activeTab}
                                onChange={props.handleTabChange}
                                indicatorColor='primary'
                                textColor='primary'
                            >
                                <DeviceCommandsTab label={`${translate('t.terminal')}`} />
                                <DeviceCommandsTab label={`${translate('t.history')}`} />
                            </DeviceCommandsTabs>
                        </DeviceCommandsTabsContainer>

                        <TabContent value={activeTab} index={0} testId='DeviceCommandsTerminal'>
                            <TopBarTerminal deviceId={props.deviceId} />
                            <TabContentTerminal>
                                <DeviceCommandsTable />
                            </TabContentTerminal>
                        </TabContent>
                        <TabContent value={activeTab} index={1} testId='DeviceCommandsHistory'>
                            <TabContentHistory>
                                <DeviceCommandsTable />
                            </TabContentHistory>
                        </TabContent>
                    </>
                }
            ></Widget>
        </DeviceCommandsTableViewContent>
    );
};
