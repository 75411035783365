import React, { useEffect, useMemo, useState } from 'react';
import { RemoveSensorContent } from './RemoveSensor.view';
import { InitialValues, RemoveSensorProps } from './RemoveSensor.type';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Vehicle from 'api/Vehicle';
import { VehicleList, VehicleQueryKeys } from 'models/Vehicle.type';
import { optionsCodeBook } from '../../Ui/Components/UiDropDown/UiDropDown.type';
import SensorMovement from 'api/SensorMovement';
import { Status } from 'models/SensorInfo.type';
import { useRecoilState } from 'recoil';
import { ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { cacheTimeToMilliseconds, getCache, isCacheAvailable } from 'helpers/cache';
import { SensorMovementQueryKeys } from 'models/SensorMovement.type';
import { Wrapper } from 'helpers/wrapper';

const vehicleApi = new Vehicle();
const sensorMovementApi = new SensorMovement();

const RemoveSensor: React.FC<RemoveSensorProps> = (props): JSX.Element => {
    const [unmountReasonParsed, setUnmountReasonParsed] = useState<optionsCodeBook[]>();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const queryCache = useQueryClient().getQueryCache();
    const modalDispatch = ModalDispatcher(modalAtom, setStateModalAtom);
    const isFaulty = useMemo(() => {
        return props.sensor.sensorInfo?.status == Status.FAULT || props.sensor.sensorInfo?.status == Status.SUSPICIOUS;
    }, [props.sensor.sensorInfo?.status]);
    const initialValues: InitialValues = {
        unmountReason: undefined
    };

    const parseData = (data): optionsCodeBook[] => {
        return data.codeBook.reduce((acc: optionsCodeBook[], curr) => {
            acc.push({ id: curr, name: curr });
            return acc;
        }, []);
    };

    const { isLoading: unmountReasonLoading } = useQuery(
        [SensorMovementQueryKeys.getSensorsUnmountReason],
        sensorMovementApi.getSensorsUnmountReason,
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours'),
            onSuccess: (dataOnSuccess) => setUnmountReasonParsed(parseData(dataOnSuccess))
        }
    );

    useEffect(() => {
        isCacheAvailable([SensorMovementQueryKeys.getSensorsUnmountReason], queryCache) &&
            setUnmountReasonParsed(
                parseData(getCache([SensorMovementQueryKeys.getSensorsUnmountReason], queryCache)[0]?.state?.data)
            );
    }, []);

    const handleSubmit = (values: InitialValues) => {
        props.removeSensorMutate({ sensorId: props.sensor.id, unmountReason: values.unmountReason });
    };

    const { data: vehicleDataXHR, isLoading: vehicleLoading } = useQuery<VehicleList, Error>(
        [VehicleQueryKeys.getList],
        () => vehicleApi.getList(),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(55, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(60, 'minutes')
        }
    );

    return (
        <RemoveSensorContent
            data-testid='RemoveSensor-testid'
            sensor={props.sensor}
            vehicles={vehicleDataXHR ?? []}
            vehiclesLoading={vehicleLoading}
            unmountReasonList={unmountReasonParsed ?? []}
            unmountReasonLoading={unmountReasonLoading}
            initialValues={initialValues}
            isFaulty={isFaulty}
            removeSensorLoading={props.removeSensorLoading}
            modalDispatch={modalDispatch}
            handleSubmit={handleSubmit}
        />
    );
};

export default Wrapper(RemoveSensor);
