import React from 'react';
import { Chip } from '@mui/material';
import * as type from './UiTableChipBar.type';
import { Wrapper } from 'helpers/wrapper';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UserInfo } from 'states/global/User';
import { ChipBarContent, ContainerFilter, TitleFilter } from './UiTableChipBar.style';
import { VARIANT_CHIP } from 'components/Ui/variables';
import { useUserLocalStorageSettingsTable } from 'components/CustomHooks/UserLocalStorageSettings';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';
import { TableAtom } from '../UiTable/TableAtom';
import { TableActions, TableDispatcher } from 'states/component/Table';

const UiTableChipBar: React.FC<type.ChipBarProps> = (props): JSX.Element | null => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();
    const [tableState, setTableState] = useRecoilState(TableAtom(`${props.queryKey}-Table`));
    const tableDispatch = TableDispatcher(tableState, setTableState);

    const resetValue = (filter) => {
        tableDispatch({
            type: TableActions.PAGE_FILTER_CHANGED,
            payload: tableState.queryPageFilter.filter((currentFilter) => currentFilter.id !== filter.id)
        });
    };

    return tableState.queryPageFilter.filter((val) => val.value.value !== undefined).length > 0 ? (
        <>
            <TitleFilter variant='caption'>{translate('t.active_filters')}: </TitleFilter>

            <ContainerFilter>
                {tableState.queryPageFilter.map((filter, key) => {
                    const selectValue: string =
                        (filter.value.humanValue &&
                            !DateTime.fromISO(filter.value.humanValue).invalid &&
                            DateTime.fromISO(filter.value.humanValue).toFormat(
                                userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD'
                            )) ||
                        filter.value.humanValue ||
                        filter.value.value;
                    const currentValue: string[] = selectValue?.split(';');
                    const index: number = currentValue?.length === 2 ? 1 : 0;
                    return (
                        currentValue?.length && (
                            <Chip
                                data-testid={`Chip-Filter-${props.queryKey}Table${filter.id}`}
                                sx={{ margin: '4px 0px 2px 4px' }}
                                variant={VARIANT_CHIP}
                                key={key}
                                label={
                                    <ChipBarContent>
                                        {getUserSettingTable(`table.${props.queryKey}Table`)?.filter.find(
                                            (currentFilter) =>
                                                currentFilter.id === filter.id && !!currentFilter.value.value
                                        ) && <FontAwesomeIcon icon={faFloppyDisk} />}
                                        {filter.value.name}: <strong>{currentValue[index]}</strong>
                                    </ChipBarContent>
                                }
                                onDelete={() => {
                                    resetValue(filter);
                                }}
                            />
                        )
                    );
                })}
            </ContainerFilter>
        </>
    ) : null;
};

export default Wrapper(UiTableChipBar);
