export type VehicleLoadingLogModel = {
    createdAt: string;
};

export type VehicleLoadingLogEnabled = {
    vehicleLoadingLog: {
        enabled: boolean;
    };
};

export enum VehicleLoadingLogQueryKeys {
    getEnabled = 'vehicleLoadingLog-getEnabled'
}
