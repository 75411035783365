import { Paginator } from 'helpers/api/type';
import { SortByProps } from 'components/Ui/UiTable/UiTable.type';
import { VehicleModel } from './Vehicle.type';
import { DeviceModel } from './Device.type';

export type VehicleEventModel = {
    id: number;
    code: {
        id: number;
        description: string;
        code: string;
    };
    requestId: number;
    measuredAt: string;
    createdAt: string;
    vehicle: Pick<VehicleModel, 'id' | 'name'>;
    device: Pick<DeviceModel, 'id' | 'serialNumber'>;
};

export type VehicleEventModelAlerts = Paginator<VehicleEventModel>;

export type GetAlertsParams = Partial<{
    page: number;
    limit: number;
    vehicleId: number;
    measuredFrom: string;
    orderParams: SortByProps[];
}>;

export enum VehicleEventQueryKeys {
    getAlerts = 'vehicleEvent-getAlerts'
}
