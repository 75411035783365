import React from 'react';
import { CustomCardHeaderWithTab, TabCustom, TabsCustom } from './UiWidgetTab.style';
import { UiWidgetTabViewProps } from './UiWidgetTab.type';
import { useTranslation } from 'react-i18next';
import { CardContentCustom, CustomCard, CustomCardHeaderContainer } from '../UiWidget/UiWidget.style';
import UiLoadingPage from '../UiLoadingPage/UiLoadingPage';

export const UiWidgetTabContent: React.FC<UiWidgetTabViewProps> = ({
    width,
    unit,
    height,
    headerAction,
    loading,
    tab,
    idTab,
    $contentHeight,
    testid,
    actionScroll,
    handleChangeTab,
    TabPanel,
    padding,
    scrollButtons = true
}): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <CustomCard
            data-testid={testid || 'UiWidgetTab2Container'}
            width={width || 100}
            unit={unit || '%'}
            height={height}
            $contentHeight={$contentHeight}
            padding={padding}
        >
            <CustomCardHeaderContainer>
                <CustomCardHeaderWithTab
                    action={<>{headerAction}</>}
                    title={
                        loading ? (
                            translate('t.loading')
                        ) : (
                            <TabsCustom
                                variant='scrollable'
                                scrollButtons={scrollButtons}
                                allowScrollButtonsMobile
                                value={idTab}
                                onChange={handleChangeTab}
                                aria-label='simple tabs example'
                                indicatorColor='primary'
                            >
                                {tab?.headers.map((titleTab, index) => (
                                    <TabCustom
                                        key={index}
                                        label={titleTab.label}
                                        icon={titleTab.icon}
                                        ishidden={titleTab.hideTab ? titleTab.hideTab : undefined}
                                        iconPosition='start'
                                        data-testid={`UiWidgetTab2-Tab-${titleTab.label?.replace(/\s+/g, '-')}`}
                                    />
                                ))}
                            </TabsCustom>
                        )
                    }
                />
            </CustomCardHeaderContainer>
            <CardContentCustom
                height={`${$contentHeight}px`}
                $scrollbar={true}
                onScroll={(event) => actionScroll && actionScroll(event)}
            >
                {loading && <UiLoadingPage size='30px' />}
                {tab?.constents.map((content, index) => {
                    return (
                        <TabPanel key={index} value={idTab} index={index}>
                            {content?.body}
                        </TabPanel>
                    );
                })}
            </CardContentCustom>
        </CustomCard>
    );
};
