import React, { useEffect, useState } from 'react';
import { GetVehicleStatusQueryProps } from './VehicleStatus.type';
import Vehicle from 'api/Vehicle';
import { useQuery } from '@tanstack/react-query';
import { VehicleQueryKeys, VehicleStatus2 } from 'models/Vehicle.type';
import { useRecoilValue } from 'recoil';
import { VehicleFiltersAtom } from 'components/Vehicle/VehicleStatusWidget/Components/VehicleFilters/VehicleFilters.atom';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { UserInfo } from 'states/global/User';

const vehicleAPI = new Vehicle();

export const GetVehicleStatusQuery: React.FC<GetVehicleStatusQueryProps> = ({ children }): JSX.Element => {
    const [interval, setInterval] = useState<number | false>(50000);
    const vehicleFiltersAtom = useRecoilValue(VehicleFiltersAtom);
    const userInfo = useRecoilValue(UserInfo);

    useEffect(() => {
        return () => setInterval(false);
    }, []);

    const useQueryProperties = useQuery<VehicleStatus2, Error, VehicleStatus2>(
        [
            VehicleQueryKeys.getVehicleStatus,
            vehicleFiltersAtom.filterBy,
            vehicleFiltersAtom.sortBy,
            vehicleFiltersAtom.filterByVehicleType,
            userInfo.user?.customer.id
        ],
        () =>
            vehicleAPI.getVehicleStatusData(
                vehicleFiltersAtom.filterBy,
                vehicleFiltersAtom.sortBy,
                vehicleFiltersAtom.filterByVehicleType
            ),
        {
            refetchOnWindowFocus: false,
            refetchInterval: interval,
            staleTime: cacheTimeToMilliseconds(55, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(60, 'minutes')
        }
    );

    return <>{children(useQueryProperties)}</>;
};

export default GetVehicleStatusQuery;
