import React from 'react';
import * as view from './ListSubscribedUsers.view';
import * as type from './ListSubscribedUsers.type';
import Notification from 'api/Notification';
import { useQuery } from '@tanstack/react-query';
import { NotificationQueryKeys, UsersSubscribed } from 'models/Notification.type';

const ListSubscribedUsers: React.FC<type.ListSubscribedUsersProps> = ({
    NotificationId,
    NotificationType
}): JSX.Element => {
    const notificationApi = new Notification();

    const { isFetching: SubscribedIsFetched, data: SubscribedData } = useQuery<UsersSubscribed>(
        [NotificationQueryKeys.getSubscribed],
        () => notificationApi.getSubscribed(NotificationId, NotificationType),
        {}
    );

    return (
        <view.ListSubscribedUsersContent
            data-testid={'ListSubscribedUsers-testid'}
            data={SubscribedData}
            isLoading={SubscribedIsFetched}
        />
    );
};

export default ListSubscribedUsers;
