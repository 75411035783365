import React from 'react';
import { AnnouncementsActionsContainer } from './AnnouncementsActions.style';
import { AnnouncementsActionsViewProps } from './AnnouncementsActions.type';
import Modal from '../Modal/Modal';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { AnnouncementsActionsState } from '../../states/component/Announcements';
import { useTranslation } from 'react-i18next';
import { ShowAnnouncementsActionsModal } from '../../states/global/Modal';
import { DEFAULT_FORM_VALUES_GENERAL } from './AnnouncementsActions';
import { AnnouncementsForm } from './AnnoucementsForm';
import { ActionButton } from './ActionButton';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';

export const AnnouncementsActionsContent: React.FC<AnnouncementsActionsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const announcementsActionsState = useRecoilValue(AnnouncementsActionsState);
    const resetAnnouncementsActionsState = useResetRecoilState(AnnouncementsActionsState);
    const closeAnnouncementsActionModal = useResetRecoilState(ShowAnnouncementsActionsModal);

    return (
        <AnnouncementsActionsContainer data-testid='AnnouncementsActionsContent'>
            <Modal
                testid='announcements-actions-modal'
                type='ShowAnnouncementsActionsModal'
                leftTitle={`${translate(`t.${announcementsActionsState.action}`)} ${translate(`t.notification`)}`}
                height={590}
                content={
                    props.notificationLoading && announcementsActionsState.action !== 'add' ? (
                        <UiLoadingPage size='30px' />
                    ) : (
                        <AnnouncementsForm
                            formValues={props.formValues}
                            sendData={props.sendData}
                            sendWhatNewData={props.sendWhatNewData}
                            customersData={props.customersData}
                            currentTab={props.currentTab}
                            setCurrentTab={props.setCurrentTab}
                            hiddenTab={props.hiddenTab}
                            articles={props.articles}
                        />
                    )
                }
                onClose={() => {
                    closeAnnouncementsActionModal();
                    resetAnnouncementsActionsState();
                    props.setFormValues(DEFAULT_FORM_VALUES_GENERAL);
                    props.setCurrentTab(0);
                }}
                buttons={
                    <ActionButton
                        isProcessing={props.isProcessing}
                        data-testid='save-button'
                        currentTab={props.hiddenTab !== undefined ? (!props.hiddenTab ? 1 : 0) : props.currentTab}
                    />
                }
                width={800}
                id='announcements-actions-modal'
            />
        </AnnouncementsActionsContainer>
    );
};
