import React from 'react';
import { CommentUpdateContent } from './CommentUpdate.view';
import { CommentUpdateProps, InitialValues } from './CommentUpdate.type';
import Tyre from 'api/Tyre';
import { Success } from 'components/Popup/Popup';
import { useMutation } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Wrapper } from 'helpers/wrapper';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';
import { TyreData, TyreQueryKeys, TyreUpdateComment } from 'models/Tyre.type';

const tyreApi = new Tyre();

const CommentUpdate: React.FC<CommentUpdateProps> = ({ tyreId, showComment, queryKey }): JSX.Element => {
    const { t: translate } = useTranslation();
    const queryTableFn = useRecoilValue(QueryFn(`${queryKey}-QueryFn`));

    const initialValues: InitialValues = {
        comment: showComment ?? ''
    };

    const { mutate: updateTyreCommentMutate, isLoading: updateTyreCommentLoading } = useMutation<
        TyreData,
        unknown,
        TyreUpdateComment
    >([TyreQueryKeys.updateTyreComment], tyreApi.updateTyreComment, {
        onSuccess: (data) => {
            if (data) {
                Success({
                    text: translate('t.updated_comment')
                });
                queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            }
        }
    });

    const handleSubmit = (values: InitialValues): void => {
        updateTyreCommentMutate({ tyreId: tyreId, updateData: values });
    };

    return (
        <CommentUpdateContent
            data-testid={'CommentUpdate-testid'}
            showComment={showComment}
            initialValues={initialValues}
            loading={updateTyreCommentLoading}
            handleSubmit={handleSubmit}
        />
    );
};

export default Wrapper(CommentUpdate);
