import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { SpeedIncidentsViewContent } from './SpeedIncidents.style';
import { SpeedIncidentsViewProps } from './SpeedIncidents.type';
import { useTranslation } from 'react-i18next';
import HeatMapSpeed from '../../components/Tracking/HeatMapSpeedIncident';
import { MapActionsEnum, MapModes } from '../../components/Map/Map.type';
import Widget from '../../components/Ui/Components/UiWidget';
import Map from '../../components/Map';
import { LoadedStatusAllowed, MapAction } from '../../states/global/Map';
import { applyStyleByMode } from 'helpers';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import MapLegend from '../../components/Ui/Components/UiMapComponent';
import SpeedIncidentsLegend from 'components/Maps/SpeedIncidentsLegend/SpeedIncidentsLegend';

export const SpeedIncidentsContent: React.FC<SpeedIncidentsViewProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [mapAction, setMapAction] = useRecoilState(MapAction);
    const ThemeMode = useRecoilValue(Theme);
    const loadedStatusAllowed = useRecoilValue(LoadedStatusAllowed);

    return (
        <SpeedIncidentsViewContent data-testid='SpeedIncidentsContent'>
            <HeatMapSpeed data-testid={'SpeedIncidentsContent-HeatMapSpeed'} />
            <Widget
                testid='SpeedIncidentsContent-Widget'
                title={translate('t.speed_incidents')}
                collapseButton={false}
                fullScreenButton={true}
                avatar={
                    <UiIcon
                        data-testid={'SpeedIncidentsContent-UiIcon'}
                        icon={faLocationDot}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                $contentHeight={72}
                onResize={() => {
                    setMapAction({
                        action: MapActionsEnum.RESIZE_MAP,
                        params: {
                            lastAction: mapAction
                        }
                    });
                }}
                unit='vh'
                padding={0}
                applyPadding={false}
                marginBottom={false}
            >
                {loadedStatusAllowed && (
                    <MapLegend>
                        <SpeedIncidentsLegend />
                    </MapLegend>
                )}
                <Map mapMode={MapModes.SPEED_INCIDENTS} data-testid={'SpeedIncidentsContent-Map'} />
            </Widget>
        </SpeedIncidentsViewContent>
    );
};
