export type DeviceRevisionModel = {
    id: number;
    name: string;
    description: string;
};

export type DeviceRevisionCodeBook = {
    codeBook: DeviceRevisionModel[];
};

export enum DeviceRevisionQueryKeys {
    getDeviceRevisionCodebook = 'deviceRevision-GetDeviceRevisionCodebook'
}
