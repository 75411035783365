import styled from 'styled-components';

export const AnnouncementsTableContainer = styled.div`
    font-size: 1.25rem;
`;

export const AnnoucementsKnowledgebase = styled.span`
    text-decoration: none;
    font-size: 0.86rem;
    padding: 0px 5px;
    font-weight: unset;
    display: inline-block;
    cursor: pointer;
    color: rgb(0, 112, 255);
    :hover {
        text-decoration: underline;
    }
`;
