import React from 'react';
import {
    ErrorProps,
    ErrorsProps,
    GeneralProps,
    GeneralcustomSettingProps,
    SuccessProps,
    WarningProps
} from './Popup.type';
import { ErrorContent, GeneralContent, SuccessContent, WarningContent } from './Popup.view';
import { ToastOptions, toast } from 'react-toastify';
import ErrorPopup from './Error';

export const Popup = (
    content: JSX.Element,
    props?: (ErrorProps | ErrorsProps | SuccessProps | WarningProps | GeneralProps | GeneralcustomSettingProps) & {
        position?: string;
        duration?: number | false;
    }
): void => {
    let settings = Object.assign(
        {
            position: props?.position || 'top-right',
            autoClose: props?.duration || 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            collapse: false
        },
        props
    );
    toast(content, settings as ToastOptions);
};

export const Error = (props: ErrorProps): void => {
    Popup(<ErrorPopup data-testid={'ErrorPopup-testid'} {...props} />, { ...(props.customSetting as ErrorProps) });
};

export const Errors = (props: ErrorsProps): void => {
    Popup(<ErrorContent data-testid={'ErrorContent-testid'} {...props} />, { ...(props.customSetting as ErrorsProps) });
};

export const Success = (props: SuccessProps): void => {
    Popup(<SuccessContent data-testid={'SuccessContent-testid'} {...props} />, {
        ...(props.customSetting as SuccessProps)
    });
};

export const Warning = (props: WarningProps): void => {
    Popup(<WarningContent data-testid={'WarningContent-testid'} {...props} />, {
        ...(props.customSetting as WarningProps)
    });
};

export const General = (props: GeneralProps): void => {
    Popup(<GeneralContent data-testid={'GeneralContent-testid'} content={props.content} />, {
        ...(props?.customSetting as GeneralcustomSettingProps)
    });
};
