import React from 'react';
import * as type from './UiLegendContentTablet.type';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';

const UiLegendContentTabletContent: React.FC<type.UiLegendContentTabletProps> = (props): JSX.Element => {
    return (
        <table className='legend-table'>
            <tbody>
                {props.items.map((item, key) => {
                    return (
                        <tr key={key}>
                            <td style={{ verticalAlign: 'top' }}>
                                <UiIcon easyReadingValue='xl' icon={['fas', item.icon]} />
                            </td>
                            <td style={{ verticalAlign: 'top', paddingBottom: '3px' }}>{item.title}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default UiLegendContentTabletContent;
