import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

type PassProps = {
    icon: IconProp;
    colorTheme?: ColorType;
    testid?: string;
    easyReadingValue?: SizeProp;
};

export type UiIconProps = {} & PassProps & FontAwesomeIconProps;

export type UiIconViewProps = {} & PassProps & FontAwesomeIconProps;

export type ColorType = {
    light: string;
    dark: string;
};

export const sizeArray: SizeProp[] = [
    '2xs',
    'xs',
    'sm',
    'lg',
    'xl',
    '2xl',
    '1x',
    '2x',
    '3x',
    '4x',
    '5x',
    '6x',
    '7x',
    '8x',
    '9x',
    '10x'
];
