import React, { useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { FormControlLabelEl } from './UiTableHideColumnsCheckbox.style';
import { UiTableHideColumnsCheckboxProps } from './UiTableHideColumnsCheckbox.type';
import { Checkbox } from '@mui/material';
import { ColumnVisibility, HiddenColumnsIds, TableFn } from '../UiTable/TableAtom';
import useUserLocalStorageSettings, {
    useUserLocalStorageSettingsTable
} from 'components/CustomHooks/UserLocalStorageSettings';

const UiTableHideColumnsCheckboxContent: React.FC<UiTableHideColumnsCheckboxProps> = (props): JSX.Element => {
    const tableFn = useRecoilValue(TableFn(`${props.queryKey}-TableFn`));
    const columnVisibility = useRecoilValue(ColumnVisibility(`${props.queryKey}-ColumnVisibility`));
    const localStorageTableKey = useMemo(() => `table.${props.queryKey}Table`, [props.queryKey]);
    const { setUserSettings } = useUserLocalStorageSettings([localStorageTableKey]);
    const setHiddenColumns = useSetRecoilState(HiddenColumnsIds(`${props.queryKey}-HiddenColumnsIds`));
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();

    const getHiddenColumnsByName = (hiddenColumnName, columnId, isVisible): string[] => {
        if (isVisible) {
            return [
                ...(getUserSettingTable(localStorageTableKey) &&
                getUserSettingTable(localStorageTableKey)[hiddenColumnName]
                    ? getUserSettingTable(localStorageTableKey)[hiddenColumnName]
                    : []),
                columnId
            ];
        } else {
            return getUserSettingTable(localStorageTableKey)[hiddenColumnName]?.filter((column) => column !== columnId);
        }
    };

    // TODO: find better solution to store hidden columns via table hidden colums effect
    const setUserHiddenColumns = (columnId, isVisible) => {
        let userHiddenColumns: string[] = getHiddenColumnsByName('userHiddenColumns', columnId, isVisible);
        let defaultHiddenColumns: string[] = !isVisible
            ? getHiddenColumnsByName('defaultHiddenColumns', columnId, isVisible)
            : getUserSettingTable(localStorageTableKey)?.defaultHiddenColumns ?? [];

        setUserSettings(localStorageTableKey, {
            ...getUserSettingTable(localStorageTableKey),
            userHiddenColumns,
            defaultHiddenColumns
        });

        setHiddenColumns([...new Set([...defaultHiddenColumns, ...userHiddenColumns])]);
    };

    const CheckboxEl = useMemo(() => {
        return (
            <FormControlLabelEl
                data-testid={`HideColumnsCheckbox-${props.queryKey}Table-${props.columnId}`}
                control={
                    <Checkbox
                        disabled={false}
                        color='primary'
                        onChange={() => {
                            setUserHiddenColumns(props.columnId, columnVisibility[props.columnId].isVisible);
                            tableFn.toggleHideColumn &&
                                tableFn.toggleHideColumn instanceof Function &&
                                tableFn.toggleHideColumn(props.columnId, columnVisibility[props.columnId].isVisible);
                        }}
                    />
                }
                label={<>{props.label}</>}
                checked={columnVisibility[props.columnId].isVisible}
            />
        );
    }, [columnVisibility[props.columnId].isVisible]);

    return CheckboxEl;
};

export default UiTableHideColumnsCheckboxContent;
