import PortalApi from 'helpers/api/PortalApiClient';
import {
    GPSAlertsTable,
    GetAlertsParams,
    GetIncidentsParams,
    OverSpeedingPreview,
    OverspeedingCSVCriteria,
    SpeedEventModel,
    SpeedIncidentMap,
    SpeedIncidentsMap
} from '../models/SpeedEvent.type';
import { FilterProps } from 'components/Ui/UiTable/UiTable.type';
import { getParams } from 'helpers';

const getAlertsDefaultProps: GetAlertsParams = {
    page: 1,
    limit: 150
};

export default class SpeedEvent extends PortalApi<SpeedEventModel> {
    route = 'speed-event';

    getAlerts = async (params: GetAlertsParams = getAlertsDefaultProps): Promise<GPSAlertsTable> => {
        let filterParams: FilterProps[] = [];

        params.measuredFrom &&
            filterParams.push({ id: 'measuredFrom', value: { name: 'measuredFrom', value: `${params.measuredFrom}` } });
        params.vehicleId &&
            filterParams.push({ id: 'vehicleId', value: { name: 'vehicleId', value: `${params.vehicleId}` } });

        return await this.getTable({
            criteria: getParams({
                queryPageIndex: params.page ? params.page - 1 : 0,
                queryPageSize: params.limit ?? 10,
                queryPageSortBy: params.orderParams ?? [],
                queryPageFilter: filterParams
            })
        });
    };

    getOverSpeedingReports = async (props): Promise<OverSpeedingPreview> => {
        const criteria = props.additionalProps;

        if (!props.additionalProps.vehicleId) {
            delete criteria['vehicleId'];
        }

        if (props.additionalProps.loaded) {
            criteria.loaded = props.additionalProps.loaded === -1 ? null : props.additionalProps.loaded;
        }

        return this.getByCriteria({
            extendUrl: 'preview',
            criteria: criteria
        });
    };

    getOverSpeedingReportsCsv = async (params: OverspeedingCSVCriteria): Promise<void> => {
        if (params.loaded) {
            params.loaded = params.loaded === -1 ? null : params.loaded;
        }

        if (!params.vehicleId) {
            delete params['vehicleId'];
        }

        return this.getByCriteria({
            extendUrl: 'export',
            criteria: params
        });
    };

    getSpeedIncidents = (params: GetIncidentsParams): Promise<SpeedIncidentsMap> => {
        if (params.loaded === -1) {
            delete params['loaded'];
        }
        if (params.vehicleOperator === null) {
            delete params['vehicleOperator'];
        }

        return this.getByCriteria({ extendUrl: 'incidents', criteria: params, version: 'v2' });
    };

    getSpeedIncidentById = (incidentId: number): Promise<SpeedIncidentMap> => {
        return this.get({ extendUrl: `${incidentId}`, version: 'v2' });
    };
}
