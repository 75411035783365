import { applyStyleByMode } from 'helpers';
import styled, { createGlobalStyle } from 'styled-components';
import { Box, IconButton, PaletteMode, useTheme } from '@mui/material';

export const GlobalStyle = createGlobalStyle<{ $theme: PaletteMode }>`
    .rs__popper_actions {

        &&& svg {
            font-size: 1.125rem;
            :hover {
                ${({ $theme }) => {
                    return applyStyleByMode({
                        theme: $theme,
                        light: `color: #cbcbcb;`
                    });
                }}
            }
        }

        div:last-child {
            z-index: 5;
            background-color: #00647a;

            .delete {
                color: #ffffff !important;
            }

            .cancel {
                color: rgb(255 255 255 / 65%) !important;
            }
        }
    }

    .MuiAvatar-circular {
        ${({ $theme }) => {
            return applyStyleByMode({
                theme: $theme,
                dark: `background: revert-layer !important;`
            });
        }}
    }
    &&& {
        .MuiPaper-elevation1 {
            box-shadow: 0px 1px 5px 3px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,1,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
        }
    }
`;

export const ShiftTimeCalendarContainer = styled.div``;

export const IconButtonCloneContainer = styled.div`
    &&& {
        position: absolute;
        margin: -23px 0px 0px 282px;
        ${() => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                light: `margin: -21px 0px 0px 282px;`
            });
        }}

        ${() => useTheme().breakpoints.up('xl')} {
            margin: -21px 0px 0px 285px;
        }
    }
`;

export const IconButtonClone = styled(IconButton)`
    &&& {
        svg {
            ${() => {
                return applyStyleByMode({
                    theme: useTheme().palette.mode,
                    dark: `font-size: 1.25rem;`
                });
            }}
            :hover {
                ${() => {
                    return applyStyleByMode({
                        theme: useTheme().palette.mode,
                        light: `color: #cbcbcb`
                    });
                }}
            }
        }
    }
`;

export const ShiftTImeCalendarLoaderLoaderContainer = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 6px;

    ${() => useTheme().breakpoints.between('lg', 'xl')} {
        gap: 5px;
    }

    ${() => useTheme().breakpoints.only('md')} {
        gap: 4px;
    }

    ${() => useTheme().breakpoints.between('xs', 'sm')} {
        gap: 3px;
    }
`;

export const ContainerSchedulerLoading = styled(Box)`
    height: 100%;
    display: flex;
    justify-content: center;
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: 'background-color: rgb(229 229 229 / 65%) !important;',
            dark: 'background-color: rgb(0 0 0 / 75%) !important;'
        });
    }}
`;
