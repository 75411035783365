import React from 'react';
import { CustomTableCell, SoftwarePackagesContainer, CustomTableContainer } from './SoftwarePackages.style';
import { Grid, Typography } from '@mui/material';
import { SoftwarePackagesViewProps } from './SoftwarePackages.type';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { HubAdministrationModelResponse } from 'models/HubAdministration.type';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';

export const SoftwarePackagesContent: React.FC<SoftwarePackagesViewProps> = ({ data, isLoading }): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <SoftwarePackagesContainer data-testid='SoftwarePackagesContent'>
            <Card variant='outlined'>
                <CardContent>
                    <Grid container item>
                        <Grid item xs={12} md={12} lg={12}>
                            <CustomTableContainer $isLoading={isLoading}>
                                <Table size='small'>
                                    <TableHead></TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography>{translate('t.service')} </Typography>
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography>
                                                    {' '}
                                                    {(data as HubAdministrationModelResponse).device?.deviceInfo
                                                        ?.service ?? '-'}
                                                </Typography>
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography> {translate('t.system_tools')}</Typography>
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography>
                                                    {' '}
                                                    {(data as HubAdministrationModelResponse).device?.deviceInfo
                                                        ?.tools ?? '-'}
                                                </Typography>
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography> {translate('t.kernel')}</Typography>
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography>
                                                    {' '}
                                                    {(data as HubAdministrationModelResponse).device?.deviceInfo
                                                        ?.kernel ?? '-'}
                                                </Typography>
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography> {translate('t.firmware')}</Typography>
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography>
                                                    {' '}
                                                    {(data as HubAdministrationModelResponse).device?.deviceInfo?.fmw ??
                                                        '-'}
                                                </Typography>
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography> {translate('t.exchange')}</Typography>
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography>
                                                    {' '}
                                                    {(data as HubAdministrationModelResponse).device?.deviceInfo
                                                        ?.exchange ?? '-'}
                                                </Typography>
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography> {translate('t.ping_reboot')}</Typography>
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography>
                                                    {' '}
                                                    {(data as HubAdministrationModelResponse).device?.deviceInfo
                                                        ?.pingReboot ?? '-'}
                                                </Typography>
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography> {translate('t.sshtunneld')}</Typography>
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography>
                                                    {' '}
                                                    {(data as HubAdministrationModelResponse).device?.deviceInfo
                                                        ?.sshtunneld ?? '-'}
                                                </Typography>
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography> {translate('t.status_monitor')}</Typography>
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography>
                                                    {' '}
                                                    {(data as HubAdministrationModelResponse).device?.deviceInfo
                                                        ?.statusMonitor ?? '-'}
                                                </Typography>
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography> {translate('t.usb')}</Typography>
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography>
                                                    {' '}
                                                    {(data as HubAdministrationModelResponse).device?.deviceInfo?.usb ??
                                                        '-'}
                                                </Typography>
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography> {translate('t.watchdog')} </Typography>
                                            </CustomTableCell>
                                            <CustomTableCell component='th' scope='row'>
                                                <Typography>
                                                    {' '}
                                                    {(data as HubAdministrationModelResponse).device?.deviceInfo
                                                        ?.watchdog ?? '-'}{' '}
                                                </Typography>
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CustomTableContainer>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </SoftwarePackagesContainer>
    );
};
