import React, { useCallback } from 'react';
import { BesRankOverviewContent } from './BesRankOverview.view';
import { PercentageForGraphType } from './BesRankOverview.type';
import { Wrapper } from 'helpers/wrapper';
import ExternalSystemMddBesTyreLog from 'api/ExternalSystemMddBesTyreLog';
import { useQuery } from '@tanstack/react-query';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { ExternalSystemMddBesTyreLogQueryKeys } from 'models/ExternalSystemMddBesTyreLog.type';
import { useRecoilValue } from 'recoil';
import { CustomerSettings } from 'states/global/CustomerSettings';

const externalSystemMddBesTyreLogAPI = new ExternalSystemMddBesTyreLog();

const BesRankOverview: React.FC = (): JSX.Element => {
    const customerSettings = useRecoilValue(CustomerSettings);
    const { isLoading, data } = useQuery(
        [ExternalSystemMddBesTyreLogQueryKeys.getTyreLogOverview],
        () => externalSystemMddBesTyreLogAPI.getTyreLogOverview(),
        {
            refetchInterval: 50000,
            staleTime: cacheTimeToMilliseconds(50, 'seconds'),
            cacheTime: cacheTimeToMilliseconds(50, 'seconds'),
            enabled: customerSettings.mdd_bes
        }
    );

    const createPercentageForView = useCallback((): PercentageForGraphType[] => {
        if (!data) return [];

        let percentageForView: PercentageForGraphType[] = [];
        let totalTyresWithBes = 0;
        const sortedData = data.externalSystemMddBesTyreLogOverview.rank.sort((a, b) => b.level - a.level);
        let totalPercentage = 0;
        let bes0Count = 0;

        for (let i = 0; i < sortedData.length; i++) {
            totalTyresWithBes += sortedData[i].count;
            if (sortedData[i].level === 0) {
                bes0Count = sortedData[i].count;
            }
        }

        if (totalTyresWithBes === 0) {
            for (let i = 0; i < 4; i++) {
                percentageForView.push({ level: i, percentage: 0, count: 0 });
            }
            return percentageForView;
        }

        for (let i = 0; i < sortedData.length - 1; i++) {
            const besRankData = sortedData[i];
            const percentage = Math.ceil((100 / totalTyresWithBes) * besRankData.count);
            percentageForView.push({ level: besRankData.level, percentage, count: besRankData.count });
            totalPercentage += percentage;
        }

        percentageForView.push({ level: 0, percentage: 100 - totalPercentage, count: bes0Count });

        return percentageForView.sort((a, b) => a.level - b.level);
    }, [data]);

    return (
        <BesRankOverviewContent
            data-testid='BesRankOverview-testid'
            isLoading={isLoading}
            createPercentageForView={createPercentageForView}
        />
    );
};

export default Wrapper(BesRankOverview);
