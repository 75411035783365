export type CounterActionModel = CounterAction[];

export type CounterAction = {
    id: number;
    name: string;
};

export enum CounterActionQueryKeys {
    getOptions = 'counterAction-getOptions'
}
