import styled from 'styled-components';
import Popover from '@mui/material/Popover';
import { COLOR_GREY_4, SECONDARY } from 'components/Ui/colors';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { useTheme } from '@mui/material';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const VehicleDetailsActionMenuViewContent = styled.div``;

export const PredictionViewContent = styled.div`
    & .MuiTypography-root {
        display: inline-block;
        margin: 0px 2.5px;
    }

    svg {
        margin-left: 2.5px;
        margin-right: 2.5px;
    }

    & .MuiPaper-root {
        min-width: 705px;
        max-width: 800px;
        & .MuiGrid-root {
            padding: 5px 10px;
        }
    }
`;

export const CustomPopover = styled(Popover)<{ top: number; left: number }>`
    &.MuiPopover-root {
        width: 1000px;
        ${(props) => `
            top: ${props.top}px !important;
            left: ${props.left}px !important;
        `};
    }
`;

export const Header = styled.div`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `
            color: ${useTheme().palette.primary.main};
            font-size: ${common.titleSize};
            font-weight: 600;
            padding: 0px;
            margin : 0 auto;
            width: 97%;
            border-bottom: 1px solid ${SECONDARY};
        `;
    }}
`;

export const FontAwesomeIconCustom = styled(UiIcon)`
    --fa-primary-color: ${COLOR_GREY_4};
`;
