import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AlertsDefaultVehiclesListContainer } from './AlertsDefaultVehiclesList.style';
import { AlertsDefaultVehiclesListProps } from './AlertsDefaultVehiclesList.type';
import { AffectedVehiclesCount, FilterVehicleValue } from '../AlertsAffectedVehicles/atom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useQuery } from '@tanstack/react-query';
import RuleApi from 'api/Rule';
import { DefaultVehiclesWithPaginator, RULE_TYPE } from 'models/Rule.type';
import {
    AlertInfo,
    SchemaBox,
    SchemaContent,
    VehicleLabel,
    VehicleListContainer
} from '../AlertsAffectedVehiclesList/AlertsAffectedVehiclesList.style';
import { Skeleton, Tooltip, debounce } from '@mui/material';
import VehicleSchema from 'components/Vehicle/NewVehicleSchema/VehicheSchema';
import { useTranslation } from 'react-i18next';
import { RuleQueryKeys } from 'models/Rule.type';

const Rule = new RuleApi();

const AlertsDefaultVehiclesListContent: React.FC<AlertsDefaultVehiclesListProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const filterValue = useRecoilValue(FilterVehicleValue);
    const setAffectedVehiclesCount = useSetRecoilState(AffectedVehiclesCount);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [vehicleData, setVehicleData] = useState<DefaultVehiclesWithPaginator['items'][0][]>([]);

    const { isLoading, isRefetching, refetch } = useQuery<any, Error, DefaultVehiclesWithPaginator>(
        [RuleQueryKeys.getDefaultVehicles, filterValue.searchValue],
        () =>
            Rule.getDefaultVehicles({
                limit: 30,
                page,
                name: filterValue.searchValue,
                wheelRuleRuleType: 1
            }),
        {
            refetchOnWindowFocus: false,
            enabled: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: (data) => {
                if (page === 1) {
                    setVehicleData([]);
                }

                const hasMoreData = (data?.paginator?.page ?? 0) < (data?.paginator?.pageCount ?? 0);
                setAffectedVehiclesCount((value) => ({
                    latest: filterValue.searchValue ? value.latest : data.paginator.totalCount,
                    current: filterValue.searchValue ? value.latest : data.paginator.totalCount
                }));
                setVehicleData((current) => [...current, ...data.items]);
                if (hasMoreData) {
                    setPage((current) => current + 1);
                }
                setHasMore(hasMoreData);
            }
        }
    );

    const onScroll = (e): void => {
        if (e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 10) {
            hasMore && refetch();
        }
    };

    const getColoredWheels = (wheels: DefaultVehiclesWithPaginator['items'][0]['wheel']): Record<string, any> => {
        const colors = {};
        for (let i = 0, length = wheels.length; i < length; i++) {
            if (wheels[i].rule.ruleType.id === RULE_TYPE.DEFAULT) {
                colors[wheels[i].position] = {};
            }
        }
        return colors;
    };

    useEffect(() => {
        if (isLoading || isRefetching) {
            setAffectedVehiclesCount((value) => ({ ...value, current: -1 }));
        }
    }, [isRefetching, isLoading]);

    const resetList = () => {
        setHasMore(false);
        setPage(1);
        setTimeout(() => {
            refetch();
        }, 100);
    };

    const loadVehiclesByName = useCallback(
        debounce(() => {
            resetList();
        }, 2000),
        [filterValue.searchValue]
    );

    useEffect(() => {
        loadVehiclesByName();
    }, [filterValue.inpuValue]);

    const skeletons = useMemo(() => {
        let skeletons: JSX.Element[] = [];
        for (let i = 0; i < 10; i++) {
            skeletons.push(
                <SchemaContent>
                    <VehicleLabel variant='subtitle2' gutterBottom>
                        <Skeleton variant='rectangular' width={100} height={20} />
                    </VehicleLabel>
                    <SchemaBox>
                        <Skeleton variant='rectangular' width={100} height={120} />
                    </SchemaBox>
                </SchemaContent>
            );
        }
        return skeletons;
    }, []);

    return (
        <AlertsDefaultVehiclesListContainer data-testid='AlertsDefaultVehiclesListContent'>
            {isLoading ? (
                skeletons
            ) : vehicleData.length === 0 ? (
                <AlertInfo severity='info'>
                    {filterValue.searchValue.length
                        ? translate('t.vehicle_not_found')
                        : translate('t.no_affected_vehicles')}
                </AlertInfo>
            ) : (
                <VehicleListContainer onScroll={onScroll} elevation={0}>
                    {vehicleData.map((vehicle, index) => {
                        return (
                            <SchemaContent key={index}>
                                <Tooltip title={vehicle.name}>
                                    <VehicleLabel variant='subtitle2' gutterBottom>
                                        {vehicle.name}
                                    </VehicleLabel>
                                </Tooltip>
                                <SchemaBox>
                                    <VehicleSchema
                                        schema={vehicle.vehicleConfigurationV2.distribution}
                                        emptySchema
                                        highlightColor='#f00'
                                        highlightWheels={getColoredWheels(vehicle.wheel)}
                                    />
                                </SchemaBox>
                            </SchemaContent>
                        );
                    })}
                </VehicleListContainer>
            )}
        </AlertsDefaultVehiclesListContainer>
    );
};

export default AlertsDefaultVehiclesListContent;
