import { AreaTypeModel } from './AreaType.type';

type Area = {};

export type AreaFeaturesResponse = {
    area: [number, number][];
    areaType: AreaTypeModel | null;
    id: number;
    leftTopX: number;
    leftTopY: number;
    rightBottomX: number;
    rightBottomY: number;
    name: string;
    color: string;
    minSpeed?: number;
    maxSpeed?: number;
    maxSpeedTemperatureL1?: number;
    maxSpeedTemperatureL2?: number;
    maxSpeedTemperatureL3?: number;
    generateAreaEvents: boolean;
    generateSpeedAlerts: boolean;
    temperatureBasedSpeedAlerts: boolean;
    maintenanceArea: boolean;
    createdAt: Date;
    createdAtFrom?: string;
    createdAtTo?: string;
};
export type AreaFeaturesResponseData = {
    area: AreaFeaturesResponse;
};

export type AreaRequest = Omit<Partial<AreaFeaturesResponse>, 'areaType'> & { areaType?: number };

export type AreaGPSAlert = {
    name: string;
};

export enum AreaQueryKeys {
    getByIdArea = 'area-getByIdArea',
    getLazyDropdown = 'area-getLazyDropdown',
    getAreaFeatures = 'area-getAreaFeatures'
}

export default Area;
