import React from 'react';
import { useRecoilValue } from 'recoil';
import { AssignedReportsTypography, InfoMessage, ReportPermissionsViewContent } from './ReportPermissions.style';
import { ReportPermissionsViewProps } from './ReportPermissions.type';
import { useTranslation } from 'react-i18next';
import { SubHead, TableName } from '../../components/User/MinesPermissions/MinesPermissions.style';
import UiWidget from '../../components/Ui/Components/UiWidget/UiWidget';
import ReportPermissionsTable from '../../components/Report/ReportPermissionsTable';
import { Autocomplete, Grid, TextField, useMediaQuery, useTheme } from '@mui/material';
import { firstCapital } from 'helpers/converter/text';
import { applyStyleByMode } from 'helpers';
import { faShieldCheck } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';

export const ReportPermissionsContent: React.FC<ReportPermissionsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const showXsSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

    const ThemeMode = useRecoilValue(Theme);
    return (
        <ReportPermissionsViewContent data-testid='ReportPermissionsContent'>
            <UiWidget
                title={translate('t.report_permissions')}
                avatar={
                    <UiIcon
                        data-testid='ReportPermissionsAvatar'
                        icon={faShieldCheck}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
            >
                <InfoMessage severity='info' variant='filled' data-testid='ReportPermissionsAccessNote'>
                    {translate('p.admin_super_admin_access_all_roles')}
                </InfoMessage>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <SubHead>
                            <Autocomplete
                                options={props.availableRoles?.filter((role) => ![1, 2].includes(role.id)) || []}
                                getOptionLabel={(role) =>
                                    firstCapital(role?.name.replace('ROLE_', '').replaceAll('_', ' ') || '')
                                }
                                size='small'
                                onChange={(event, role) => {
                                    props.setSelectedRole(role);
                                }}
                                disableClearable={true}
                                value={props.selectedRole}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={translate('t.role')}
                                        variant='outlined'
                                        style={{
                                            width: showXsSm ? '200px' : '300px'
                                        }}
                                        data-testid='ReportPermissionsRoleList'
                                    />
                                )}
                                disabled={false}
                            />
                        </SubHead>
                        <ReportPermissionsTable
                            assignToRoleLoading={props.assignToRoleLoading}
                            notAssignedTable={true}
                            buttonAction={props.assignToRole}
                            data={props.reportPermissions().availableReportsData}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <SubHead>
                            <TableName elevation={0}>
                                <AssignedReportsTypography data-testid='ReportPermissionsAssignedReportsHeader'>
                                    {translate('t.assigned_reports')}
                                </AssignedReportsTypography>
                            </TableName>
                        </SubHead>
                        <ReportPermissionsTable
                            removeFromRoleLoading={props.removeFromRoleLoading}
                            notAssignedTable={false}
                            buttonAction={props.removeFromRole}
                            data={props.reportPermissions().assignedReportsData}
                            testid='ReportPermissionsAssignedReport-table'
                        />
                    </Grid>
                </Grid>
            </UiWidget>
        </ReportPermissionsViewContent>
    );
};
