type SensorFaultyReportVerifiedStatusModel = {
    id: number;
    name: string;
};

export type SensorFaultyReportVerifiedStatusCodebook = {
    codeBook: SensorFaultyReportVerifiedStatusModel[];
};

export enum SensorFaultyReportVerifiedStatusQueryKeys {
    get = 'sensorFaultyReportVerifiedStatus-get'
}

export default SensorFaultyReportVerifiedStatusModel;
