import { Paginator } from './Paginator.type';

export type ReportModel = {
    downloadLink?: string;
    reportId: number;
    reportName: string;
    reportType: string;
    requestedAt: number;
    status: string;
    updatedAt: number;
    requested_at?: string;
    requestedFrom?: string;
    requestedTo?: string;
};

export type ReportModelDetail = {
    dateFrom: number;
    dateTo: number;
    emails: string[];
    format: {
        accelerationUnit: string;
        altitudeUnit: string;
        dateFormat: string;
        dateFormatC: string;
        dateTimeFormat: string;
        language: string;
        pressureUnit: string;
        speedUnit: string;
        temperatureUnit: string;
        timeFormat: string;
        timeFormatC: string;
        timezone: string;
    };
    vehicleId: number;
    wheelCustomPosition: number;
};

export type ReportWithPaginator = {
    items: ReportModel[];
    paginator: Paginator;
};

export type ReportCancel = {
    reportId: number;
    reportType: string;
};

export type ReportCancelResponse = {
    status: number;
};

export enum ReportQueryKeys {
    getAvailableToAssign = 'report-AvailableToAssingReports',
    getAssignedToRoles = 'report-AssignedReports',
    getDetail = 'report-getDetail'
}
