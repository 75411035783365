import React, { useCallback, useEffect } from 'react';
import { SeverityRankContent } from './SeverityRank.view';
import { SeverityRankProps } from './SeverityRank.type';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { SelectedVehicle } from 'states/global/Statistics';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import VehicleApi from 'api/Vehicle';
import { BesRankTicks, BesRankTimeline } from './atom';
import { Interval, Settings } from 'luxon';
import { TopBarRankDateTimePicker } from 'components/Tyre/SeverityRankTopbar/Components/RankDateRangePickers/RankDateRangePickers.atom';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { VehicleQueryKeys } from 'models/Vehicle.type';
import { Wrapper } from 'helpers/wrapper';

const Vehicle = new VehicleApi();

const SeverityRank: React.FC<SeverityRankProps> = (props): JSX.Element => {
    const setSelectedVehicle = useSetRecoilState(SelectedVehicle);
    const resetSelectedVehicle = useResetRecoilState(SelectedVehicle);
    const setRankTimeline = useSetRecoilState(BesRankTimeline);
    const setRankTicks = useSetRecoilState(BesRankTicks);
    const dateRange = useRecoilValue(TopBarRankDateTimePicker);

    let { id } = useParams();

    const { isLoading, data } = useQuery(
        [VehicleQueryKeys.getVehicleById],
        () => {
            const vehicleId = id || props.vehicleId;
            if (vehicleId) {
                return Vehicle.getVehicleById(+vehicleId);
            }
        },
        {
            enabled: true,
            staleTime: cacheTimeToMilliseconds(1, 'hours'),
            cacheTime: cacheTimeToMilliseconds(1, 'hours')
        }
    );

    useEffect(() => {
        if (data) {
            setSelectedVehicle({
                id: data.id,
                icon: data.icon,
                name: data.name,
                distribution: data.vehicleConfigurationV2.distribution
            });
        }
    }, [data]);

    useEffect(() => {
        return () => {
            resetSelectedVehicle();
        };
    }, []);

    const generateTimeline = useCallback(() => {
        if (dateRange.dateFrom.isValid && dateRange.dateTo.isValid) {
            let timeline = {};
            Interval.fromDateTimes(
                dateRange?.dateFrom.toUTC().startOf('day'),
                dateRange?.dateTo.toUTC().plus({ days: 1 }).startOf('day')
            )
                .splitBy({ day: 1 })
                .map((d) => d.start)
                .forEach((element) => {
                    const timeKey = element.valueOf() / 1000;
                    timeline[timeKey] = { timeKey };
                });

            setRankTimeline(timeline);

            const ticks = Interval.fromDateTimes(
                dateRange?.dateFrom.toUTC().startOf('day'),
                dateRange?.dateTo.toUTC().plus({ days: 1 }).startOf('day')
            )
                .splitBy({ day: 1 })
                .map((d) => d.start)
                .filter((element) => {
                    return element.weekday === 1;
                })
                .map((element) => element.valueOf() / 1000);

            setRankTicks(ticks);
        }
    }, [dateRange, Settings.defaultZone]);

    generateTimeline();
    return (
        <SeverityRankContent
            distribution={data?.vehicleConfigurationV2.distribution}
            vehicleName={data?.name}
            isLoadingVehicle={isLoading}
            data-testid={'SeverityRank-testid'}
        />
    );
};

export default Wrapper(SeverityRank);
