import React, { useCallback, DragEvent, useRef, useState, useEffect } from 'react';
import { ShiftTimeCalendarContent } from './ShiftTimeCalendar.view';
import { EventsScheduler, SchedulerTranslations, ShiftTimeCalendarProps } from './ShiftTimeCalendar.type';
import { Wrapper } from 'helpers/wrapper';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DefaultRequestPropsType, PatchPutByIdType } from 'helpers/api/type';
import { Success, Warning } from 'components/Popup/Popup';
import { useTranslation } from 'react-i18next';
import { ProcessedEvent } from '@aldabil/react-scheduler/types';
import TeamShift from 'api/TeamShift';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { TeamShiftModel, TeamShiftPayload } from 'models/TeamShift.type';
import type { SchedulerRef } from '@aldabil/react-scheduler/types';
import { DateTime } from 'luxon';
import { TimeRange } from '../ShiftTimeCalendarAction/ShiftTimeCalendarAction.type';
import useFreeShiftChecker from './FreeShiftChecker';
import { toCamelCase_H } from 'helpers';

const teamShiftApi = new TeamShift();

const ShiftTimeCalendar: React.FC<ShiftTimeCalendarProps> = (): JSX.Element => {
    const [loadingScheduler, setLoadingScheduler] = useState(true);
    const { t: translate } = useTranslation();
    const { userFormatUTC, fromUTCtoUserTimezone } = useConverter();
    const calendarRef = useRef<SchedulerRef>(null);
    const [shifts, setShifts] = useState<EventsScheduler[]>([]);
    const { isRangeFreeTime } = useFreeShiftChecker();

    const schedulerTranslations: SchedulerTranslations = {
        navigation: {
            month: translate('t.month'),
            week: translate('t.week'),
            day: translate('t.day'),
            today: translate('t.today'),
            agenda: translate('t.agenda')
        },
        moreEvents: `${translate('t.more')}...`,
        noDataToDisplay: translate('t.there_no_data'),
        loading: `${translate('t.loading')}...`
    };

    const {
        data: teamShiftData,
        isLoading: teamShiftLoading,
        refetch,
        isFetching
    } = useQuery(['GetTeamShiftList'], () => teamShiftApi.get({}), {
        refetchOnWindowFocus: false,
        onSuccess: (dataOnSuccess: { teamShift: TeamShiftModel[] }) => {
            dataOnSuccess?.teamShift && setShifts(parserEvents(dataOnSuccess?.teamShift));
            setLoadingScheduler(false);
        }
    });

    const { mutate: removeTeamShift } = useMutation(teamShiftApi.removeTeamShift, {
        onSuccess: () => {
            Success({
                text: translate('t.removed_shift')
            });
            refetch();
        }
    });

    const { mutate: addTeamShift } = useMutation(teamShiftApi.addTeamShift, {
        onSuccess: (data) => {
            if (data) {
                Success({
                    text: `${translate('t.shift')} ${translate('t.has_been_cloned')}`
                });
                calendarRef.current && calendarRef.current.scheduler.triggerLoading(false);
                refetch();
            }
        },
        onError: (e) => {
            console.error(e);
        }
    });

    const { mutate: updateTeamShift } = useMutation<
        DefaultRequestPropsType,
        unknown,
        PatchPutByIdType<TeamShiftPayload, DefaultRequestPropsType>
    >(teamShiftApi.patchById, {
        onSuccess: () => {
            Success({ text: `${translate('t.shift')} ${translate('p.has_been_edited')}` });
            refetch();
        }
    });

    const handleEventDrop = async (
        event: DragEvent<HTMLButtonElement>,
        droppedOn: Date,
        updatedEvent: ProcessedEvent,
        originalEvent: ProcessedEvent
    ): Promise<ProcessedEvent | void> => {
        const dateCurrent: DateTime = DateTime.now().startOf('day');
        const dateFullFrom = updatedEvent.start.toISOString();
        const dateFullTo = updatedEvent.end.toISOString();

        if (dateFullFrom && dateCurrent.diff(DateTime.fromISO(dateFullFrom).setZone('utc')).as('day') >= 1) {
            Warning({ text: translate('p.date_before_today') });
            return new Promise((resolve) => {
                resolve();
            });
        } else {
            const dateFromUTCOrigin = DateTime.fromISO(originalEvent.shiftTime.startTime).setZone('utc');
            const dateToUTCOrigin = DateTime.fromISO(originalEvent.shiftTime.endTime).setZone('utc');
            const timeFrom = dateFromUTCOrigin.toFormat('HH:mm:ss');
            const timeTo = dateToUTCOrigin.toFormat('HH:mm:ss');
            const dateFrom = dateFullFrom.slice(0, 10);
            const dateTo = dateFullTo.slice(0, 10);
            const timeRange: TimeRange = { startTime: timeFrom, endTime: timeTo };
            const getIsRangeFreeTime = await isRangeFreeTime(dateFrom, updatedEvent?.event_id as number, timeRange);

            if (!getIsRangeFreeTime) {
                const message: string = translate('p.there_is_no_space_shift', {
                    date: dateFrom,
                    time: `${fromUTCtoUserTimezone({
                        date: originalEvent.shiftTime?.startTime,
                        format: 'time'
                    })} - ${fromUTCtoUserTimezone({ date: originalEvent.shiftTime?.endTime, format: 'time' })}`
                });
                Warning({ text: message, customSetting: { duration: 7000 } });
                return new Promise((resolve) => {
                    resolve();
                });
            }

            const newValues: TeamShiftPayload = {
                shiftTime: updatedEvent.shiftTime?.id,
                startDate: dateFrom,
                endDate: dateTo
            };

            return new Promise((resolve, reject) => {
                updateTeamShift(
                    { id: Number(updatedEvent.event_id), data: newValues },
                    {
                        onSuccess: () => {
                            resolve();
                        },
                        onError: (error) => {
                            reject(error);
                        }
                    }
                );
            });
        }
    };

    const handleClone = useCallback(async (event: EventsScheduler): Promise<void> => {
        const dateCurrent: DateTime = DateTime.now().startOf('day');
        const dateFrom: DateTime = fromUTCtoUserTimezone({
            date: event?.fullStartDateTime,
            format: 'dateTime',
            returnObjectLuxon: true
        });
        const shiftDate: string = dateFrom.plus({ days: 1 }).toFormat('yyyy-MM-dd');

        if (event?.shiftDate && dateCurrent.diff(DateTime.fromISO(event?.shiftDate).setZone('utc')).as('day') >= 1) {
            Warning({ text: translate('p.date_before_today') });
        } else {
            const newValues: TeamShiftPayload = {
                shiftTime: event.shiftTime?.id,
                startDate: shiftDate,
                endDate: shiftDate
            };

            const startTime: string = DateTime.fromISO(event.shiftTime?.startTime, { zone: 'utc' }).toFormat(
                'HH:mm:ss'
            );
            const endTime: string = DateTime.fromISO(event.shiftTime?.endTime, { zone: 'utc' }).toFormat('HH:mm:ss');
            const timeRange: TimeRange = { startTime, endTime };
            const getIsRangeFreeTime = await isRangeFreeTime(shiftDate, event?.event_id as number, timeRange);

            if (calendarRef.current && getIsRangeFreeTime) {
                calendarRef.current.scheduler.triggerLoading(true);

                addTeamShift(newValues, {
                    onError: (error) => {
                        if (calendarRef.current) {
                            calendarRef.current.scheduler.triggerLoading(false);
                        }
                        console.warn(error);
                    }
                });
            } else {
                const message: string = translate('p.there_is_no_space_shift', {
                    date: shiftDate,
                    time: `${fromUTCtoUserTimezone({
                        date: event.shiftTime?.startTime,
                        format: 'time'
                    })} - ${fromUTCtoUserTimezone({
                        date: event.shiftTime?.endTime,
                        format: 'time'
                    })}`
                });
                Warning({ text: message, customSetting: { duration: 7000 } });
            }
        }
    }, []);

    const handleDelete = async (deletedId): Promise<string | number | void> => {
        return new Promise((resolve, reject) => {
            removeTeamShift(deletedId, {
                onSuccess: () => {
                    resolve();
                },
                onError: (error) => {
                    reject(error);
                }
            });
        });
    };

    const parserEvents = (shifts: TeamShiftModel[]): EventsScheduler[] => {
        return shifts
            .filter((shift) => !shift.isSlave)
            .map((shift) => {
                return {
                    event_id: shift.id,
                    title: toCamelCase_H(shift?.shiftTime?.teamShiftName?.name ?? ''),
                    start: new Date(
                        fromUTCtoUserTimezone({
                            date: shift?.startDateTime,
                            format: 'dateTime',
                            displaySeconds: true
                        })
                    ),
                    end: new Date(
                        fromUTCtoUserTimezone({
                            date: shift?.endDateTime,
                            format: 'dateTime',
                            displaySeconds: true
                        })
                    ),
                    team: shift?.shiftTime?.teamShiftName ?? '',
                    color: '#00647a',
                    shiftTime: shift?.shiftTime ?? '',
                    shiftDate: shift?.startDateTime,
                    fullStartDateTime: fromUTCtoUserTimezone({
                        date: shift?.startDateTime,
                        format: 'dateTime',
                        displaySeconds: true
                    })
                };
            });
    };

    useEffect(() => {
        if (isFetching) {
            calendarRef.current && calendarRef.current.scheduler.triggerLoading(true);
        } else {
            calendarRef.current && calendarRef.current.scheduler.triggerLoading(false);
        }
    }, [isFetching]);

    return (
        <ShiftTimeCalendarContent
            data-testid='ShiftTimeCalendar-testid'
            loading={teamShiftLoading || loadingScheduler}
            shifts={shifts}
            handleClone={handleClone}
            handleDelete={handleDelete}
            schedulerTranslations={schedulerTranslations}
            calendarRef={calendarRef}
            handleEventDrop={handleEventDrop}
            refetch={refetch}
        />
    );
};

export default Wrapper(ShiftTimeCalendar);
