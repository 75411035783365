import { Status } from './SensorInfo.type';
import { Sensor } from './Sensors.type';
import { Paginator as GenericPaginator } from 'helpers/api/type';
import Wheel from './Wheel.type';

export type SensorHistory = {
    action: string;
    status: `${Status}`;
    createdAt: string;
    sensor: Sensor | null;
    wheel: Wheel | null;
};

export type SensorHistoryWithPaginator = GenericPaginator<SensorHistory>;

export enum SensorHistoryQueryKeys {
    getLazyDropdown = 'sensorHistory-dropdown',
    exportSensorWheelHistoryByIdSensors = 'sensorHistory-exportSensorWheelHistoryByIdSensors'
}
