import React from 'react';
import * as type from './UiCheckbox.type';
import * as view from './UiCheckbox.view';
// import module

const UiCheckbox: React.FC<type.UiCheckboxProps> = ({ testid, ...newProps }) => {
    return (
        <view.UiCheckboxContent
            {...newProps}
            skinlabel={newProps.skinlabel ? 1 : 0}
            data-testid={testid || 'UiCheckbox-testid'}
        />
    );
};

export default UiCheckbox;
