import { Paginator } from './Paginator.type';

export type Translation = {
    id?: number;
    locale: string;
    column: string;
    content: string;
};

export type SystemNotificationTableModel = {
    items: MessageTable[];
    paginator: Paginator;
};

export type MessageTable = {
    id: number;
    dateFrom: string;
    dateTo: string;
    active: boolean;
    allCustomers: boolean;
    message: string;
    translation: Translation[];
    helpjuiceId?: number | null;
    knowledgebaseUrl?: string;
};

type CustomerProps = {
    customer: {
        id: number;
    }[];
};

export type SystemNotificationModel = {
    systemNotification: MessageTable & CustomerProps;
};

export enum SystemNotificationQueryKeys {
    get = 'systemNotification-get',
    getByCriteria = 'systemNotification-getByCriteria'
}
