import PortalApiClient from 'helpers/api/PortalApiClient';
import { getParams } from 'helpers';
import {
    BatteryStatsResponse,
    DevicesList,
    HubAdd,
    HubAdministrationList,
    HubAdministrationResponse,
    WifiCredentialsResponse,
    HubAdministrationModelResponse,
    HubHistoryPlacementResponse
} from '../models/HubAdministration.type';
import { AllHubsListResponse, DeviceStatistics, DeviceTable, DeviceType, DeviceModel } from '../models/Device.type';
import { DefaultRequestPropsType, Paginator } from 'helpers/api/type';

export default class Device extends PortalApiClient<DeviceModel> {
    route = 'device';

    getBatteryStats = async (props): Promise<BatteryStatsResponse> => {
        return await this.get({
            extendUrl: `${props.deviceId}/battery-voltage/${props.dateTimeStart}/${props.dateTimeEnd}`
        });
    };

    getAvailableDevices = async (): Promise<DeviceType> => {
        return await this.get({ extendUrl: 'available' });
    };

    getAdministrationHubTable = async (props): Promise<HubAdministrationResponse> => {
        return await this.getTable({ criteria: getParams(props) });
    };

    unassignAdministrationHub = async (id: number): Promise<HubAdministrationList | unknown> => {
        return await this.post({ extendUrl: `${id}/unassign`, data: id });
    };

    addAdministrationHub = async (data: HubAdd): Promise<HubAdministrationList | unknown> => {
        return await this.post({
            extendUrl: `${data.id}/assign`,
            data: { uuid: data.macAddress }
        });
    };

    getHistoryPlacementHubTable = async (props, additionalProps): Promise<HubHistoryPlacementResponse> => {
        return await this.getTable({
            criteria: getParams(props),
            extendUrl: `${additionalProps.deviceId}/history-placement`,
            version: 'v2'
        });
    };

    getUserById = async (userId: number): Promise<HubAdministrationModelResponse> => {
        return await this.get({ extendUrl: `${userId}` });
    };

    getDevicesList = async (): Promise<DevicesList> => {
        return await this.get({ extendUrl: 'available/codebook' });
    };

    getExport = async (props): Promise<string> => {
        return this.getByCriteria({
            extendUrl: 'export',
            criteria: getParams(props)
        });
    };

    getExportAll = async (props): Promise<string> => {
        return this.getByCriteria({
            extendUrl: 'all/export',
            criteria: getParams(props)
        });
    };

    getDetails = async (userId): Promise<HubAdministrationModelResponse> => {
        return await this.get({ extendUrl: userId });
    };

    getStatistics = async (id): Promise<DeviceStatistics> => {
        return await this.get({ extendUrl: `${id}/statistics` });
    };

    patchComment = async (props): Promise<Object> => {
        return await this.patchById({ id: props.id, data: props.comment });
    };

    getWifiCredentials = async (props): Promise<WifiCredentialsResponse> => {
        return await this.get({ extendUrl: `${props.id}/wifi-credentials` });
    };

    getNewHubsTable = async (props): Promise<AllHubsListResponse> => {
        return await this.getTable({ extendUrl: 'new-hubs', criteria: { ...getParams(props), hasUuid: 1 } });
    };

    getLazyLoadingDropdown = async <T extends unknown>(
        props: DefaultRequestPropsType
    ): Promise<Paginator<T, DeviceModel>> => {
        if (props.criteria) props.criteria.idString = props.criteria?.id;
        delete props.criteria?.id;

        return await this.getTable(props);
    };

    getGlobalHubs = async (props): Promise<Paginator<DeviceTable, unknown>> => {
        return await this.getTable({ extendUrl: 'all', criteria: { ...getParams(props) } });
    };

    sendGlobalHubCommand = async (props): Promise<unknown> => {
        const criteria = props.criteria;
        criteria?.page && delete criteria?.page;
        criteria?.limit && delete criteria?.limit;
        props.criteria && delete props.criteria;

        const data = props.value
            ? { commandName: props.name, value: props.value, deviceIds: props.deviceIds }
            : { commandName: props.name, deviceIds: props.deviceIds };

        return await this.post({ extendUrl: 'all/send-command-6', data, criteria });
    };

    getDeviceById = async (deviceId: number): Promise<DeviceModel> => {
        return this.getById({ id: deviceId });
    };

    patchAppRegion = async (props): Promise<DeviceModel> => {
        return await this.patchById({ id: props.id, data: props.data, extendUrl: 'app-region' });
    };

    patchDeviceStatus = async (props): Promise<DeviceModel> => {
        return await this.patchById({ id: props.id, data: props.data, extendUrl: 'status' });
    };

    unassignAll = async (props): Promise<Object> => {
        return await this.post({ extendUrl: `${props.id}/unassign-all` });
    };

    reloadHubs = async (): Promise<Object> => {
        return await this.post({ extendUrl: `validate-assigned` });
    };

    reuseHub = async (deviceId: number): Promise<DeviceModel> => {
        return await this.patch({
            extendUrl: `recycle-uuid/${deviceId}`
        });
    };
}
