import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { applyStyleByMode } from 'helpers';
import { Alert, Box, useTheme } from '@mui/material';
import { WHITE } from 'components/Ui/colors';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

export const MapContent = styled.div<{ ref }>`
    height: 100%;
    width: 100%;
    position: relative;
    .panel .layer {
        display: flex;
        justify-content: left;
        align-items: center;
    }
    .panel {
        height: auto !important;
        ${() => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                light: `background-color: ${useTheme().palette.background.paper};`,
                dark: `background-color: ${useTheme().palette.background.paper};`
            });
        }}
    }
    .layer-switcher button {
        opacity: 0.9 !important;
        background-color: rgba(0, 60, 136, 0.5) !important;
    }

    .layer-switcher {
        top: 0.5em;
    }

    .start-draw {
        top: 4em;
        left: 0.5em;
    }

    .end-draw {
        top: 6em;
        left: 0.5em;
    }

    .ol-control button {
        cursor: pointer;
    }

    .ol-popup {
        position: absolute;
        ${() => {
            const theme = useTheme();
            return applyStyleByMode({
                theme: theme.palette.mode,
                light: `background-color: ${theme.palette.background.paper};`,
                dark: `background-color: ${theme.palette.background.paper};`
            });
        }}
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
        padding: 15px;
        border-radius: 10px;
        border: 1px solid #cccccc;
        bottom: 12px;
        left: -50px;
        min-width: 280px;
    }
    .ol-popup:after,
    .ol-popup:before {
        top: 100%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    .ol-popup:after {
        border-top-color: white;
        border-width: 10px;
        left: 48px;
        margin-left: -10px;
    }
    .ol-popup:before {
        border-top-color: #cccccc;
        border-width: 11px;
        left: 48px;
        margin-left: -11px;
    }
    .ol-popup-closer {
        text-decoration: none;
        position: absolute;
        top: 2px;
        right: 8px;
    }
    .ol-popup-closer:after {
        content: '✖';
    }
`;

export const Loader = styled.div`
    position: absolute;
    background: rgba(255, 255, 255, 0.4);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
    display: flex;
    z-index: 1299;
    justify-content: center;
    align-items: center;
`;

export const HeatmapLoader = styled(CircularProgress)`
    height: 40px !important;
    width: 40px !important;
`;

export const AlertMsg = styled(Alert)`
    &.MuiAlert-root {
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        border-radius: 0;
    }
`;

export const CloseMsgBtn = styled(UiIconButton)`
    &.MuiIconButton-root {
        position: absolute;
        right: 1em;
        top: 10px;
    }
`;

export const InfoPopup = styled(Box)`
    position: absolute;
    display: inline-block;
    height: auto;
    width: auto;
    z-index: 100;
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: `background-color: ${useTheme().palette.background.paper};`,
            dark: `background-color: ${useTheme().palette.background.paper};`
        });
    }};
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: `color: ${useTheme().palette.text.primary};`,
            dark: `color: ${WHITE};`
        });
    }};
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    left: 50%;
    transform: translateX(3%);
    visibility: hidden;
    pointer-events: none;
`;
