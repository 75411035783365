import React, { useEffect, useState } from 'react';
import { CustomerTableContent } from './CustomerTable.view';
import { CustomerTableProps } from './CustomerTable.type';
import { Wrapper } from 'helpers/wrapper';
import CustomerApi from 'api/Customer';
import { useMutation, useQuery } from '@tanstack/react-query';
import { UserInfo } from 'states/global/User';
import { useRecoilValue } from 'recoil';
import { Success } from 'components/Popup/Popup';
import { useTranslation } from 'react-i18next';
import { CustomerForm } from 'models/Customer.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { CustomerQueryKeys } from 'models/Customer.type';

const Customer = new CustomerApi();

const CustomerTable: React.FC<CustomerTableProps> = (): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();
    const [enableRequest, setEnableRequest] = useState<boolean>(false);

    const {
        data: CustomerData,
        isLoading,
        refetch
    } = useQuery(
        [CustomerQueryKeys.getById, userInfo.user?.customer.id],
        () => Customer.getById({ id: userInfo.user?.customer.id || 0, apiProject: '' }),
        {
            enabled: enableRequest,
            staleTime: cacheTimeToMilliseconds(30, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(30, 'minutes')
        }
    );

    const { mutate: editCustomerMutate, isLoading: editCustomerLoading } = useMutation(Customer.editCustomer, {
        onSuccess: () => {
            Success({
                text: translate('t.edited_customer')
            });
            refetch();
        }
    });

    useEffect(() => {
        if (userInfo?.user && Object.keys(userInfo.user).length) {
            setEnableRequest(true);
        }
    }, [userInfo]);

    return (
        <CustomerTableContent
            customerData={CustomerData as CustomerForm}
            data-testid='CustomerTable-testid'
            editCustomerMutate={editCustomerMutate}
            editCustomerLoading={editCustomerLoading}
            isLoading={isLoading}
        />
    );
};

export default Wrapper(CustomerTable);
