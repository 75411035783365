import React, { useState, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
    MainDetailsContainer,
    MainDetailsView,
    TableCustom,
    CustomTableHead,
    LoadingContainer
} from './MainDetails.style';
import { MainDetailsProps } from './MainDetails.type';
import { useTranslation } from 'react-i18next';
import { Wrapper } from 'helpers/wrapper';
import Tyre from 'api/Tyre';
import { TyreLifeDetail, TyreQueryKeys } from 'models/Tyre.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { cacheTimeToMilliseconds, getCache, isCacheAvailable } from 'helpers/cache';

const tyreApi = new Tyre();
export const MainDetailsContent: React.FC<MainDetailsProps> = ({ tyreId }): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromUTCtoUserTimezone, fromServerToUserUnit, convertType } = useConverter();
    const [tyreLifeDetails, setTyreLifeDetails] = useState<TyreLifeDetail>();
    const queryCache = useQueryClient().getQueryCache();

    const { isLoading, isFetching, refetch } = useQuery(
        [TyreQueryKeys.getTyreLifeDetail, tyreId],
        () => tyreApi.getTyreLifeDetail(tyreId),
        {
            enabled: false,
            retry: false,
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(15, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(15, 'minutes'),
            onSuccess: (result) => checkData(result),
            onError: () => setTyreLifeDetails(undefined)
        }
    );

    const checkData = (data) => {
        data.tyreLifeDetail && setTyreLifeDetails(data.tyreLifeDetail);
    };

    useEffect(() => {
        if (!isNaN(tyreId)) {
            !isCacheAvailable([TyreQueryKeys.getTyreLifeDetail, tyreId], queryCache)
                ? refetch()
                : checkData(getCache([TyreQueryKeys.getTyreLifeDetail, tyreId], queryCache)[0].state.data);
        }
    }, [tyreId]);

    const createHeader = (): JSX.Element => (
        <>
            <MainDetailsContainer data-testid='MainDetailsContent-MainDetailsContainer-1'>
                <tr>
                    <th>{translate('t.tyre')}:</th>
                    <th>{tyreLifeDetails?.tyre.tyreSerialNumber ?? '-'}</th>
                </tr>
                <tr>
                    <th>{translate('t.born')}:</th>
                    <th>
                        {fromUTCtoUserTimezone({
                            date: tyreLifeDetails?.born ?? '',
                            format: 'dateTime'
                        })}
                    </th>
                </tr>
                <tr>
                    <th>{translate('t.disposed')}:</th>
                    <th>
                        {fromUTCtoUserTimezone({
                            date: tyreLifeDetails?.disposed ?? '',
                            format: 'dateTime'
                        })}
                    </th>
                </tr>
            </MainDetailsContainer>
            <MainDetailsContainer data-testid='MainDetailsContent-MainDetailsContainer-2'>
                <tr>
                    <th>{translate('t.operating_hours')}:</th>
                    <th>
                        {tyreLifeDetails?.operating ? (
                            <>
                                {`${
                                    tyreLifeDetails?.operating?.years
                                        ? `${Math.floor(tyreLifeDetails?.operating?.years)} ${translate('t.years')},`
                                        : tyreLifeDetails?.operating?.months
                                        ? ` ${Math.floor(tyreLifeDetails?.operating?.months)} ${translate('t.months')},`
                                        : tyreLifeDetails?.operating?.days
                                        ? ` ${Math.floor(tyreLifeDetails?.operating?.days)} ${translate('t.days')},`
                                        : tyreLifeDetails?.operating?.hours
                                        ? ` ${Math.floor(tyreLifeDetails?.operating?.hours)} ${translate('t.hours')},`
                                        : tyreLifeDetails?.operating?.minutes
                                        ? ` ${Math.floor(tyreLifeDetails?.operating?.minutes)} ${translate(
                                              't.minutes'
                                          )},`
                                        : '-,'
                                }`.slice(0, -1)}
                            </>
                        ) : (
                            '-'
                        )}
                    </th>
                </tr>
                <tr>
                    <th>{translate('t.driven_distance')}:</th>
                    <th>
                        {fromServerToUserUnit({
                            type: convertType.distance,
                            value: tyreLifeDetails?.drivenDistance
                                ? (tyreLifeDetails?.drivenDistance as number) / 1000
                                : undefined,
                            displayUnits: true,
                            fixed: 1,
                            displayIfEmpty: '-'
                        }) ?? '-'}
                    </th>
                </tr>
                <tr>
                    <th>{translate('t.days_in_service')}:</th>
                    <th>
                        {tyreLifeDetails?.bornDisposeDiff ? (
                            <>
                                {`
                            ${
                                tyreLifeDetails?.bornDisposeDiff?.years
                                    ? ` ${Math.floor(tyreLifeDetails?.bornDisposeDiff?.years)} ${translate('t.years')},`
                                    : ''
                            }${
                                    tyreLifeDetails?.bornDisposeDiff?.months
                                        ? ` ${Math.floor(tyreLifeDetails?.bornDisposeDiff?.months)} ${translate(
                                              't.months'
                                          )},`
                                        : ''
                                }${
                                    tyreLifeDetails?.bornDisposeDiff?.days
                                        ? ` ${Math.floor(tyreLifeDetails?.bornDisposeDiff?.days)} ${translate(
                                              't.days'
                                          )},`
                                        : ''
                                }${
                                    tyreLifeDetails?.bornDisposeDiff?.hours
                                        ? ` ${Math.floor(tyreLifeDetails?.bornDisposeDiff?.hours)} ${translate(
                                              't.hours'
                                          )},`
                                        : ''
                                }${
                                    tyreLifeDetails?.bornDisposeDiff?.minutes
                                        ? ` ${Math.floor(tyreLifeDetails?.bornDisposeDiff?.minutes)} ${translate(
                                              't.minutes'
                                          )},`
                                        : ''
                                }`.slice(0, -1)}
                            </>
                        ) : (
                            '-'
                        )}
                    </th>
                </tr>
            </MainDetailsContainer>
            <MainDetailsContainer data-testid='MainDetailsContent-MainDetailsContainer-3'>
                <tr>
                    <th>{translate('t.avg_KM_per_day')}:</th>
                    <th>
                        {fromServerToUserUnit({
                            type: convertType.distance,
                            value: tyreLifeDetails?.averageDistancePerDay
                                ? (tyreLifeDetails?.averageDistancePerDay as number) / 1000
                                : undefined,
                            displayUnits: true,
                            fixed: 1,
                            displayIfEmpty: '-'
                        }) ?? '-'}
                    </th>
                </tr>
                <tr>
                    <th>{translate('t.avg_operating_hours_per_day')}:</th>
                    <th>
                        {tyreLifeDetails?.averageOperatingHoursPerDay
                            ? `${tyreLifeDetails?.averageOperatingHoursPerDay?.toFixed(3)} ${translate('t.hrs')}`
                            : '-'}
                    </th>
                </tr>
            </MainDetailsContainer>
        </>
    );

    return (
        <MainDetailsView data-testid='MainDetailsViewContent'>
            {(isLoading || isFetching) && !isNaN(tyreId) ? (
                <LoadingContainer>
                    <UiLoadingPage />
                    {/*<Tooltip title={translate('p.main_details_info')}>
                        <InfoIcon fontSize='small' />
                    </Tooltip>*/}
                </LoadingContainer>
            ) : (
                <TableCustom data-testid='MainDetailsContent-TableCustom'>
                    <CustomTableHead> {createHeader()}</CustomTableHead>
                </TableCustom>
            )}
        </MainDetailsView>
    );
};

export default Wrapper(MainDetailsContent);
