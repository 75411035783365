import { CustomerCodebook } from 'models/Customer.type';
import { Paginator } from './Paginator.type';

export type CustomerReportTableResponse = {
    items: CustomerReportModel[];
    paginator: Paginator;
};

export type CustomerReportModel = {
    customer: Omit<CustomerCodebook, 'region'>;
    noGps: boolean;
    noDiskSpace: boolean;
    tunnelServerDiscrepancy: boolean;
    cpuReport: boolean;
    nonTransmittingSensors: boolean;
    minimumActiveDeviceTrigger: number;
};

export enum CustomerReportQueryKeys {
    getById = 'customerReport-getById'
}
