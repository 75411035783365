import styled from 'styled-components';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box } from '@mui/material';

export const UiTableFilterColumnsBtnViewContent = styled(Box)({
    display: 'inline-block'
});

export const FilterIcon = styled(FilterListIcon)({
    fontSize: 16
});
