import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { UiTableHideColumnsContentView } from './UiTableHideColumnsContent.style';
import { UiTableHideColumnsContentProps } from './UiTableHideColumnsContent.type';
import { ColumnNamesIds } from '../UiTable/TableAtom';
import UiTableHideColumnsCheckbox from '../UiTableHideColumnsCheckbox/UiTableHideColumnsCheckbox';

const UiTableHideColumnsContent: React.FC<UiTableHideColumnsContentProps> = (props): JSX.Element => {
    const columnNamesIds = useRecoilValue(ColumnNamesIds(`${props.queryKey}-ColumnNamesIds`));

    const Checkboxes = useMemo(() => {
        return (
            <UiTableHideColumnsContentView data-testid={`HideColumnsViewContent-${props.queryKey}Table`}>
                {columnNamesIds
                    ?.filter((column) => !!column.name && column.id !== 'expander')
                    .map((column, index) => (
                        <UiTableHideColumnsCheckbox
                            queryKey={props.queryKey}
                            columnId={column.id}
                            label={column.name}
                            key={index}
                        />
                    ))}
            </UiTableHideColumnsContentView>
        );
    }, [columnNamesIds]);

    return Checkboxes;
};

export default UiTableHideColumnsContent;
