import React, { useEffect, useState } from 'react';
import { AddSensorContent } from './AddSensor.view';
import { AddSensorProps, InitialValues } from './AddSensor.type';
import { Wrapper } from 'helpers/wrapper';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Vehicle from 'api/Vehicle';
import { VehicleList, VehicleQueryKeys } from 'models/Vehicle.type';
import { optionsCodeBook } from 'components/Ui/Components/UiDropDown/UiDropDown.type';
import SensorType from 'api/SensorType';
import SensorMovement from 'api/SensorMovement';
import { cacheTimeToMilliseconds, getCache, isCacheAvailable } from 'helpers/cache';
import { SensorTypeQueryKeys } from 'models/SensorType.type';
import { SensorMovementQueryKeys } from 'models/SensorMovement.type';

const vehicleApi = new Vehicle();
const sensorTypeApi = new SensorType();
const sensorMovementApi = new SensorMovement();

const AddSensor: React.FC<AddSensorProps> = (props): JSX.Element => {
    const [fittingReasonParsed, setFittingReasonParsed] = useState<optionsCodeBook[]>();
    const queryCache = useQueryClient().getQueryCache();
    const initialValues: InitialValues = {
        fittingReason: '',
        sensorId: null,
        sensorTypeId: null,
        wheelId: null,
        vehicle: undefined
    };

    const parseData = (data): optionsCodeBook[] => {
        return data.codeBook.reduce((acc: optionsCodeBook[], curr) => {
            acc.push({ id: curr, name: curr });
            return acc;
        }, []);
    };

    const { data: sensorTypeListData, isLoading: sensorTypeListLoading } = useQuery<optionsCodeBook[]>(
        [SensorTypeQueryKeys.getSensorsTypeCodebook],
        sensorTypeApi.getSensorsTypeCodebook,
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours')
        }
    );

    const { isLoading: fittingreasonLoading } = useQuery(
        [SensorMovementQueryKeys.getSensorsFittingReason],
        sensorMovementApi.getSensorsFittingReason,
        {
            refetchOnWindowFocus: false,
            onSuccess: (dataOnSuccess) => setFittingReasonParsed(parseData(dataOnSuccess)),
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours')
        }
    );

    const handleSubmit = (values: InitialValues) => {
        if (values.wheelId) {
            props.addSensorMutate({ ...values, sensorId: props.sensorId });
        }
    };

    const { data: vehicleDataXHR, isLoading: vehicleLoading } = useQuery<VehicleList, Error>(
        [VehicleQueryKeys.getList],
        () => vehicleApi.getList(),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(55, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(60, 'minutes')
        }
    );

    useEffect(() => {
        isCacheAvailable([SensorMovementQueryKeys.getSensorsFittingReason], queryCache) &&
            setFittingReasonParsed(
                parseData(getCache([SensorMovementQueryKeys.getSensorsFittingReason], queryCache)[0]?.state?.data)
            );
    }, []);

    return (
        <AddSensorContent
            data-testid='AddSensor-testid'
            vehicles={vehicleDataXHR ?? []}
            vehiclesLoading={vehicleLoading}
            sensorType={sensorTypeListData ?? []}
            fittingReasonList={fittingReasonParsed ?? []}
            sensorTypeLoading={sensorTypeListLoading}
            fittingReasonLoading={fittingreasonLoading}
            initialValues={initialValues}
            refForAddSensor={props.refForAddSensor}
            handleSubmit={handleSubmit}
        />
    );
};

export default Wrapper(AddSensor);
