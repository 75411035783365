import styled from 'styled-components';
import { Alert, Box, IconButton } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { useTheme } from '@mui/material';
import { ThemeMode } from '../../states/global/Theme';

export const SystemNotificationMessageContainer = styled.div<{ hide: boolean }>`
    ${(props) => props.hide && 'display: none'};
    margin-bottom: ${applyStyleByMode({ styleJade: '7px' })};
    font-weight: ${applyStyleByMode({ styleJade: '100' })};
`;

export const WarningMessage = styled(Alert)`
    &.MuiAlert-standardWarning {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 3px 7px;
        min-height: 30px;
        font-weight: ${applyStyleByMode({ styleJade: '100' })};
        font-size: ${applyStyleByMode({ styleJade: '0.86rem', styleOld: '1.05rem' })};
        margin-bottom: 1px;
        box-shadow: 0 1px 2px #cecece;
    }
    & .MuiAlert-message {
        padding: 0;
        width: calc(100% - 50px);
    }
    & .MuiAlert-icon {
        padding: 0;
    }
`;

export const WarningMessageCover = styled(Box)`
    font-weight: ${applyStyleByMode({ styleJade: '100' })};
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
`;

export const CloseButton = styled.div`
    position: absolute;
    right: 30px;
`;

export const WhatsNewAnnoucement = styled(Box)`
    position: absolute;
    width: 300px;
    height: 100px;
    right: 1em;
    z-index: 10;
    border-radius: 4px;
    padding: 15px;
    cursor: pointer;
    ${() => {
        const theme = useTheme();
        return `background: ${
            theme.palette.mode === ThemeMode.light
                ? 'linear-gradient(135deg, #bdd3ff, #5991fc)'
                : 'linear-gradient(135deg, rgb(47 103 214), rgb(0 51 150))'
        }`;
    }}
`;

export const Stars = styled('span')`
    ${
        /*@keyframes ColorUpdate {
        0% {color: inherit;}
        50% {color: transparent}
        100% {color: inherit;}
    }

    animation-name: ColorUpdate;
    animation-duration: 2s;
    animation-iteration-count: infinite;*/ ''
    }
`;

export const WhatsNewCloseBtn = styled(IconButton)`
    &.MuiButtonBase-root {
        position: absolute;
        top: 0.6em;
        right: 0.5em;
    }
`;
