import React from 'react';
import { VehicleGroupProps } from './VehicleGroup.type';
import { useQuery } from '@tanstack/react-query';
import VehicleTypeApi from 'api/VehicleType';
import { VehicleTypesCodeBook, VehicleTypeQueryKeys } from 'models/VehicleType.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { UserInfo } from 'states/global/User';
import { useRecoilValue } from 'recoil';

const vehicleTypeAPI = new VehicleTypeApi();

const GetVehicleGroupCodebook: React.FC<VehicleGroupProps> = ({ children }): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const useQueryProperties = useQuery<VehicleTypesCodeBook, Error>(
        [VehicleTypeQueryKeys.getCodebook, userInfo.user?.customer.id],
        () => vehicleTypeAPI.getCodebook({ version: 'v2' }),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            staleTime: cacheTimeToMilliseconds(55, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(60, 'minutes')
        }
    );

    return <>{children(useQueryProperties)}</>;
};

export default GetVehicleGroupCodebook;
