import React from 'react';
import { JobsTableContainer } from './JobsTable.style';
import { JobsTableViewProps } from './JobsTable.type';
import UiSimpleTable from 'components/Ui/Components/UiSimpleTable/UiSimpleTable';
import DeviceUpdateJob from 'api/DeviceUpdateJob';

const deviceUpdateJobApi = new DeviceUpdateJob();

export const JobsTableContent: React.FC<JobsTableViewProps> = ({ batchId, columns }): JSX.Element => {
    return (
        <JobsTableContainer data-testid={`JobsTableContent-${batchId}`}>
            <UiSimpleTable
                isTableOnModal
                columns={columns}
                fetchFn={deviceUpdateJobApi.getJobTableSimple}
                additionalFetchProps={{ deviceUpdateBatchId: batchId }}
                queryKey={`jobsTable-${batchId}`}
                refreshButton
                style={{ backgroundColor: 'red !important' }}
            />
        </JobsTableContainer>
    );
};
