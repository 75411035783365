import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import SensorFaultyReportVerifiedStatusApi from 'api/SensorFaultyReportVerifiedStatus';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { FaultySensorReporterState } from 'states/component/FaultySensorReporter';
import { useTranslation } from 'react-i18next';
import useConverter from '../CustomHooks/Converter/Converter';
import { applyStyleByMode } from 'helpers';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { TextField, OutlinedInput, InputLabel, IconButton } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FaultySensorReportsContent } from './FaultySensorReports.view';
import { SensorFaultyReportVerifiedStatusCodebook } from 'models/SensorFaultyReportVerifiedStatus.type';
import { cacheTimeToMilliseconds, getCache, isCacheAvailable } from 'helpers/cache';
import { SensorFaultyReportVerifiedStatusQueryKeys } from 'models/SensorFaultyReportVerifiedStatus.type';
import { Column } from 'components/Ui/UiTable/UiTable.type';
import { TableItem } from 'models/SensorFaultyReport.type';
import { Wrapper } from 'helpers/wrapper';

const SensorFaultyReportVerifiedStatus = new SensorFaultyReportVerifiedStatusApi();
import UiDatePickerFilter from 'components/Ui/Components/UiDatePickerFilter/UiDatePickerFilter';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';
import useTableFilter from 'components/CustomHooks/TableFilterState/TableFilterState';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

const FaultySensorReports: React.FC = (): JSX.Element => {
    const queryCache = useQueryClient().getQueryCache();
    const [verifiedStatusData, setVerifiedStatusData] = useState<SensorFaultyReportVerifiedStatusCodebook['codeBook']>(
        []
    );

    const setFaultySensorReporterState = useSetRecoilState(FaultySensorReporterState);
    const queryTableFn = useRecoilValue(QueryFn(`FaultySensorReports-QueryFn`));
    const { fromUTCToTimezone } = useConverter();
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    const { refetch: getVerifiedStatusData } = useQuery(
        [SensorFaultyReportVerifiedStatusQueryKeys.get],
        () => SensorFaultyReportVerifiedStatus.get({}),
        {
            enabled: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours'),
            onSuccess: (dataOnSuccess: SensorFaultyReportVerifiedStatusCodebook) =>
                setVerifiedStatusData(dataOnSuccess.codeBook)
        }
    );

    const columns: Column<TableItem>[] = [
        {
            Header: '',
            accessor: 'id',
            width: 50,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => {
                return (
                    <UiIconButton
                        testid={`Action-FaultySensorReport-Button-${props.row.original.id}`}
                        onClick={() => {
                            setFaultySensorReporterState({
                                sensorId: props.row.original.sensor?.id,
                                vehicleId: props.row.original.sensor?.wheel?.vehicle?.id || 0,
                                sensorName: props.row.original.sensor?.serialNumberHex,
                                internalOnVehicle: true,
                                vehicleName: props.row.original.sensor?.wheel?.vehicle?.name,
                                isExternal: props.row.original.sensor?.sensorType?.id === 2,
                                reportDetailId: props.value,
                                reportDateFrom: props.row.original.dateFrom,
                                reportDateTo: props.row.original.dateTo,
                                showOnlyGraph: true,
                                notValidate: !props.row.original.verifiedAt,
                                displayAllHubs: true,
                                onVerified: false
                            });
                        }}
                    >
                        <UiIcon
                            icon={['fas', 'pen-to-square']}
                            size='1x'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#000',
                                theme: ThemeMode?.mode,
                                light: '#0000008a',
                                dark: WHITE
                            })}
                        />
                    </UiIconButton>
                );
            }
        },
        {
            Header: translate('t.sensor'),
            accessor: (row: TableItem) => row.sensor.serialNumberHex,
            id: 'sensor.serialNumberHex',
            width: 100,
            Cell: (props) => (
                <>
                    {props.row.original.sensor?.sensorType?.id === 2 && (
                        <UiIcon
                            icon={['fas', 'up-right-from-square']}
                            size='sm'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#000',
                                theme: ThemeMode?.mode,
                                light: '#000',
                                dark: WHITE
                            })}
                        />
                    )}{' '}
                    {props.value}
                </>
            )
        },
        {
            Header: translate('t.problem'),
            accessor: 'problem',
            Cell: (props) => <>{translate(`t.${props.value}`)}</>,
            disableFilters: true,
            width: 100
        },
        {
            Header: translate('t.date_from'),
            accessor: 'dateFrom',
            Cell: (props) => <>{fromUTCToTimezone(props.value, false)}</>,
            Filter: ({ setFilter, state, ...props }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='DateFrom'
                            keyFilter='dateFrom'
                            label={`${translate('t.date_from')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: translate('t.date_to'),
            accessor: 'dateTo',
            Cell: (props) => <>{fromUTCToTimezone(props.value, false)}</>,
            Filter: ({ setFilter, state, ...props }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='DateTo'
                            keyFilter='dateTo'
                            label={`${translate('t.date_to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: translate('t.disabled_reporting'),
            accessor: 'action',
            width: 80,
            Cell: (props) => {
                return <>{props.value ? translate('t.yes') : translate('t.no')}</>;
            },
            Filter: ({ setFilter, column, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });
                return (
                    <FormControl
                        variant='outlined'
                        size='small'
                        style={{ width: '100%', marginTop: '5px', marginBottom: '10px' }}
                    >
                        <InputLabel htmlFor='disabled-action' shrink>
                            <>{column.Header}</>
                        </InputLabel>
                        <Select
                            variant='outlined'
                            label={<>{column.Header}</>}
                            value={cellValue?.value ?? ''}
                            onChange={(e) => {
                                const value = !e.target.value
                                    ? undefined
                                    : { name: column.Header, value: e.target.value };
                                setFilterValue(value);
                            }}
                            inputProps={{
                                name: 'disabled-action',
                                id: 'disabled-action'
                            }}
                            input={<OutlinedInput notched label={<>{column.Header}</>} />}
                        >
                            <MenuItem value={''} data-test='test'>
                                {translate('t.all')}
                            </MenuItem>
                            <MenuItem value={`1;${translate('t.yes')}`} data-test='yes test'>
                                {translate('t.yes')}
                            </MenuItem>
                            <MenuItem value={`0;${translate('t.no')}`}>{translate('t.no')}</MenuItem>
                        </Select>
                    </FormControl>
                );
            }
        },
        {
            Header: translate('t.reported_by'),
            accessor: (row) => row.user.lastname,
            id: 'userLastname',
            disableSortBy: true,
            Cell: (props) => {
                return <>{props.value}</>;
            }
        },

        {
            Header: translate('t.reported_at'),
            accessor: 'createdAt',
            Cell: (props) => <>{fromUTCToTimezone(props.value, false)}</>,
            Filter: ({ setFilter, state, ...props }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='CreatedAtFrom'
                            keyFilter='createdAtFrom'
                            label={`${translate('t.reported_from')}`}
                        />
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='CreatedAtTo'
                            keyFilter='createdAtTo'
                            label={`${translate('t.reported_to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: translate('t.first_activation'),
            accessor: 'firstActivation',
            disableFilters: true,
            disableSortBy: true,
            width: 100,
            Cell: (props) => <>{fromUTCToTimezone(props.value, false)}</>,
            Filter: ({ setFilter, state, ...props }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='FirstActivationFrom'
                            keyFilter='firstActivationFrom'
                            label={`${translate('t.first_activation')} ${translate('t.from')}`}
                        />
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='FirstActivationTo'
                            keyFilter='firstActivationTo'
                            label={`${translate('t.first_activation')} ${translate('t.to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: translate('t.last_transmission'),
            accessor: (row) => row.sensor.sensorInfo.measuredAt,
            disableFilters: true,
            disableSortBy: true,
            width: 100,
            Cell: (props) => <>{fromUTCToTimezone(props.value, false)}</>,
            Filter: ({ setFilter, state, ...props }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='LastTransmissionFrom'
                            keyFilter='lastTransmissionFrom'
                            label={`${translate('t.last_transmission')} ${translate('t.from')}`}
                        />
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='LastTransmissionTo'
                            keyFilter='lastTransmissionTo'
                            label={`${translate('t.last_transmission')} ${translate('t.to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },

        {
            Header: translate('t.vehicle_name'),
            accessor: (row) => row.sensor.wheel?.vehicle.name,
            width: 100,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => {
                return <>{props.value ?? '-'}</>;
            }
        },

        {
            Header: translate('t.wheel_position'),
            accessor: (row) => row.sensor.wheel?.customPosition,
            width: 100,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => {
                return <>{props.value ?? '-'}</>;
            }
        },
        {
            Header: translate('t.mounted_status'),
            accessor: (row) => row.sensor.lastMovement?.removalReason,
            disableFilters: true,
            disableSortBy: true,
            width: 100,
            Cell: (props) => {
                return <>{props.value ?? '-'}</>;
            }
        },
        {
            Header: translate('t.report_validity'),
            accessor: (row) => row.verifiedStatus.name,
            id: 'verifiedStatus.name',
            width: 80,
            Cell: (props) => {
                return <>{props.value ? translate(`t.${props.value.toLowerCase()}`) : ''}</>;
            },
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });
                return (
                    <FormControl variant='outlined' size='small' style={{ width: '100%', marginTop: '5px' }}>
                        <InputLabel htmlFor='verified-status-native-simple' shrink>
                            <>{column.Header}</>
                        </InputLabel>
                        <Select
                            variant='outlined'
                            label={<>{column.Header}</>}
                            value={cellValue?.value || ''}
                            onChange={(e) => {
                                let value: object | undefined = undefined;
                                const currentValue = e.target.value;
                                if (currentValue) {
                                    value = { name: column.Header, value: e.target.value };
                                }
                                setFilterValue(value);
                            }}
                            inputProps={{
                                name: 'verified-status',
                                id: 'verified-status-native-simple'
                            }}
                            input={<OutlinedInput notched label={<>{column.Header}</>} />}
                        >
                            <MenuItem value={''}>{translate('t.all')}</MenuItem>
                            {verifiedStatusData.map((verifiedStatus, index) => (
                                <MenuItem
                                    value={`${verifiedStatus.id};${translate(
                                        `t.${verifiedStatus.name.toLowerCase()}`
                                    )}`}
                                    key={index}
                                >
                                    {translate(`t.${verifiedStatus.name.toLowerCase()}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }
        },
        {
            Header: translate('t.validate_at'),
            accessor: 'verifiedAt',
            width: 120,
            Cell: (props) => <>{fromUTCToTimezone(props.value, false)}</>,
            Filter: ({ setFilter, state, ...props }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='VerifiedAtFrom'
                            keyFilter='verifiedAtFrom'
                            label={translate('t.validate_from')}
                        />
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='VerifiedAtTo'
                            keyFilter='verifiedAtTo'
                            label={translate('t.validate_to')}
                        />
                    </LocalizationProvider>
                );
            }
        },

        {
            Header: '',
            disableFilters: true,
            width: 0,
            disableSortBy: true,
            accessor: 'verifiedAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            width: 0,
            disableSortBy: true,
            accessor: 'verifiedAtTo'
        },
        {
            Header: '',
            disableFilters: true,
            width: 0,
            disableSortBy: true,
            accessor: 'createdAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            width: 0,
            disableSortBy: true,
            accessor: 'createdAtTo'
        },
        {
            Header: '',
            disableFilters: true,
            width: 0,
            disableSortBy: true,
            accessor: 'firstActivationFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            width: 0,
            accessor: 'firstActivationTo'
        },
        {
            Header: '',
            disableFilters: true,
            width: 0,
            disableSortBy: true,
            accessor: 'lastActivationFrom'
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'lastActivationTo'
        }
    ];

    useEffect(() => {
        !isCacheAvailable([SensorFaultyReportVerifiedStatusQueryKeys.get], queryCache)
            ? getVerifiedStatusData()
            : setVerifiedStatusData(
                  (
                      getCache([SensorFaultyReportVerifiedStatusQueryKeys.get], queryCache)[0]?.state
                          ?.data as SensorFaultyReportVerifiedStatusCodebook
                  ).codeBook
              );
    }, []);

    return <FaultySensorReportsContent columns={columns} data-testid='FaultySensorReports-testid' />;
};

export default Wrapper(FaultySensorReports);
