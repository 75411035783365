import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { useTheme } from '@mui/material';
import { CommonTypeTheme } from 'components/Ui/Theme';
import { SIZE_TEXT_VARIANT } from 'components/Ui/variables';
export const VehicleStatusWidgetViewContent = styled.div`
    && .MuiCardContent-root {
        padding: 0 !important;
        overflow: auto;
        ${applyStyleByMode({
            styleOld: 'overflow: auto;'
        })}
    }
    ${applyStyleByMode({
        styleJade: `
            && .MuiAutocomplete-popupIndicator {
                padding: 4px;
                margin-top: 3px;
            }
        `
    })}
`;

export const WidgetContentWrapper = styled.div`
    && {
        background-color: #cecece !important;
        height: 73vh;
    }
`;

export const CustomTextField = styled(TextField)`
    padding: 0px;
    width: 100%;
    border-bottom: none;
`;

export const FiltersContainer = styled.section`
    background: #f5f5f5;
    width: 100%;
    border-bottom: 2px solid #7b7b7ba6;

    transition: max-height 0.2s linear;
    overflow: hidden;
    max-height: 0;
    position: sticky;
    top: 0;
    z-index: 10;

    &.active {
        max-height: 300px;
    }
`;

export const CustomIcon = styled.i`
    transform: scale(0.7);
`;

export const LegendIcon = styled.i<{ color?: string }>`
    color: ${(props) => props.color};
    ${(props) => props.color && `color: ${props.color};`}
    padding-right: 10px;
`;

export const LegendTable = styled.table`
    ${applyStyleByMode({
        styleJade: `font-size: ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return common.easyMode ? SIZE_TEXT_VARIANT(2) : SIZE_TEXT_VARIANT(1);
        }};`
    })}
`;