import { atom } from 'recoil';
import { CRUD } from 'variables';

type VehicleActionProps = {
    vehicleId?: number;
    action?: CRUD;
    vehicleName?: string;
    hasDevice?: boolean;
};

export const VehicleActionAtom = atom<VehicleActionProps>({
    key: 'VehicleAdd',
    default: {
        vehicleId: 0,
        action: CRUD.EMPTY,
        vehicleName: ''
    }
});
