import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import CurrentTimeView from './CurrentTime.view';
import { InicialValuesForm } from './CurrentTime.type';
import { User, UserInfo } from 'states/global/User';
import { IUserSetting } from 'components/User/GeneralSettingsForm/GeneralSettingsForm.type';
import Account from 'api/Account';
import { TimeToPHPFormat, getSortedTimezone } from 'helpers';
import { Errors, Success } from 'components/Popup/Popup';
import { UserModel } from 'models/User.type';
import TimeZone from 'api/TimeZone';
import { ITimeZone, ITimeZoneFormatted, TimeZoneQueryKeys } from 'models/TimeZone.type';
import { useQuery } from '@tanstack/react-query';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { Wrapper } from 'helpers/wrapper';

const ApiAccount = new Account();
const ApiTimeZone = new TimeZone();

const CurrentTimeContent: React.FC = (): JSX.Element => {
    const [datetime, setDatetime] = useState<string>('');
    const datetimeLoop = useRef<NodeJS.Timeout>();
    const didMount = React.useRef(false);
    const { fromUTCtoUserTimezone, dateTimeFormat } = useConverter();
    const { t: translate } = useTranslation();
    const UserState = useRecoilValue(UserInfo);
    const setUser = useSetRecoilState(User);
    const [closePopUp, setPopUp] = useState<boolean | undefined>(undefined);
    const [timeZonesData, setTimeZonesData] = useState<ITimeZoneFormatted[]>();

    const [inicialValuesFormik, setInicialValuesFormik] = useState<InicialValuesForm>({
        language: '',
        timeFormat: '',
        useMineTimezoneFormik: 'false',
        timezone: ''
    });

    const { isLoading: timeZoneLoading, data: timeZoneData } = useQuery<boolean, Error, ITimeZone[]>(
        [TimeZoneQueryKeys.getCodebook],
        () => ApiTimeZone.getCodebook({ apiProject: undefined }),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours')
        }
    );

    const updateClockTime = (): void => {
        setDatetime(fromUTCtoUserTimezone({ date: DateTime.local().toUTC(), displaySeconds: true, format: 'time' }));
    };

    const startClockInterval = (): void => {
        clearInterval(datetimeLoop.current);
        datetimeLoop.current = setInterval(() => {
            updateClockTime();
        }, 1000);
    };

    const getUser = async () => {
        try {
            const dataU: UserModel = await ApiAccount.getParsed();

            if (Object.keys(dataU).length) {
                setUser(dataU);
                setPopUp(false);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('Get user ', error);
        }
    };

    const handleSubmit = async (generalSettingsFormData: InicialValuesForm, setSubmitting): Promise<void> => {
        generalSettingsFormData.timeFormat = TimeToPHPFormat(generalSettingsFormData.timeFormat);

        generalSettingsFormData.useMineTimezone =
            generalSettingsFormData.useMineTimezoneFormik == 'false' ? false : true;

        const { userSetting }: IUserSetting = await ApiAccount.patchSetting(generalSettingsFormData);
        if (userSetting) {
            setPopUp(true);
            Success({ text: translate('t.modified_format') });
            getUser();
        } else {
            Errors({ text: translate('t.error_modifying_format') });
        }
        setSubmitting(false);
    };

    useEffect(() => {
        if (UserState && UserState?.user && !!Object.keys(UserState.user.userSetting).length) {
            setInicialValuesFormik({
                language: UserState.user.userSetting.language,
                timeFormat: UserState.user.userSetting.timeFormat,
                useMineTimezoneFormik: `${UserState.user.userSetting.useMineTimezone}`,
                timezone: UserState.user.userSetting.timezone.id
            });
        }
    }, [UserState]);

    useEffect(() => {
        startClockInterval();
        //after a recoil update we need to refresh the interval to use the new timezone
        if (didMount.current) {
            startClockInterval();
        } else {
            didMount.current = true;
        }
        return () => {
            clearInterval(datetimeLoop.current);
        };
    }, [UserState.user?.userSetting.timezone.id]);

    useEffect(() => {
        if (timeZoneData) {
            if (!timeZoneLoading) {
                let newTimezone = getSortedTimezone(timeZoneData, dateTimeFormat);
                setTimeZonesData(newTimezone);
            }
        }
    }, [timeZoneData, UserState.user?.userSetting.dateFormat]);

    return (
        <CurrentTimeView
            data-testid='CurrentTimeView-testID'
            handleSubmit={handleSubmit}
            datetime={datetime}
            inicialValuesFormik={inicialValuesFormik}
            closePopUp={closePopUp}
            timeZoneData={timeZonesData || []}
        />
    );
};
export default Wrapper(CurrentTimeContent);
