import { Paper } from '@mui/material';
import { styled as styleMui } from '@mui/system';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

export const AlertsAffectedVehiclesViewContent = styleMui(Paper)({
    padding: '5px 10px'
});

export const ExpandIconButton = styleMui(UiIconButton)((props: { expand: boolean }) => ({
    transform: props.expand ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 300ms linear',
    marginLeft: 'auto'
}));
