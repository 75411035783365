import React, { useState, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
    HeaderTable,
    TableCustom,
    SimpleCell,
    CustomTableHead,
    AlertsTableContent as AlertsTableContainer
} from './AlertsTable.style';
import * as type from './AlertsTable.type';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { TyreNotification, TyreNotificationsResponse } from 'models/Notification.type';
import NotificationEvent from 'api/NotificationEvent';
import { cacheTimeToMilliseconds, getCache, isCacheAvailable } from 'helpers/cache';
import { NotificationEventQueryKeys } from 'models/NotificationEvent.type';
import { Wrapper } from 'helpers/wrapper';

const BORDER_GAP = 8;
const notificationEvent = new NotificationEvent();

export const AlertsTableContent: React.FC<type.AlertsDetailsProps> = ({ tyreId }): JSX.Element => {
    const { t: translate } = useTranslation();
    const [tyreNotificationData, setTyreNotificationData] = useState<TyreNotification[]>([]);
    const queryCache = useQueryClient().getQueryCache();

    const { isLoading, refetch } = useQuery(
        [NotificationEventQueryKeys.getTotalAlertsByTyre, tyreId],
        () => notificationEvent.getTotalAlertsByTyre(tyreId),
        {
            enabled: false,
            retry: false,
            refetchOnWindowFocus: false,
            refetchInterval: false,
            staleTime: cacheTimeToMilliseconds(5, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(5, 'minutes'),
            onSuccess: (successData) => setTyreNotificationData(successData?.tyreTotalAlerts ?? []),
            onError: () => setTyreNotificationData([])
        }
    );

    useEffect(() => {
        if (
            !isNaN(tyreId) &&
            !isCacheAvailable([NotificationEventQueryKeys.getTotalAlertsByTyre, tyreId], queryCache)
        ) {
            refetch();
        } else if (
            !isNaN(tyreId) &&
            isCacheAvailable([NotificationEventQueryKeys.getTotalAlertsByTyre, tyreId], queryCache)
        ) {
            const tyreNotificationCached: TyreNotificationsResponse | unknown = getCache(
                [NotificationEventQueryKeys.getTotalAlertsByTyre, tyreId],
                queryCache
            )[0]?.state?.data;
            setTyreNotificationData((tyreNotificationCached as TyreNotificationsResponse)?.tyreTotalAlerts ?? []);
        }
    }, [tyreId]);

    const createHeader = (): JSX.Element => (
        <>
            <tr>
                <th colSpan={2} />
                <HeaderTable colSpan={1}>
                    <p>{translate('t.total_alerts')}: </p>
                </HeaderTable>
                <HeaderTable colSpan={1}>
                    <p>{translate('t.count')}</p>
                </HeaderTable>
                <HeaderTable colSpan={1} borderGap={BORDER_GAP}>
                    <p>{translate('t.hours')}</p>
                </HeaderTable>
                <HeaderTable colSpan={1}> </HeaderTable>
                <HeaderTable colSpan={1}>
                    <p>{translate('t.count')}</p>
                </HeaderTable>
                <HeaderTable colSpan={1} borderGap={BORDER_GAP}>
                    <p>{translate('t.hours')}</p>
                </HeaderTable>
                <HeaderTable colSpan={1}> </HeaderTable>
                <HeaderTable colSpan={1}>
                    <p>{translate('t.count')}</p>
                </HeaderTable>
                <HeaderTable colSpan={1}>
                    <p>{translate('t.hours')}</p>
                </HeaderTable>
            </tr>

            <tr>
                <th colSpan={2} />
                <SimpleCell alertLevel={1} colSpan={1}>
                    {translate('t.temperature_level')} 1
                </SimpleCell>
                <SimpleCell alertLevel={1} colSpan={1} className='numbers'>
                    {tyreNotificationData[6]?.count ?? '0'}
                </SimpleCell>
                <SimpleCell alertLevel={1} colSpan={1} className='numbers' borderGap={BORDER_GAP}>
                    {tyreNotificationData[6]?.hours ?? '0'}
                </SimpleCell>
                <SimpleCell alertLevel={1} colSpan={1}>
                    {translate('t.low_pressure_level')} 1
                </SimpleCell>
                <SimpleCell alertLevel={1} colSpan={1} className='numbers'>
                    {tyreNotificationData[0]?.count ?? '0'}
                </SimpleCell>
                <SimpleCell alertLevel={1} colSpan={1} className='numbers' borderGap={BORDER_GAP}>
                    {tyreNotificationData[0]?.hours ?? '0'}
                </SimpleCell>
                <SimpleCell alertLevel={1} colSpan={1}>
                    {translate('t.high_pressure_level')} 1
                </SimpleCell>
                <SimpleCell alertLevel={1} colSpan={1} className='numbers'>
                    {tyreNotificationData[3]?.count ?? '0'}
                </SimpleCell>
                <SimpleCell alertLevel={1} colSpan={1} className='numbers'>
                    {tyreNotificationData[3]?.hours ?? '0'}
                </SimpleCell>
            </tr>

            <tr>
                <th colSpan={2} />
                <SimpleCell alertLevel={2} colSpan={1}>
                    {translate('t.temperature_level')} 2
                </SimpleCell>
                <SimpleCell alertLevel={2} colSpan={1} className='numbers'>
                    {tyreNotificationData[7]?.count ?? '0'}
                </SimpleCell>
                <SimpleCell alertLevel={2} colSpan={1} className='numbers' borderGap={BORDER_GAP}>
                    {tyreNotificationData[7]?.hours ?? '0'}
                </SimpleCell>
                <SimpleCell alertLevel={2} colSpan={1}>
                    {translate('t.low_pressure_level')} 2
                </SimpleCell>
                <SimpleCell alertLevel={2} colSpan={1} className='numbers'>
                    {tyreNotificationData[1]?.count ?? '0'}
                </SimpleCell>
                <SimpleCell alertLevel={2} colSpan={1} className='numbers' borderGap={BORDER_GAP}>
                    {tyreNotificationData[1]?.hours ?? '0'}
                </SimpleCell>
                <SimpleCell alertLevel={2} colSpan={1}>
                    {translate('t.high_pressure_level')} 2
                </SimpleCell>
                <SimpleCell alertLevel={2} colSpan={1} className='numbers'>
                    {tyreNotificationData[4]?.count ?? '0'}
                </SimpleCell>
                <SimpleCell alertLevel={2} colSpan={1} className='numbers'>
                    {tyreNotificationData[4]?.hours ?? '0'}
                </SimpleCell>
            </tr>

            <tr>
                <th colSpan={2} />
                <SimpleCell alertLevel={3} colSpan={1}>
                    {translate('t.temperature_level')} 3
                </SimpleCell>
                <SimpleCell alertLevel={3} colSpan={1} className='numbers'>
                    {tyreNotificationData[8]?.count ?? '0'}
                </SimpleCell>
                <SimpleCell alertLevel={3} colSpan={1} className='numbers' borderGap={BORDER_GAP}>
                    {tyreNotificationData[8]?.hours ?? '0'}
                </SimpleCell>
                <SimpleCell alertLevel={3} colSpan={1}>
                    {translate('t.low_pressure_level')} 3
                </SimpleCell>
                <SimpleCell alertLevel={3} colSpan={1} className='numbers'>
                    {tyreNotificationData[2]?.count ?? '0'}
                </SimpleCell>
                <SimpleCell alertLevel={3} colSpan={1} className='numbers' borderGap={BORDER_GAP}>
                    {tyreNotificationData[2]?.hours ?? '0'}
                </SimpleCell>
                <SimpleCell alertLevel={3} colSpan={1}>
                    {translate('t.high_pressure_level')} 3
                </SimpleCell>
                <SimpleCell alertLevel={3} colSpan={1} className='numbers'>
                    {tyreNotificationData[5]?.count ?? '0'}
                </SimpleCell>
                <SimpleCell alertLevel={3} colSpan={1} className='numbers'>
                    {tyreNotificationData[5]?.hours ?? '0'}
                </SimpleCell>
            </tr>
        </>
    );

    return (
        <AlertsTableContainer data-testid='AlertsTableContent'>
            {isLoading ? (
                <CircularProgress size={'1em'} />
            ) : (
                <TableCustom data-testid='AlertsTableContent-TableCustom'>
                    <CustomTableHead> {createHeader()}</CustomTableHead>
                </TableCustom>
            )}
        </AlertsTableContainer>
    );
};

export default Wrapper(AlertsTableContent);
