import React, { useEffect, useState } from 'react';
import { LogoutButtonContent } from './LogoutButton.view';
import { LogoutButtonProps } from './LogoutButton.type';
import { ModalContent, ModalMute, ModalTitle } from './LogoutButton.style';
import { logout } from 'helpers/authentication/index';
import { GlobalUserInfo, User, UserInfo } from 'states/global/User';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { useTranslation } from 'react-i18next';
import Button from '../../Ui/Components/UiButton/UiButton';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Checkbox from '../../Ui/Components/UiCheckbox';
import Account from 'api/Account';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import Alert from '@mui/material/Alert';
import { VARIANT_ALERT } from '../../Ui/variables';
import { useMediaQuery, useTheme } from '@mui/material';
import { Wrapper } from 'helpers/wrapper';
import { useNavigate } from 'react-router-dom';
import { removeAllQueryKeysFromSystem } from 'helpers/cache';

const LogoutButton: React.FC<LogoutButtonProps> = ({ mode, isCustomerDisabled }): JSX.Element => {
    const accountApi = new Account();
    const userInfo = useRecoilValue(UserInfo);
    const [checkMuteNotification, setCheckMuteNotification] = useState<boolean>(false);
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const isSmallDevice = useMediaQuery(theme.breakpoints.up('sm'));
    const navigate = useNavigate();
    const setUser = useSetRecoilState(User);
    const queryClient = useQueryClient();

    const { mutate: notificationMuteMutate, isLoading: notificationMuteLoading } = useMutation(
        accountApi.patchNotificationMute,
        {
            onSuccess: () => {
                removeUserLoginInfo();
            }
        }
    );

    const removeUserLoginInfo = () => {
        logout();
        localStorage.removeItem('language');
        localStorage.removeItem('isOnline');
        navigate(`${process.env.PUBLIC_URL}/login`);
        closeModal();
        setUser(null);
        GlobalUserInfo.user = null;
    };

    const generateModalContent = (checked): JSX.Element => {
        return (
            <ModalContent>
                <ModalTitle>
                    <Alert variant={VARIANT_ALERT} severity='info'>
                        {translate('t.logout_msg')}
                    </Alert>
                </ModalTitle>
                {!isCustomerDisabled && (
                    <ModalMute>
                        <Checkbox
                            label={translate('t.mute_notification_msg')}
                            name='active'
                            checked={checked}
                            labelPlacement='start'
                            onChange={(e, checked) => handleChange(checked)}
                        />
                    </ModalMute>
                )}
            </ModalContent>
        );
    };

    const uploadModal = (muteNotification) => {
        if (modalAtom.isOpen) {
            modalDispach({
                type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                ModalPropsPayload: {
                    ...contentModalResolved,
                    content: generateModalContent(muteNotification),
                    isOpen: true
                }
            });
        }
    };

    const closeModal = () => {
        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalResolved,
                isOpen: false
            }
        });
    };

    const contentModalResolved = {
        id: 'LogoutModal',
        leftTitle: `${translate('t.logout')} - ${userInfo.user?.firstname} ${userInfo.user?.lastname}`,
        content: generateModalContent(checkMuteNotification),
        buttons: (
            <Button
                skin='success'
                variant='contained'
                onClick={() => {
                    handleLogout();
                }}
                loading={notificationMuteLoading}
                data-testid='LogoutButton'
            >
                {translate('t.logout')}
            </Button>
        ),
        width: isSmallDevice ? 500 : 300,
        height: isSmallDevice ? 160 : 160,
        widthUnit: 'px',
        onClose: () => closeModal()
    };

    const handleChange = (checked) => {
        setCheckMuteNotification(checked);
    };

    const handleModalLogout = () => {
        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalResolved,
                isOpen: true
            }
        });
    };

    const handleLogout = () => {
        isCustomerDisabled ? removeUserLoginInfo() : notificationMuteMutate(checkMuteNotification);
        removeAllQueryKeysFromSystem(queryClient);
    };

    useEffect(() => {
        typeof userInfo.user?.userSetting?.muteNotification !== 'undefined' &&
            setCheckMuteNotification(userInfo.user?.userSetting?.muteNotification);
    }, [userInfo]);

    useEffect(() => {
        uploadModal(checkMuteNotification);
    }, [checkMuteNotification]);

    useEffect(() => {
        uploadModal(checkMuteNotification);
    }, [notificationMuteLoading]);

    return (
        <LogoutButtonContent handleModalLogout={handleModalLogout} data-testid={'LogoutButton-testid'} mode={mode} />
    );
};

export default Wrapper(LogoutButton);
