import React, { useCallback, useEffect } from 'react';
import { AlertSettingsContent } from './AlertSettings.view';
import { ALERT_INDEX, AlertSettingsProps } from './AlertSettings.type';
import { Wrapper } from 'helpers/wrapper';
import { useSetRecoilState, useResetRecoilState } from 'recoil';
import {
    AlertSettingPriority,
    CustomAlertsLevelCheckbox,
    DefaultPressureLevel,
    IsLoadingAlertSettings,
    PressureLevelsSelector,
    RefetchAlertSettings
} from './atom';
import { AlertLevelEnum } from 'models/Alerts.type';
import RuleApi from 'api/Rule';
import { useQuery } from '@tanstack/react-query';
import { RuleModelWheel } from 'models/Rule.type';
import { DefaultTemperatureSliderValue, TemperatureSliderValue } from '../AlertTemperatureSlider/atom';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { RuleQueryKeys } from 'models/Rule.type';

const Rule = new RuleApi();

const AlertSettings: React.FC<AlertSettingsProps> = (props): JSX.Element => {
    const setInitValuesLow3 = useSetRecoilState(PressureLevelsSelector(-3));
    const setInitValuesLow2 = useSetRecoilState(PressureLevelsSelector(-2));
    const setInitValuesLow1 = useSetRecoilState(PressureLevelsSelector(-1));
    const setInitValuesOptimal = useSetRecoilState(PressureLevelsSelector(0));
    const setInitValuesHigh1 = useSetRecoilState(PressureLevelsSelector(1));
    const setInitValuesHigh2 = useSetRecoilState(PressureLevelsSelector(2));
    const setInitValuesHigh3 = useSetRecoilState(PressureLevelsSelector(3));
    const setInitValuesHot1 = useSetRecoilState(PressureLevelsSelector(11));
    const setInitValuesHot2 = useSetRecoilState(PressureLevelsSelector(12));
    const setInitValuesHot3 = useSetRecoilState(PressureLevelsSelector(13));
    const setAlertSettingPriority = useSetRecoilState(AlertSettingPriority);
    const setSliderValue = useSetRecoilState(TemperatureSliderValue);
    const setDefaultSliderValue = useSetRecoilState(DefaultTemperatureSliderValue);
    const setDefaultPressureLevel = useSetRecoilState(DefaultPressureLevel);
    const resetDefaultPressureLevel = useResetRecoilState(DefaultPressureLevel);
    const resetSliderValue = useResetRecoilState(TemperatureSliderValue);
    const resetDefaultSliderValue = useResetRecoilState(DefaultTemperatureSliderValue);
    const { fromServerToUserUnit, convertType } = useConverter();
    const setIsLoadingAlertSettings = useSetRecoilState(IsLoadingAlertSettings);
    const setRefetchAlertSettings = useSetRecoilState(RefetchAlertSettings);
    const resetCustomAlertsFlag = useResetRecoilState(CustomAlertsLevelCheckbox);

    const getConvertedValue = useCallback(
        (value: number, temperature?: boolean) => {
            return +fromServerToUserUnit({
                type: temperature ? convertType.temperature : convertType.pressure,
                value,
                fixed: temperature ? 0 : 1,
                displayUnits: false
            });
        },
        [fromServerToUserUnit]
    );

    const adjustPressureValue = useCallback((current, value) => {
        return {
            ...current,
            value,
            action: AlertLevelEnum.ADJUST_VALUE
        };
    }, []);

    const initPressureValue = useCallback((current, value) => {
        return {
            ...current,
            value,
            action: AlertLevelEnum.INIT_VALUES
        };
    }, []);

    const alertSettingsQuery = useQuery<any, Error, RuleModelWheel>(
        [props.wheelId ? RuleQueryKeys.getWheel : RuleQueryKeys.getDefault, props.wheelId],
        () => (props.wheelId ? Rule.getWheel(props.wheelId) : Rule.getDefault()),
        {
            refetchOnWindowFocus: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: (data) => {
                if (data) {
                    const optimal = getConvertedValue(data.rule?.optimalColdPressure || 0);
                    const low3 = getConvertedValue(
                        data.rule?.ruleLimit[ALERT_INDEX.COLD_PRESSURE_LOW_LEVEL_3].limitTo || 0
                    );
                    const low2 = getConvertedValue(
                        data.rule?.ruleLimit[ALERT_INDEX.COLD_PRESSURE_LOW_LEVEL_2].limitTo || 0
                    );
                    const low1 = getConvertedValue(
                        data.rule?.ruleLimit[ALERT_INDEX.COLD_PRESSURE_LOW_LEVEL_1].limitTo || 0
                    );
                    const high3 = getConvertedValue(
                        data.rule?.ruleLimit[ALERT_INDEX.COLD_PRESSURE_HIGH_LEVEL_3].limitFrom || 0
                    );
                    const high2 = getConvertedValue(
                        data.rule?.ruleLimit[ALERT_INDEX.COLD_PRESSURE_HIGH_LEVEL_2].limitFrom || 0
                    );
                    const high1 = getConvertedValue(
                        data.rule?.ruleLimit[ALERT_INDEX.COLD_PRESSURE_HIGH_LEVEL_1].limitFrom || 0
                    );
                    const highHot1 = getConvertedValue(
                        data.rule?.ruleLimit[ALERT_INDEX.HOT_PRESSURE_HIGH_LEVEL_1].limitFrom || 0
                    );
                    const highHot2 = getConvertedValue(
                        data.rule?.ruleLimit[ALERT_INDEX.HOT_PRESSURE_HIGH_LEVEL_2].limitFrom || 0
                    );
                    const highHot3 = getConvertedValue(
                        data.rule?.ruleLimit[ALERT_INDEX.HOT_PRESSURE_HIGH_LEVEL_3].limitFrom || 0
                    );
                    const sliderValue: [number, number, number] = [
                        getConvertedValue(data.rule?.ruleLimit[ALERT_INDEX.TEMPERATURE_LEVEL_1].limitFrom || 0, true),
                        getConvertedValue(data.rule?.ruleLimit[ALERT_INDEX.TEMPERATURE_LEVEL_2].limitFrom || 0, true),
                        getConvertedValue(data.rule?.ruleLimit[ALERT_INDEX.TEMPERATURE_LEVEL_3].limitFrom || 0, true)
                    ];

                    setDefaultPressureLevel({
                        low3,
                        low2,
                        low1,
                        optimal,
                        high1,
                        high2,
                        high3,
                        highHot1,
                        highHot2,
                        highHot3
                    });

                    setAlertSettingPriority(data.rule?.priority || 0);
                    setInitValuesOptimal((current) => initPressureValue(current, optimal));
                    setInitValuesLow3((current) => adjustPressureValue(current, low3));
                    setInitValuesLow2((current) => adjustPressureValue(current, low2));
                    setInitValuesLow1((current) => adjustPressureValue(current, low1));
                    setInitValuesHigh3((current) => adjustPressureValue(current, high3));
                    setInitValuesHigh2((current) => adjustPressureValue(current, high2));
                    setInitValuesHigh1((current) => adjustPressureValue(current, high1));
                    setInitValuesHot3((current) => initPressureValue(current, highHot3));
                    setInitValuesHot2((current) => initPressureValue(current, highHot2));
                    setInitValuesHot1((current) => initPressureValue(current, highHot1));

                    setSliderValue(sliderValue);
                    setDefaultSliderValue(sliderValue);
                }
            }
        }
    );

    const defineGlobalQueryFn = useCallback(() => {
        setRefetchAlertSettings((current) => ({ ...current, refetch: alertSettingsQuery.refetch }));
    }, [alertSettingsQuery.refetch]);

    defineGlobalQueryFn();

    useEffect(() => {
        setIsLoadingAlertSettings(alertSettingsQuery.isLoading || alertSettingsQuery.isFetching);
    }, [alertSettingsQuery.isLoading, alertSettingsQuery.isFetching]);

    useEffect(() => {
        return () => {
            resetCustomAlertsFlag();
            resetSliderValue();
            resetDefaultSliderValue();
            resetDefaultPressureLevel();
        };
    }, []);

    return <AlertSettingsContent elevation={props.elevation} {...props} data-testid={'AlertSettings-testid'} />;
};

export default Wrapper(AlertSettings);
