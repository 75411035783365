import PortalApi from 'helpers/api/PortalApiClient';
import { HelpjuiceArticle, HelpjuiceModel, HelpjuiceArticles } from '../models/Helpjuice.type';

export default class Helpjuice extends PortalApi<HelpjuiceModel> {
    route = 'helpjuice';

    getArticles = async (): Promise<HelpjuiceArticles> => {
        return await this.get({ extendUrl: 'articles', apiProject: undefined });
    };

    getArticle = async (articleId: number): Promise<{ article: HelpjuiceArticle }> => {
        return await this.get({ extendUrl: `article/${articleId}`, apiProject: undefined });
    };
}
