import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { CustomersCodebookQueryType, CustomersPaginationQueryType } from './Customers.type';
import {
    CustomerByIdResponse,
    CustomerCodebook,
    CustomerCodebookResponse,
    CustomerQueryKeys,
    CustomerTableModel
} from 'models/Customer.type';
import CustomerClient from 'api/Customer';
import { DefaultRequestPropsType } from 'helpers/api/type';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { UserInfo } from 'states/global/User';
import { useRecoilValue } from 'recoil';

const customerClient = new CustomerClient();

export const GetCustomersPaginationQuery: React.FC<CustomersPaginationQueryType> = ({
    children,
    requestParams
}): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const useQueryProperties = useQuery<DefaultRequestPropsType, Error, CustomerTableModel>(
        [CustomerQueryKeys.getByCriteria, requestParams],
        () => customerClient.getByCriteria({ criteria: requestParams, apiProject: undefined }),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(5, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(5, 'minutes')
        }
    );
    return <>{children(useQueryProperties)}</>;
};

export const GetCustomersCodebookQuery: React.FC<CustomersCodebookQueryType> = ({ children }): JSX.Element => {
    const useQueryProperties = useQuery<
        CustomerCodebook[] | CustomerByIdResponse | unknown,
        Error,
        CustomerCodebookResponse
    >([CustomerQueryKeys.getCodebook], () => customerClient.getCodeBook(), {
        staleTime: cacheTimeToMilliseconds(2, 'hours'),
        cacheTime: cacheTimeToMilliseconds(2, 'hours'),
        refetchOnWindowFocus: false
    });
    return <>{children(useQueryProperties)}</>;
};

export const GetCustomersByRegionQuery: React.FC<CustomersCodebookQueryType> = ({
    regionId,
    children
}): JSX.Element => {
    const useQueryProperties = useQuery<CustomerTableModel, Error, CustomerCodebookResponse>(
        [CustomerQueryKeys.getCustomersByRegion, regionId],
        () => customerClient.getCustomersByRegion(regionId),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours')
        }
    );
    return <>{children(useQueryProperties)}</>;
};

export const GetAvailableCustomersCodebookQuery: React.FC<CustomersCodebookQueryType> = ({ children }): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const useQueryProperties = useQuery<CustomerCodebookResponse, Error, CustomerCodebookResponse>(
        [CustomerQueryKeys.getAvailableAsCodebook, userInfo.user?.id],
        () => customerClient.getAvailableAsCodebook(),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours')
        }
    );
    return <>{children(useQueryProperties)}</>;
};
