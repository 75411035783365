import styled from 'styled-components';
import { useTheme } from '@mui/material';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

export const EasyButton = styled(UiIconButton)<{ $active: boolean }>`
    ${(props) =>
        props.$active &&
        `
        color: ${useTheme().palette.primary.main} !important;
    `}
`;
