import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import SensorsAPI from 'api/Sensors';
import SensorTypeAPI from 'api/SensorType';
import { SensorManagementRowViewType } from './SensorManagementRow.type';
import { Column, SensorManagementRowViewContent, StyledButton } from './SensorManagementRow.style';
import { SensorStateAtom } from './SensorManagementRow.atom';
import GeneralGetQuery from 'components/Mixs/GeneralGetQuery/GeneralGetQuery';
import { MenuItem, TextField } from '@mui/material';
import { SensorStateEnum } from './SensorManagementRow.reducer';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete/LazyloadingAutocomplete';
import { Sensor, SensorQueryKeys } from 'models/Sensors.type';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { CRUD } from 'variables';
import useModal from 'components/CustomHooks/ModalHook/ModalHook';
import SensorManagementPopup from '../SensorManagementPopup/SensorManagementPopup';
import { VehicleDetailsActionMenuAtom } from 'components/Vehicle/VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import { FaultySensorReporterState } from 'states/component/FaultySensorReporter';
import {
    HasInternalSensorAtom,
    VehicleDetailsWidgetAtom
} from 'components/Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { TyreDetail } from 'models/Wheel.type';
import { VARIANT_BUTTON } from 'components/Ui/variables';
import { Status } from 'models/SensorInfo.type';
import { VehicleModel } from 'models/Vehicle.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { SensorTypeQueryKeys } from 'models/SensorType.type';

const sensorsAPI = new SensorsAPI();
const sensorTypeAPI = new SensorTypeAPI();

export const SensorManagementRowView = ({
    switchPositions,
    position,
    dispatch
}: SensorManagementRowViewType): JSX.Element => {
    const { t: translate } = useTranslation();
    const sensorState = useRecoilValue(SensorStateAtom(position));
    const { setModalState } = useModal(1);
    const vehicleDetailsActionMenuAtom = useRecoilValue(VehicleDetailsActionMenuAtom);
    const setFaultySensorReporterState = useSetRecoilState(FaultySensorReporterState);
    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());
    const data: TyreDetail | undefined = vehicleDetailsWidgetAtom.tyreDetails?.filter(
        (tyre) => tyre.wheel.position === vehicleDetailsActionMenuAtom.data?.wheel.customPosition
    )[0] as TyreDetail;
    const hasInternalSensor = useRecoilValue(HasInternalSensorAtom());

    return (
        <SensorManagementRowViewContent>
            <Column width={sensorState.originalSensor ? 70 : 84}>
                {sensorState.originalSensor &&
                    vehicleDetailsActionMenuAtom.data &&
                    vehicleDetailsActionMenuAtom.data.attachedSensors.length > 1 && (
                        <StyledButton
                            id={`switch-position-${position}`}
                            width={70}
                            variant='contained'
                            skin={position === 1 ? 'success' : '#ED7D31'}
                            onClick={() => {
                                switchPositions(position);
                            }}
                            testid={`RepositioningTheSensor-${sensorState.originalSensor?.id}-Button`}
                        >
                            {position === 1 ? (
                                <UiIcon icon={['fas', 'arrow-down']} />
                            ) : (
                                <UiIcon icon={['fas', 'arrow-up']} />
                            )}
                        </StyledButton>
                    )}
            </Column>
            <Column width={200}>
                <LazyloadingAutocomplete
                    id={`sensor-dropdown-${position}`}
                    label={translate('t.sensors')}
                    queryId={`${SensorQueryKeys.getLazyDropdown}-${sensorState.originalSensor?.id}`} //queryId={`GetUnassignedSensors${sensorState.originalSensor?.id}`}
                    query={sensorsAPI.getTable}
                    defaultFilter={[
                        {
                            value: {
                                name: 'active',
                                value: '1'
                            },
                            id: 'active'
                        },
                        {
                            value: {
                                name: 'isWheel',
                                value: 'false'
                            },
                            id: 'isWheel'
                        }
                    ]}
                    optionKey={'serialNumberHex'}
                    optionValue={'id'}
                    size={'small'}
                    width={200}
                    onValueChange={(value?: number, label?: string, origValue?: Sensor) => {
                        if (origValue) {
                            dispatch({
                                type: SensorStateEnum.CHANGE_NEW_SENSOR,
                                payload: {
                                    id: origValue.id,
                                    serialNumberHex: origValue.serialNumberHex,
                                    serialNumber: origValue.sensorSerialNumber,
                                    isExternal: origValue.sensorType?.id === 2,
                                    isFaulty:
                                        origValue.sensorInfo?.status === Status.FAULT ||
                                        origValue.sensorInfo?.status === Status.SUSPICIOUS
                                }
                            });
                        }
                    }}
                    preselectedValue={{
                        value: sensorState.originalSensor?.id || 0,
                        label: sensorState.originalSensor?.serialNumberHex || '',
                        origItem: sensorState.originalSensor
                    }}
                    data-testid={`SensorManagement-Sensors-${sensorState.originalSensor?.id}-AutoComplete`}
                />
            </Column>
            <Column width={200}>
                <GeneralGetQuery
                    query={sensorTypeAPI.getSensorsTypeCodebook}
                    queryName={SensorTypeQueryKeys.getSensorsTypeCodebook}
                    queryOptions={{
                        staleTime: cacheTimeToMilliseconds(2, 'hours'),
                        cacheTime: cacheTimeToMilliseconds(2, 'hours')
                    }}
                >
                    {({ data }) => (
                        <TextField
                            select
                            id={`${position || 'new'}-sensor-type`}
                            label={translate('t.sensor_type')}
                            size='small'
                            fullWidth
                            value={sensorState.typeId}
                            disabled={sensorState.originalSensor?.id === sensorState.newSensor?.id}
                            onChange={(event) => {
                                dispatch({
                                    type: SensorStateEnum.CHANGE_SENSOR_TYPE,
                                    payload: +event.target.value
                                });
                            }}
                            data-testid={`SensorManagement-SensorType-${sensorState.originalSensor?.id}-Select`}
                        >
                            {data?.map((type) => (
                                <MenuItem key={type.id} value={type.id}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                </GeneralGetQuery>
            </Column>
            <Column width={100}>
                <StyledButton
                    id={`sensor-action-${position}`}
                    width={100}
                    variant='contained'
                    disabled={sensorState.action === CRUD.ADD && !sensorState.newSensor}
                    skin={sensorState.action === CRUD.REMOVE ? 'error' : 'success'}
                    onClick={() => {
                        setModalState({
                            isOpen: true,
                            height: 400,
                            width: 600,
                            content: <SensorManagementPopup sensorAction={sensorState.action} position={position} />
                        });
                    }}
                    testid={`SensorManagement-${sensorState.action}-Button`}
                >
                    {translate(`t.${sensorState.action}`)}
                </StyledButton>
            </Column>
            <Column width={sensorState.action === CRUD.ADD ? 146 : 130}>
                {sensorState.action !== CRUD.ADD && (
                    <StyledButton
                        id={`reset-report-${position}`}
                        width={sensorState.action === CRUD.REMOVE ? 135 : 130}
                        variant={VARIANT_BUTTON}
                        skin={sensorState.action === CRUD.REMOVE ? '#C37E00' : 'success'}
                        color={sensorState.action === CRUD.REMOVE ? 'warning' : undefined}
                        startIcon={sensorState.action === CRUD.REMOVE && <UiIcon icon={['fas', 'bug']} size={'sm'} />}
                        onClick={() => {
                            if (sensorState.action === CRUD.REMOVE) {
                                setFaultySensorReporterState({
                                    vehicleId: (vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id || 0,
                                    vehicleName: (vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.name || '',
                                    sensorId: sensorState.originalSensor?.id || 0,
                                    sensorName: sensorState.originalSensor?.serialNumberHex || '',
                                    showOnlyGraph: true,
                                    wheelPosition: data?.wheel.position,
                                    internalOnVehicle: hasInternalSensor,
                                    isExternal: !!sensorState.originalSensor?.isExternal,
                                    allowReportLeakAlert: true
                                });
                            } else {
                                dispatch({
                                    type: SensorStateEnum.RESET_NEW_SENSOR
                                });
                            }
                        }}
                        testid={`SensorManagement-${sensorState.action}-Button`}
                    >
                        {translate(`t.${sensorState.action === CRUD.REMOVE ? 'report_sensor' : 'reset'}`)}
                    </StyledButton>
                )}
            </Column>
        </SensorManagementRowViewContent>
    );
};
