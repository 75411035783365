import React from 'react';
import { UiTableFilterColumnsBtnProps } from './UiTableFilterColumnsBtn.type';
import { Wrapper } from 'helpers/wrapper';
import { useRecoilState } from 'recoil';
import { FilterBtnStates } from '../UiTable/TableAtom';
import { IconsTypography } from '../UiTable/UiTable.style';
import UiButton from '../Components/UiButton/UiButton';
import { FilterIcon, UiTableFilterColumnsBtnViewContent } from './UiTableFilterColumnsBtn.style';
import { useTranslation } from 'react-i18next';
import { Tooltip, useMediaQuery, useTheme, IconButton } from '@mui/material';

const UiTableFilterColumnsBtn: React.FC<UiTableFilterColumnsBtnProps> = ({ queryKey, onlyIconBtns }): JSX.Element => {
    const [filterBtnState, setFilterBtnState] = useRecoilState(FilterBtnStates);
    const { t: translate } = useTranslation();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setFilterBtnState({
            open: !filterBtnState.open,
            anchorEl: event.currentTarget
        });
    };

    const theme = useTheme();
    const isXlDevice = useMediaQuery(theme.breakpoints.up(1560));
    return (
        <UiTableFilterColumnsBtnViewContent>
            {isXlDevice && !onlyIconBtns ? (
                <UiButton
                    variant='outlined'
                    testid={`FilterColumns-${queryKey}Table-Button`}
                    size={'small'}
                    onClick={handleClick}
                >
                    <FilterIcon />
                    <IconsTypography> {translate('t.filters')}</IconsTypography>
                </UiButton>
            ) : (
                <Tooltip title={translate('t.filters')} placement='bottom' arrow disableInteractive>
                    <IconButton onClick={handleClick} data-testid={`FilterColumns-${queryKey}Table-Button`}>
                        <FilterIcon />
                    </IconButton>
                </Tooltip>
            )}
        </UiTableFilterColumnsBtnViewContent>
    );
};

export default Wrapper(UiTableFilterColumnsBtn);
