import React, { useEffect, useMemo } from 'react';
import * as type from './Layout.type';
import { LayoutContent } from './Layout.view';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { User, Role, UserInfo } from 'states/global/User';
import Account from 'api/Account';
import { SidebarMenu } from 'states/global/SidebarMenu';
import { Theme } from 'states/global/Theme';
import { UserModel } from 'models/User.type';
import { useLocation } from 'react-router-dom';
import ReactGA, { GaPageTitle, getTrafficTypeFromEmail } from 'helpers/ga';
import CustomerChecker from 'pages/CustomerChecker/CustomerChecker';
import { LoadedStatusAllowed } from 'states/global/Map';
import { VehicleLoadingLogEnabled } from 'models/VehicleLoadingLog.type';
import VehicleLoadingLogApi from 'api/VehicleLoadingLog';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { VehicleLoadingLogQueryKeys } from 'models/VehicleLoadingLog.type';
import { DateTime, Settings } from 'luxon';
import { Wrapper } from 'helpers/wrapper';

const apiAccount = new Account();
const VehicleLoadingLog = new VehicleLoadingLogApi();

let previousPath: string | null = null;

const Layout: React.FC<type.LayoutProps> = (props): JSX.Element => {
    const [userData, setUser] = useRecoilState(User);
    const userInfo = useRecoilValue(UserInfo);
    const navigate = useNavigate();
    const setRole = useSetRecoilState(Role);
    const sidebarMenu = useRecoilValue(SidebarMenu);
    const theme = useRecoilValue(Theme);
    const location = useLocation();
    const { i18n } = useTranslation();
    const [loadedStatusAllowed, setLoadedStatusAllowed] = useRecoilState(LoadedStatusAllowed);

    DateTime.local().setZone(userInfo.user?.userSetting.timezone.timezoneName || 'Europe/Bratislava');
    Settings.defaultZone = userInfo.user?.userSetting.timezone.timezoneName || 'Europe/Bratislava';

    const { data, refetch } = useQuery<VehicleLoadingLogEnabled>(
        [VehicleLoadingLogQueryKeys.getEnabled],
        () => VehicleLoadingLog.getEnabled(),
        {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchInterval: false,
            enabled: false
        }
    );

    const getUser = async () => {
        try {
            const dataU: UserModel = await apiAccount.getParsed();

            if (Object.keys(dataU).length) {
                if (dataU.passwordExpireDate) {
                    return navigate('/change-password');
                }

                setUser(dataU);
                setRole(dataU.role.name);
            }
        } catch (error) {
            false;
        }
    };

    const userHasEnabledCustomer = useMemo(() => {
        return userData !== null && !userData?.customer.disabledOrActiveTerminatedAt;
    }, [userData]);

    useEffect(() => {
        if (!userData) {
            getUser();
        }

        const allNumbers = /[0-9]/g;
        const pagePath = location.pathname.replace(allNumbers, '');

        // Only send a pageview event if the path has changed
        if (userData && location.pathname !== previousPath) {
            ReactGA.send({ hitType: 'pageview', page: pagePath, title: GaPageTitle[pagePath] || pagePath });
            ReactGA.set({ itrack_customer_name: `${userData?.customer.name} - ID:${userData?.customer.id}` });
            ReactGA.set({ itrack_traffic_type: getTrafficTypeFromEmail(userData?.email) });
            ReactGA.set({ itrack_user_id: userData?.id });
            ReactGA.set({ itrack_user_email: userData?.email });

            previousPath = location.pathname; // Update previous path after sending the event
        }

        if (userData) {
            i18n.changeLanguage(userData.userSetting.language);
        }
    }, [userData, location]);

    useEffect(() => {
        if (userData && !userData.customer.disabledOrActiveTerminatedAt && loadedStatusAllowed === null) {
            refetch();
        }
    }, [userData, loadedStatusAllowed]);

    useEffect(() => {
        if (data?.vehicleLoadingLog.enabled !== undefined) {
            setLoadedStatusAllowed(data?.vehicleLoadingLog.enabled);
        }
    }, [data?.vehicleLoadingLog.enabled]);

    if (!userData) {
        return <></>;
    }

    if (!userHasEnabledCustomer) {
        return <CustomerChecker />;
    }

    return (
        <LayoutContent
            sidebarMenu={sidebarMenu}
            theme={theme}
            userData={userData}
            {...props}
            data-testid='Layout-testid'
        />
    );
};

export default Wrapper(Layout);
