import PortalApi from 'helpers/api/PortalApiClient';
import { TeamShiftTimeModel, TeamShiftTimePayload, TeamShiftTimeResponse } from '../models/TeamShiftTime.type';
import { getParams } from 'helpers';

export default class TeamShiftTime extends PortalApi<TeamShiftTimeModel> {
    route = 'team-shift-time';

    getAll = async (props): Promise<TeamShiftTimeResponse> => {
        return await this.getTable<TeamShiftTimeModel>({
            criteria: getParams(props)
        });
    };

    addTeamShiftTime = async (data: TeamShiftTimePayload): Promise<TeamShiftTimeModel> => {
        return await this.post({ data: data });
    };

    updateTeamShiftTime = async (data: any): Promise<any> => {
        return await this.patchById({ data: data.updateData, id: data.tyreId });
    };
}
