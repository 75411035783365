import React, { useEffect, useCallback, useState } from 'react';
import { TyreLifespanReportContent } from './TyreLifespanReport.view';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Tyre from 'api/Tyre';
import { DateTime, Interval, Settings } from 'luxon';
import { BesRankTimeline, BesRankTicks } from '../SeverityRank/atom';
import { TyreLifeSpanDataAtom, TyreLifespanDataDefaultValue } from './TyreLifespanReport.atom';
import { TyreModel, TyreQueryKeys } from 'models/Tyre.type';
import { cacheTimeToMilliseconds, getCache, isCacheAvailable } from 'helpers/cache';
import { useLocation, useNavigate } from 'react-router-dom';
import { Wrapper } from 'helpers/wrapper';

const tyreApi = new Tyre();

const TyreLifespanReport: React.FC = (): JSX.Element => {
    const [tyreLifespanData, setFirstTyreLifespanData] = useRecoilState(TyreLifeSpanDataAtom());
    const queryCache = useQueryClient().getQueryCache();
    const [tyreId, setTyreId] = useState<number>(NaN);
    const setRankTimeline = useSetRecoilState(BesRankTimeline);
    const setRankTicks = useSetRecoilState(BesRankTicks);
    const location = useLocation();
    const navigate = useNavigate();

    const getTyreId = (): number => {
        let id: string | null;

        const urlParams = new URLSearchParams(location.search);
        id = urlParams.get('id');

        if (id) {
            return parseInt(id);
        }

        return NaN;
    };

    const generateBESTimeline = useCallback((): void => {
        let timeline = {};
        Interval.fromDateTimes(
            DateTime.local().minus({ days: 30 }).toUTC().startOf('day'),
            DateTime.local().toUTC().plus({ days: 1 }).startOf('day')
        )
            .splitBy({ day: 1 })
            .map((d) => d.start)
            .forEach((element) => {
                const timeKey = element.valueOf() / 1000;
                timeline[timeKey] = { timeKey };
            });

        setRankTimeline(timeline);
        const ticks = Interval.fromDateTimes(
            DateTime.local().minus({ days: 30 }).toUTC().startOf('day'),
            DateTime.local().toUTC().plus({ days: 1 }).startOf('day')
        )
            .splitBy({ day: 1 })
            .map((d) => d.start)
            .filter((element) => {
                return element.weekday === 1;
            })
            .map((element) => element.valueOf() / 1000);

        setRankTicks(ticks);
    }, [Settings.defaultZone]);

    const { refetch: refetchTyreDetails } = useQuery([`TyreExist-${tyreId}`], () => tyreApi.getTyreLifeDetail(tyreId), {
        enabled: false,
        retry: false,
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        staleTime: cacheTimeToMilliseconds(15, 'minutes'),
        cacheTime: cacheTimeToMilliseconds(15, 'minutes'),
        onSuccess: (dataOnSuccess) => {
            setFirstTyreLifespanData({
                ...tyreLifespanData,
                tyreSerialNumber: dataOnSuccess.tyreLifeDetail.tyre.tyreSerialNumber ?? '',
                customPosition: dataOnSuccess.tyreLifeDetail.tyre.customPosition ?? NaN,
                vehicleId: dataOnSuccess.tyreLifeDetail.tyre.vehicleId,
                vehicleName: dataOnSuccess.tyreLifeDetail.tyre.vehicleName ?? ''
            });
            generateBESTimeline();
        },
        onError: () => {
            setFirstTyreLifespanData(TyreLifespanDataDefaultValue);
        }
    });

    const { refetch: refetchLastTyreSerialNumber } = useQuery(
        [TyreQueryKeys.getLastTyreInspected, tyreLifespanData.customPosition, tyreLifespanData.vehicleId],
        () =>
            tyreApi.getLastTyreInspected({
                customPosition: tyreLifespanData.customPosition,
                vehicleId: tyreLifespanData.vehicleId
            }),
        {
            staleTime: cacheTimeToMilliseconds(15, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(15, 'minutes'),
            enabled: false,
            retry: false,

            refetchOnWindowFocus: false,
            onSuccess: (dataOnSuccess) => {
                const Item = dataOnSuccess.items.length ? dataOnSuccess.items[0] : undefined;
                setFirstTyreLifespanData({
                    ...tyreLifespanData,
                    tyreSerialNumber: Item?.tyreSerialNumber ?? ''
                });

                setTyreId(Item?.id ?? NaN);
            }
        }
    );

    const handleTyreChange = (): void => {
        if (!isNaN(tyreId) && !isCacheAvailable([TyreQueryKeys.getTyreLifeDetail, tyreId], queryCache)) {
            refetchTyreDetails();
            tyreId && navigate(`?id=${tyreId}`, { replace: true });
        } else if (!isNaN(tyreId) && isCacheAvailable([TyreQueryKeys.getTyreLifeDetail, tyreId], queryCache)) {
            const cacheValue = getCache([TyreQueryKeys.getTyreLifeDetail, tyreId], queryCache)[0]?.state?.data as {
                tyreLifeDetail: { tyre: TyreModel };
            };
            setFirstTyreLifespanData({
                ...tyreLifespanData,
                tyreSerialNumber: cacheValue?.tyreLifeDetail?.tyre?.tyreSerialNumber ?? ''
            });
            setTyreId(cacheValue?.tyreLifeDetail?.tyre?.id ?? NaN);
        }
    };

    useEffect(() => {
        if (!isNaN(getTyreId())) {
            setFirstTyreLifespanData({ ...tyreLifespanData });
            setTyreId(getTyreId());
        }

        return () => {
            setFirstTyreLifespanData(TyreLifespanDataDefaultValue);
            setTyreId(NaN);
        };
    }, []);

    useEffect(() => {
        handleTyreChange();
    }, [tyreId]);

    useEffect(() => {
        isNaN(tyreId) &&
            !tyreLifespanData.tyreSerialNumber.length &&
            tyreLifespanData.vehicleId &&
            !isNaN(tyreLifespanData.vehicleId) &&
            tyreLifespanData.customPosition &&
            !isNaN(tyreLifespanData.customPosition) &&
            refetchLastTyreSerialNumber();
    }, [tyreLifespanData.customPosition]);

    return (
        <>
            <TyreLifespanReportContent data-testid='TyreLifespanReport-testid' tyreId={tyreId} setTyreId={setTyreId} />
        </>
    );
};

export default Wrapper(TyreLifespanReport);
