import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { FormikProps, SendDataProductionProps } from './AnnouncementsActions.type';
import { useTranslation } from 'react-i18next';
import { FormDiv } from './AnnouncementsActions.style';
import { Form, Formik } from 'formik';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import Checkbox from '../Ui/Components/UiCheckbox';
import HelperText from '../Mixs/HelperText';
import UiDateRangePicker2 from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2';
import { DateTime } from 'luxon';
import RegionCustomerSelector from 'components/Customer/RegionCustomerSelector/RegionCustomerSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEarthAmerica, faEarthEurope } from '@fortawesome/pro-solid-svg-icons';
import UiWidgetTab from 'components/Ui/Components/UiWidgetTab/UiWidgetTab';
import { CRUD } from 'variables';
import { AnnouncementsActionsState } from 'states/component/Announcements';
import { useRecoilValue } from 'recoil';

export const AnnouncementsForm: React.FC<FormikProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const announcementsActionsState = useRecoilValue(AnnouncementsActionsState);

    const defaultWhatsNewValue = useMemo(() => {
        if ('helpjuiceId' in props.formValues) {
            const helpjuiceId = props.formValues.helpjuiceId;
            return (props.articles?.articles || []).find((article) => article.id === helpjuiceId);
        }
        return null;
    }, [props.articles, props.formValues]);

    const isWhatsNewEurope = useMemo(() => {
        if ('helpjuiceId' in props.formValues) {
            return props.formValues.customer.find((customer) => customer.id === 12); // has demo customer
        }
        return false;
    }, [props.formValues]);

    const isEdit = useMemo(() => {
        return announcementsActionsState.action === CRUD.EDIT;
    }, [announcementsActionsState.action]);

    const isClone = useMemo(() => {
        return announcementsActionsState.action === CRUD.CLONE;
    }, [announcementsActionsState.action]);

    const FormSchemas = {
        production: Yup.object().shape({
            message: Yup.string().required().required(translate('t.required')),
            dateFrom: Yup.number().required(),
            dateTo: Yup.number().required(),
            dateFrom2: Yup.number().required(),
            dateTo2: Yup.number().required(),
            active: Yup.boolean(),
            customer: Yup.array(),
            translations: Yup.array(),
            helpjuiceId: Yup.number().required(translate('t.required')),
            knowledgebaseUrl: Yup.string()
        }),
        general: Yup.object().shape({
            message: Yup.string().required().required(translate('t.required')),
            dateFrom: Yup.number().required(),
            dateTo: Yup.number().required(),
            active: Yup.boolean(),
            allCustomers: Yup.boolean(),
            customer: Yup.array(),
            translations: Yup.array()
        })
    };

    const GeneralForm = (
        <FormDiv data-testid='AnnouncementsForm-container'>
            <Formik
                initialValues={props.formValues}
                validationSchema={FormSchemas.general}
                enableReinitialize={true}
                onSubmit={(values) => {
                    props.sendData(JSON.parse(JSON.stringify(values)));
                }}
            >
                {(formikProps) => (
                    <Form id='announcements-form-general'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                    id='message-input'
                                    name='message'
                                    label={translate('t.message')}
                                    variant='outlined'
                                    error={!!formikProps.errors.message && !!formikProps.touched.message}
                                    value={formikProps.values.message}
                                    fullWidth
                                    helperText={
                                        <HelperText
                                            error={!!formikProps.errors.message && !!formikProps.touched.message}
                                            text={formikProps.errors.message}
                                            defaultText={translate('t.required')}
                                        />
                                    }
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    inputProps={{
                                        form: {
                                            autocomplete: 'off'
                                        },
                                        'data-testid': 'message-input'
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    size='small'
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <UiDateRangePicker2
                                    validFutureDate
                                    dateFrom={DateTime.fromMillis(formikProps.values.dateFrom)}
                                    dateTo={DateTime.fromMillis(formikProps.values.dateTo)}
                                    dateFromLabel={translate('t.date_from')}
                                    dateToLabel={translate('t.date_to')}
                                    onDateChange={(newDate) => {
                                        if (newDate && !newDate[0].invalid && newDate[0] != '') {
                                            formikProps.setFieldValue('dateFrom', newDate[0].valueOf(), true);
                                        } else {
                                            formikProps.setFieldValue('dateFrom', NaN, true);
                                        }

                                        if (newDate && !newDate[1].invalid && newDate[1] != '') {
                                            formikProps.setFieldValue('dateTo', newDate[1].valueOf(), true);
                                        } else {
                                            formikProps.setFieldValue('dateTo', NaN, true);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <Checkbox
                                    testid='all-customers-checkbox'
                                    label={translate('t.all_customers')}
                                    name='allCustomers'
                                    checked={
                                        'allCustomers' in formikProps.values
                                            ? formikProps.values.allCustomers || false
                                            : false
                                    }
                                    onChange={formikProps.handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <Checkbox
                                    testid='active-checkbox'
                                    label={translate('t.active')}
                                    name='active'
                                    checked={formikProps.values.active || false}
                                    onChange={formikProps.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <RegionCustomerSelector
                                    selectedCustomers={formikProps.values.customer}
                                    disabled={
                                        'allCustomers' in formikProps.values ? formikProps.values.allCustomers : false
                                    }
                                    onChange={(_event, newValue) => {
                                        formikProps.setFieldValue('customer', newValue, false);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </FormDiv>
    );

    const WhatsNewForm = (
        <FormDiv>
            <Formik
                initialValues={props.formValues}
                validationSchema={FormSchemas.production}
                enableReinitialize={true}
                onSubmit={(values) => {
                    props.sendWhatNewData(JSON.parse(JSON.stringify(values)));
                }}
            >
                {(formikProps) => {
                    if (isEdit && !(formikProps.values as SendDataProductionProps).dateFrom2) {
                        (formikProps.values as SendDataProductionProps).dateFrom2 = formikProps.values.dateFrom;
                        (formikProps.values as SendDataProductionProps).dateTo2 = formikProps.values.dateTo;
                    }
                    return (
                        <Form id='announcements-form-production'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4} md={4} lg={4}>
                                            <TextField
                                                date-testid='message-input'
                                                id='message-input'
                                                name='message'
                                                label={translate('t.message')}
                                                variant='outlined'
                                                error={!!formikProps.errors.message && !!formikProps.touched.message}
                                                value={formikProps.values.message}
                                                fullWidth
                                                disabled
                                                helperText={
                                                    <HelperText
                                                        error={
                                                            !!formikProps.errors.message &&
                                                            !!formikProps.touched.message
                                                        }
                                                        text={formikProps.errors.message}
                                                        defaultText={translate('t.required')}
                                                    />
                                                }
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                inputProps={{
                                                    form: {
                                                        autocomplete: 'off'
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                size='small'
                                            />
                                        </Grid>
                                        <Grid item xs={8} md={8} lg={8}>
                                            <Autocomplete
                                                options={props.articles?.articles || []}
                                                getOptionLabel={(option) => option.name}
                                                value={defaultWhatsNewValue}
                                                onChange={(e, newValue) => {
                                                    if (newValue?.id) {
                                                        formikProps.setFieldValue('helpjuiceId', newValue.id, true);
                                                        formikProps.setFieldValue(
                                                            'knowledgebaseUrl',
                                                            newValue.url,
                                                            true
                                                        );
                                                    }
                                                }}
                                                size='small'
                                                renderInput={(params) => {
                                                    return (
                                                        <TextField
                                                            {...params}
                                                            date-testid='message-input'
                                                            id='message-input'
                                                            name='helpjuiceId'
                                                            label={translate('t.knowledgebase_url')}
                                                            variant='outlined'
                                                            error={
                                                                'helpjuiceId' in formikProps.errors
                                                                    ? !!formikProps.errors.helpjuiceId &&
                                                                      !!formikProps.touched.message
                                                                    : false
                                                            }
                                                            fullWidth
                                                            helperText={
                                                                <HelperText
                                                                    error={
                                                                        !!formikProps.errors.message &&
                                                                        !!formikProps.touched.message
                                                                    }
                                                                    text={
                                                                        'helpjuiceId' in formikProps.errors
                                                                            ? formikProps.errors.helpjuiceId
                                                                            : ''
                                                                    }
                                                                    defaultText={translate('t.required')}
                                                                />
                                                            }
                                                            onChange={formikProps.handleChange}
                                                            onBlur={formikProps.handleBlur}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            size='small'
                                                        />
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {(announcementsActionsState.action !== CRUD.EDIT || (isEdit && isWhatsNewEurope)) && (
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4} md={4} lg={4}>
                                                <Typography>
                                                    <FontAwesomeIcon icon={faEarthEurope} /> {translate('t.europe')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} md={8} lg={8}>
                                                <UiDateRangePicker2
                                                    validFutureDate
                                                    dateFrom={DateTime.fromMillis(formikProps.values.dateFrom)}
                                                    dateTo={DateTime.fromMillis(formikProps.values.dateTo)}
                                                    dateFromLabel={translate('t.date_from')}
                                                    dateToLabel={translate('t.date_to')}
                                                    onDateChange={(newDate) => {
                                                        if (newDate && !newDate[0].invalid && newDate[0] != '') {
                                                            formikProps.setFieldValue(
                                                                'dateFrom',
                                                                newDate[0].valueOf(),
                                                                true
                                                            );
                                                        } else {
                                                            formikProps.setFieldValue('dateFrom', NaN, true);
                                                        }

                                                        if (newDate && !newDate[1].invalid && newDate[1] != '') {
                                                            formikProps.setFieldValue(
                                                                'dateTo',
                                                                newDate[1].valueOf(),
                                                                true
                                                            );
                                                        } else {
                                                            formikProps.setFieldValue('dateTo', NaN, true);
                                                        }
                                                    }}
                                                    adjustToPeriod={{
                                                        period: 14,
                                                        periodType: 'days'
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {(announcementsActionsState.action !== CRUD.EDIT || (isEdit && !isWhatsNewEurope)) && (
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4} md={4} lg={4}>
                                                <Typography>
                                                    <FontAwesomeIcon icon={faEarthAmerica} /> {translate('t.africa')},{' '}
                                                    {translate('t.australia')}, {translate('t.north_america')},{' '}
                                                    {translate('t.south_america')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} md={8} lg={8}>
                                                <UiDateRangePicker2
                                                    validFutureDate
                                                    dateFrom={DateTime.fromMillis(
                                                        'dateFrom2' in formikProps.values
                                                            ? formikProps.values.dateFrom2 || 0
                                                            : 0
                                                    )}
                                                    dateTo={DateTime.fromMillis(
                                                        'dateTo2' in formikProps.values
                                                            ? formikProps.values.dateTo2 || 0
                                                            : 0
                                                    )}
                                                    dateFromLabel={translate('t.date_from')}
                                                    dateToLabel={translate('t.date_to')}
                                                    adjustToPeriod={{
                                                        period: 14,
                                                        periodType: 'days'
                                                    }}
                                                    onDateChange={(newDate) => {
                                                        if (newDate && !newDate[0].invalid && newDate[0] != '') {
                                                            formikProps.setFieldValue(
                                                                'dateFrom2',
                                                                newDate[0].valueOf(),
                                                                true
                                                            );
                                                        } else {
                                                            formikProps.setFieldValue('dateFrom2', NaN, true);
                                                        }

                                                        if (newDate && !newDate[1].invalid && newDate[1] != '') {
                                                            formikProps.setFieldValue(
                                                                'dateTo2',
                                                                newDate[1].valueOf(),
                                                                true
                                                            );
                                                        } else {
                                                            formikProps.setFieldValue('dateTo2', NaN, true);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid item xs={12} md={12} lg={12}>
                                    <Checkbox
                                        data-testid='active'
                                        label={translate('t.active')}
                                        name='active'
                                        checked={formikProps.values.active || false}
                                        onChange={formikProps.handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </FormDiv>
    );

    return isClone ? (
        GeneralForm
    ) : isEdit ? (
        'helpjuiceId' in props.formValues && props.formValues.helpjuiceId ? (
            WhatsNewForm
        ) : (
            GeneralForm
        )
    ) : (
        <UiWidgetTab
            height={481}
            unit='px'
            margin={0}
            setNumberTab={(tab) => {
                props.setCurrentTab(tab);
            }}
            padding={'0 10px'}
            tab={{
                headers: [
                    {
                        label: translate('t.general'),
                        icon: <></>
                    },
                    {
                        label: translate('p.whats_new_in_itrack'),
                        icon: <></>
                    }
                ],
                constents: [
                    {
                        id: 1,
                        body: GeneralForm
                    },
                    {
                        id: 2,
                        body: WhatsNewForm
                    }
                ]
            }}
        />
    );
};
