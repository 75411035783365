import React, { useEffect, useMemo, useState } from 'react';
import { ReportDetailProps } from './ReportDetail.type';
import { ReportDetailContent } from './ReportDetail.view';
import SensorFaultyReportApi from 'api/SensorFaultyReport';
import SensorFaultyReportVerifiedStatusApi from 'api/SensorFaultyReportVerifiedStatus';
import { Wrapper } from 'helpers/wrapper';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { FaultySensorReporterState } from 'states/component/FaultySensorReporter';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SensorFaultyReportModel } from 'models/SensorFaultyReport.type';
import SensorFaultyReportVerifiedStatusModel from 'models/SensorFaultyReportVerifiedStatus.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { SensorFaultyReportActualSensorStatusQueryKeys } from 'models/SensorFaultyReportActualSensorStatus.type';
import { SensorFaultyReportQueryKeys } from 'models/SensorFaultyReport.type';

const SensorFaultyReportVerifiedStatus = new SensorFaultyReportVerifiedStatusApi();
const SensorFaultyReport = new SensorFaultyReportApi();

const ReportDetail: React.FC<ReportDetailProps> = (props): JSX.Element => {
    const [verifiedStatus, setVerifiedStatus] = useState<number>();
    const [reportComment, setReportComment] = useState<string>('');
    const faultySensorReporterState = useRecoilValue(FaultySensorReporterState);
    const queryClient = useQueryClient();

    const { t: translate } = useTranslation();
    const { fromUTCToTimezone } = useConverter();

    const { isFetching, data: reportDetail } = useQuery<SensorFaultyReportModel, boolean>(
        [SensorFaultyReportQueryKeys.getById, props.detailId],
        () => SensorFaultyReport.getById({ id: props.detailId }),
        {
            enabled: props.detailId !== undefined,
            staleTime: cacheTimeToMilliseconds(10, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(10, 'minutes'),
            refetchOnWindowFocus: false
        }
    );

    const { isFetching: verifiedStatusFetching, data: verifiedStatusData } = useQuery<
        { codeBook: SensorFaultyReportVerifiedStatusModel[] },
        boolean
    >([SensorFaultyReportActualSensorStatusQueryKeys.get], () => SensorFaultyReportVerifiedStatus.get({}), {
        enabled: props.detailId !== undefined,
        staleTime: cacheTimeToMilliseconds(2, 'hours'),
        cacheTime: cacheTimeToMilliseconds(2, 'hours'),
        refetchOnWindowFocus: false
    });

    const cellsShow = useMemo(
        () => [
            {
                label: translate('t.problem'),
                value: translate(`t.${reportDetail?.problem}`)
            },
            {
                label: translate('t.status'),
                value: translate(`t.${reportDetail?.verifiedStatus.name.toLowerCase()}`)
            },
            {
                label: translate('t.disabled_reporting'),
                value: reportDetail?.action ? translate('t.yes') : translate('t.no')
            },
            {
                label: translate('t.reported_at'),
                value: reportDetail?.createdAt ? fromUTCToTimezone(reportDetail?.createdAt) : '-'
            },
            {
                label: translate('t.reported_by'),
                value: `${reportDetail?.user?.firstname} ${reportDetail?.user?.lastname}`
            }
        ],

        [reportDetail]
    );

    const updateVerifyingAllowed = () => {
        const canBeVerified = verifiedStatus !== reportDetail?.verifiedStatus?.id;
        let data = {};
        if (canBeVerified) {
            data = {
                verifiedStatus,
                comment: reportComment
            };
        }
        props.setVerifyingAllowed({ status: canBeVerified, data });
    };

    useEffect(() => {
        if (faultySensorReporterState.sensorId) {
            queryClient.invalidateQueries(['faultySensorReportDetail']);
            queryClient.invalidateQueries(['verifiedStatusFalutyReport']);
        }
    }, [faultySensorReporterState]);

    useEffect(() => {
        updateVerifyingAllowed();
    }, [verifiedStatus, reportComment]);

    return (
        <ReportDetailContent
            data-testid='ReportDetail-testid'
            reportDetail={reportDetail}
            isLoading={isFetching}
            isLoadingVerifiedStatus={verifiedStatusFetching}
            verifiedStatus={verifiedStatus}
            reportComment={reportComment}
            verifiedStatusData={verifiedStatusData?.codeBook}
            cells={cellsShow}
            updateReportComment={setReportComment}
            updateVerifiedStatus={setVerifiedStatus}
        />
    );
};

export default Wrapper(ReportDetail);
