import React from 'react';
import { CommentUpdateContent } from './CommentUpdate.view';
import { CommentUpdateProps, InitialValues } from './CommentUpdate.type';
import Sensors from 'api/Sensors';
import { Success } from '../../Popup/Popup';
import { useMutation } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Wrapper } from 'helpers/wrapper';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';

const sensorsApi = new Sensors();
const CommentUpdate: React.FC<CommentUpdateProps> = ({ commentId, analysisId, showComment, testid }): JSX.Element => {
    const { t: translate } = useTranslation();
    const queryTableFn = useRecoilValue(QueryFn(`firstSensorPage-QueryFn`));
    const initialValues: InitialValues = {
        comment: showComment ?? null
    };

    const { mutate: updateSensorIdCommentMutate, isLoading: updateSensorIdCommentLoading } = useMutation(
        sensorsApi.patchSensorsComment,
        {
            onSuccess: () => {
                Success({
                    text: translate('t.updated_comment')
                });
                queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            }
        }
    );

    const { mutate: updateSensorIdAnalysisMutate, isLoading: updateSensorIdAnalysisLoading } = useMutation(
        sensorsApi.patchSensorsAnalysis,
        {
            onSuccess: () => {
                Success({
                    text: translate('t.updated_analysis')
                });
                queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            }
        }
    );

    const handleSubmit = (values: InitialValues): void => {
        if (commentId != undefined) {
            updateSensorIdCommentMutate({ commentId: commentId, comment: values });
        }
        if (analysisId != undefined) {
            updateSensorIdAnalysisMutate({ analysisId: analysisId, analysis: values });
        }
    };

    const hasOnlySpaces = (str): boolean => {
        return /^\s*$/.test(str);
    };

    return (
        <CommentUpdateContent
            data-testid={testid || 'CommentSensorsUpdate-testid'}
            showComment={hasOnlySpaces(showComment) ? null : showComment}
            title={commentId ? 'comment' : 'analysis'}
            initialValues={initialValues}
            loading={commentId ? updateSensorIdCommentLoading : updateSensorIdAnalysisLoading}
            handleSubmit={handleSubmit}
        />
    );
};

export default Wrapper(CommentUpdate);
