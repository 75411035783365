export type DeviceFaultyStatusModel = {
    id: number;
    statusName: string;
};

export type DeviceFaultyStatusAssigned = {
    id: number;
    status: DeviceFaultyStatusModel;
};

export type PatchDeviceFaultyStatus = ({ id: number } | { status: number })[];

export enum DeviceFaultyStatusQueryKeys {
    getCodebook = 'deviceFaultyStatus-getCodebook'
}
