import React, { useEffect, useState } from 'react';
import { TeamShiftTimeActionsContent } from './TeamShiftTimeActions.view';
import { TeamShiftTimeActionsProps, InitialValues } from './TeamShiftTimeActions.type';
import { Wrapper } from 'helpers/wrapper';
import { Success, Warning } from 'components/Popup/Popup';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import TeamShiftTime from 'api/TeamShiftTime';
import { CRUD } from 'variables';
import { ShowTeamShiftTimeActionsModal } from 'states/global/Modal';
import { TeamShiftTimeActionsState } from './TeamShiftTimeActions.atom';
import { DefaultRequestPropsType, PatchPutByIdType } from 'helpers/api/type';
import { TeamShiftTimePayload } from 'models/TeamShiftTime.type';
import { DateTime } from 'luxon';
import TeamShiftName from 'api/TeamShiftName';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TeamShiftNameModelResponse } from 'models/TeamShiftName.type';
import useTeamShiftTimeFreeShiftsChecker from './TeamShiftTimeFreeShiftsChecker';
import { TimeRange } from '../ShiftTimeCalendarAction/ShiftTimeCalendarAction.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';

const teamShiftTimeApi = new TeamShiftTime();
const teamShiftNameApi = new TeamShiftName();

const TeamShiftTimeActions: React.FC<TeamShiftTimeActionsProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const queryFn = useRecoilValue(QueryFn(`teamShiftTime-QueryFn`));
    const showTeamShiftTimeActionsModal = useSetRecoilState(ShowTeamShiftTimeActionsModal);
    const [teamShiftTimeActionsState, setTeamShiftTimeActionsState] = useRecoilState(TeamShiftTimeActionsState);
    const initialValuesDefault = {
        timeFrom: DateTime.local(),
        timeTo: DateTime.local().plus({ hours: 1 }),
        teamShiftName: null
    };
    const [initialValues, setInitialValues] = useState<InitialValues>(initialValuesDefault);
    const { isRangeFreeTime } = useTeamShiftTimeFreeShiftsChecker();
    const { fromUserTimezonetoUTC, fromUTCtoUserTimezone } = useConverter();
    const currentDate: DateTime = DateTime.now();

    const { data: teamShiftNameData } = useQuery<TeamShiftNameModelResponse>(
        ['GetTeamShiftNameSelect'],
        () => teamShiftNameApi.get({}),
        {
            refetchOnWindowFocus: false
        }
    );

    const { mutate: addTeamShiftTime, isLoading: addTeamShiftTimeLoading } = useMutation<
        DefaultRequestPropsType,
        unknown,
        TeamShiftTimePayload
    >(teamShiftTimeApi.addTeamShiftTime, {
        onSuccess: (data) => {
            if (data) {
                Success({
                    text: `${translate('t.team_shift_time')} ${translate('p.has_been_added')}`
                });
                queryFn.refetch instanceof Function && queryFn.refetch();
                showTeamShiftTimeActionsModal(false);
                setTeamShiftTimeActionsState({
                    action: CRUD.EMPTY,
                    id: '',
                    values: undefined
                });
                setInitialValues(initialValuesDefault);
            }
        },
        onError: (e) => {
            console.error(e);
        }
    });

    const { mutate: updateTeamShiftTime, isLoading: updateTeamShiftTimeLoading } = useMutation<
        DefaultRequestPropsType,
        unknown,
        PatchPutByIdType<TeamShiftTimePayload, DefaultRequestPropsType>
    >(teamShiftTimeApi.patchById, {
        onSuccess: () => {
            Success({ text: `${translate('t.team_shift_time')} ${translate('p.has_been_edited')}` });
            queryFn.refetch instanceof Function && queryFn.refetch();
            showTeamShiftTimeActionsModal(false);
            setTeamShiftTimeActionsState({
                action: CRUD.EMPTY,
                id: '',
                values: undefined
            });
            setInitialValues(initialValuesDefault);
        }
    });

    const handleSubmit = async (values: InitialValues): Promise<void> => {
        const startTimeUTC = fromUserTimezonetoUTC({ date: values.timeFrom, customFormat: 'HH:mm:ss', format: 'time' });
        const endTimeUTC = fromUserTimezonetoUTC({ date: values.timeTo, customFormat: 'HH:mm:ss', format: 'time' });
        const timeRange: TimeRange = { startTime: startTimeUTC, endTime: endTimeUTC };
        const getIsRangeFreeTime = await isRangeFreeTime(Number(teamShiftTimeActionsState?.id) || null, timeRange);

        const valuesPyload: TeamShiftTimePayload = {
            startTime: startTimeUTC,
            endTime: endTimeUTC,
            teamShiftName: Number(values?.teamShiftName?.id)
        };

        if (getIsRangeFreeTime) {
            if (teamShiftTimeActionsState.action === CRUD.ADD) {
                addTeamShiftTime(valuesPyload);
            } else {
                updateTeamShiftTime({
                    id: teamShiftTimeActionsState.id ? Number(teamShiftTimeActionsState.id) : NaN,
                    data: valuesPyload
                });
            }
        } else {
            Warning({ text: translate('p.time_from_or_to_recorded'), customSetting: { duration: 7000 } });
        }
    };

    useEffect(() => {
        if (teamShiftTimeActionsState?.values?.timeFrom && teamShiftTimeActionsState?.values?.timeTo) {
            setInitialValues({
                timeFrom: fromUTCtoUserTimezone({
                    date: teamShiftTimeActionsState?.values?.timeFrom?.replace(
                        '1970-01-01',
                        currentDate.toFormat('yyyy-MM-dd')
                    ),
                    format: 'dateTime',
                    returnObjectLuxon: true
                }),
                timeTo: fromUTCtoUserTimezone({
                    date: teamShiftTimeActionsState?.values?.timeTo?.replace(
                        '1970-01-01',
                        currentDate.toFormat('yyyy-MM-dd')
                    ),
                    format: 'dateTime',
                    returnObjectLuxon: true
                }),
                teamShiftName: teamShiftTimeActionsState?.values?.teamShiftName
            });
        }

        return () => {
            setInitialValues(initialValuesDefault);
        };
    }, [teamShiftTimeActionsState]);

    return (
        <TeamShiftTimeActionsContent
            data-testid='TeamShiftTimeActions-testid'
            addTeamShiftTimeLoading={addTeamShiftTimeLoading || updateTeamShiftTimeLoading}
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            teamList={teamShiftNameData ? teamShiftNameData?.items : []}
        />
    );
};

export default Wrapper(TeamShiftTimeActions);
