import React from 'react';
import { UiIconFontAwesome } from './UiIcon.style';
import { UiIconViewProps } from './UiIcon.type';

export const UiIconContent: React.FC<UiIconViewProps> = ({
    icon,
    size,
    colorTheme,
    color,
    transform,
    ...props
}): JSX.Element => {
    return (
        <UiIconFontAwesome
            {...props}
            icon={icon}
            size={size}
            $colorTheme={colorTheme}
            color={color}
            transform={transform}
        />
    );
};
