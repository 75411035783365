import React from 'react';
import { useRecoilValue } from 'recoil';
import {
    ButtonStatistics,
    DeviceStatisticsTableContainer,
    GridContainer,
    LastTransmissionSpan,
    PercentageGraph,
    Span,
    Text,
    TextBar,
    TextPercentage
} from './DeviceStatisticsTable.style';
import { DeviceStatisticsTableViewProps } from './DeviceStatisticsTable.type';
import { useTranslation } from 'react-i18next';
import Widget from '../../Ui/Components/UiWidget/UiWidget';
import { faCalendar, faChartColumn } from '@fortawesome/pro-solid-svg-icons';
import { Grid } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import SensorButtonAround from '../SensorButtonAround/SensorButtonAround';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import useConverter from 'components/CustomHooks/Converter/Converter';

export const DeviceStatisticsTableContent: React.FC<DeviceStatisticsTableViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    const { fromUTCToTimezone } = useConverter();
    const parsePercentageData = (percentage) => {
        let color = '#000';

        if (percentage >= 90) {
            color = '#3c4444';
        } else {
            color = '#3c4444'; // '#5f945b';
        }

        percentage = percentage ? percentage : 0;

        return `linear-gradient(to right, ${color}
            ${percentage}%, rgb(200,200,200) 1%, rgb(200,200,200) 
            ${100 - percentage - 1}%)`;
    };

    return (
        <DeviceStatisticsTableContainer data-testid='DeviceStatisticsTableContent'>
            <Widget
                data-testid='AdministrationTableContent'
                avatar={
                    <UiIcon
                        icon={faChartColumn}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#333',
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                title={translate('t.hub_statistics')}
                loading={false}
                content={
                    props.statisticsLoading ? (
                        <UiLoadingPage size='25px' />
                    ) : (
                        <Grid container>
                            <GridContainer container xs={12} md={12}>
                                <Grid item xs={6} md={12}>
                                    <Text>
                                        <UiIcon
                                            icon={faCalendar}
                                            size='lg'
                                            fixedWidth
                                            color={applyStyleByMode({
                                                styleOld: '#333',
                                                theme: ThemeMode?.mode,
                                                light: '#0000008a',
                                                dark: WHITE
                                            })}
                                        />
                                        <LastTransmissionSpan>
                                            {translate('t.last_time_transmitted')}:
                                        </LastTransmissionSpan>
                                        {fromUTCToTimezone(props.statisticsData?.lastTransmission || '', true)}
                                        <LastTransmissionSpan $last>
                                            {props.getDiffFromLastTransmitted(props.statisticsData?.lastTransmission)}
                                        </LastTransmissionSpan>
                                    </Text>
                                </Grid>

                                <Grid item xs={9} md={9}>
                                    <TextBar>{translate('t.used_space_disk')}</TextBar>
                                    <TextPercentage>{100 - (props.statisticsData?.disk ?? 0) ?? '-'}%</TextPercentage>
                                </Grid>
                                <Grid item xs={9} md={9}>
                                    <PercentageGraph
                                        style={{
                                            backgroundImage: parsePercentageData(
                                                100 - (props.statisticsData?.disk ?? 0)
                                            )
                                        }}
                                    ></PercentageGraph>
                                </Grid>

                                <Grid item xs={9} md={9}>
                                    <TextBar>{translate('t.memory_usage')}</TextBar>
                                    <TextPercentage>{props.statisticsData?.memory ?? '-'}%</TextPercentage>
                                </Grid>
                                <Grid item xs={9} md={9}>
                                    <PercentageGraph
                                        style={{
                                            backgroundImage: parsePercentageData(props.statisticsData?.memory)
                                        }}
                                    ></PercentageGraph>
                                </Grid>

                                <Grid item xs={9} md={9}>
                                    <TextBar>{translate('t.cpu_usage')}</TextBar>
                                    <TextPercentage>{props.statisticsData?.cpu ?? '-'}%</TextPercentage>
                                </Grid>
                                <Grid item xs={9} md={9}>
                                    <PercentageGraph
                                        style={{ backgroundImage: parsePercentageData(props.statisticsData?.cpu) }}
                                    ></PercentageGraph>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Span>
                                        {translate('t.battery_status')}: {props.statisticsData?.battery} mV
                                    </Span>
                                    {'  '}
                                    <Span>
                                        {translate('t.power_supply')}: {props.statisticsData?.powerSource} mV
                                    </Span>
                                </Grid>

                                {!props.distinctCustomer && (
                                    <div>
                                        <SensorButtonAround
                                            deviceId={props.deviceId}
                                            lastTimeConnected={props.statisticsData?.lastTransmission || null}
                                            applyCustomStyle
                                        />
                                        <ButtonStatistics
                                            onClick={() => {
                                                props.refetchGps();
                                                props.handleModalGps();
                                            }}
                                        >
                                            {translate('t.gps_statistics')}
                                        </ButtonStatistics>
                                        <ButtonStatistics
                                            onClick={() => {
                                                props.handleModalBattery();
                                            }}
                                        >
                                            {translate('t.battery_voltage')}
                                        </ButtonStatistics>
                                    </div>
                                )}
                            </GridContainer>
                        </Grid>
                    )
                }
            ></Widget>
        </DeviceStatisticsTableContainer>
    );
};
