import React, { useCallback, useState } from 'react';
import { ReportPermissionsContent } from './ReportPermissions.view';
import { ReportPermissionsProps } from './ReportPermissions.type';
import { Wrapper } from 'helpers/wrapper';
import { useMutation, useQuery } from '@tanstack/react-query';
import RoleApi from 'api/Role';
import { RoleCodeBook, RoleQueryKeys } from 'models/Role.type';
import ReportingApi from 'api/Reporting';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { ReportQueryKeys } from 'models/Report.type';

const Role = new RoleApi();
const Reporting = new ReportingApi();

const ReportPermissions: React.FC<ReportPermissionsProps> = (): JSX.Element => {
    const [selectedRole, setSelectedRole] = useState<RoleCodeBook>({
        id: 3,
        name: 'ROLE_TECHNICAL_USER'
    });
    const { data: availableRoles } = useQuery<RoleCodeBook[], Error, { codeBook: RoleCodeBook[] }>(
        [RoleQueryKeys.getAvailable],
        () => Role.getAvailable(),
        {
            refetchOnWindowFocus: true,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours')
        }
    );
    const { data: availableAsignReports, refetch: availableAssignRefetch } = useQuery(
        [ReportQueryKeys.getAvailableToAssign],
        () => Reporting.getAvailableToAssign(),
        {
            refetchOnWindowFocus: true,
            staleTime: cacheTimeToMilliseconds(5, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(5, 'minutes')
        }
    );

    const { data: assignedReports, refetch: assignedRefetch } = useQuery(
        [ReportQueryKeys.getAssignedToRoles],
        () => Reporting.getAssignedToRoles(),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(5, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(5, 'minutes')
        }
    );

    const { mutate: removeFromRoleMutate, isLoading: removeFromRoleMutateLoading } = useMutation(
        Reporting.removeFromRole,
        {
            onSuccess: () => {
                availableAssignRefetch();
                assignedRefetch();
            }
        }
    );

    const { mutate: assignToRoleMutate, isLoading: assignToRoleMutateLoading } = useMutation(Reporting.assignToRole, {
        onSuccess: () => {
            availableAssignRefetch();
            assignedRefetch();
        }
    });

    const removeFromRole = async (reportId: number) => {
        if (selectedRole?.id) {
            removeFromRoleMutate({
                reportingId: reportId,
                roleId: selectedRole?.id
            });
        }
    };

    const assignToRole = async (reportId: number) => {
        if (selectedRole?.id) {
            assignToRoleMutate({
                reportingId: reportId,
                roleId: selectedRole?.id
            });
        }
    };

    const reportPermissions = useCallback(() => {
        const filteredReportsByRole = assignedReports?.filter((reports) => reports.roleId === selectedRole?.id);
        const assignedReportsIds = filteredReportsByRole?.map((reports) => reports.reportingId);
        const assignedReportsData = availableAsignReports?.filter((report) =>
            (assignedReportsIds || []).includes(report.id)
        );
        const availableReportsData = availableAsignReports?.filter(
            (report) => !(assignedReportsIds || []).includes(report.id)
        );
        return { assignedReportsData, availableReportsData };
    }, [availableAsignReports, assignedReports, selectedRole]);

    return (
        <ReportPermissionsContent
            availableRoles={availableRoles?.codeBook ?? []}
            availableAsignReports={availableAsignReports}
            selectedRole={selectedRole}
            assignToRoleLoading={assignToRoleMutateLoading}
            removeFromRoleLoading={removeFromRoleMutateLoading}
            reportPermissions={reportPermissions}
            setSelectedRole={setSelectedRole}
            assignToRole={assignToRole}
            removeFromRole={removeFromRole}
            data-testid={'ReportPermissions-testid'}
        />
    );
};

export default Wrapper(ReportPermissions);
