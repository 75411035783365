import React, { useEffect } from 'react';
import { TemperatureExposureContent } from './TemperatureExposure.view';
import { TemperatureExposureProps } from './TemperatureExposure.type';
import { Wrapper } from 'helpers/wrapper';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import WheelApi from 'api/Wheel';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { SelectedVehicle } from 'states/global/Statistics';
import { VehicleWheels } from './TemperatureExposure.atom';
import { cacheTimeToMilliseconds, getCache, isCacheAvailable } from 'helpers/cache';
import { TyreDetailResponse, WheelQueryKeys } from 'models/Wheel.type';

const Wheel = new WheelApi();

const TemperatureExposure: React.FC<TemperatureExposureProps> = (): JSX.Element => {
    const selectedVehicle = useRecoilValue(SelectedVehicle);
    const resetSelectedVehicle = useResetRecoilState(SelectedVehicle);
    const queryCache = useQueryClient().getQueryCache();
    const setVehicleWheels = useSetRecoilState(VehicleWheels);

    const { isLoading, isFetching, refetch } = useQuery(
        [WheelQueryKeys.getTyresDetail, selectedVehicle.id],
        () => {
            if (selectedVehicle.id !== 0) {
                return Wheel.getTyresDetail(selectedVehicle.id);
            }
        },
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(15, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(15, 'minutes'),
            onSuccess: (data) => {
                if (data) {
                    const sortedData = [...data].sort(
                        (wheel1, wheel2) => wheel1.wheel.customPosition - wheel2.wheel.customPosition
                    );
                    setVehicleWheels({ isLoading: false, wheels: sortedData });
                }
            },
            enabled: false
        }
    );

    useEffect(() => {
        if (!isCacheAvailable([WheelQueryKeys.getTyresDetail, selectedVehicle.id], queryCache)) {
            refetch();
        } else if (getCache([WheelQueryKeys.getTyresDetail, selectedVehicle.id], queryCache)[0]?.state?.data) {
            const sortedData = [
                ...(getCache([WheelQueryKeys.getTyresDetail, selectedVehicle.id], queryCache)[0]?.state
                    ?.data as TyreDetailResponse)
            ].sort((wheel1, wheel2) => wheel1.wheel.customPosition - wheel2.wheel.customPosition);
            setVehicleWheels({ isLoading: false, wheels: sortedData });
        }
    }, [selectedVehicle.id]);

    useEffect(() => {
        return () => {
            resetSelectedVehicle();
        };
    }, []);

    return <TemperatureExposureContent isLoading={isLoading || isFetching} data-testid='TemperatureExposure-testid' />;
};

export default Wrapper(TemperatureExposure);
