import React from 'react';
import { TableBodyType, TypeStage } from '../SystemTotals.type';
import { CustomTableHead, HeaderTable, TableCustom, ProgressBarWrapper } from '../SystemTotals.style';
import SystemTotalsSimpleTable from './SystemTotalsSimpleTablet.view';
import SystemTotalsAdvancedTable from './SystemTotalsAdvancedTablet.view';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { SystemTotalsStage } from '../SystemTotals.atom';
import { GetSystemTotalsData } from 'components/Queries/SystemTotals/SystemTotals';
import UiProgressBar from 'components/Ui/Components/UiCircularProgress';
import { cacheTimeToMilliseconds } from 'helpers/cache';

const Header = () => {
    const { t: translate } = useTranslation();

    return (
        <tr>
            <th colSpan={2} align='center' />
            <HeaderTable colSpan={2}>{translate('t.operating')}</HeaderTable>
            <HeaderTable colSpan={2}>{translate('t.maintenance')}</HeaderTable>
            <HeaderTable colSpan={2}>{translate('t.out_of_service')}</HeaderTable>
        </tr>
    );
};

const Body = ({ data, isLoading }: TableBodyType) => {
    const systemTotalsStage = useRecoilValue(SystemTotalsStage);

    if (data && !isLoading) {
        if (systemTotalsStage === TypeStage.SIMPLE) {
            return <SystemTotalsSimpleTable data={data.systemTotals} />;
        }
        return <SystemTotalsAdvancedTable data={data.systemTotals} />;
    }
    return (
        <tbody>
            <tr>
                <td>
                    <ProgressBarWrapper>
                        <UiProgressBar />
                    </ProgressBarWrapper>
                </td>
            </tr>
        </tbody>
    );
};

const SystemTotalsMainTable = () => {
    const systemTotalsStage = useRecoilValue(SystemTotalsStage);

    return (
        <TableCustom className='system-totals table-bordered table table-striped table-hover'>
            <CustomTableHead>{systemTotalsStage !== TypeStage.SIMPLE && <Header />}</CustomTableHead>
            <GetSystemTotalsData
                options={{
                    refetchInterval: 30000,
                    staleTime: cacheTimeToMilliseconds(5, 'minutes'),
                    cacheTime: cacheTimeToMilliseconds(5, 'minutes')
                }}
            >
                {({ data, isLoading }) => <Body data={data} isLoading={isLoading} />}
            </GetSystemTotalsData>
        </TableCustom>
    );
};

export default SystemTotalsMainTable;
