import React from 'react';
import { ContainerPopper, HeaderUiPopper, UiPopperContainer } from './UiPopper.style';
import { UiPopperViewProps } from './UiPopper.type';
import { useTranslation } from 'react-i18next';
import Popper from '@mui/material/Popper';
import { ClickAwayListener, Fade, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UiIconButton from '../Components/UiIconButton/UiIconButton';

export const UiPopperContent: React.FC<UiPopperViewProps> = ({
    content,
    anchorEl,
    open,
    handleClosePopper,
    handleClickOpen,
    rootElemnet,
    titlePopper,
    popperPlacementType
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const RootElement: React.ReactElement = rootElemnet || (
        <UiIconButton testid='OpenLegendPopper-Button'>
            <InfoOutlinedIcon />
        </UiIconButton>
    );

    return (
        <UiPopperContainer data-testid='UiPopperContainer-view'>
            <Popper
                sx={{ zIndex: 1700, p: 2 }}
                open={open}
                anchorEl={anchorEl}
                disablePortal={false}
                placement={popperPlacementType || 'right-end'}
                transition
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClosePopper}>
                        <Fade {...TransitionProps} timeout={350}>
                            <ContainerPopper sx={{ zIndex: 1700, p: 1.5 }}>
                                <HeaderUiPopper>
                                    <Typography variant='body1' className='legend-header'>
                                        {titlePopper || translate('t.legend')}
                                        <UiIconButton
                                            aria-label='close'
                                            onClick={() => {
                                                handleClosePopper();
                                            }}
                                            testid='NotificationTireTresholdsLegend-ClosePopup-Button'
                                        >
                                            <CloseIcon fontSize='small' />
                                        </UiIconButton>
                                    </Typography>
                                </HeaderUiPopper>
                                {content}
                            </ContainerPopper>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
            {React.cloneElement(RootElement, {
                onClick: handleClickOpen()
            })}
        </UiPopperContainer>
    );
};
