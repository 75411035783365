import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { AlertsFilterVehiclesByListViewContent } from './AlertsFilterVehiclesByList.style';
import { AlertsFilterVehiclesByListViewProps } from './AlertsFilterVehiclesByList.type';
import { useTranslation } from 'react-i18next';
import { FilterIds, SelectedAvailableFilters } from '../AlertsAvailableFilters/atom';
import { AlertInfo } from '../AlertsAffectedVehiclesList/AlertsAffectedVehiclesList.style';
import AlertsFilterVehiclesInput from '../AlertsFilterVehiclesInput';
import AlertsConfigurationFilterInput from '../AlertsConfigurationFilterInput/AlertsConfigurationFilterInput';
import AlertsFilterVehicleInputContainer from '../AlertsFilterVehicleInputContainer';
import { AvailableFilterBox } from '../AlertsAvailableFiltersList/AlertsAvailableFiltersList.style';
import { useTheme } from '@mui/material';
import VehicleModelApi from 'api/VehicleModel';
import VehicleApi from 'api/Vehicle';
import { AvailableFilter } from '../AlertsAvailableFilters/AlertsAvailableFilters.type';
import VehicleTypeApi from 'api/VehicleType';

const VehicleModel = new VehicleModelApi();
const Vehicle = new VehicleApi();
const VehicleType = new VehicleTypeApi();

export const AlertsFilterVehiclesByListContent: React.FC<AlertsFilterVehiclesByListViewProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const selectedFilters = useRecoilValue(SelectedAvailableFilters);
    const theme = useTheme();

    const FilterContent = useCallback((props: { filter: AvailableFilter }) => {
        const content = {
            [FilterIds.VEHICLE_CONFIGURATION]: <AlertsConfigurationFilterInput filter={props.filter} />,
            [FilterIds.VEHICLE]: (
                <AlertsFilterVehiclesInput
                    filter={props.filter}
                    isLazyloading
                    query={Vehicle.getTable}
                    queryId='vehicle-dropdown'
                    defaultFilter={[
                        {
                            id: 'hasWheel',
                            value: {
                                value: '1',
                                name: 'hasWheel'
                            }
                        }
                    ]}
                />
            ),
            [FilterIds.VEHICLE_MODEL]: (
                <AlertsFilterVehiclesInput
                    filter={props.filter}
                    query={VehicleModel.getUsed}
                    labelTranslationKey='t.vehicle_model'
                    optionKey={(object: any) => {
                        return object?.vehicleManufacturer?.manufacturer && object?.modelName
                            ? `${object?.vehicleManufacturer?.manufacturer} - ${object?.modelName}`
                            : '';
                    }}
                    queryId='vehicle-model-dropdown'
                />
            ),
            [FilterIds.DEFAULT]: (
                <AvailableFilterBox theme={theme} isDisabled={false} marginLeft={'0 !important'}>
                    {translate('t.default_alert_settings')}
                </AvailableFilterBox>
            ),
            [FilterIds.VEHICLE_GROUP]: (
                <AlertsFilterVehiclesInput
                    filter={props.filter}
                    query={VehicleType.get}
                    queryId='vehicle-type-dropdown'
                    optionKey={(object: any) => {
                        return object.type;
                    }}
                    labelTranslationKey='t.vehicle_type'
                />
            )
        };
        return content[props.filter.id];
    }, []);

    return (
        <AlertsFilterVehiclesByListViewContent data-testid='AlertsFilterVehiclesByListContent'>
            {!selectedFilters.length ? (
                <AlertInfo severity='info' data-testid='AlertsNoFiltersSelected'>
                    {translate('t.no_filters_selected')}
                </AlertInfo>
            ) : (
                selectedFilters.map((filter, index) => {
                    return (
                        <AlertsFilterVehicleInputContainer filter={filter} key={index}>
                            <FilterContent filter={filter} />
                        </AlertsFilterVehicleInputContainer>
                    );
                })
            )}
        </AlertsFilterVehiclesByListViewContent>
    );
};
