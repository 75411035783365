import { atom } from 'recoil';

export type WheelPrediction = {
    id: number;
    wheelId: number;
    position: number;
    criticalTemperature: number;
    criticalTemperatureAt: string;
    createdAt: string;
    measuredAt: string;
    levelAlert?: number;
    remainingHours?: string;
    notificationEventId?: number;
    notificationEventLevel: 0 | 1 | 2 | 3 | undefined;
};

export type TemperaturePredictionActionMenuProps = {
    isOpen: boolean;
    positionX: number;
    positionY: number;
    data?: {
        vehicleName?: string;
        counterActionsExists?: boolean;
        wheelPredictions?: Partial<WheelPrediction>[];
        vehicleId?: number;
    };
};

export const DefaultTempPredictionMenuAtom = {
    isOpen: false,
    positionX: 0,
    positionY: 0
};

export const TemperaturePredictionMenuAtom = atom<TemperaturePredictionActionMenuProps>({
    key: 'TemperaturePredictionMenuAtom',
    default: DefaultTempPredictionMenuAtom
});
