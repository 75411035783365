import React from 'react';
import { Skeleton } from '@mui/material';
import { ShiftTImeCalendarLoaderLoaderContainer } from './ShiftTimeCalendar.style';

const ShiftTImeCalendarLoader: React.FC = (): JSX.Element => {
    return (
        <ShiftTImeCalendarLoaderLoaderContainer data-testid='ShiftTImeCalendarLoader-Container'>
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />

            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />

            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />

            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />

            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />

            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
            <Skeleton variant='rounded' width='19.2%' height={100} />
        </ShiftTImeCalendarLoaderLoaderContainer>
    );
};

export default ShiftTImeCalendarLoader;
