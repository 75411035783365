import { useRecoilValue } from 'recoil';
import * as math from 'mathjs';
import { UserInfo, UserUnits } from '../../../states/global/User';
import {
    ConverterFromServerProps,
    ConverterFromUserProps,
    ConverterTypeEnum,
    HideData,
    PressureUnits,
    UseConverterReturnType,
    generalConvertTimezoneType
} from './Converter.type';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { NotValue } from 'variables';

const units = {
    psi: 'psi',
    bar: 'bar',
    kpa: 'kPa',
    c: 'degC',
    f: 'degF',
    'km/h': 'km/h',
    mph: 'mi/h',
    'm/s2': 'm/s^2',
    'ft/s2': 'ft/s^2',
    m: 'm',
    yard: 'yard',
    ft: 'ft',
    km: 'km',
    mi: 'mi'
};

const serverUnits = {
    acceleration: 'm/s^2',
    altitude: 'm',
    distance: 'km',
    pressure: 'psi',
    speed: 'km/h',
    temperature: 'degC'
};

const useConverter = (): UseConverterReturnType => {
    const userInfo = useRecoilValue(UserInfo);
    const userUnits = useRecoilValue(UserUnits);
    const { t: translate } = useTranslation();

    const fromServerToUserUnit = ({
        type,
        value,
        displayUnits = false,
        fixed = 0,
        displayIfEmpty = '',
        toType
    }: ConverterFromServerProps): string => {
        let userUnit = units[userUnits[toType || type]];
        let serverUnit = serverUnits[type];

        if (value === null || value === undefined) return displayIfEmpty;
        if (userUnit.toLowerCase() === PressureUnits.KPA) fixed = 0;

        return `${math
            .unit(value, serverUnit)
            .toNumber(userUnit)
            .toFixed(userUnit === PressureUnits.BAR ? 3 : fixed)}${
            displayUnits ? ` ${displayUserUnits[toType || type]}` : ''
        }`;
    };

    const fromUserToServerUnit = ({ type, value, fixed = 0 }: ConverterFromUserProps): number => {
        if (value === null || value === undefined) return 0;
        return Number(math.unit(value, units[userUnits[type]]).toNumber(serverUnits[type]).toFixed(fixed));
    };

    const convertType = {
        acceleration: ConverterTypeEnum.ACCELERATION,
        altitude: ConverterTypeEnum.ALTITUDE,
        distance: ConverterTypeEnum.DISTANCE,
        pressure: ConverterTypeEnum.PRESSURE,
        speed: ConverterTypeEnum.SPEED,
        temperature: ConverterTypeEnum.TEMPERATURE
    };

    const displayUserUnits = {
        acceleration: units[userUnits[convertType.acceleration]],
        altitude: units[userUnits[convertType.altitude]],
        distance: units[userUnits[convertType.distance]],
        pressure: units[userUnits[convertType.pressure]],
        speed: units[userUnits[convertType.speed]].replace('mi/h', 'mph'),
        temperature: units[userUnits[convertType.temperature]].replace('deg', '°')
    };

    /**
     *hexToDecimal and decimalToHex will be not necessary when every
     *endpoint will be returning hexSerialNumber instead of serialNumber
     */
    const decimalToHex = (value: string): string => {
        if (!value) return '';
        const serialNumber: number = parseInt(value, 10);
        let parsedSerialNumber: string = serialNumber.toString(16).toUpperCase();

        if (serialNumber > 268435455 && serialNumber < 268500992) {
            const prefixes = { '1000': 'T', '1001': 'H' };
            parsedSerialNumber = `${prefixes[parsedSerialNumber.substring(0, 4)]}${parsedSerialNumber.substring(4)}`;
        }

        return parsedSerialNumber;
    };

    const hexToDecimal = (value: string): string => {
        return parseInt(value, 16).toString(10);
    };

    const secondToDateTime = (seconds: number, hideData?: HideData): string => {
        const date = {
            days: Math.floor(seconds / 60 / 60 / 24),
            hours: Math.floor(seconds / 60 / 60) % 24,
            minutes: Math.floor(seconds / 60) % 60,
            seconds: seconds % 60
        };
        const dayString =
            date.days === 1 ? `${date.days} ${translate('t.day')}` : `${date.days} ${translate('t.days')}`;
        const hourString = date.hours === 1 ? translate('t.hour') : translate('t.hours');
        const minuteString = date.minutes === 1 ? translate('t.minute') : translate('t.minutes');

        return `${hideData?.day ? '' : dayString} ${date.hours} ${hourString} ${date.minutes} ${minuteString}`;
    };

    const dateTimeFormat = (formatType: 'dateTime' | 'date' | 'time' = 'dateTime', displaySeconds = true): string => {
        const timeFormat: string = userInfo?.user?.userSetting.timeFormat || 'HH:mm';
        let dateFormatByLuxon = userInfo?.user?.userSetting.dateFormat || 'yyyy-mm-dd';

        dateFormatByLuxon = dateFormatByLuxon.replace(/YYYY/, 'yyyy').replace(/DD/, 'dd');

        const userFormats: { dateFormat: string; timeFormat: string } = {
            dateFormat: dateFormatByLuxon,
            timeFormat: timeFormat
        };

        if (displaySeconds) {
            userFormats.timeFormat = `${userFormats.timeFormat}:ss`;
        }
        if (userFormats.timeFormat.includes('hh:mm')) {
            userFormats.timeFormat = `${userFormats.timeFormat} a`;
        }

        switch (formatType) {
            case 'date':
                return `${userFormats.dateFormat}`;
            case 'dateTime':
                return `${userFormats.dateFormat} ${userFormats.timeFormat}`;
            case 'time':
                return `${userFormats.timeFormat}`;
            default:
                return `${userFormats.dateFormat} ${userFormats.timeFormat}`;
        }
    };

    const fromUserTimezoneToTimezone = (dateTime: Date): DateTime => {
        const userTimezone: string = userInfo.user?.userSetting.timezone.timezoneName || 'Europe/Bratislava';
        return DateTime.fromISO(dateTime).setZone(userTimezone);
    };

    const fromUTCtoUserTimezone = ({
        date,
        format,
        displaySeconds = true,
        displayIfEmpty = NotValue,
        returnObjectLuxon = false
    }: generalConvertTimezoneType): string | DateTime => {
        const dateFormat = dateTimeFormat(format, displaySeconds);
        const userTimezone: string = userInfo.user?.userSetting.timezone.timezoneName || 'Europe/Bratislava';
        if (!date) {
            return displayIfEmpty;
        }

        //Temporal fix. BE returns random date format
        let parsedDate = DateTime.fromISO(date, { zone: 'utc' });

        /*if (format === 'date') {
            parsedDate = DateTime.fromFormat(
                `${parsedDate.toFormat('yyyy-MM-dd')} ${parsedDate.toFormat('hh:mm:ss')}`,
                'yyyy-MM-dd hh:mm:ss',
                { zone: 'utc' }
            );
        }*/

        if (parsedDate.invalid) {
            // temporal fix for unix, send in seconds, not miliseconds from parent component
            // remove when migrated and unified
            if (typeof date === 'number') {
                parsedDate = DateTime.fromSeconds(date / 1000, { zone: 'utc' });
            } else {
                parsedDate = DateTime.fromFormat(date, `yyyy-MM-dd hh:mm${displaySeconds ? ':ss' : ''}`, {
                    zone: 'utc'
                });
            }
        }
        const parsedDateResponse = parsedDate.setZone(userTimezone);

        return returnObjectLuxon ? parsedDateResponse : parsedDateResponse.toFormat(dateFormat);
    };

    const fromUserTimezonetoUTC = ({
        date,
        displaySeconds = true,
        displayIfEmpty = NotValue,
        returnObjectLuxon = false,
        customFormat
    }: generalConvertTimezoneType): string | DateTime => {
        const dateFormat = `yyyy-MM-dd hh:mm${displaySeconds ? ':ss' : ''}`;
        const userTimezone: string = userInfo.user?.userSetting.timezone.timezoneName || 'Europe/Bratislava';
        if (!date) {
            return displayIfEmpty;
        }

        //Temporal fix. BE returns random date format
        let parsedDate = DateTime.fromISO(date, { zone: userTimezone });

        if (parsedDate.invalid) {
            // temporal fix for unix, send in seconds, not miliseconds from parent component
            // remove when migrated and unified
            if (typeof date === 'number') {
                parsedDate = DateTime.fromSeconds(date / 1000, { zone: userTimezone });
            } else {
                parsedDate = DateTime.fromFormat(date, `yyyy-MM-dd hh:mm${displaySeconds ? ':ss' : ''}`, {
                    zone: userTimezone
                });
            }
        }

        const parsedDateResponse = DateTime.fromISO(parsedDate, { zone: userTimezone }).setZone('utc');

        return returnObjectLuxon ? parsedDateResponse : parsedDateResponse.toFormat(customFormat || dateFormat);
    };

    const fromTimezoneToUTC = (
        dateTime: Date | DateTime | number,
        displaySeconds = true,
        displayTime = true,
        customFormat?: string
    ): string => {
        const userTimezone: string = userInfo.user?.userSetting.timezone.timezoneName || 'Europe/Bratislava';
        let parsedDate = DateTime.fromISO(dateTime, { zone: userTimezone });

        if (parsedDate.invalid) {
            // temporal fix for unix, send in seconds, not miliseconds from parent component
            // remove when migrated and unified
            if (typeof dateTime === 'number') {
                parsedDate = DateTime.fromSeconds(dateTime / 1000, { zone: 'utc' });
            } else {
                parsedDate = DateTime.fromFormat(dateTime, 'yyyy-MM-dd hh:mm:ss', { zone: 'utc' });
            }
        }

        return DateTime.fromISO(parsedDate, { zone: userTimezone })
            .setZone('utc')
            .toFormat(customFormat || `yyyy-MM-dd${displayTime ? ` HH:mm${displaySeconds ? ':ss' : ''}` : ''}`);
    };

    const fromUTCToTimezone = (utc: string | Date | number, displaySeconds = true, displayIfEmpty = '-'): string => {
        const format = dateTimeFormat('dateTime', displaySeconds);
        const userTimezone: string = userInfo.user?.userSetting.timezone.timezoneName || 'Europe/Bratislava';

        if (!utc) return displayIfEmpty;

        let parsedDate = DateTime.fromISO(utc, { zone: 'utc' });

        if (parsedDate.invalid) {
            // temporal fix for unix, send in seconds, not miliseconds from parent component
            // remove when migrated and unified
            if (typeof utc === 'number') {
                parsedDate = DateTime.fromSeconds(utc / 1000, { zone: 'utc' });
            } else {
                parsedDate = DateTime.fromFormat(utc, 'yyyy-MM-dd hh:mm:ss', { zone: 'utc' });
            }
        }
        return DateTime.fromISO(parsedDate, { zone: 'utc' }).setZone(userTimezone).toFormat(format);
    };

    const userFormatUTC = ({
        date,
        format,
        displaySeconds = true,
        displayIfEmpty = NotValue,
        returnObjectLuxon = false,
        customFormat
    }: generalConvertTimezoneType): string | DateTime => {
        const dateFormat = dateTimeFormat(format, displaySeconds);
        if (!date) {
            return displayIfEmpty;
        }

        let parsedDate = DateTime.fromISO(date, { zone: 'utc' });

        if (parsedDate.invalid) {
            parsedDate = DateTime.fromFormat(date, 'yyyy-MM-dd hh:mm:ss', { zone: 'utc' });
        }

        return returnObjectLuxon ? parsedDate : parsedDate.toFormat(customFormat || dateFormat);
    };

    return {
        decimalToHex,
        displayUserUnits,
        fromServerToUserUnit,
        fromUserToServerUnit,
        hexToDecimal,
        convertType,
        secondToDateTime,
        fromUTCToTimezone,
        dateTimeFormat,
        fromTimezoneToUTC,
        fromUTCtoUserTimezone,
        fromUserTimezoneToTimezone,
        fromUserTimezonetoUTC,
        userFormatUTC
    };
};

export default useConverter;
