import React from 'react';
import { UiIconContent } from './UiIcon.view';
import { sizeArray, UiIconProps } from './UiIcon.type';
import { Wrapper } from 'helpers/wrapper';
import { useRecoilValue } from 'recoil';
import { Theme } from 'states/global/Theme';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

const UiIcon: React.FC<UiIconProps> = (props): JSX.Element => {
    const theme = useRecoilValue(Theme);

    const getEasyReadingModeSize = (): SizeProp | undefined => {
        let sizeIndex = sizeArray.findIndex((element) => element == props.size);

        if (sizeIndex + 1 > 6 || sizeIndex > 6) {
            return sizeArray[sizeIndex];
        } else if (sizeIndex == -1) {
            return undefined;
        }
        return sizeArray[sizeIndex + 1];
    };

    return (
        <UiIconContent
            data-testid={props?.testid || 'UiIcon-testid'}
            {...props}
            size={theme.easyReadingMode ? props.easyReadingValue ?? getEasyReadingModeSize() : props.size}
        />
    );
};

export default Wrapper(UiIcon);
