import PortalApi from 'helpers/api/PortalApiClient';
import { VehicleEventModel, VehicleEventModelAlerts } from '../models/VehicleEvent.type';
import { FilterProps } from 'components/Ui/UiTable/UiTable.type';
import { GetAlertsParams } from '../models/SpeedEvent.type';
import { getParams } from 'helpers';

const getAlertsDefaultProps: GetAlertsParams = {
    page: 1,
    limit: 150
};

export default class VehicleEvent extends PortalApi<VehicleEventModel> {
    route = 'vehicle-event';

    getAlerts = async (params: GetAlertsParams = getAlertsDefaultProps): Promise<VehicleEventModelAlerts> => {
        let filterParams: FilterProps[] = [];

        params.measuredFrom &&
            filterParams.push({ id: 'measuredFrom', value: { name: 'measuredFrom', value: `${params.measuredFrom}` } });
        params.vehicleId &&
            filterParams.push({ id: 'vehicleId', value: { name: 'vehicleId', value: `${params.vehicleId}` } });

        return await this.getTable({
            criteria: getParams({
                queryPageIndex: params.page ? params.page - 1 : 0,
                queryPageSize: params.limit ?? 10,
                queryPageSortBy: params.orderParams ?? [],
                queryPageFilter: filterParams
            })
        });
    };
}
