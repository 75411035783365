import React from 'react';
import * as view from './ActiveVersions.view';
import * as type from './ActiveVersions.type';
import { Wrapper } from 'helpers/wrapper';
import { useQuery } from '@tanstack/react-query';
import Version from 'api/Version';
import { VersionAPIModel, VersionQueryKeys } from 'models/Version.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';

const activeVersionAPI = new Version();

const ActiveVersions: React.FC<type.ActiveVersionsProps> = (): JSX.Element => {
    const { data } = useQuery<boolean, Error, VersionAPIModel>(
        [VersionQueryKeys.getVersions],
        () => {
            return activeVersionAPI.get({ apiProject: undefined });
        },
        {
            refetchInterval: false,
            staleTime: cacheTimeToMilliseconds(1, 'hours'),
            cacheTime: cacheTimeToMilliseconds(1, 'hours')
        }
    );

    return <view.ActiveVersionsContent data-testid='ActiveVersions-testid' data={data} />;
};

export default Wrapper(ActiveVersions);
