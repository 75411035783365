export type AreaTypeModel = {
    id: number;
    name: string;
    translationKey: string;
    color: string;
};

export enum AreaTypeQueryKeys {
    getLazyDropdown = 'areaType-getLazyDropdown'
}
