import styled from 'styled-components';
import { LinearProgress, useTheme } from '@mui/material';
import {
    ALERT_LEVEL_1_PRED_TEMP_DARK,
    ALERT_LEVEL_2_PRED_TEMP_DARK,
    BACKGROUND_PAPER_DARK_MODE
} from 'components/Ui/colors';
import { applyStyleByMode } from 'helpers';

export const PREDICTED_TEMP_COLOR = '#FF0101';
export const CRITICAL_TEMP_COLOR = '#CE4F03';
export const CRITICAL_TEMP_COLOR_2 = '#f85d00';
export const ACTUAL_TEMP_COLOR = '#545454';
export const ACTUAL_TEMP_COLOR_DARK = '#1aa4ab';
export const AMBIENT_TEMP_COLOR = '#018806';
export const AMBIENT_TEMP_COLOR_DARK = '#0fe367';

export const PredictedGraphContent = styled('div')`
    width: 100%;
    height: 100%;
`;

export const TimerLinearProgress = styled(LinearProgress)<{ alertLevel: number }>`
    &.MuiLinearProgress-root {
        height: 15px;
        border-radius: 10px;

        ${() => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                light: 'background-color: rgba(219, 219, 219, 0.7);',
                dark: 'background-color: #000;'
            });
        }}
    }

    & .MuiLinearProgress-bar {
        height: 15px;
        border-radius: 10px;
        background-color: ${(props) =>
            props.alertLevel > 1 ? ALERT_LEVEL_2_PRED_TEMP_DARK : ALERT_LEVEL_1_PRED_TEMP_DARK};
    }
`;

export const Table = styled('table')<{ $legend?: boolean }>`
    width: 100%;
    ${(props) =>
        props.$legend &&
        `
        padding: 10px 16px;
    `}
`;

export const GraphLegendContent = styled('div')`
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: 'background-color: #f6f6f6;',
            dark: `background-color: ${BACKGROUND_PAPER_DARK_MODE};`
        });
    }}
`;

export const TableRow = styled('tr')``;

export const TableColumn = styled('td')`
    padding: 6px 0;
`;

export const RowName = styled('div')`
    padding-left: 3px;
`;

export const RowData = styled('div')`
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: 'color: black;',
            dark: 'color: #fff;'
        });
    }}
    font-weight: bold;
    font-weight: bolder;
    padding-left: 3px;
`;

export const Row = styled('div')`
    display: flex;
`;

export const StyledDiv = styled('div')`
    padding-bottom: 16px;
`;

export const TimerTextContent = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LeftDiv = styled('div')`
    display: flex;
    align-content: center;
`;

export const TimerText = styled('div')`
    padding: 0 5px;
`;

export const VehicleCounterActionsTime = styled('div')`
    display: flex;
    flex-direction: column;
`;
