import { Paginator } from 'helpers/api/type';
import { AreaGPSAlert } from './Area.type';
import { GetParamsProps } from './Table.type';
import { VehicleIdName } from './Vehicle.type';
import { SortByProps } from 'components/Ui/UiTable/UiTable.type';

export type SpeedEventModel = {};

export type GetAlertsParams = Partial<{
    page: number;
    limit: number;
    vehicleId: number;
    measuredFrom: string;
    orderParams: SortByProps[];
}>;

export type GpsAlert = {
    active: boolean;
    area: AreaGPSAlert;
    currentSpeed: number;
    eventType: string;
    id: number;
    maxSpeed: number;
    measuredAt: string;
    minSpeed: number;
    positionX: number;
    positionY: number;
    vehicle: VehicleIdName;
};

export type OverSpeedingPreview = {
    speedEventPreview: OverSpeedingPreviewModel[];
};

export type OverSpeedingPreviewModel = {
    notificationId: number;
    vehicleName: string;
    measuredAt: string;
    speed: number;
    speedLimit: number;
    areaName: string;
};

export type GPSAlertsTable = Paginator<GpsAlert>;

export type GetIncidentsParams = {
    vehicleIds: string | null;
    measuredFrom: string;
    measuredTo: string;
    loaded?: number;
    version?: string;
    vehicle?: number[] | null;
    vehicleOperator?: number | null;
};

export type SpeedIncidentsMap = {
    speedIncidents: SpeedIncident[];
    total: number;
};

export type SpeedIncidentMap = {
    speedEvents: SpeedIncident & {
        speed: number;
        maxSpeed: number;
        realMaxSpeed: number;
        realMinSpeed?: number;
        altitude: number;
        positionAt: {
            latitude: number;
            longitude: number;
        };
        trackLog: {
            speed: number;
            altitude: number;
        };
        vehicle: VehicleIdName;
        vehicleOperator: {
            id: number;
            firstName: string;
            lastName: string;
        } | null;
    };
};

export type SpeedIncident = {
    id: number;
    loaded: boolean;
    measuredAt: string;
    positionAt: {
        latitude: number;
        longitude: number;
    };
    vehicle: VehicleIdName | null;
    vehicleOperator: {
        id: number;
        firstName: string;
        lastName: string;
    } | null;
};

export type OverspeedingCSVCriteria = {
    vehicleId?: number;
    measuredFrom: string;
    measuredTo: string;
    loaded?: number | null;
    operatorId?: number | null;
};

export enum SpeedEventQueryKeys {
    getAlerts = 'speedEvent-GetGpsAlert',
    getOverSpeedingReportsCsv = 'speedEvent-GetTempTyreNotificationsCSV',
    getSpeedIncidentById = 'speedEvent-getSpeedIncidentById',
    getLazyDropdown = 'speedEvent-getLazyDropdown'
}
