import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ContainerMessageNotRoute, RoutesMapViewContent, TextMoreIcon } from './RoutesMap.style';
import { RoutesMapViewProps } from './RoutesMap.type';
import { useTranslation } from 'react-i18next';
import Widget from '../../Ui/Components/UiWidget';
import { MapAction } from '../../../states/global/Map';
import { MapActionsEnum } from '../../Map/Map.type';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import Map from '../../Map';
import { applyStyleByMode } from 'helpers';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';

export const RoutesMapContent: React.FC<RoutesMapViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [mapAction, setMapAction] = useRecoilState(MapAction);
    const ThemeMode = useRecoilValue(Theme);

    return (
        <RoutesMapViewContent data-testid='RoutesMapContent'>
            <Widget
                data-testid={'RoutesMapContent-Widget'}
                title={translate('t.map')}
                collapseButton={false}
                fullScreenButton={true}
                avatar={
                    <UiIcon
                        data-testid={'RoutesMapContent-UiIcon'}
                        icon={faLocationDot}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                $contentHeight={73}
                onResize={() => {
                    setMapAction({
                        action: MapActionsEnum.RESIZE_MAP,
                        params: {
                            lastAction: mapAction
                        }
                    });
                }}
                unit='vh'
                padding={0}
                applyPadding={false}
                marginBottom={false}
            >
                {!!props.route && !!props.route.length ? (
                    <Map enableDrawing={false} data-testid={'RoutesMapContent-Map'} />
                ) : (
                    <ContainerMessageNotRoute>
                        <TextMoreIcon>
                            <span>{translate('p.please_choose_route_to_visualize')}</span>
                            <UiIcon
                                data-testid={'RoutesMapContent-UiIcon'}
                                icon={['fas', 'shuffle']}
                                size='lg'
                                fixedWidth
                            />
                        </TextMoreIcon>
                    </ContainerMessageNotRoute>
                )}
            </Widget>
        </RoutesMapViewContent>
    );
};
