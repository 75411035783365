import React from 'react';
import * as type from './RealTimeNotification.type';
import ButtonShowNotifications from '../ButtonShowNotifications/ButtonShowNotifications';
import NotificationEvent from 'api/NotificationEvent';
import { MqttResponse, NotificationEventQueryKeys } from 'models/NotificationEvent.type';
import { useQuery } from '@tanstack/react-query';
import { Wrapper } from 'helpers/wrapper';

const RealTimeNotification: React.FC<type.RealTimeNotificationProps> = (): JSX.Element => {
    const notificationEvent = new NotificationEvent();
    const { data: XHRMqttData } = useQuery<MqttResponse>(
        [NotificationEventQueryKeys.getMqtt],
        () => notificationEvent.getMqtt(),
        {
            refetchOnWindowFocus: false,
            staleTime: 0,
            cacheTime: 0
        }
    );

    return XHRMqttData ? <ButtonShowNotifications MqttData={XHRMqttData} /> : <></>;
};

export default Wrapper(RealTimeNotification);
