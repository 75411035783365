import React, { useMemo } from 'react';
import Graph from './Components/Graph';
import GraphLegend from './Components/GraphLegend';
import Timer from './Components/Timer';
import InformationTable from './Components/InformationTable';
import { PredictedGraphContent } from './PredictedGraph.style';
import { useQuery } from '@tanstack/react-query';
import WheelPredictionAPI from 'api/WheelPrediction';
import { PredictedGraphProps } from './PredictedGraph.type';
import CounterAction from '../CounterAction';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { Grid } from '@mui/material';
import VehicleCounterActionTable from './Components/VehicleCounterActionTable';
import { GetCounterActions } from 'components/Queries/TPMS/Tpms';
import { useRecoilValue } from 'recoil';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { WheelPredictionQueryKeys } from 'models/WheelPrediction.type';

const wheelPredictionAPI = new WheelPredictionAPI();

const PredictedGraph = ({
    vehicleName,
    wheelPredictionId,
    alertLevel,
    vehicleId
}: PredictedGraphProps): JSX.Element => {
    const random = useMemo(() => Math.floor(Math.random() * 100000), []);
    const customerSettings = useRecoilValue(CustomerSettings);

    let { data, refetch, isLoading } = useQuery(
        [WheelPredictionQueryKeys.getGraphData, random, wheelPredictionId],
        () => wheelPredictionAPI.getGraphData(wheelPredictionId),
        {
            retry: false,
            refetchInterval: 60000,
            keepPreviousData: false,
            staleTime: 0,
            cacheTime: 0
        }
    );

    const counterActionsArray = useMemo(
        () => data?.tyreTempPredictionGraph.counterAction?.counterActionIds || [],
        [data?.tyreTempPredictionGraph.counterAction?.counterActionIds]
    );

    const criticalTemperatures = useMemo(() => {
        const temperatures = {};
        if (data?.tyreTempPredictionGraph.notificationLimits.length) {
            data.tyreTempPredictionGraph.notificationLimits.map((limit) => {
                temperatures[limit.level] = limit.criticalTemperature;
            });
        }

        return temperatures;
    }, [data?.tyreTempPredictionGraph.notificationLimits]);

    const mainCriticalTemperature = useMemo(() => {
        if (Object.keys(criticalTemperatures)) {
            return customerSettings.temperature_prediction_workflow === 1
                ? criticalTemperatures['2']
                : criticalTemperatures['3'];
        }
        return 0;
    }, [criticalTemperatures]);

    return isLoading ? (
        <UiLoadingPage />
    ) : (
        <>
            {data && (
                <Grid container spacing={2} columns={100}>
                    <Grid item xs={72}>
                        <PredictedGraphContent>
                            <Timer
                                criticalTemperature={mainCriticalTemperature}
                                remainingHours={data.tyreTempPredictionGraph.remainingHours}
                                alertLevel={alertLevel}
                            />
                            <InformationTable
                                vehicleName={vehicleName}
                                tyreTemperature={data.tyreTempPredictionGraph.actualTemperature}
                                wheelPosition={data.tyreTempPredictionGraph.position}
                                lastHubTransmission={data.tyreTempPredictionGraph.lastHubTransmission}
                                measuredAt={data.tyreTempPredictionGraph.measuredAt}
                                criticalTemperatureAt={data.tyreTempPredictionGraph.criticalTemperatureAt}
                                criticalTemperature={mainCriticalTemperature}
                                remainingHours={data.tyreTempPredictionGraph.remainingHours}
                            />
                            <CounterAction
                                vehicleId={data.tyreTempPredictionGraph.vehicleId}
                                expiresAt={data.tyreTempPredictionGraph.counterAction?.expiresAt || ''}
                                counterActionIds={counterActionsArray}
                                refetch={refetch}
                            />
                            <GraphLegend />
                            <Graph
                                data={{
                                    predictionData: data.tyreTempPredictionGraph.predictionData,
                                    sensorData: data.tyreTempPredictionGraph.sensorData,
                                    weatherData: data.tyreTempPredictionGraph.weatherData,
                                    forecastData: data.tyreTempPredictionGraph.forecastData
                                }}
                                criticalTemperatures={criticalTemperatures}
                            />
                        </PredictedGraphContent>
                    </Grid>
                    <Grid item xs={28}>
                        <GetCounterActions>
                            {({ data }) => (
                                <VehicleCounterActionTable vehicleId={vehicleId} counterActionsOptions={data} />
                            )}
                        </GetCounterActions>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default PredictedGraph;
