import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useQuery } from '@tanstack/react-query';
import {
    LifespanReportDetailsViewContent,
    GraphContainer,
    ExportContainer,
    LifespanReportDetailsToPrint,
    ExportDataButton
} from './LifespanReportDetails.style';
import { TextNoData } from '../LifespanReportHistory/LifespanReportHistory.style';
import { LifespanReportDetailsViewProps } from './LifespanReportDetails.type';
import { useTranslation } from 'react-i18next';
import Widget from 'components/Ui/Components/UiWidget/UiWidget';
import { faFileChartColumn } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import MainDetails from './Components/MainDetailsTable/MainDetails';
import SeverityRankGraph from '../SeverityRankGraph/SeverityRankGraph';
import UiMenu from 'components/Ui/Components/UiMenu/UiMenu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Print } from '@mui/icons-material';
import { AlertsTableContent } from './Components/AlertsTable/AlertsTable';
import { TemperatureExposureBySensorResponse } from 'models/SensorLog.type';
import TemperatureExposureReport from 'components/Mixs/TemperatureExposureReport/TemperatureExposureReport';
import { CustomerSettings } from 'states/global/CustomerSettings';
import useConverter from 'components/CustomHooks/Converter/Converter';
import SensorLog from 'api/SensorLog';
import PercentageTyreGraph from './Components/PercentageTyreGraph/PercentageTyreGraph';
import ReactToPrint from 'react-to-print';
import { DateTime } from 'luxon';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { SensorLogQueryKeys } from 'models/SensorLog.type';

const sensorLogApi = new SensorLog();

export const LifespanReportDetailsContent: React.FC<LifespanReportDetailsViewProps> = ({
    tyreId,
    customPosition,
    vehicleId,
    label,
    testid
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const customerSettings = useRecoilValue(CustomerSettings);
    const { fromTimezoneToUTC } = useConverter();
    const buttonToPrintRef = React.useRef<HTMLButtonElement>(null);
    const componentToPrintRef = React.useRef<React.ReactInstance | null>(null);
    const [tempExposureDate, setTempExposureDate] = React.useState<string[] | undefined[]>([
        fromTimezoneToUTC(DateTime.local().minus({ months: 3 })),
        fromTimezoneToUTC(DateTime.local())
    ]);
    const [showScroll, setShowScroll] = React.useState(true);
    const [tempExposure, setTempExposure] = React.useState<TemperatureExposureBySensorResponse>({
        temperatureCategories: {},
        temperatureExposureReport: []
    });
    const [loadingUnmount, setLoadingUnmount] = React.useState(false);

    const reactToPrintContent = React.useCallback(() => {
        return componentToPrintRef.current;
    }, [componentToPrintRef]);

    const {
        refetch,
        isLoading: isLoadingExposure,
        isFetching: isFetchingExposure
    } = useQuery(
        [SensorLogQueryKeys.getTemperatureExposureByTyre, tyreId, tempExposureDate[0], tempExposureDate[1]],
        () =>
            sensorLogApi.getTemperatureExposureByTyre({
                tyreId: tyreId,
                dateFrom: tempExposureDate[0],
                dateTo: tempExposureDate[1]
            }),
        {
            enabled: false,
            retry: false,
            refetchOnWindowFocus: false,
            refetchInterval: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: (dataOnSuccess) => {
                if (dataOnSuccess.temperatureExposureReport.length == 0 && tempExposureDate[0] && tempExposureDate[1]) {
                    setTempExposureDate([undefined, undefined]);
                } else {
                    setTempExposure(dataOnSuccess);
                }
            },
            onError: () =>
                setTempExposure({
                    temperatureCategories: {},
                    temperatureExposureReport: []
                })
        }
    );

    useEffect(() => {
        !isNaN(tyreId) && refetch();
    }, [tyreId, tempExposureDate]);

    useEffect(() => {
        if (!showScroll && buttonToPrintRef.current) {
            buttonToPrintRef.current.click();

            setLoadingUnmount(true);
        }
        if (showScroll) setLoadingUnmount(false);
    }, [showScroll]);

    return (
        <>
            <div style={{ display: 'none' }}>
                <ReactToPrint
                    data-testid={'LifespanReportDetailsContent-ReactToPrint'}
                    trigger={() => <button ref={buttonToPrintRef}></button>}
                    content={reactToPrintContent}
                    onAfterPrint={() => setShowScroll(true)}
                    documentTitle={`Tyre LifeSpan Report_iTrack - Mining TPMS 24/7`}
                />
            </div>
            <LifespanReportDetailsViewContent data-testid={`${testid || 'LifespanReportDetailsContent'}`}>
                <Widget
                    testid='LifespanReportDetailsContent-Widget-tyreLifeSpanReport'
                    avatar={<UiIcon icon={faFileChartColumn} />}
                    title={label ? label : translate('t.tyre_lifespan_report')}
                    loading={false}
                    content={
                        <>
                            {isNaN(tyreId) ? (
                                <TextNoData>{translate('t.there_no_data')}</TextNoData>
                            ) : (
                                <>
                                    {loadingUnmount ? (
                                        <UiLoadingPage size='30px' />
                                    ) : (
                                        <LifespanReportDetailsToPrint
                                            ref={(el) => (componentToPrintRef.current = el)}
                                            data-testid={`LifespanReportDetailsContent-LifespanReportDetailsToPrint-${tyreId}`}
                                        >
                                            <MainDetails
                                                tyreId={tyreId}
                                                data-testid={`LifespanReportDetailsContent-MainDetails-${tyreId}`}
                                            />
                                            <GraphContainer>
                                                {isLoadingExposure || isFetchingExposure ? (
                                                    <UiLoadingPage size='30px' />
                                                ) : (
                                                    <>
                                                        <Widget
                                                            testid={`LifespanReportDetailsContent-Widget-temperatureExposure-${tyreId}`}
                                                            avatar={<UiIcon icon={faFileChartColumn} />}
                                                            title={`${translate('t.temperature_exposure')} (${translate(
                                                                't.last_3_months'
                                                            )}) `}
                                                            loading={false}
                                                            $contentHeight={300}
                                                            scrollbar={false}
                                                            content={
                                                                <TemperatureExposureReport
                                                                    data={tempExposure}
                                                                    data-testid={`LifespanReportDetailsContent-TemperatureExposureReport-${tyreId}`}
                                                                />
                                                            }
                                                        />
                                                    </>
                                                )}

                                                <AlertsTableContent
                                                    tyreId={tyreId}
                                                    data-testid={`LifespanReportDetailsContent-AlertsTableContent-${tyreId}`}
                                                />
                                                <PercentageTyreGraph
                                                    tyreId={tyreId}
                                                    data-testid={`LifespanReportDetailsContent-PercentageTyreGraph-${tyreId}`}
                                                />
                                                {customerSettings.mdd_bes &&
                                                    !isNaN(tyreId) &&
                                                    !isNaN(customPosition) &&
                                                    !isNaN(vehicleId) && (
                                                        <SeverityRankGraph
                                                            key={`LifespanReportDetailsContent-SeverityRankGraph-${tyreId}-${vehicleId}-${customPosition}`}
                                                            data-testid={`LifespanReportDetailsContent-SeverityRankGraph-testid-${tyreId}-${vehicleId}-${customPosition}`}
                                                            tyreId={tyreId}
                                                            vehicleId={vehicleId}
                                                            tyreCustomPosition={customPosition}
                                                            newMode={true}
                                                        />
                                                    )}
                                            </GraphContainer>

                                            <ExportContainer>
                                                <UiMenu
                                                    data-testid={'LifespanReportDetailsContent-UiMenu'}
                                                    items={[
                                                        {
                                                            title: translate('t.print'),
                                                            value: 'print',
                                                            iconUI: <Print />,
                                                            actionGeneral: () => setShowScroll(false)
                                                        }
                                                    ]}
                                                    rootEl={
                                                        <ExportDataButton
                                                            data-testid={
                                                                'LifespanReportDetailsContent-ExportDataButton'
                                                            }
                                                            endIcon={<ExpandMoreIcon />}
                                                            //loading={loading}
                                                            color='secondary'
                                                            variant='outlined'
                                                        >
                                                            {translate('t.export')}
                                                        </ExportDataButton>
                                                    }
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center'
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center'
                                                    }}
                                                />
                                            </ExportContainer>
                                        </LifespanReportDetailsToPrint>
                                    )}
                                </>
                            )}
                        </>
                    }
                ></Widget>
            </LifespanReportDetailsViewContent>
        </>
    );
};
