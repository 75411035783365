export type AreaEventModel = {};

export type AreaEntriesPreview = {
    areaPreview: AreaEntriesPreview[];
};

export type AreaEntriesPreviewModel = {
    areaName: string;
    vehicleName: string;
    enteredAt: string;
    leftAt: string;
    duration: number;
};

export enum AreaEventQueryKeys {
    getAreaEntriesReportsCsv = 'areaEvent-getAreaEntriesReportsCsv'
}
