export type ExternalSystemModel = {
    id: number;
    type: string;
    name: string;
};

export type CurrentExternalSystemsResponse = {
    externalSystem: CurrentExternalSystems[];
};

export type CurrentExternalSystems = {
    id: number;
    externalSystem: ExternalSystemModel;
};

export enum ExternalSystemQueryKeys {
    getCurrent = 'externalSystem-getCurrent',
    getAllExternalSystems = 'externalSystem-getAllExternalSystems'
}
