import React from 'react';
import TempPressAlertLevelTable from '../../components/TempPressAlertLevelTable';
import { TempPressAlertLevelsContainer } from './TempPressAlertLevels.style';
import UiWidget from '../../components/Ui/Components/UiWidget/UiWidget';
import { applyStyleByMode } from 'helpers';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { faAbacus } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';

export const TempPressAlertLevelsContent: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <TempPressAlertLevelsContainer data-testid='TempPressAlertLevelsContent'>
            <UiWidget
                title={translate('t.temp_press_alert_levels')}
                id='temp-press-alert-level-widget'
                avatar={
                    <UiIcon
                        icon={faAbacus}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                content={<TempPressAlertLevelTable />}
            />
        </TempPressAlertLevelsContainer>
    );
};
