import React, { useMemo } from 'react';
import { AlertDetail, HasAlert, VehicleStatsRowProps } from './VehicleStatsRow.type';
import { VehicleStatsRowContent } from './VehicleStatsRow.view';
import { snakeToCamel } from 'helpers/converter/text';

const VehicleStatsRow: React.FC<VehicleStatsRowProps> = ({ row, showModalAlerts }): JSX.Element => {
    const alertDetailData = useMemo(() => {
        let alertDetail: AlertDetail = {};

        const validAlerts = row.notifications.filter(
            (newListNotificationItem) => newListNotificationItem.timeType !== 'alert_out_of_interval'
        );

        let totalValues = {
            current: 0,
            previous: 0
        };

        for (let i = 0, length = validAlerts?.length; i < length; i++) {
            const index = `${validAlerts[i].type2}_${validAlerts[i].type}`;
            if (!alertDetail[index]) {
                alertDetail[index] = {
                    current: 0,
                    previous: 0,
                    variance: 0
                };
            }
            const aletActivity = validAlerts[i].timeType.replace('_active', '');
            alertDetail[index][aletActivity] += validAlerts[i].count;
            alertDetail[index].variance = alertDetail[index].current - alertDetail[index].previous;

            totalValues[aletActivity] += validAlerts[i].count;
        }

        return { alertDetail, totalValues };
    }, [row.notifications]);

    const hasSystemAlert = useMemo(() => row.iconAlerts?.length > 0, [row.iconAlerts]);

    const vehicleAlerts = useMemo(() => {
        let hasAlert: HasAlert = {
            temperature: false,
            lowPressure: false,
            highPressure: false,
            temperaturePrediction: false
        };
        if (row.alert < 1) {
            return hasAlert;
        }
        for (const notificationsKey in row.notifications) {
            const alert = row.notifications[notificationsKey];
            const allAlerts =
                hasAlert.temperature && hasAlert.lowPressure && hasAlert.highPressure && hasAlert.temperaturePrediction;
            if ((alert.timeType === 'current_active' || alert.timeType === 'alert_out_of_interval') && !allAlerts) {
                const simplyfiedType = alert.type.replace('_hot', '');
                const type =
                    simplyfiedType === 'pressure'
                        ? snakeToCamel(`${alert.type2}_${simplyfiedType}`)
                        : snakeToCamel(simplyfiedType);
                if (hasAlert[type] !== undefined) {
                    hasAlert[type] = true;
                }
            }
        }
        return hasAlert;
    }, [row.alert, row.notifications]);

    return (
        <VehicleStatsRowContent
            data-testid={'VehicleStatsRow-testid'}
            row={row}
            alertDetailData={alertDetailData}
            vehicleAlerts={vehicleAlerts}
            hasSystemAlert={hasSystemAlert}
            showModalAlerts={showModalAlerts}
        />
    );
};

export default VehicleStatsRow;
