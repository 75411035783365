import React from 'react';
import { SensorStatisticsContent } from './SensorStatistics.view';
import {
    SensorStatisticsProps,
    SensorStatisticsType,
    StatisticsDataKeysType,
    StatisticsScalesType
} from './SensorStatistics.type';
import { useRecoilValue } from 'recoil';
import { generateYConfig } from 'helpers';
import { DateTimePicker, Timeline } from 'states/global/Statistics';
import SensorLog from 'api/SensorLog';
import { DateTime } from 'luxon';
import { xDomainConfig, yDomainConfig } from 'components/Graph/Graph.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Theme, ThemeMode } from 'states/global/Theme';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { SensorLogQueryKeys } from 'models/SensorLog.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';

const sensorLogApi = new SensorLog();

const SensorStatistics: React.FC<SensorStatisticsProps> = ({ sensorId, sensorType }): JSX.Element => {
    const timeline = useRecoilValue(Timeline);
    const { t: translate } = useTranslation();
    const { fromUTCtoUserTimezone, fromTimezoneToUTC } = useConverter();
    const ThemeModeValue = useRecoilValue(Theme);
    const lineColor: string = ThemeModeValue?.mode === ThemeMode.light ? '#000' : '#16a085';
    const globalDateTimePicker = useRecoilValue(DateTimePicker);

    const statisticsScales: StatisticsScalesType = {
        [`${SensorStatisticsType.BATTERY_STATISTICS}`]: {
            scale: { left: [1500, 4000], right: [1500, 4000] },
            scaleNumber: 300
        },
        [`${SensorStatisticsType.RSSI_STATISTICS}`]: {
            scale: { left: [-110, -20], right: [-110, -20] },
            scaleNumber: 15
        },
        [`${SensorStatisticsType.TRANSMISSIONS_STATISTICS}`]: {
            scale: { left: [0, 600], right: [0, 600] },
            scaleNumber: 50
        }
    };

    const statisticsDataKeys: StatisticsDataKeysType = {
        [`${SensorStatisticsType.BATTERY_STATISTICS}`]: {
            dataKey: 'batteryVoltage',
            resultFetchKey: 'sensorBatteryVoltage',
            resultFetchItemKey: 'avgBattery'
        },
        [`${SensorStatisticsType.RSSI_STATISTICS}`]: {
            dataKey: 'rssi',
            resultFetchKey: 'sensorRssi',
            resultFetchItemKey: 'avgRssi'
        },
        [`${SensorStatisticsType.TRANSMISSIONS_STATISTICS}`]: {
            dataKey: 'transmissions',
            resultFetchKey: 'sensorTransmissions',
            resultFetchItemKey: 'transmissions'
        }
    };

    const yDomainConfig: yDomainConfig[] = [
        {
            id: '1',
            name: `${sensorType === SensorStatisticsType.RSSI_STATISTICS ? 'RSSI' : translate(`t.${sensorType}`)} ${
                sensorType === SensorStatisticsType.BATTERY_STATISTICS ? '(mV)' : ''
            }`,
            isShared: true
        },
        {
            id: '2',
            name: `${sensorType === SensorStatisticsType.RSSI_STATISTICS ? 'RSSI' : translate(`t.${sensorType}`)} ${
                sensorType === SensorStatisticsType.BATTERY_STATISTICS ? ' (mV)' : ''
            }`,
            isShared: true
        }
    ];

    const xDomainConfig: xDomainConfig[] = [
        {
            dataKey: 'measuredAt',
            yAxisId: 'hiddenData',
            name: translate('t.measured_at'),
            hide: true,
            showDot: false
        },
        {
            dataKey: statisticsDataKeys[sensorType].dataKey,
            stroke: lineColor,
            yAxisId: '1',
            name: `${sensorType === SensorStatisticsType.RSSI_STATISTICS ? 'RSSI' : translate(`t.${sensorType}`)}
            ${sensorType === SensorStatisticsType.TRANSMISSIONS_STATISTICS ? ` ${translate('t.in_last_week')}` : ''} `,
            showDot: false
        }
    ];

    const xAditionalDomainConfig: xDomainConfig[] = [
        {
            dataKey: '',
            name: '',
            yAxisId: '2',
            showDot: false,
            hide: true
        }
    ];

    const yDomain2 = generateYConfig(yDomainConfig, statisticsScales[sensorType].scaleNumber, {
        left: statisticsScales[sensorType].scale.left,
        right: statisticsScales[sensorType].scale.right
    });

    const getStatistic = async () => {
        const payload = {
            sensorId: sensorId,
            measuredFrom: fromTimezoneToUTC(globalDateTimePicker.current.dateFrom),
            measuredTo: fromTimezoneToUTC(globalDateTimePicker.current.dateTo)
        };

        return await (sensorType === SensorStatisticsType.BATTERY_STATISTICS
            ? sensorLogApi.getStatisticsSensorBattery(payload)
            : sensorType === SensorStatisticsType.TRANSMISSIONS_STATISTICS
            ? sensorLogApi.getStatisticsSensortransmissions(payload)
            : sensorLogApi.getStatisticsSensorRssi(payload)
        ).then((result) => {
            return result[`${statisticsDataKeys[sensorType].resultFetchKey}`].map((item) => {
                return {
                    [`${statisticsDataKeys[sensorType].dataKey}`]:
                        item[`${statisticsDataKeys[sensorType].resultFetchItemKey}`],
                    measuredAt: fromUTCtoUserTimezone({ date: item['measuredAt'], format: 'dateTime' }),
                    timeKey: DateTime.fromISO(item.measuredAt).valueOf() / 1000
                };
            });
        });
    };

    const { refetch } = useQuery(
        [SensorLogQueryKeys.getTransmissionCSV, globalDateTimePicker],
        () =>
            sensorLogApi.getSensorTransmissionCSV({
                sensorId: sensorId,
                measuredFrom: globalDateTimePicker.current.dateFrom,
                measuredTo: globalDateTimePicker.current.dateTo
            }),
        {
            enabled: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: (results: string) => {
                const blob = new Blob([results ?? ' '], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', `${translate(`t.transmissions`)} report.csv`);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        }
    );

    return (
        <SensorStatisticsContent
            data-testid={`SensorStatistics-${sensorType}-testid`}
            xDomainConfig={
                sensorType === SensorStatisticsType.BATTERY_STATISTICS
                    ? xDomainConfig
                    : xDomainConfig.concat(xAditionalDomainConfig)
            }
            yDomain2={yDomain2}
            timeline={timeline}
            getStatistic={getStatistic}
            scale={statisticsScales[sensorType].scale}
            csvExport={refetch}
            sensorType={sensorType}
        />
    );
};

export default SensorStatistics;
