import React from 'react';
import { ContainerExtraFilter, SensorsTablesContainer, SensorTableContainer } from './SensorsTables.style';
import { SensorsTablesViewProps } from './SensorsTables.type';
import { useTranslation } from 'react-i18next';
import SensorsTable from '../SensorsTable/SensorsTable';
import UiWidgetTab from 'components/Ui/Components/UiWidgetTab/UiWidgetTab';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import RemovedSensorsTable from '../RemovedSensorsTable/RemovedSensorsTable';
import { useMediaQuery, useTheme } from '@mui/material';
import ExtraFilter from 'components/Ui/UiTableExtraFilter';

export const SensorsTablesContent: React.FC<SensorsTablesViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const showXsSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

    return (
        <SensorsTablesContainer data-testid='SensorsTablesContent'>
            <UiWidgetTab
                scrollButtons={false}
                title={translate('t.sensors')}
                closeButton={false}
                testid='Widget-Sensors'
                $minwidth={1250}
                setNumberTab={props.setNumberTab}
                headerAction={
                    !showXsSm ? (
                        <ContainerExtraFilter>
                            {props.currentTab === 0 && (
                                <ExtraFilter
                                    tableKey='firstSensorPage'
                                    testid='Sensors-Attached-Wheels'
                                    extraFilter={{
                                        id: 'isWheel',
                                        value: {
                                            name: translate('t.sensors_attached_wheels'),
                                            value: '1',
                                            humanValue: 'True'
                                        }
                                    }}
                                />
                            )}
                        </ContainerExtraFilter>
                    ) : undefined
                }
                tab={{
                    headers: [
                        {
                            label: translate('t.sensors'),
                            icon: <UiIcon icon={['fas', 'link-slash']} size='1x' />
                        },
                        {
                            label: translate('t.removed_sensors'),
                            icon: <UiIcon icon={['fas', 'link-slash']} size='1x' />
                        }
                    ],
                    constents: [
                        {
                            id: 0,
                            body: (
                                <SensorTableContainer>
                                    <SensorsTable />
                                </SensorTableContainer>
                            )
                        },
                        {
                            id: 1,
                            body: (
                                <SensorTableContainer>
                                    <RemovedSensorsTable />
                                </SensorTableContainer>
                            )
                        }
                    ]
                }}
            />
        </SensorsTablesContainer>
    );
};
