import React from 'react';
import { JobsLogsTableContainer } from './JobsLogsTable.style';
import { JobsLogsTableViewProps } from './JobsLogsTable.type';
import UiSimpleTable from 'components/Ui/Components/UiSimpleTable/UiSimpleTable';
import DeviceUpdateJobLog from 'api/DeviceUpdateJobLog';

const deviceUpdateJobLogApi = new DeviceUpdateJobLog();
export const JobsLogsTableContent: React.FC<JobsLogsTableViewProps> = ({ columns, jobId }): JSX.Element => {
    return (
        <JobsLogsTableContainer data-testid={`JobsLogsTableContent-${jobId}`}>
            <UiSimpleTable
                isTableOnModal
                columns={columns}
                fetchFn={deviceUpdateJobLogApi.getJobLogSimple}
                additionalFetchProps={{ deviceUpdateJobID: jobId }}
                queryKey={`jobsLogsTable-${jobId}`}
                defaultSorting={{ order: 'desc', orderBy: 'id' }}
            />
        </JobsLogsTableContainer>
    );
};
