import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import { TeamShiftNameFormProps, InitialValues } from './TeamShiftNameActions.type';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

export const TeamShiftNameForm: React.FC<TeamShiftNameFormProps> = ({ initialValues, handleSubmit }): JSX.Element => {
    const { t: translate } = useTranslation();

    const schemaValidateForm = Yup.object({
        name: Yup.string().required(translate('p.this_field_is_required'))
    });

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values: InitialValues) => {
                handleSubmit(values);
            }}
            enableReinitialize
            validationSchema={schemaValidateForm}
        >
            {(propsFormik: FormikProps<InitialValues>) => {
                const { touched, errors, handleChange, values } = propsFormik;

                return (
                    <Form id='teamShiftName-form'>
                        <Grid container spacing={2} justifyContent='flex-start' sx={{ mt: 3 }}>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <TextField
                                    id='name'
                                    label={translate('t.name')}
                                    value={values.name}
                                    variant='outlined'
                                    size='small'
                                    name='name'
                                    error={!!errors.name && !!touched.name}
                                    type='text'
                                    fullWidth
                                    onChange={handleChange}
                                    inputProps={{ step: 0.1, min: 0 }}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={errors.name}
                                    data-testid='tyre-name-textield'
                                />
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};
