import React, { useMemo } from 'react';
import { Title, AlertMessage } from './HistoricalLog.style';
import { HistoricalLogViewProps } from './HistoricalLog.type';
import UiTable from 'components/Ui/UiTable/UiTable';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { DateTimePicker, SelectedVehicle } from 'states/global/Statistics';
import useConverter from 'components/CustomHooks/Converter/Converter';
import NotificationEvent from 'api/NotificationEvent';
import { DateTime } from 'luxon';

const notificationEventAPI = new NotificationEvent();

export const HistoricalLogContent: React.FC<HistoricalLogViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const statisticsDateTime = useRecoilValue(DateTimePicker);
    const hidden: string[] = ['measuredFrom'];
    const { fromTimezoneToUTC } = useConverter();
    const selectedVehicle = useRecoilValue(SelectedVehicle);
    const maxDateReached = useMemo(() => {
        return DateTime.now().diff(statisticsDateTime.current.dateFrom, ['days']).days > 31;
    }, [statisticsDateTime.current.dateFrom]);

    return (
        <>
            <Title>{translate('t.historical_log')}</Title>
            {maxDateReached ? (
                <AlertMessage severity='warning'>{translate('p.historical_logs_max_date')}</AlertMessage>
            ) : (
                <UiTable
                    columns={props.columns}
                    fetchFn={notificationEventAPI.getTyreEvent}
                    additionalProps={{
                        measuredFrom: fromTimezoneToUTC(statisticsDateTime.current.dateFrom),
                        measuredTo: fromTimezoneToUTC(statisticsDateTime.current.dateTo),
                        vehicleId: selectedVehicle.id,
                        tab: 'log'
                    }}
                    maxHeight={485}
                    onlyIconsForDefActionBtns
                    minHeight={420}
                    refresh={false}
                    hiddenColumns={hidden}
                    queryKey='historicalLogList'
                />
            )}
        </>
    );
};
