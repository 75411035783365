import Account from 'api/Account';
import User from 'api/User';
import React, { useEffect, useState } from 'react';
import * as view from './GeneralSettingsForm.view';
import * as type from './GeneralSettingsForm.type';
import { useRecoilState } from 'recoil';
import TimeZone from 'api/TimeZone';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Errors, Success } from 'components/Popup/Popup';
import { ITimeZone, ITimeZoneFormatted, TimeZoneQueryKeys } from 'models/TimeZone.type';
import { DateToPHPFormat, TimeToPHPFormat, getSortedTimezone } from 'helpers';
import { User as UserState } from 'states/global/User';
import { UserModel } from 'models/User.type';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { Wrapper } from 'helpers/wrapper';

const ApiAccount = new Account();
const ApiUser = new User();
const ApiTimeZone = new TimeZone();

const GeneralSettingsForm: React.FC<type.GeneralSettingsFormProps> = ({
    userDataSetting,
    isCurrentUser,
    userId,
    setReloadPage
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const [viewLoading, setViewLoading] = useState<boolean>(true);
    const [timeZonesData, setTimeZonesData] = useState<null | ITimeZoneFormatted[]>(null);
    const [userState, setUserState] = useRecoilState(UserState);
    const { dateTimeFormat } = useConverter();

    const [inicialValuesFormik, setInicialValuesFormik] = useState<type.InicialValuesForm>({
        language: '',
        timeZone: '',
        dateFormat: '',
        showTimesIntimeZoneMineOrMyLocalTimeZone: '',
        timeFormat: '',
        loginTimeOutPeriod: '',
        pressureUnit: '',
        temperatureUnit: '',
        speedUnit: '',
        accelerationUnit: '',
        altitudeUnit: '',
        statisticsValue: '',
        targetPressure: false,
        coldPressure: 0,
        showOutServiceVehicles: false,
        showColdPressureForExternalSensors: false,
        showTemperatureForExternalSensors: false,
        showColdPressureInTyreSummary: false
    });

    const {
        isLoading: timeZoneLoading,

        data: timeZoneData
    } = useQuery<boolean, Error, ITimeZone[]>(
        [TimeZoneQueryKeys.getCodebook],
        () => ApiTimeZone.getCodebook({ apiProject: undefined }),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours')
        }
    );

    const loginTimeOutPeriodList: type.CommonDropDown[] = [
        { value: 31536000, name: 'No' },
        { value: 3600, name: '1 hour' },
        { value: 86400, name: '1 day' },
        { value: 604800, name: '1 week' },
        { value: 18144000, name: '1 month' }
    ];

    const pressureUnitList: type.CommonDropDown[] = [
        { value: 'kpa', name: 'kPa' },
        { value: 'psi', name: 'PSI' },
        { value: 'bar', name: 'bar' }
    ];

    const handleSubmit = async (generalSettingsFormData: type.InicialValuesForm, setSubmitting): Promise<void> => {
        generalSettingsFormData.timeOutPeriod = generalSettingsFormData.loginTimeOutPeriod;
        generalSettingsFormData.timezone = generalSettingsFormData.timeZone;
        generalSettingsFormData.useMineTimezone =
            generalSettingsFormData.showTimesIntimeZoneMineOrMyLocalTimeZone == 'false' ? false : true;
        generalSettingsFormData.dateFormat = DateToPHPFormat(generalSettingsFormData.dateFormat);
        generalSettingsFormData.timeFormat = TimeToPHPFormat(generalSettingsFormData.timeFormat);
        generalSettingsFormData.showOutOfService = generalSettingsFormData.showOutServiceVehicles;

        const { ...newGeneralSettingsFormData } = generalSettingsFormData;

        const GeneralPatch = isCurrentUser
            ? ApiAccount.patchSetting(newGeneralSettingsFormData)
            : ApiUser.patchSetting(userId as number, newGeneralSettingsFormData);
        const { userSetting }: type.IUserSetting = await GeneralPatch;
        if (userSetting) {
            Success({ text: translate('t.general_settings_modified_successfully') });

            const newUserState = { ...userState };
            newUserState.userSetting = userSetting;
            isCurrentUser && setUserState(newUserState as UserModel);
            setReloadPage(true);
        } else {
            Errors({ text: translate('t.error_modifying_general_settings') });
        }

        setSubmitting(false);
    };

    useEffect(() => {
        if (userDataSetting && !!Object.keys(userDataSetting).length) {
            setViewLoading(true);
            setInicialValuesFormik({
                language: userDataSetting.language,
                timeZone: isCurrentUser ? userDataSetting?.customerTimezone?.id || '' : userDataSetting.timezone.id,
                dateFormat: userDataSetting.dateFormat,
                showTimesIntimeZoneMineOrMyLocalTimeZone: userDataSetting.useMineTimezone.toString(),
                timeFormat: userDataSetting.timeFormat,
                loginTimeOutPeriod: userDataSetting.timeOutPeriod,
                pressureUnit: userDataSetting.pressureUnit ? userDataSetting.pressureUnit.toLowerCase() : null,
                temperatureUnit: userDataSetting.temperatureUnit,
                speedUnit: userDataSetting.speedUnit,
                accelerationUnit: userDataSetting.accelerationUnit,
                altitudeUnit: userDataSetting.altitudeUnit,
                targetPressure: userDataSetting.targetPressure,
                coldPressure: userDataSetting.coldPressure,
                statisticsValue: userDataSetting.statisticsValue,
                showOutServiceVehicles: userDataSetting.showOutOfService,
                showColdPressureForExternalSensors: userDataSetting.showColdPressureForExternalSensors,
                showTemperatureForExternalSensors: userDataSetting.showTemperatureForExternalSensors,
                showColdPressureInTyreSummary: userDataSetting.showColdPressureInTyreSummary
            });
        }
        setTimeout(() => {
            setViewLoading(false);
        }, 2000);
    }, [userDataSetting]);

    useEffect(() => {
        if (timeZoneData) {
            if (!timeZoneLoading) {
                let newTimezone = getSortedTimezone(timeZoneData, dateTimeFormat);
                setTimeZonesData(newTimezone);
            }
        } else {
            setTimeZonesData(null);
        }
    }, [timeZoneData, userDataSetting?.dateFormat, userDataSetting?.timeFormat]);

    return !viewLoading && timeZonesData ? (
        <view.GeneralSettingsFormContent
            data-testid={'GeneralSettingsForm-testid'}
            inicialValuesFormik={inicialValuesFormik}
            timeZoneData={timeZonesData ? timeZonesData : []}
            loginTimeOutPeriodList={loginTimeOutPeriodList}
            handleSubmit={handleSubmit}
            pressureUnitList={pressureUnitList}
        />
    ) : (
        <UiLoadingPage size='30px' />
    );
};

export default Wrapper(GeneralSettingsForm);
