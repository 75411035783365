import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
    AlertInfo,
    AlertsAffectedVehiclesListViewContent,
    SchemaBox,
    SchemaContent,
    VehicleLabel,
    VehicleListContainer,
    LoadingSchema,
    LoadingSchemaCover
} from './AlertsAffectedVehiclesList.style';
import { AlertsAffectedVehiclesListProps } from './AlertsAffectedVehiclesList.type';
import { useTranslation } from 'react-i18next';
import { AffectedVehiclesCount, FilterVehicleValue, RefetchAffectedVehicles } from '../AlertsAffectedVehicles/atom';
import { Grid, Skeleton, Tooltip, Typography, debounce, useMediaQuery, useTheme } from '@mui/material';
import VehicleSchema from 'components/Vehicle/NewVehicleSchema/VehicheSchema';
import { useQuery } from '@tanstack/react-query';
import VehicleApi from 'api/Vehicle';
import { VehiclesWithPaginator } from 'models/Vehicle.type';
import { AlertVehicleFilters } from '../AlertsFilterVehiclesBy/atom';
import { DefaultVehiclesWithPaginator } from 'models/Rule.type';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { ProcessingFilterSelection } from '../AlertsAvailableFilters/atom';
import { SettingSteps, SettingsStep } from '../AlertSettings/atom';
import { VehicleQueryKeys } from 'models/Vehicle.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';

const Vehicle = new VehicleApi();

const AlertsAffectedVehiclesListContent: React.FC<AlertsAffectedVehiclesListProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const filterValue = useRecoilValue(FilterVehicleValue);
    const setAffectedVehiclesCount = useSetRecoilState(AffectedVehiclesCount);
    const filters = useRecoilValue(AlertVehicleFilters);
    const setRefetchAffectedVehicles = useSetRecoilState(RefetchAffectedVehicles);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [triggerLoading, setTriggerLoading] = useState<boolean>(false);
    const [schemaLoading, setSchemaLoadingLoading] = useState<boolean>(false);
    const [vehicleData, setVehicleData] = useState<
        VehiclesWithPaginator['items'][0][] | DefaultVehiclesWithPaginator['items'][0][]
    >([]);
    const settingsStep = useRecoilValue(SettingsStep);
    const theme = useTheme();
    const xlScreen = useMediaQuery(theme.breakpoints.up('xl'));
    const setProcessingSelection = useSetRecoilState(ProcessingFilterSelection);

    const getVehicleParams = useCallback(() => {
        let params: any = {};
        if (filterValue.searchValue.length && settingsStep !== SettingSteps.TEMPERATURE_PRESSURE_SETTING) {
            params.name = filterValue.searchValue;
        }
        if (filters.vehicleConfiguration) {
            params.vehicleConfiguration = filters.vehicleConfiguration.id;
        }

        if (filters.vehicleModelV2) {
            params.vehicleModelV2 = filters.vehicleModelV2.modelName;
        }

        if (filters.vehicleManufacturerV2) {
            params.vehicleManufacturerV2 = filters.vehicleManufacturerV2.manufacturer;
        }

        if (filters.vehicle) {
            params.id = filters.vehicle.id;
        }
        if (filters.vehicleTypeType) {
            params.vehicleTypeType = filters.vehicleTypeType.type;
        }

        params.limit = xlScreen ? 40 : 30;
        params.page = page;
        return params;
    }, [
        filterValue.searchValue,
        filters.vehicleConfiguration,
        filters.vehicleModelV2,
        filters.vehicleManufacturerV2,
        filters.vehicle,
        page,
        xlScreen
    ]);

    const hasFilters = useMemo(() => Object.keys(filters).length > 0, [filters]);

    const { isLoading, isRefetching, refetch } = useQuery<
        any,
        Error,
        VehiclesWithPaginator | DefaultVehiclesWithPaginator
    >(
        [VehicleQueryKeys.getAffectedVehicles],
        () =>
            // will be used in next iteration
            /*filters.default
                ? Rule.getDefaultVehicles({
                      limit: 30,
                      page,
                      name: filterValue.searchValue,
                      wheelRuleRuleType: 1
                  })
                : Vehicle.getTable({
                      criteria: getVehicleParams()
                  }),*/
            Vehicle.getTable({
                criteria: getVehicleParams()
            }),
        {
            refetchOnWindowFocus: false,
            enabled: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: (data) => {
                const hasMoreData = (data?.paginator?.page ?? 0) < (data?.paginator?.pageCount ?? 0);
                if (data) {
                    setAffectedVehiclesCount((value) => ({
                        latest: filterValue.searchValue ? value.latest : hasFilters ? data.paginator.totalCount : 0,
                        current: filterValue.searchValue ? value.latest : hasFilters ? data.paginator.totalCount : 0
                    }));
                }
                if (filters.default) {
                    setVehicleData(
                        (current) =>
                            (hasFilters
                                ? [...current, ...data.items]
                                : []) as DefaultVehiclesWithPaginator['items'][0][]
                    );
                } else {
                    setVehicleData(
                        (current) =>
                            (hasFilters ? [...current, ...data.items] : []) as VehiclesWithPaginator['items'][0][]
                    );
                }
                if (hasMoreData) {
                    setPage((current) => current + 1);
                }
                setHasMore(hasMoreData);
                setTriggerLoading(false);
                setSchemaLoadingLoading(false);
                setProcessingSelection(false);
            }
        }
    );
    // preparation for next iteration
    /*const getColoredWheels = (wheels: DefaultVehiclesWithPaginator['items'][0]['wheel'] = []): Record<string, any> => {
        const colors = {};
        for (let i = 0, length = wheels.length; i < length; i++) {
            if (wheels[i].rule.ruleType.id === RULE_TYPE.DEFAULT) {
                colors[wheels[i].position] = {};
            }
        }
        return colors;
    };*/

    const onScroll = (e): void => {
        if (e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 10) {
            if (hasMore) {
                refetch();
                setSchemaLoadingLoading(true);
            }
        }
    };

    const resetList = () => {
        if (hasFilters) {
            setTriggerLoading(true);
        }
        setVehicleData([]);
        setHasMore(false);
        setPage(1);
        refetch();
    };

    const loadVehiclesByName = useCallback(
        debounce(() => {
            if (hasFilters) {
                resetList();
            }
        }, 1000),

        [filterValue.searchValue]
    );

    useEffect(() => {
        loadVehiclesByName();
    }, [filterValue.inpuValue]);

    const defineGlobalQueryFn = useCallback(() => {
        setRefetchAffectedVehicles((current) => ({ ...current, refetch: resetList }));
    }, [refetch, hasFilters]);

    const skeletons = useMemo(() => {
        let skeletons: JSX.Element[] = [];
        for (let i = 0; i < 10; i++) {
            skeletons.push(
                <SchemaContent>
                    <VehicleLabel variant='subtitle2' gutterBottom>
                        <Skeleton variant='rectangular' width={100} height={20} />
                    </VehicleLabel>
                    <SchemaBox>
                        <Skeleton variant='rectangular' width={100} height={120} />
                    </SchemaBox>
                </SchemaContent>
            );
        }
        return skeletons;
    }, []);

    useEffect(() => {
        if (isRefetching) {
            setAffectedVehiclesCount((value) => ({ ...value, current: -1 }));
        }
    }, [isRefetching]);

    useEffect(() => {
        if (filterValue.inpuValue !== '' || filterValue.searchValue !== '') defineGlobalQueryFn();
    }, [filterValue]);

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            defineGlobalQueryFn();
            // setAffectedVehiclesCount((value) => ({ ...value, current: -1 }));
        }
    }, [filters]);

    return (
        <AlertsAffectedVehiclesListViewContent data-testid='AlertsAffectedVehiclesListContent' height={props.height}>
            {triggerLoading ? (
                skeletons
            ) : vehicleData.length === 0 && filterValue.searchValue.length ? (
                <AlertInfo severity='info'>{translate('t.vehicle_not_found')}</AlertInfo>
            ) : (
                <>
                    {filters.default && (
                        <Grid item xs={12} md lg>
                            <AlertInfo severity='info' data-testid='infoAboutDefaultAlerts' style={{ marginBottom: 5 }}>
                                <Typography display={'inline-block'}>
                                    {translate('t.red_highlighted_wheels_default_alerts')}{' '}
                                    {translate('t.change_settings_effect_on_red_highlighted_wheels')}
                                </Typography>
                            </AlertInfo>
                        </Grid>
                    )}
                    {schemaLoading && (
                        <LoadingSchemaCover>
                            <LoadingSchema show={schemaLoading} theme={theme}>
                                <UiLoadingPage />
                            </LoadingSchema>
                        </LoadingSchemaCover>
                    )}
                    <VehicleListContainer onScroll={onScroll} elevation={0}>
                        {vehicleData.map(
                            (vehicle, index) =>
                                vehicle.vehicleConfigurationV2.distribution && (
                                    <SchemaContent key={index}>
                                        <Tooltip key={`tooltip-schema-${index}`} title={vehicle.name}>
                                            <VehicleLabel
                                                key={`vehicle-label-${index}`}
                                                variant='subtitle2'
                                                gutterBottom
                                            >
                                                {vehicle.name}
                                            </VehicleLabel>
                                        </Tooltip>
                                        <SchemaBox key={`schema-box-${index}`}>
                                            <VehicleSchema
                                                key={`vehicle-schema-${index}`}
                                                schema={vehicle.vehicleConfigurationV2.distribution}
                                                emptySchema
                                                allowDisplaySelectedTyre
                                                //allowDisplaySelectedTyre={!filters.default} /* functionality is ready for next iteration all three lines*/
                                                //highlightColor='#f00'
                                                //highlightWheels={filters.default ? getColoredWheels(vehicle.wheel) : undefined}
                                            />
                                        </SchemaBox>
                                    </SchemaContent>
                                )
                        )}
                    </VehicleListContainer>
                </>
            )}
        </AlertsAffectedVehiclesListViewContent>
    );
};

export default AlertsAffectedVehiclesListContent;
