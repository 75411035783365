import React from 'react';
import { UiLoadingPageViewContent } from './UiLoadingPage.style';
import { UiLoadingPageProps } from './UiLoadingPage.type';
import CircularProgress from '@mui/material/CircularProgress';

const UiLoadingPage: React.FC<UiLoadingPageProps> = ({ size, $marginTop, color, testid, height }): JSX.Element => {
    return (
        <UiLoadingPageViewContent
            data-testid={`${testid || 'UiLoadingPage'}-Container`}
            $marginTop={$marginTop}
            $height={height}
        >
            <CircularProgress
                size={size || '1.75em'}
                color={color || 'inherit'}
                data-testid={testid || 'UiLoadingPage'}
            />
        </UiLoadingPageViewContent>
    );
};

export default UiLoadingPage;
