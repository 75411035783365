import { TableContainer } from '@mui/material';
import styled from 'styled-components';

export const UiTableInstanceViewContent = styled.div``;

export const TableContainerCustom = styled(TableContainer)<{
    $maxHeight?: number;
    $minHeight?: number;
}>`
    ${({ $minHeight, $maxHeight }) => {
        return `
                height: auto;
                min-height: ${$minHeight || ($maxHeight ? $maxHeight - 60 : 380)}px;
                max-height: calc(100vh - 270px);
                overflow: auto;
                display: grid;
            `;
    }}

    ${({ $maxHeight }) =>
        $maxHeight &&
        `
        max-height: ${$maxHeight}px;
       
    `}
`;
