import React, { useState } from 'react';
import * as view from './ShowWifiPassword.view';
import { ShowWifiPasswordProps } from './ShowWifiPassword.type';
import { Wrapper } from 'helpers/wrapper';
import { useQuery } from '@tanstack/react-query';
import Device from 'api/Device';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { DeviceQueryKeys } from 'models/Device.type';
import { WifiCredentials } from '../../../models/HubAdministration.type';

const deviceApi = new Device();

const ShowWifiPassword: React.FC<ShowWifiPasswordProps> = (props): JSX.Element => {
    const [hidden, setHidden] = useState<boolean>(true);
    const [copiedSSID, setCopiedSSID] = useState<boolean>(false);
    const [copiedPassword, setCopiedPassword] = useState<boolean>(false);

    const handleCopy = (text, value) => {
        navigator.clipboard.writeText(text.toString() ?? ' ');

        value == 1 ? setCopiedSSID(true) : setCopiedPassword(true);

        setTimeout(() => {
            value == 1 ? setCopiedSSID(false) : setCopiedPassword(false);
        }, 900);
    };

    const handleShowPassword = () => {
        setHidden(!hidden);
    };

    const { data: keyData, isLoading: keyLoading } = useQuery(
        [DeviceQueryKeys.getWifiCredentials, props.deviceId],
        () => deviceApi.getWifiCredentials({ id: props.deviceId }),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(15, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(15, 'minutes')
        }
    );

    return (
        <view.ShowWifiPasswordContent
            data-testid='ShowWifiPasswordContent-testid'
            keyLoading={keyLoading}
            handleCopy={handleCopy}
            handleShowPassword={handleShowPassword}
            copiedSSID={copiedSSID}
            copiedPassword={copiedPassword}
            keyData={
                keyData?.items[0] ?? {
                    wifiName: '',
                    password: null
                }
            }
            hidden={hidden}
        />
    );
};

export default Wrapper(ShowWifiPassword);
