import Button from '@mui/material/Button';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Menu } from '@mui/icons-material';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    Bar,
    BarGroup,
    BarGroupIcon,
    BarGroupLogo,
    ButtonIcon,
    IconButtonMobile,
    LeftBar,
    LogoContainer,
    RightBar,
    RightBarContainer,
    RightBarLeft,
    RightBarRight,
    TopBar
} from './TopBar.style';
import * as type from './TopBar.type';
import CustomerSelector from '../Customer/CustomerSelector/CustomerSelector';
import { CustomerSelectorAtom } from '../Customer/CustomerSelectorAtom';
import { User } from '../../states/global/User';
import NotificationsToggleButton from './NotificationsToggleButton/NotificationsToggleButton';
import CurrentTime from './CurrentTime/CurrentTime';
import LogoutButton from './LogoutButton/LogoutButton';
import EasyReadingButton from './EasyReadingButton/EasyReadingButton';
import ThemeButton from './ThemeButton/ThemeButton';
import RealTimeNotification from './RealTimeNotification/RealTimeNotification';
import { ThemeMode } from '../../states/global/Theme';
import ChooseLanguageButton from './ChooseLanguageButton/ChooseLanguageButton';
import LogoGlobalAdmin from '../Customer/GlobalAdmin/Components/LogoGlobalAdmin/LogoGlobalAdmin';
import { useProSidebar } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { useMediaQuery, useTheme, Hidden } from '@mui/material';
import AmbientTemperature from 'components/AmbientTemperature/AmbientTemperature';
import PageHelpButton from './PageHelpButton/PageHelpButton';
import { UserInfo } from '../../states/global/User';
import UserNotification from '../User/UserNotification/UserNotification';

import { getImgSource } from 'helpers/image';
import CurrentDate from './CurrentDate/CurrentDate';

export const TopBarContent: React.FC<type.TopBarContentProps> = (props): JSX.Element => {
    const UserState = useRecoilValue(User);
    const [selectorState, setSelectorState] = useRecoilState(CustomerSelectorAtom);
    const { collapsed } = useProSidebar();
    const theme = useTheme();
    const showXsSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const userInfo = useRecoilValue(UserInfo);

    return (
        <TopBar position='fixed' data-testid='TopBarContent'>
            <Bar>
                <LeftBar $collapsedSidebar={!collapsed}>
                    <IconButtonMobile
                        edge='start'
                        color='inherit'
                        aria-label='open drawer'
                        onClick={props.showSidebarResponsive}
                        size='large'
                        testid='CollapsSidebar-Mobile-Button'
                    >
                        <Menu />
                    </IconButtonMobile>

                    <Hidden only={['xs', 'sm']}>
                        <Link to='/dashboard'>
                            <LogoContainer>
                                <img
                                    src={
                                        props.themeMode === ThemeMode.light
                                            ? getImgSource('logo')
                                            : getImgSource('logolight')
                                    }
                                    className='Header-logo'
                                    alt='logo itrack'
                                ></img>
                            </LogoContainer>
                        </Link>
                    </Hidden>
                </LeftBar>

                <RightBar>
                    {props.loading ? (
                        ''
                    ) : (
                        <RightBarContainer>
                            <RightBarLeft>
                                <BarGroup>
                                    <Button
                                        onClick={() => {
                                            setSelectorState({ ...selectorState, isOpen: true });
                                        }}
                                        id='customer-selector'
                                        size='small'
                                        data-testid='CustomerSelect-Button'
                                    >
                                        {!showXsSm && <p>{UserState?.customer.name}</p>}
                                        <BarGroupIcon>
                                            <ExpandMore />
                                        </BarGroupIcon>

                                        {UserState?.customer?.hasLogo ? (
                                            <BarGroupLogo>
                                                <LogoGlobalAdmin
                                                    maxWidth='90px'
                                                    maxHeight='40px'
                                                    customerId={UserState?.customer.id || 12}
                                                />
                                            </BarGroupLogo>
                                        ) : (
                                            ''
                                        )}
                                    </Button>
                                </BarGroup>{' '}
                            </RightBarLeft>
                            <RightBarRight>
                                <Hidden only={['sm', 'xs', 'md']}>
                                    <CurrentDate />
                                    <CurrentTime />
                                    <AmbientTemperature />
                                </Hidden>
                                <ChooseLanguageButton />
                                <ButtonIcon>
                                    <RealTimeNotification />
                                </ButtonIcon>
                                <Hidden only={['sm', 'xs']}>
                                    <NotificationsToggleButton />
                                </Hidden>
                                <Hidden only={['xs']}>
                                    <ButtonIcon>
                                        <EasyReadingButton />
                                    </ButtonIcon>
                                </Hidden>
                                <Hidden only={['sm', 'xs']}>
                                    <ButtonIcon>
                                        <ThemeButton />
                                    </ButtonIcon>
                                </Hidden>
                                <Hidden only={['sm', 'xs']}>
                                    <ButtonIcon>
                                        <LogoutButton />
                                    </ButtonIcon>
                                </Hidden>
                                <ButtonIcon>
                                    <PageHelpButton />
                                </ButtonIcon>
                            </RightBarRight>
                        </RightBarContainer>
                    )}
                </RightBar>
            </Bar>
            <CustomerSelector></CustomerSelector>
            {userInfo.user?.userSetting.desktopNotification && (
                <UserNotification data-testid='TopBar-UserNotification' />
            )}
        </TopBar>
    );
};
