import React, { useReducer } from 'react';
import { AddUserFormContent } from './AddUserForm.view';
import { AddUserActions, AddUserFormProps, InicialvaluesForm } from './AddUserForm.type';
import { optionsCodeBook } from 'components/Ui/Components/UiDropDown/UiDropDown.type';
import { useTranslation } from 'react-i18next';
import { Errors, Success } from 'components/Popup/Popup';
import { useRecoilValue } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ITimeZone, TimeZoneQueryKeys } from 'models/TimeZone.type';
import { AccountModelResponse, UserAdd, UserAvatar } from 'models/User.type';
import TimeZone from 'api/TimeZone';
import { UserInfo } from 'states/global/User';
import User from 'api/User';
import { AddUserInitalState, AddUserReducer } from './AddUserForm.reducer';
import { CustomerSettings } from 'states/global/CustomerSettings';

import { UserDataPost } from '../ChangePasswordForm/ChangePasswordForm.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { useNavigate } from 'react-router-dom';

const userApi = new User();
const ApiTimeZone = new TimeZone();

export const existsUserName = async (userName: string): Promise<boolean> => {
    const usersDataXHR: AccountModelResponse = await userApi.getUserByUserName(userName?.toLowerCase());
    return !usersDataXHR?.user;
};

const AddUserForm: React.FC<AddUserFormProps> = (): JSX.Element => {
    const [state, dispatch] = useReducer(AddUserReducer, AddUserInitalState);
    const { t: translate } = useTranslation();
    const [sendform, setSendform] = React.useState<boolean>(true);
    const UserState = useRecoilValue(UserInfo);
    const customerSettings = useRecoilValue(CustomerSettings);
    const navigate = useNavigate();

    const setPreviewImg = (img: string) => {
        dispatch({
            type: AddUserActions.SET_USER_PREVIEW_IMG,
            payload: img
        });
    };

    const showMessageAddUser = (userId: number) => {
        dispatch({
            type: AddUserActions.SET_USER_LOADING,
            payload: false
        });
        Success({
            text: translate('t.registered_user')
        });
        setTimeout(() => {
            navigate(`${process.env.PUBLIC_URL}/profile/${userId}`);
        }, 2000);
    };

    const { mutate: addUserMutate } = useMutation<UserDataPost, unknown, UserAdd>(userApi.addUser, {
        onSuccess: (data) => {
            state.userAvatar && setUserAvatar({ userId: data.data.user.id, image: state.userAvatar });
            !state.userAvatar && showMessageAddUser(data.data.user.id);
        },
        onError: () => {
            dispatch({
                type: AddUserActions.SET_USER_LOADING,
                payload: false
            });
        }
    });

    const { mutate: setUserAvatarMutate } = useMutation(userApi.patchAvatar, {});

    const setUserAvatar = (data: UserAvatar) => {
        setUserAvatarMutate(data);
        showMessageAddUser(data.userId);
    };

    const handleSubmit = async (valuesForm: InicialvaluesForm): Promise<void> => {
        dispatch({
            type: AddUserActions.SET_USER_LOADING,
            payload: true
        });

        try {
            const data: UserAdd = {
                username: valuesForm.username,
                email: valuesForm.email,
                phoneNumber: valuesForm.phoneNumber,
                password: valuesForm.password,
                firstname: valuesForm.firstname,
                lastname: valuesForm.lastname,
                role: valuesForm.role,
                resetPassword: valuesForm.resetPassword ? 1 : 0,
                userSetting: {
                    statisticsValue: 'min',
                    mapType: valuesForm.mapType.toString(),
                    language: 'en_US',
                    timezone: valuesForm.timeZone, //do we need swap the timezone payloads too?
                    useMineTimezone: true
                }
            };

            addUserMutate(data);
        } catch (error) {
            false;
        }
    };

    const handleCapture = (e: React.ChangeEvent): void => {
        const reader = new FileReader();
        const target = e.target as HTMLInputElement;
        reader.onloadend = () => {
            return dispatch({
                type: AddUserActions.SET_USER_AVATAR,
                payload: reader.result ?? ''
            });
        };

        if (target.files) {
            reader.readAsDataURL(target.files[0]);
            dispatch({
                type: AddUserActions.SET_USER_PREVIEW_IMG,
                payload: URL.createObjectURL(target.files[0])
            });
        }

        if (target.files) {
            const siezekiloByte: number = parseInt((target.files[0].size / 1024).toFixed(1));
            if (siezekiloByte > 2048) {
                setSendform(false);
                Errors({ text: translate('t.the_maximum_image_size_is_2mb') });
            } else {
                setSendform(true);
            }
        }
    };

    const { data: timeZoneDataXHR, isLoading: timeZoneLoading } = useQuery<boolean, Error, ITimeZone[]>(
        [TimeZoneQueryKeys.getCodebook],
        () => ApiTimeZone.getCodebook({ apiProject: undefined }),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours')
        }
    );

    const mapTypeList: optionsCodeBook[] = [
        { id: 'osm', name: 'Open street map' },
        { id: 'bing', name: 'Bing Maps' },
        { id: 'mapbox', name: 'Mapbox' },
        { id: 'arcgis', name: 'ArcGIS' }
    ];

    const inicialvaluesForm: InicialvaluesForm = {
        username: '',
        email: '',
        role: 4,
        phoneNumber: '',
        firstname: '',
        lastname: '',
        avatar: '',
        password: '',
        confirmPassword: '',
        timeZone: UserState?.user?.customer?.timezone?.id ?? 25,
        mapType: 'osm',
        resetPassword: customerSettings.require_password_reset
    };

    return (
        <AddUserFormContent
            data-testid={'AddUserForm-testid'}
            inicialvaluesForm={inicialvaluesForm}
            handleSubmit={handleSubmit}
            selectedFile={state.userAvatar}
            previewImg={state.previewImg}
            setPreviewImg={setPreviewImg}
            handleCapture={handleCapture}
            sendform={sendform}
            timeZoneData={timeZoneDataXHR ?? []}
            mapTypeList={mapTypeList}
            timeZoneLoading={timeZoneLoading}
            loading={state.userLoading}
            existsUserName={existsUserName}
        />
    );
};

export default AddUserForm;
