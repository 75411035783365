import React, { useCallback, useMemo, useState } from 'react';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { IconButton, Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TooltipText } from './VehicleActions.style';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

const ExternalSystemsInfo = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLElement>): void => {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        },
        [anchorEl]
    );

    return (
        <>
            <UiIconButton onClick={handleClick}>
                <UiIcon icon={['fas', 'circle-info']} />
            </UiIconButton>
            <Popover
                id='external-system-value-info'
                open={open}
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >
                <TooltipText>{translate('p.key_pair_representation')}</TooltipText>
            </Popover>
        </>
    );
};

export default ExternalSystemsInfo;
