import React from 'react';
import {
    ContainerSchedulerLoading,
    GlobalStyle,
    IconButtonClone,
    IconButtonCloneContainer,
    ShiftTimeCalendarContainer
} from './ShiftTimeCalendar.style';
import { EventsScheduler, ShiftTimeCalendarViewProps } from './ShiftTimeCalendar.type';
import { Scheduler } from '@aldabil/react-scheduler';
import { es, enUS, uk, frCA, enGB, pt, ru, arSA } from 'date-fns/locale';
import { Box, Typography, useTheme } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import ShiftTimeCalendarAction from '../ShiftTimeCalendarAction/ShiftTimeCalendarAction';
import { ContentCopy as CloneIcon } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import { User } from 'states/global/User';
import ShiftTImeCalendarLoader from './ShiftTImeCalendarLoader';
import { Translations } from '@aldabil/react-scheduler/types';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';

export const ShiftTimeCalendarContent: React.FC<ShiftTimeCalendarViewProps> = ({
    loading,
    handleClone,
    shifts,
    handleDelete,
    schedulerTranslations,
    calendarRef,
    handleEventDrop,
    refetch
}): JSX.Element => {
    const user = useRecoilValue(User);

    const language = {
        en_US: enUS,
        es_ES: es,
        uk_UA: uk,
        ar_SA: arSA,
        fr_CA: frCA,
        en_GB: enGB,
        ru_RU: ru,
        pt_PT: pt
    };

    return (
        <ShiftTimeCalendarContainer data-testid='ShiftTimeCalendarContent'>
            <GlobalStyle $theme={useTheme().palette.mode} />
            {loading ? (
                <ShiftTImeCalendarLoader />
            ) : (
                <Scheduler
                    data-testid='ShiftTimeCalendar-scheduler'
                    ref={calendarRef}
                    customEditor={(scheduler) => <ShiftTimeCalendarAction scheduler={scheduler} refetch={refetch} />}
                    onDelete={handleDelete}
                    onEventDrop={handleEventDrop}
                    view='month'
                    loadingComponent={
                        <ContainerSchedulerLoading data-testid='ShiftTimeCalendar-loading'>
                            <UiLoadingPage size='25px' />
                        </ContainerSchedulerLoading>
                    }
                    hourFormat={user?.userSetting?.timeFormat === 'hh:mm' ? '12' : '24'}
                    events={shifts}
                    agenda={false}
                    locale={language[user?.userSetting.language ?? 'en_GB']}
                    translations={schedulerTranslations as Translations}
                    viewerTitleComponent={(event) => {
                        return (
                            <Box sx={{ display: 'inline-flex' }}>
                                <Typography variant='h6'>{event?.title ?? ''}</Typography>
                                <IconButtonCloneContainer>
                                    <span>
                                        <IconButtonClone
                                            size='small'
                                            sx={{ marginTop: '-7px', marginLeft: '6px' }}
                                            onClick={() => handleClone(event as EventsScheduler)}
                                            color='inherit'
                                            data-testid='ShiftTimeCalendar-buttonClone'
                                        >
                                            <CloneIcon data-testid='ShiftTimeCalendar-icon' />
                                        </IconButtonClone>
                                    </span>
                                </IconButtonCloneContainer>
                            </Box>
                        );
                    }}
                    viewerExtraComponent={(_fields, event) => {
                        return (
                            <div>
                                <Typography
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    color='textSecondary'
                                    variant='caption'
                                    noWrap
                                    data-testid='ShiftTimeCalendarViewerComponent-team-container'
                                >
                                    <UiIcon
                                        data-testid='ShiftTimeCalendarViewerComponent-icon'
                                        icon={faUsers}
                                        size='lg'
                                        fixedWidth
                                        transform='right-3'
                                    />{' '}
                                    <Box data-testid='ShiftTimeCalendarViewerComponent-teamName' sx={{ ml: 1.5 }}>
                                        {event?.team?.name || ''}
                                    </Box>
                                </Typography>
                            </div>
                        );
                    }}
                    day={{
                        startHour: 0,
                        step: 60,
                        endHour: 24
                    }}
                    week={{
                        startHour: 0,
                        endHour: 24,
                        weekStartOn: 6,
                        weekDays: [0, 1, 2, 3, 4, 5, 6],
                        step: 60
                    }}
                />
            )}
        </ShiftTimeCalendarContainer>
    );
};
