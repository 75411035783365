import styled from 'styled-components';
import { Box, useTheme } from '@mui/material';
import { ERROR, ORANGE, PRIMARY } from 'components/Ui/colors';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

export const PopupContent = styled.div`
    padding: 10px 0px;
    width: 100%;
    height: 100%;

    p {
        width: 90%;
    }
`;

export const PopupError = styled(PopupContent)`
    color: #fff;
    background: ${ERROR};
`;

export const PopupSuccess = styled(PopupContent)`
    ${() => {
        return `background: ${useTheme().palette.success.main};`;
    }}
    color: #fff;
`;

export const ErrorExclamation = styled.i`
    margin-right: 8px;
    margin-left: 8px;
    display: block;
    float: left;
    height: 65%;
    padding-top: 13px;
`;

export const ErrorCollapse = styled(Collapse)`
    width: 96%;
    margin: 0 auto;
    margin-top: 22px;
    padding-bottom: 10px;
    border-top: solid thin rgba(255, 255, 255, 0.4);
`;

export const ErrorButton = styled(UiIconButton)<{ expanded: string }>`
    position: absolute !important;
    color: #fff !important;
    padding: 8px !important;
    transform: rotate(0deg) !important;
    transition: all 0.3s ease-out !important;
    transform: ${(props) => (props.expanded === 'true' ? 'rotate(180deg) !important' : '')};
    left: 120px;
    top: 90px;
`;

export const PopupWarning = styled(PopupContent)`
    color: #fff;
    width: auto;
    background: ${ORANGE};
`;

export const TitleNotify = styled.span`
    display: block;
`;

export const ContainerTitleNotify = styled.span`
    display: flex;
`;

export const WhatsNewCoveer = styled(Box)({
    width: 300,
    padding: '20px',
    color: PRIMARY,
    background: 'linear-gradient(#b9d8ff, #dcedfc)'
});
