import { PRIMARY, SUCCESS } from '../../Ui/colors';
import styled from 'styled-components';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

export const ToggleButton = styled(UiIconButton)<{ $statusNotification?: boolean }>`
    @keyframes opacityChange {
        0% {
            background: ${PRIMARY};
        }
        50% {
            background: ${SUCCESS};
        }
        100% {
            background: ${PRIMARY};
        }
    }
    padding: 7px 4.5px !important;
    && .MuiCircularProgress-colorPrimary {
        color: #cdc9c6 !important;
    }
`;
