import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const ShiftTimeCalendarActionContainer = styled('div')`
    width: 495px;
    min-height: 215px;
`;

export const Titile = styled(Box)`
    margin-bottom: 10px;
    margin-left: 10px;
`;

export const Header = styled('div')`
    margin: 10px;
    border-bottom: 1px solid #cdcdcd;
`;

export const ContainerForm = styled('div')`
    padding: 20px;
`;
