import { Query, QueryCache, QueryClient, QueryFilters, QueryKey } from '@tanstack/react-query';
import { Duration } from 'luxon';

export const getCache = (
    cacheKey: string | unknown[],
    queryCache: QueryCache
): Query<unknown, unknown, unknown, QueryKey>[] => {
    return queryCache.getAll().filter((cache) => cache.queryKey.toString() == cacheKey.toString());
};

export const isCacheAvailable = (cacheKey: string | unknown[], queryCache: QueryCache): boolean => {
    return !(!getCache(cacheKey, queryCache)[0]?.state?.data || getCache(cacheKey, queryCache)[0]?.isStale());
};

export const removeAllQueryKeysFromModel = <T extends object>(QueryKeys: T, queryClient: QueryClient): void => {
    (Object.values(QueryKeys) as Array<keyof typeof QueryKeys>).forEach((key) => {
        queryClient.removeQueries(key as QueryFilters);
    });
};

export const removeAllQueryKeysFromSystem = (queryClient: QueryClient): void => {
    queryClient.removeQueries();
};

export const resetAllQueryKeysFromSystem = <T extends object>(QueryKeys: T, queryClient: QueryClient): void => {
    (Object.values(QueryKeys) as Array<keyof typeof QueryKeys>).forEach((key) => {
        queryClient.resetQueries(key as QueryFilters);
    });
};

export const cacheTimeToMilliseconds = (
    cacheTime = 5,
    cacheUnit: 'minutes' | 'hours' | 'seconds' = 'minutes'
): number => {
    return Duration.fromObject({ [cacheUnit]: cacheTime }).as('milliseconds');
};
