import styled from 'styled-components';

export const WhatsNewAnnouncementContainer = styled.div`
    height: 100%;
`;

export const SpinnerContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
`;
