import React, { useCallback } from 'react';
import * as view from './VehicleMapPopupTyres.view';
import { VehicleMapPopupTyresProps, Level } from './VehicleMapPopupTyres.type';
import { SensorAlert, TyreDetailResponse } from 'models/Wheel.type';
import { useQuery } from '@tanstack/react-query';
import WheelApi from 'api/Wheel';
import { MapActionData } from 'states/global/Map';
import { useRecoilValue } from 'recoil';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { WheelQueryKeys } from 'models/Wheel.type';
import { Wrapper } from 'helpers/wrapper';

const Wheel = new WheelApi();

const VehicleMapPopupTyres: React.FC<VehicleMapPopupTyresProps> = (props): JSX.Element => {
    const mapActionData = useRecoilValue(MapActionData);

    const { isLoading: tyresIsLoading, data: tyresData } = useQuery<unknown, Error, TyreDetailResponse>(
        [WheelQueryKeys.getTyresDetail, mapActionData.clickedVehicle],
        () => mapActionData?.clickedVehicle && Wheel.getTyresDetail(mapActionData.clickedVehicle),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            retry: false,
            staleTime: cacheTimeToMilliseconds(15, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(15, 'minutes'),
            enabled: props.isActive && !!mapActionData.clickedVehicle
        }
    );
    const getMaxAlertLevel = (alerts: SensorAlert[]): Level => {
        let level: Level = {
            pressure: 0,
            temperature: 0
        };

        for (let i = 0, length = alerts.length; i < length; i++) {
            const alert = alerts[i].notification;
            if (alert.type.includes('temperature')) {
                level.temperature = alert.level;
            }
            if (alert.type.includes('pressure')) {
                level.pressure = Math.max(level.pressure, alert.level);
            }
        }

        return level;
    };

    const getHasInternalSensor = useCallback(() => {
        if (tyresData) {
            for (let i = 0, length = tyresData.length; i < length; i++) {
                for (let j = 0, sensorLength = tyresData[i].attachedSensors.length; j < sensorLength; j++) {
                    if (tyresData[i].attachedSensors[j].sensor.sensorType?.id === 1) {
                        return true;
                    }
                }
            }
        }
        return false;
    }, [tyresData]);

    return (
        <view.VehicleMapPopupTyresContent
            isLoading={tyresIsLoading}
            data={tyresData}
            getMaxAlertLevel={getMaxAlertLevel}
            getHasInternalSensor={getHasInternalSensor}
            data-testid={'VehicleMapPopupTyres-testid'}
        />
    );
};

export default Wrapper(VehicleMapPopupTyres);
