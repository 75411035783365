export type TyreNotificationModel = {};

//will be removed
export type TyreNotificationPreview = {
    notificationTyrePreview: TyreNotificationPreviewModel[];
};

export type TyreNotificationTempPreview = {
    notificationTyreTempPreview: TyreNotificationTempPreviewModel[];
};

//will be removed
export type TyreNotificationPreviewModel = {
    notificationId: number;
    vehicleName: string;
    type: string;
    type2: string;
    level: number;
    measuredAt: string;
    temperature: number;
    hotPressure: number;
    coldPressure: number;
    pressureLimitFrom: number;
    pressureLimitTo: number;
    temperatureLimitFrom: number | null;
    limitFrom?: number | null;
    limitTo?: number | null;
    temperatureLimitTo: number | null;
    tyreCustomPosition: number;
    customPosition?: number;
    sensorSerialNumber: string;
    sensorSerialNumberHex: string;
    criticalTemperature: number | null;
    criticalTemperatureAt?: string | null;
};

export type TyreNotificationTempPreviewModel = {
    vehicleName: string;
    measuredAt: string;
    level: number;
    position: number;
    temperatureFrom: number;
};

export enum TyreNotificationQueryKeys {
    getTyreNotificationsReportsCsv = 'tyreNotification-GetTempPressNotificationsCSV'
}
