import React from 'react';
import { useRecoilValue } from 'recoil';
import { ContainerIconStatus, UITableCell } from './TempAndPressListBody.style';
import { TempAndPressListBodyViewProps } from './TempAndPressListBody.type';
import { useTranslation } from 'react-i18next';
import { TableRow, Tooltip } from '@mui/material';
import { ColumnStickyBody } from '../TemperaturePressureList.style';
import useConverter from '../../../CustomHooks/Converter/Converter';
import UiLink from '../../../Ui/Components/UiLink/UiLink';
import { Theme } from '../../../../states/global/Theme';
import { applyStyleByMode } from 'helpers';
import { DARK_STEEL_GREY, WHITE } from '../../../Ui/colors';
import { faThermometerThreeQuarters, faTruckClock } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { faSlash as faSlashRegular } from '@fortawesome/pro-regular-svg-icons';
import { CustomerSettings } from 'states/global/CustomerSettings';

const chartIcon: JSX.Element = <UiIcon icon={['fas', 'chart-area']} size='lg' />;

export const TempAndPressListBodyContent: React.FC<TempAndPressListBodyViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromUTCToTimezone, convertType, fromServerToUserUnit } = useConverter();
    const ThemeMode = useRecoilValue(Theme);
    const customerSettings = useRecoilValue(CustomerSettings);

    const outOfServiceIcon: JSX.Element = (
        <UiIcon
            icon={faTruckClock}
            size='lg'
            color={applyStyleByMode({
                styleOld: 'rgb(200,200,200)',
                theme: ThemeMode?.mode,
                dark: WHITE,
                light: '#0000008a'
            })}
        />
    );

    const maintenanceIcon: JSX.Element = (
        <UiIcon
            icon={['fas', 'wrench']}
            size='lg'
            color={applyStyleByMode({ styleOld: '#000', theme: ThemeMode?.mode, light: '#0000008a', dark: WHITE })}
        />
    );

    const getOutOfServiceIcon = (outOfService = 0): JSX.Element | null => {
        const isOutOfService = outOfService === 1;
        return isOutOfService ? outOfServiceIcon : null;
    };

    const getmaintenanceIcon = (maintained = 0): JSX.Element | null => {
        const isMaintained = maintained === 1;
        return isMaintained ? maintenanceIcon : null;
    };

    const vehicleCell = (vehicle, rowNumber: number, title: string): JSX.Element => {
        return (
            <ColumnStickyBody
                $theme={ThemeMode}
                key='vehicle'
                $lef={0}
                $notBorderRight={true}
                $rowNumber={rowNumber}
                $minWidth='155'
                align='left'
            >
                <UiLink
                    color={applyStyleByMode({ theme: ThemeMode?.mode, light: DARK_STEEL_GREY, dark: '#40FFA6' })}
                    $fontWeight='bold'
                    content={vehicle.name}
                    title={`${title} Id: ${vehicle?.deviceId ?? '---'}`}
                    url={`/vehicle-status?vehicle=${vehicle.id}`}
                    testId={`LinkToVehicleDetails-Buton-${rowNumber}`}
                />
            </ColumnStickyBody>
        );
    };

    const statsLink = (vehicleId = 0): JSX.Element => {
        return (
            <UiLink
                color={applyStyleByMode({ theme: ThemeMode?.mode, light: DARK_STEEL_GREY, dark: '#40FFA6' })}
                content={chartIcon}
                title={translate('t.show_statistics')}
                url={`/vehicle/${vehicleId}/statistics`}
                testId='LinkToVehicleStatistics-Buton'
            />
        );
    };

    return (
        <TableRow key={props.vehicle.id}>
            {vehicleCell(props.vehicle, props.rowNumber, translate('t.hub'))}
            <ColumnStickyBody $theme={ThemeMode} align='center' $rowNumber={props.rowNumber} $lef={155} $minWidth={49}>
                <ContainerIconStatus $height={47}>
                    {statsLink(props.vehicle.id)}
                    {getOutOfServiceIcon(props.vehicle.outOfService) && (
                        <Tooltip title={translate('t.vehicle_settings_service_off_success_title')} disableInteractive>
                            <>{getOutOfServiceIcon(props.vehicle.outOfService)}</>
                        </Tooltip>
                    )}
                    {getmaintenanceIcon(props.vehicle.maintenance) && (
                        <Tooltip title={translate('t.vehicle_in_maintenance')} disableInteractive>
                            <>{getmaintenanceIcon(props.vehicle.maintenance)}</>
                        </Tooltip>
                    )}
                    {customerSettings?.temperature_prediction &&
                        props.vehicle.predictionTemperature &&
                        !props.vehicle.genericTemperatureAlert && (
                            <Tooltip title={'Predictive Temperature'} disableInteractive>
                                <>
                                    <span className='fa-layers fa-fw'>
                                        <UiIcon
                                            icon={faSlashRegular}
                                            fontSize='16px'
                                            color={applyStyleByMode({
                                                styleOld: '#FF1616',
                                                theme: ThemeMode?.mode,
                                                dark: WHITE,
                                                light: '#787878'
                                            })}
                                        />
                                        <UiIcon
                                            icon={faThermometerThreeQuarters}
                                            fixedWidth
                                            fontSize='16px'
                                            color={applyStyleByMode({
                                                styleOld: '#FF1616',
                                                theme: ThemeMode?.mode,
                                                dark: WHITE,
                                                light: '#787878'
                                            })}
                                        />
                                    </span>
                                </>
                            </Tooltip>
                        )}
                </ContainerIconStatus>
            </ColumnStickyBody>
            {props.wheelCells(
                props.vehicle.wheels.filter((wheel) => +wheel.customPosition <= props.maxPosition),
                props.maxPosition,
                !props.vehicle.internalSensorCount
            )}
            <UITableCell align='center'>
                {props.vehicle.lastTransmission && fromUTCToTimezone(props.vehicle.lastTransmission, false)}
            </UITableCell>
            {props.tempDifferenceCell(props.vehicle.wheels)}
            <UITableCell $align='right'>
                {` ${fromServerToUserUnit({
                    type: convertType.altitude,
                    value: props.vehicle.distanceTraveled ?? 0,
                    displayUnits: false,
                    fixed: 1,
                    toType: convertType.distance
                })}`}
            </UITableCell>
        </TableRow>
    );
};
