import React, { useEffect, useState } from 'react';
import { TrackingSummaryProps } from './TrackingSummary.type';
import { TrackingSummaryContent } from './TrackingSummary.view';
import { DateTimePicker, SelectedVehicle } from 'states/global/Statistics';
import { useRecoilValue } from 'recoil';
import VehicleApi from 'api/Vehicle';
import { TrackingStatistics, VehicleQueryKeys } from 'models/Vehicle.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { useQuery } from '@tanstack/react-query';
import { Wrapper } from 'helpers/wrapper';

const TrackingSummary: React.FC<TrackingSummaryProps> = (props): JSX.Element => {
    const [data, setData] = useState<TrackingStatistics>({
        acceleration: 0,
        avgDaySpeed: 0,
        avgSpeed: 0,
        operationTime: {
            batteryTime: 0,
            idlingTime: 0,
            operatingTime: 0
        },
        breaking: 0,
        distance: 0,
        duration: 0,
        durationTime: 0,
        maxSpeed: 0,
        inMotion: false
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const selectedVehicle = useRecoilValue(SelectedVehicle);
    const statisticsDateTime = useRecoilValue(DateTimePicker);
    const Vehicle = new VehicleApi();
    const { fromTimezoneToUTC } = useConverter();

    const { refetch: loadDataRefetch } = useQuery(
        [
            VehicleQueryKeys.getTrackingStatistics,
            selectedVehicle.id,
            statisticsDateTime.current.dateFrom,
            statisticsDateTime.current.dateTo
        ],
        () =>
            Vehicle.getTrackingStatistics(
                selectedVehicle.id,
                fromTimezoneToUTC(statisticsDateTime.current.dateFrom),
                fromTimezoneToUTC(statisticsDateTime.current.dateTo)
            ),
        {
            enabled: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: (dataXHR) => {
                setData(dataXHR);
                setIsLoading(false);
            }
        }
    );

    const loadData = (): void => {
        setIsLoading(true);
        loadDataRefetch();
    };

    useEffect(() => {
        loadData();
    }, [statisticsDateTime, selectedVehicle]);

    return (
        <TrackingSummaryContent
            data-testid='TrackingSummary-testid'
            dateDifference={props.dateDifference}
            data={data}
            isLoading={isLoading}
        />
    );
};

export default Wrapper(TrackingSummary);
