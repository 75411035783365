import React from 'react';
import { Column } from '../TyreManager.style';
import { TyreModel, TyreQueryKeys } from 'models/Tyre.type';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { TyreStateAtom } from '../TyreManager.atom';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete';
import TyreAPI from 'api/Tyre';
import { TyreStateDispatcher } from '../TyreManager.reducer';
import { MenuItem } from '@mui/material';
import useModal from 'components/CustomHooks/ModalHook';
import AddTyre from '../TyreActionPopup/AddTyre';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';

const tyreAPI = new TyreAPI();

const TyreLazyLoading = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [tyreState, setTyreState] = useRecoilState(TyreStateAtom);
    const dispatcher = TyreStateDispatcher(tyreState, setTyreState);
    const { setModalState } = useModal(1);

    return (
        <Column $first>
            <LazyloadingAutocomplete
                id='tyre-serial-numbers'
                queryId={TyreQueryKeys.getTable}
                query={tyreAPI.getTable}
                optionKey='tyreSerialNumber'
                optionValue='id'
                preselectedValue={{
                    value: tyreState.newTyre.id,
                    label: tyreState.newTyre.tyreSerialNumber || '',
                    origItem: tyreState.newTyre
                }}
                label={translate('t.tyres')}
                width={200}
                size='small'
                onValueChange={(value?: number, label?: string, origItem?: TyreModel) => {
                    dispatcher({
                        id: origItem?.id || tyreState.originalTyre.id,
                        vehicleName: origItem?.vehicleName || '',
                        customPosition: origItem?.customPosition || tyreState.originalTyre.customPosition,
                        tyreSerialNumber: origItem?.tyreSerialNumber || tyreState.originalTyre.tyreSerialNumber
                    });
                }}
                groupBy={{
                    key: 'vehicleName',
                    defaultGroup: 'No Vehicle'
                }}
                querySortBy={{
                    id: 'vehicleName',
                    desc: false
                }}
                placeholder={'S/N'}
                addTopElement={(value: string) => {
                    return value ? (
                        <MenuItem
                            title={translate('t.add_tyre')}
                            style={{ backgroundColor: '#ececec' }}
                            disabled={!value || value.includes('/')}
                            onClick={() => {
                                setModalState({
                                    isOpen: true,
                                    content: <AddTyre value={value} />,
                                    width: 550,
                                    height: 175,
                                    leftTitle: translate('t.add_tyre')
                                });
                            }}
                        >
                            <UiIcon icon={['fas', 'circle-plus']} style={{ paddingRight: '5px' }} color='green' />{' '}
                            {value}
                        </MenuItem>
                    ) : (
                        <></>
                    );
                }}
            />
        </Column>
    );
};

export default TyreLazyLoading;
