import React, { useEffect, useState } from 'react';
import { SaveRoutePopupProps } from './SaveRoutePopup.type';
import { SaveRoutePopupContent } from './SaveRoutePopup.view';
import { Success } from '../Popup/Popup';
import { useRecoilValue } from 'recoil';
import { MapActionData } from 'states/global/Map';
import { JourneyData } from 'models/Vehicle.type';
import RouteApi from 'api/Route';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { DefaultRequestPropsType, RequestPropsWithData } from 'helpers/api/type';
import { Wrapper } from 'helpers/wrapper';

const SaveRoutePopup: React.FC<SaveRoutePopupProps> = (): JSX.Element => {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [forceClose, setForceClose] = useState<boolean>(false);
    const mapActionData = useRecoilValue(MapActionData);
    const Route = new RouteApi();
    const { t: translate } = useTranslation();

    const { mutate: sendDataMutation } = useMutation<DefaultRequestPropsType, unknown, RequestPropsWithData<unknown>>(
        Route.post,
        {
            onSuccess: () => Success({ text: `${translate('t.route')} ${translate('p.has_been_added')}` }),
            onSettled: () => {
                setIsProcessing(false);
                setForceClose(true);
            }
        }
    );

    const parseJourney = (journey: JourneyData[]): { ts: number; lat: number; lon: number }[] => {
        return journey.map((jour) => {
            return { ts: jour.timekey ?? NaN, lon: jour.longitude, lat: jour.latitude };
        });
    };

    const sendData = async (formData: { routeName?: string }): Promise<void> => {
        let data: { name: string; route: { ts: number; lat: number; lon: number }[] } = {
            name: formData.routeName ?? '',
            route: []
        };

        data.route = mapActionData.journey ? parseJourney(mapActionData.journey) : [];

        setIsProcessing(true);
        sendDataMutation({ data: data });
    };

    useEffect(() => {
        setForceClose(false);
    }, [forceClose]);

    return (
        <SaveRoutePopupContent
            forceClose={forceClose}
            isProcessing={isProcessing}
            sendData={sendData}
            data-testid={'SaveRoutePopup-testid'}
        />
    );
};

export default Wrapper(SaveRoutePopup);
