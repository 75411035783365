import PortalApi from 'helpers/api/PortalApiClient';
import { TeamShiftNameModel, TeamShiftNameModelPaginator } from '../models/TeamShiftName.type';
import { getParams } from 'helpers';

export default class TeamShiftName extends PortalApi<TeamShiftNameModel> {
    route = 'team-shift-name';

    getAll = async (props): Promise<TeamShiftNameModelPaginator> => {
        return await this.getTable<TeamShiftNameModel>({
            criteria: getParams(props)
        });
    };

    addTeamShiftName = async (data: { name: string }): Promise<TeamShiftNameModel> => {
        return await this.post({ data: data });
    };
}
