type SensorFaultyReportActualSensorStatus = {
    id: number;
    name: string;
};

export enum SensorFaultyReportActualSensorStatusQueryKeys {
    get = 'sensorFaultyReportActualSensorStatus-get'
}

export default SensorFaultyReportActualSensorStatus;
