import React from 'react';
import { UserStatus, UserStatusEnum, UserTableViewProps } from './UserPermissionsTable.type';
import { AutocompleteWithMargin, UserTableViewContent } from './UserPermissinsTable.style';
import { useTranslation } from 'react-i18next';
import UiTable from '../../Ui/UiTable/UiTable';
import ApiUser from 'api/User';
import MinesPermissions from '../MinesPermissions';
import { RegionModelExtended } from 'models/Region.type';
import { IconButton, TextField } from '@mui/material';
import { CustomerCodebook } from 'models/Customer.type';
import { DropDownContainer } from '../RolesDropDown/RolesDropDown.style';
import RolesDropDown from '../RolesDropDown/RolesDropDown';
import Menu from '../../Ui/Components/UiMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import useTableFilter from 'components/CustomHooks/TableFilterState/TableFilterState';
import { humanValue } from 'components/Customer/CustomeSettingInputs/CustomeSettingInputs';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

const userAPI = new ApiUser();

export const UserPermissionsTableContent: React.FC<UserTableViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const columns = [
        {
            Header: translate('t.username'),
            accessor: 'username',
            width: 150,
            Cell: (props) => <>{props.value}</>
        },
        {
            Header: translate('t.first_name'),
            accessor: 'firstname',
            width: 125,
            Cell: (props) => <>{props.value}</>
        },
        {
            Header: translate('t.last_name'),
            accessor: 'lastname',
            width: 125,
            Cell: (props) => <>{props.value}</>
        },
        {
            Header: translate('t.email'),
            accessor: 'email',
            disableSortBy: true,
            Cell: (props) => <>{props.value}</>
        },
        {
            Header: translate('t.role'),
            accessor: 'role',
            width: 100,
            disableSortBy: true,
            Cell: (props) => <>{props.value.name.replaceAll(/ROLE_|_/g, ' ')}</>,
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });

                return (
                    <DropDownContainer>
                        <RolesDropDown
                            value={cellValue?.value}
                            defaultValue={parseInt(column?.filterValue?.value)}
                            getValue={(id, humanValue) => {
                                const value = {
                                    name: translate('t.role'),
                                    humanValue: humanValue,
                                    value: `${id}`
                                };
                                if (id == null || humanValue == null) setFilterValue(undefined);
                                else setFilterValue(value);
                            }}
                            error={undefined}
                            xs={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sm={12}
                        />
                    </DropDownContainer>
                );
            }
        },
        {
            Header: translate('t.status'),
            accessor: 'enabled',
            width: 100,
            disableSortBy: true,
            Cell: (props) => <>{props.value ? translate('t.enable') : translate('t.disable')}</>,
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });

                return (
                    <AutocompleteWithMargin
                        options={[
                            { label: 'all', value: UserStatusEnum.ALL },
                            { label: 'enable', value: UserStatusEnum.ENABLE },
                            { label: 'disable', value: UserStatusEnum.DISABLE }
                        ]}
                        getOptionLabel={(option) => translate(`t.${(option as UserStatus).label}`) || ''}
                        size='small'
                        onChange={(event, newValue) => {
                            const value = !newValue
                                ? undefined
                                : {
                                      name: translate('t.status'),
                                      humanValue: translate(`t.${(newValue as UserStatus).label}`),
                                      value: `${(newValue as UserStatus).value}`
                                  };
                            setFilterValue(value);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label={translate('t.status')} variant='outlined' />
                        )}
                    />
                );
            }
        },
        {
            Header: translate('t.action'),
            accessor: 'id',
            width: 70,
            disableFilters: true,
            disableSortBy: true,
            Cell: (propsCell) => (
                <Menu
                    testid={`Menu-Permissions-${propsCell.row.original.id}`}
                    items={[
                        {
                            title: 'Edit Permissions',

                            value: propsCell.row.original.id,
                            action: () => {
                                props.editPermissions(propsCell.row.original);
                            }
                        },
                        {
                            title: 'Edit User',
                            value: propsCell.row.original.id,
                            isLink: true,
                            url: `/permissions/user/${propsCell.row.original.id}`
                        }
                    ]}
                    rootEl={
                        <UiIconButton
                            aria-controls='simple-menu'
                            aria-haspopup='true'
                            testid={`Action-Button-${propsCell.row.original.id}`}
                        >
                            <SettingsIcon />
                        </UiIconButton>
                    }
                />
            )
        },
        {
            Header: '',
            accessor: 'customer',
            Cell: (props) => <>{props?.value?.id || ''}</>,
            Filter: ({ setFilter, column, ...filterProps }) => {
                const { setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });

                return (
                    <AutocompleteWithMargin
                        options={props.customers || []}
                        getOptionLabel={(customer) => (customer as CustomerCodebook).name || ''}
                        size='small'
                        onChange={(event, newValue) => {
                            const value = !newValue
                                ? undefined
                                : {
                                      name: translate('t.customer'),
                                      humanValue: (newValue as CustomerCodebook).name,
                                      value: `${(newValue as CustomerCodebook).id}`
                                  };
                            setFilterValue(value);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label={translate('t.customers')} variant='outlined' />
                        )}
                    />
                );
            }
        },
        {
            Header: '',
            accessor: 'customerRegionId',
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });

                return (
                    <AutocompleteWithMargin
                        options={props.region || []}
                        getOptionLabel={(region) =>
                            translate(`t.${(region as RegionModelExtended).translationString}`) || ''
                        }
                        size='small'
                        onChange={(event, newValue) => {
                            const value = !newValue
                                ? undefined
                                : {
                                      name: translate('t.region'),
                                      humanValue: translate(`t.${(newValue as RegionModelExtended).translationString}`),
                                      value: `${(newValue as RegionModelExtended).id}`
                                  };
                            setFilterValue(value);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label={translate('t.region')} variant='outlined' />
                        )}
                    />
                );
            }
        }
    ];

    return (
        <UserTableViewContent data-testid='UserTableContent'>
            <UiTable
                columns={columns}
                fetchFn={userAPI.getCustomerAccess}
                queryKey='PermissionsGetUsers'
                hiddenColumns={['customer', 'customerRegionId']}
                defaultFilter={{
                    id: 'enabled',
                    value: { name: translate('t.status'), value: UserStatusEnum.ALL }
                }}
            />
            {props.showPermissionModal && (
                <MinesPermissions
                    {...props}
                    setShowPermissionModal={props.setShowPermissionModal}
                    queryKey='PermissionsGetUsers'
                />
            )}
        </UserTableViewContent>
    );
};
