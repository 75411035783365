import LoadingButton from '@mui/lab/LoadingButton';
import styled from 'styled-components';

export const UiCustomButton = styled(LoadingButton)<{ skin?: string; textcolor?: string }>`
    &.MuiButton-root {
        margin: 0px 5px;
        ${(props) => props.textcolor && `color: ${props.textcolor} !important;`}
        :not(.Mui-disabled) {
            box-shadow: none;
        }
    }
`;
