import React from 'react';
import UiWidget from 'components/Ui/Components/UiWidget';
import DataExport from 'components/Mixs/DataExport';
import { TopBarAtom } from 'components/Mixs/DataExport/TopBar/TopBar.atom';
import { useMutation } from '@tanstack/react-query';
import { Success } from 'components/Popup';
import { useRecoilValue } from 'recoil';
import WheelPrediction from 'api/WheelPrediction';
import { useTranslation } from 'react-i18next';
import WheelPositionSelect from 'components/Mixs/DataExport/WheelPositionSelect';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { applyStyleByMode } from 'helpers';
import useConverter from 'components/CustomHooks/Converter/Converter';

const wheelPredictionAPI = new WheelPrediction();
const TemperaturePredictionExport = () => {
    const { t: translate } = useTranslation();
    const topBarAtom = useRecoilValue(TopBarAtom);
    const ThemeMode = useRecoilValue(Theme);
    const { userFormatUTC } = useConverter();

    const mutation = useMutation<unknown, unknown, void>(
        () =>
            wheelPredictionAPI.getTemperaturePredictionCsv(
                topBarAtom.vehicle.id,
                topBarAtom.dateFrom.toUTC().toISO(),
                topBarAtom.dateTo.toUTC().toISO(),
                topBarAtom.wheelPosition
            ),
        {
            onSuccess: () => {
                Success({ text: translate('p.report_success_message') });
            }
        }
    );

    const columns = [
        {
            header: 'detectedAt',
            accessor: 'detectedAt',
            parseData: (data) => userFormatUTC({ date: data, format: 'dateTime', displaySeconds: true })
        },
        {
            header: 'processedAt',
            accessor: 'processedAt',
            parseData: (data) => userFormatUTC({ date: data, format: 'dateTime', displaySeconds: true })
        },
        {
            header: 'vehicleId',
            accessor: 'vehicleId'
        },
        {
            header: 'position',
            accessor: 'position'
        },
        {
            header: 'realChamberTemperatureMovingAverage',
            accessor: 'realChamberTemperatureMovingAverage'
        },
        {
            header: 'ambientTemperature',
            accessor: 'ambientTemperature'
        },
        {
            header: 'predictedRemainMinutes',
            accessor: 'predictedRemainMinutes'
        },
        {
            header: 'tgPerMin',
            accessor: 'tgPerMin'
        },
        {
            header: 'criticalTemperature',
            accessor: 'criticalTemperature'
        },
        {
            header: 'timeAfter1Hour',
            accessor: `timeAfter1Hour`,
            parseData: (data) => userFormatUTC({ date: data, format: 'dateTime', displaySeconds: true })
        },
        {
            header: 'predictedTemperature1Hour',
            accessor: 'predictedTemperature1Hour'
        },
        {
            header: 'realTemperature1Hour',
            accessor: 'realTemperature1Hour'
        },
        {
            header: 'predictedAmbientTemperature1Hour',
            accessor: 'predictedAmbientTemperature1Hour'
        },
        {
            header: 'realAmbient1Hour',
            accessor: 'realAmbient1Hour'
        },
        {
            header: 'timeAfter2Hour',
            accessor: 'timeAfter2Hour',
            parseData: (data) => userFormatUTC({ date: data, format: 'dateTime', displaySeconds: true })
        },
        {
            header: 'predictedTemperature2Hour',
            accessor: 'predictedTemperature2Hour'
        },
        {
            header: 'realTemperature2Hour',
            accessor: 'realTemperature2Hour'
        },
        {
            header: 'predictedAmbientTemperature2Hour',
            accessor: 'predictedAmbientTemperature2Hour'
        },
        {
            header: 'realAmbient2Hour',
            accessor: 'realAmbient2Hour'
        },
        {
            header: 'timeAfter3Hour',
            accessor: 'timeAfter3Hour',
            parseData: (data) => userFormatUTC({ date: data, format: 'dateTime', displaySeconds: true })
        },
        {
            header: 'predictedTemperature3Hour',
            accessor: 'predictedTemperature3Hour'
        },
        {
            header: 'realTemperature3Hour',
            accessor: 'realTemperature3Hour'
        },
        {
            header: 'predictedAmbientTemperature3Hour',
            accessor: 'predictedAmbientTemperature3Hour'
        },
        {
            header: 'realAmbient3Hour',
            accessor: 'realAmbient3Hour'
        },
        {
            header: 'counterAction',
            accessor: 'counterAction'
        },
        {
            header: 'slopeMoveAverageValue',
            accessor: 'slopeMoveAverageValue'
        },
        {
            header: 'customerSchema',
            accessor: 'customerSchema'
        },
        {
            header: 'customerName',
            accessor: 'customerName'
        }
    ];

    return (
        <UiWidget
            title={translate('t.temperature_prediction')}
            id='temperature-prediction-widget'
            avatar={
                <UiIcon
                    icon={['fas', 'temperature-full']}
                    size='lg'
                    fixedWidth
                    color={applyStyleByMode({
                        theme: ThemeMode?.mode,
                        light: '#0000008a',
                        dark: '#FFF'
                    })}
                />
            }
            content={
                <DataExport
                    columns={columns}
                    fetchFn={wheelPredictionAPI.getTempPressWheelReportPreview}
                    downloadFn={mutation.mutate}
                    queryKey='TemperaturePredictionReports'
                    additionalElement={<WheelPositionSelect />}
                    additionalFetchProps={{
                        vehicleId: topBarAtom.vehicle.id,
                        measuredFrom: topBarAtom.dateFrom.toUTC().toISO(),
                        measuredTo: topBarAtom.dateTo.toUTC().toISO(),
                        wheelPosition: topBarAtom.wheelPosition
                    }}
                />
            }
        />
    );
};

export default TemperaturePredictionExport;
