import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { AddUserFormViewContent, ContainerReuiredPassword } from './AddUserForm.style';
import {
    AvatarImg,
    ContainerBtnImage,
    ContainerButton,
    ContainerImage,
    CustomTextField
} from '../AccountSettingsForm/AccountSettingsForm.style';
import { AddUserFormViewProps, InicialvaluesForm } from './AddUserForm.type';
import { useTranslation } from 'react-i18next';
import { Grid, TextField, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import { Clear, PhotoCamera } from '@mui/icons-material';
import { ContainerBodyTab, TitleContentTab } from '../ProfileTabManager/ProfileTabManager.style';
import RolesDropDown from '../RolesDropDown/RolesDropDown';
import UiPhoneInputNumber from 'components/Ui/Components/UiPhoneInputNumber/UiPhoneInputNumber';
import Checkbox from 'components/Ui/Components/UiCheckbox';
import DropDown from 'components/Ui/Components/UiDropDown';
import Button from 'components/Ui/Components/UiButton/UiButton';
import { PASSWORD_REGEX } from 'variables';
import PasswordHelperText from 'components/Mixs/HelperText/PasswordHelperText';
import HelperText from 'components/Mixs/HelperText';
import { getImgSource } from 'helpers/image';
import { getTimezoneOffsetFormatted } from 'helpers';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

export const AddUserFormContent: React.FC<AddUserFormViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [isValid, setIsValid] = React.useState<string | null>(null);

    const schemaValidateForm = Yup.object({
        username: Yup.string()
            .trim()
            .required(translate('p.this_field_is_required'))
            .min(3, 'Name is Too Short.')
            .trim()
            .test('exists-username', translate('t.username_already_exists'), async function () {
                return (await props.existsUserName(this.parent.username)) as boolean;
            }),
        email: Yup.string().trim().required(translate('p.this_field_is_required')).email(),
        phoneNumber: Yup.string(),
        role: Yup.string().required(translate('p.this_field_is_required')),
        firstname: Yup.string().trim().required(translate('p.this_field_is_required')),
        lastname: Yup.string().trim().required(translate('p.this_field_is_required')),
        password: Yup.string()
            .required()
            .min(6)
            .test('passwords-match', translate('p.current_password_cannot_be_equal_to_new_password'), function (value) {
                return this.parent.current_password !== value;
            })
            .test(
                'username-password-match',
                translate('p.current_username_cannot_be_equal_to_new_password'),
                function (value) {
                    return !value?.toLocaleLowerCase().includes(this.parent.username.toLocaleLowerCase());
                }
            )
            .matches(PASSWORD_REGEX),
        confirmPassword: Yup.string()
            .required(translate('p.this_field_is_required'))
            .oneOf([Yup.ref('password'), null], translate('t.passwords_must_match')),
        timeZone: Yup.string().required(translate('p.this_field_is_required')),
        mapType: Yup.string().required(translate('p.this_field_is_required'))
    });

    return (
        <ContainerBodyTab>
            <TitleContentTab>{translate('t.add_user')}</TitleContentTab>
            <AddUserFormViewContent>
                <Formik
                    initialValues={props.inicialvaluesForm}
                    onSubmit={(values: InicialvaluesForm) => {
                        props.handleSubmit(values);
                    }}
                    enableReinitialize
                    validationSchema={schemaValidateForm}
                >
                    {(propsFormik: FormikProps<InicialvaluesForm>) => {
                        const { touched, errors, setFieldValue, handleChange, values } = propsFormik;

                        return (
                            <Form autoComplete='off' id='user-add-form-test-chris'>
                                <Grid container justify-content='flex-start' direction='row'>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <ContainerImage>
                                            <ContainerBtnImage previewImg={props.previewImg ? 'red' : '#2d863d'}>
                                                {props.previewImg ? (
                                                    <Tooltip title='Remove Image'>
                                                        <UiIconButton
                                                            testid={`AddUserFormContent-IconButton-PreviewImg-Image`}
                                                            aria-label='upload Avatar'
                                                            component='label'
                                                            onClick={() => {
                                                                props.setPreviewImg('');
                                                            }}
                                                            size='large'
                                                        >
                                                            <Clear color='primary' />
                                                        </UiIconButton>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title='Select Image'>
                                                        <UiIconButton
                                                            testid={`AddUserFormContent-IconButton-Image`}
                                                            aria-label='upload Avatar'
                                                            component='label'
                                                            size='large'
                                                        >
                                                            <>
                                                                <PhotoCamera />
                                                                <input
                                                                    type='file'
                                                                    hidden
                                                                    accept='image/*'
                                                                    onChange={props.handleCapture}
                                                                />
                                                            </>
                                                        </UiIconButton>
                                                    </Tooltip>
                                                )}
                                            </ContainerBtnImage>
                                            <AvatarImg
                                                data-testid={`AddUserFormContent-AvatarImg`}
                                                src={
                                                    props.selectedFile
                                                        ? `${props.selectedFile}`
                                                        : getImgSource('notAvatar')
                                                }
                                                alt=' Avatar'
                                            />
                                        </ContainerImage>
                                    </Grid>

                                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                                        <CustomTextField
                                            data-testid={`AddUserFormContent-CustomTextField-username`}
                                            id='field1'
                                            label={translate('t.username')}
                                            value={values.username}
                                            variant='outlined'
                                            size='small'
                                            name='username'
                                            error={!!errors.username}
                                            type='text'
                                            fullWidth
                                            onChange={handleChange}
                                            inputProps={{
                                                step: 0.1,
                                                min: 0,
                                                form: {
                                                    autocomplete: 'off'
                                                }
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={errors.username}
                                        />
                                    </Grid>

                                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                                        <CustomTextField
                                            data-testid={`AddUserFormContent-CustomTextField-email`}
                                            id='field2'
                                            label={translate('t.email')}
                                            value={values.email}
                                            variant='outlined'
                                            size='small'
                                            name='email'
                                            error={!!errors.email && !!touched.email}
                                            type='mail'
                                            fullWidth
                                            onChange={handleChange}
                                            inputProps={{
                                                step: 0.1,
                                                min: 0,
                                                form: {
                                                    autocomplete: 'off'
                                                }
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={errors.email}
                                        />
                                    </Grid>

                                    <RolesDropDown
                                        data-testid={`AddUserFormContent-RolesDropDown-role`}
                                        value={values.role}
                                        error={errors.role}
                                        disabled={false}
                                        getValue={(e) => setFieldValue('role', e ?? null)}
                                        defaultValue={values.role ?? null}
                                        xl={4}
                                        lg={4}
                                        md={6}
                                        sm={6}
                                        xs={12}
                                        testId='Profile-AddUser-RolesDropDown'
                                    />

                                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                                        <UiPhoneInputNumber
                                            data-testid={`AddUserFormContent-UiPhoneInputNumber-phoneNumber`}
                                            value={values.phoneNumber}
                                            setFieldValue={setFieldValue}
                                            international={true}
                                            className='PhoneInputCustom'
                                            defaultCountry=''
                                            placeholder={translate('t.phone_number')}
                                            name='phoneNumber'
                                            setIsValid={setIsValid}
                                            isValid={isValid}
                                            testId='Profile-AddUser-UiPhoneInputNumber'
                                        />
                                    </Grid>

                                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                                        <CustomTextField
                                            data-testid={`AddUserFormContent-CustomTextField-firstname`}
                                            id='field3'
                                            label={translate('t.first_name')}
                                            value={values.firstname}
                                            variant='outlined'
                                            size='small'
                                            name='firstname'
                                            error={!!errors.firstname && !!touched.firstname}
                                            type='mail'
                                            fullWidth
                                            onChange={handleChange}
                                            inputProps={{
                                                step: 0.1,
                                                min: 0,
                                                form: {
                                                    autocomplete: 'off'
                                                }
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={errors.firstname}
                                        />
                                    </Grid>

                                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                                        <CustomTextField
                                            id='field4'
                                            data-testid={`AddUserFormContent-CustomTextField-lastname`}
                                            label={translate('t.last_name')}
                                            value={values.lastname}
                                            variant='outlined'
                                            size='small'
                                            name='lastname'
                                            error={!!errors.lastname && !!touched.lastname}
                                            type='mail'
                                            fullWidth
                                            onChange={handleChange}
                                            inputProps={{
                                                step: 0.1,
                                                min: 0,
                                                form: {
                                                    autocomplete: 'off'
                                                }
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={errors.lastname}
                                        />
                                    </Grid>

                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <CustomTextField
                                            id='field5'
                                            data-testid={`AddUserFormContent-CustomTextField-password`}
                                            label={translate('t.new_password')}
                                            value={values.password}
                                            variant='outlined'
                                            size='small'
                                            name='password'
                                            type='password'
                                            fullWidth
                                            onChange={handleChange}
                                            inputProps={{
                                                step: 0.1,
                                                min: 0,
                                                autoComplete: 'new-password',
                                                form: {
                                                    autoComplete: 'new-password'
                                                }
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            onBlur={propsFormik.handleBlur}
                                            error={!!errors.password && !!touched.password}
                                            helperText={
                                                <PasswordHelperText
                                                    data-testid={`AddUserFormContent-PasswordHelperText-password`}
                                                    passwordToValidate={values.password}
                                                    fallbackHelperText={errors.password}
                                                    error={!!errors.password && !!touched.password}
                                                />
                                            }
                                        />
                                    </Grid>

                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <CustomTextField
                                            data-testid={`AddUserFormContent-CustomTextField-confirmPassword`}
                                            id='field6'
                                            label={translate('t.new_password_repeat')}
                                            value={values.confirmPassword}
                                            variant='outlined'
                                            size='small'
                                            name='confirmPassword'
                                            type='password'
                                            fullWidth
                                            onChange={handleChange}
                                            inputProps={{
                                                step: 0.1,
                                                min: 0,
                                                form: {
                                                    autocomplete: 'off'
                                                }
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.confirmPassword && !!touched.confirmPassword}
                                            helperText={
                                                <HelperText
                                                    data-testid={`AddUserFormContent-HelperText-confirmPassword`}
                                                    defaultText={translate('t.required')}
                                                    text={errors.confirmPassword}
                                                    error={!!errors.confirmPassword && !!touched.confirmPassword}
                                                />
                                            }
                                        />
                                    </Grid>

                                    <Grid item lg={8} md={12} sm={12} xs={12}>
                                        {props.timeZoneLoading ? (
                                            <CircularProgress />
                                        ) : (
                                            <Autocomplete
                                                data-testid={`AddUserFormContent-Autocomplete-timeZone`}
                                                options={props.timeZoneData}
                                                getOptionLabel={(timeZone) => {
                                                    const offset = getTimezoneOffsetFormatted(timeZone.timezoneName);
                                                    return `${offset} ${
                                                        timeZone?.timezoneName && timeZone?.timezoneName
                                                    }`;
                                                }}
                                                onChange={(e, timeZone) => {
                                                    setFieldValue('timeZone', timeZone ? timeZone.id : null);
                                                }}
                                                defaultValue={
                                                    props.timeZoneData && !!props.timeZoneData.length
                                                        ? props.timeZoneData.find(
                                                              (timeZone) => timeZone.id === values.timeZone
                                                          )
                                                        : null
                                                }
                                                size='small'
                                                renderInput={(params) => (
                                                    <TextField
                                                        data-testid={`AddUserFormContent-TextField-timeZone`}
                                                        {...params}
                                                        label={translate('t.timezone')}
                                                        variant='outlined'
                                                        name='timeZone'
                                                        value={values.timeZone}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        helperText={
                                                            errors.timeZone && translate('p.this_field_is_required')
                                                        }
                                                        error={!!errors.timeZone}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Grid>

                                    <Grid item lg={4} md={6} sm={6} xs={12}>
                                        <DropDown
                                            data-testid='AddUserFormContent-DropDown-mapType'
                                            options={props.mapTypeList}
                                            inputName='mapType'
                                            label={translate('t.map_type')}
                                            value={values.mapType}
                                            error={errors.mapType}
                                            disabled={false}
                                            getValue={(e) => setFieldValue('mapType', e ?? null)}
                                            defaultValue={values.mapType ?? null}
                                        />
                                    </Grid>

                                    <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                                        <ContainerReuiredPassword>
                                            <Checkbox
                                                data-testid='AddUserFormContent-Checkbox-resetPassword'
                                                label={translate('p.requite_password_reset')}
                                                name='resetPassword'
                                                checked={values.resetPassword}
                                                labelPlacement='end'
                                                onChange={handleChange}
                                            />
                                        </ContainerReuiredPassword>
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                        <ContainerButton direction='right'>
                                            <Button
                                                data-testid='AddUserFormContent-Button-add'
                                                skin='success'
                                                type='submit'
                                                color='primary'
                                                testid='Save-Form-Button-AddUserForm'
                                                variant='contained'
                                                disabled={props.loading || !!isValid || !!errors.username}
                                            >
                                                {props.loading ? (
                                                    <CircularProgress size='1.75em' />
                                                ) : (
                                                    translate('t.add')
                                                )}
                                            </Button>
                                        </ContainerButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </AddUserFormViewContent>
        </ContainerBodyTab>
    );
};
