import { getImgSource } from 'helpers/image';
import { Geometry, Point } from 'ol/geom';
import { JourneyData, LastPositionV2 } from '../../../models/Vehicle.type';
import { DefaultSettings, MapActions, CustomLayers, VehicleTimeInfo } from '../Map.type';
import Feature from 'ol/Feature';
import * as OlProj from 'ol/proj';
import { Style, Fill, Stroke, Text, Icon } from 'ol/style';
import Vector from 'ol/source/Vector';
import { DrawVehicleAtTimeConfig, VehiclePositionConfig } from 'states/global/Map';
import { DateTime } from 'luxon';

let animationIntervals = {};

const createHeadingStyle = (heading: number, speed: number, measuredAt: Date): Style | null => {
    const hasSpeed: boolean = speed > 0;
    const lessThen3Hrs: boolean = DateTime.now().diff(measuredAt, 'hours').hours < 4;
    if (hasSpeed && lessThen3Hrs) {
        return new Style({
            image: new Icon({
                opacity: 1,
                src: getImgSource('heading'),
                rotation: heading * 0.0174532925
            })
        });
    }
    return null;
};

export const createVehicle = (
    vehicleSettings: LastPositionV2,
    defaultSettings: DefaultSettings,
    withHeading?: boolean
): Feature<Geometry> => {
    const featureId = `vehicle-${vehicleSettings.vehicle.id}`;
    let vehicleFeature = new Feature({
        geometry: new Point(
            OlProj.transform(
                [vehicleSettings.trackLog.longitude, vehicleSettings.trackLog.latitude],
                defaultSettings.epsg[0],
                defaultSettings.epsg[1]
            )
        ),
        id: featureId
    });
    vehicleFeature.setId(featureId);

    const icon = getImgSource(`${vehicleSettings.vehicle.icon}`);

    const vehicleStyle = new Style({
        text: new Text({
            text: vehicleSettings.vehicle.name,
            offsetX: 0,
            offsetY: 25,
            font: 'Normal 12px Arial',
            stroke: new Stroke({
                color: '#6f6f6f',
                width: 3
            }),
            fill: new Fill({
                color: '#fbfafa'
            })
        }),
        zIndex: 9999,
        image: icon
            ? new Icon({
                  opacity: 1,
                  src: icon,
                  scale: 0.6
              })
            : undefined
    });

    let styles: Style[] = [vehicleStyle];
    if (withHeading) {
        const iconHeadingStyle = createHeadingStyle(
            vehicleSettings.trackLog.heading,
            vehicleSettings.trackLog.speed,
            vehicleSettings.trackLog.measuredAt
        );
        iconHeadingStyle && styles.push(iconHeadingStyle);
    }

    vehicleFeature.setStyle(styles);
    return vehicleFeature;
};

export const drawVehicleAtTime = (
    params: DrawVehicleAtTimeConfig['params'],
    mapData,
    map: MapActions,
    granularity: number,
    storeMapActionData?: (value) => void
): void => {
    const { vehicleId, journey } = mapData;
    const { time: timekey } = params;

    let vehicleInfo: VehicleTimeInfo = findVehicleTimeInfo(timekey, journey);
    if (vehicleInfo.coordinates.length === 0) {
        vehicleInfo = findNextVehicleTimeInfo(timekey, journey, granularity);
    }

    if (vehicleInfo.coordinates.length && vehicleId) {
        map.moveFeatureTo(
            `vehicle-${vehicleId}`,
            CustomLayers.VEHICLE,
            new Point(
                OlProj.transform(vehicleInfo.coordinates, map.defaultSettings.epsg[0], map.defaultSettings.epsg[1])
            )
        );
    }

    if (storeMapActionData && mapData.vehiclesPosition) {
        storeMapActionData({
            vehiclesPosition: {
                ...mapData.vehiclesPosition,
                [`${vehicleId}`]: {
                    ...mapData.vehiclesPosition[`${vehicleId}`],
                    latitude: vehicleInfo.coordinates[1],
                    longitude: vehicleInfo.coordinates[0],
                    altitude: vehicleInfo.altitude,
                    speed: vehicleInfo.speed
                }
            }
        });
    }
};

const findVehicleTimeInfo = (timekey: number, journey: JourneyData[], onlyCoordinates?: boolean): VehicleTimeInfo => {
    for (let i = 0, length = journey?.length || 0; i < length; i++) {
        if (journey[i].timekey == timekey) {
            if (journey[i].altitude && journey[i].speed && !onlyCoordinates) {
                return {
                    coordinates: [journey[i].longitude, journey[i].latitude],
                    altitude: journey[i].altitude as number,
                    speed: journey[i].speed as number
                };
            }
            return {
                coordinates: [journey[i].longitude, journey[i].latitude]
            };
        }
    }
    return { coordinates: [] };
};

const findNextVehicleTimeInfo = (
    timekey: number,
    journey: JourneyData[],
    granularity: number,
    onlyCoordinates?: boolean
): VehicleTimeInfo => {
    for (let i = 1; i < granularity; i++) {
        const datePlus1Sec: number = DateTime.fromSeconds(timekey + 1).toUnixInteger();
        const data: VehicleTimeInfo = findVehicleTimeInfo(datePlus1Sec, journey, onlyCoordinates);
        if (data.coordinates.length !== 0) {
            return data;
        }
    }
    return { coordinates: [] };
};

const parseVehiclesAtPosition = (vehicles: LastPositionV2[]): {} => {
    let preparedVehicles = {};
    for (let i = 0, length = vehicles.length; i < length; i++) {
        preparedVehicles[vehicles[i].vehicle.id] = vehicles[i];
    }
    return preparedVehicles;
};

export const drawVehiclesPosition = (
    params: VehiclePositionConfig['params'],
    map: MapActions,
    storeMapActionData: (value) => void
): void => {
    const { vehicles, redrawAllVehicles } = params;
    storeMapActionData({ vehiclesPosition: parseVehiclesAtPosition(vehicles) });
    const updatedVehicleSource = new Vector();
    const vehicleLayer = map.findLayerByName(CustomLayers.VEHICLE);

    for (let i = 0, length = vehicles.length; i < length; i++) {
        const featureId = `vehicle-${vehicles[i].vehicle.id}`;
        let feature = map.findFeatureInLayer(featureId, CustomLayers.VEHICLE);
        if (!feature || redrawAllVehicles) {
            updatedVehicleSource.addFeature(createVehicle(vehicles[i], map.defaultSettings, true));
        } else {
            const newCoordinates = OlProj.transform(
                [vehicles[i].trackLog.longitude, vehicles[i].trackLog.latitude],
                map.defaultSettings.epsg[0],
                map.defaultSettings.epsg[1]
            );
            drawVehicleAnimation(featureId, newCoordinates, map, vehicles[i]);
        }
    }
    if (updatedVehicleSource.getFeatures().length) {
        vehicleLayer.length && vehicleLayer[0].setSource(updatedVehicleSource);
    }
};

const drawVehicleAnimation = (
    featureId: string,
    newCoordinates: number[],
    map: MapActions,
    vehicleSettings: LastPositionV2
): void => {
    if (animationIntervals[featureId]) {
        clearInterval(animationIntervals[featureId]);
    }

    const feature = map.findFeatureInLayer(featureId, CustomLayers.VEHICLE);
    let styles = feature?.getStyle() as Style[];
    const featureHasHeading: boolean = (styles.length ?? 0) > 1;
    if (featureHasHeading) {
        styles.pop();
        const iconHeadingStyle = createHeadingStyle(
            vehicleSettings.trackLog.heading,
            vehicleSettings.trackLog.speed,
            vehicleSettings.trackLog.measuredAt
        );
        iconHeadingStyle && styles.push(iconHeadingStyle);
        feature?.setStyle(styles);
    }
    const geom = feature?.getGeometry() as Point;
    const currentCoordinates = geom.getCoordinates();

    const isDiffPosition: boolean =
        currentCoordinates[0] !== newCoordinates[0] && currentCoordinates[1] !== newCoordinates[1];

    if (isDiffPosition) {
        const refreshInterval = 50;
        const steps = 1300;
        let step = 0;
        animationIntervals[featureId] = setInterval(() => {
            const directions = {
                directionLongitude: (newCoordinates[0] - currentCoordinates[0]) / steps,
                directionLatitude: (newCoordinates[1] - currentCoordinates[1]) / steps
            };

            const calculatedPoint = [
                currentCoordinates[0] + step * directions.directionLongitude,
                currentCoordinates[1] + step * directions.directionLatitude
            ];

            const isDiffCalculatedPosition: boolean =
                calculatedPoint[0] !== newCoordinates[0] && calculatedPoint[1] !== newCoordinates[1];
            if (isDiffCalculatedPosition && step < steps && !document.hidden) {
                map.moveFeatureTo(featureId, CustomLayers.VEHICLE, new Point(calculatedPoint));
            } else {
                clearInterval(animationIntervals[featureId]);
            }
            step++;
        }, refreshInterval);
    }
};

export const clearAllIntervals = () => {
    for (const key in animationIntervals) {
        clearInterval(animationIntervals[key]);
    }
};
