import React, { useEffect } from 'react';
import { ModalProps } from './Modal.type';
import { ModalContent } from './Modal.view';
import { useRecoilState, useResetRecoilState } from 'recoil';
import {
    ShowModal,
    ShowVehicleModal,
    ShowPermissionModal,
    ShowVehicleTypesAddModal,
    ShowFaultySensorReporterModal,
    ShowSaveRouteModal,
    ShowAddAreaModal,
    ShowResolveAlertsByVehicleModal,
    ShowConfirmFastLeakAlertsModal,
    ShowAnnouncementsActionsModal,
    ShowConfirmationPopupModal,
    ShowTeamShiftNameActionsModal,
    ShowTeamShiftTimeActionsModal
} from '../../states/global/Modal';
import { Wrapper } from 'helpers/wrapper';

const Modal: React.FC<ModalProps> = (props) => {
    const Modals = {
        ShowModal,
        ShowVehicleModal,
        ShowPermissionModal,
        ShowVehicleTypesAddModal,
        ShowFaultySensorReporterModal,
        ShowSaveRouteModal,
        ShowAddAreaModal,
        ShowResolveAlertsByVehicleModal,
        ShowConfirmFastLeakAlertsModal,
        ShowAnnouncementsActionsModal,
        ShowConfirmationPopupModal,
        ShowTeamShiftNameActionsModal,
        ShowTeamShiftTimeActionsModal
    };

    const [isOpen, setIsOpen] = useRecoilState<boolean>(Modals[props.type]);
    const resetOpenModal = useResetRecoilState(Modals[props.type]);

    const toggleModal = () => {
        setIsOpen(!isOpen);
        resetOpenModal();
    };

    useEffect(() => {
        if (props.forceClose) {
            setIsOpen(false);
        }
    }, [props.forceClose]);

    useEffect(() => {
        if (isOpen) {
            props.onOpen && props.onOpen();
        }
    }, [isOpen]);

    useEffect(() => {
        if (props.forceOpen) {
            setIsOpen(props.forceOpen);
        }
    }, [props.forceOpen]);

    return (
        <ModalContent
            toggleModal={toggleModal}
            isOpen={isOpen}
            {...props}
            data-testid={props.testid || 'Modal-testid'}
        />
    );
};

Modal.defaultProps = { widthUnit: 'px', heightUnit: 'px' };

export default Wrapper(Modal);
