export type TyreLogDetailByVehicleResponse = {
    externalSystemMddBesTyreLogDetail: {
        vehicleId: number;
        position: number;
        besSeverityRankTotal: number;
        lastCreatedAt: string;
        lastJudgementDate: string;
        severityFor: number;
        severityMeasure: number;
        severityMeasurePast1Month: number | null;
        severityMeasurePast1Week: number | null;
    }[];
};
export type ExternalSystemMddBesTyreLogModel = {
    id: number;
    vehicleId: number;
    tyreId: number;
    position: number;
    tyreSerialNumber: string;
    besSeverityRankTotal: number;
    besSeverityRank3DAvg: number;
    payload: number;
    ipHotDailyAvg: number;
    chamberTempDailyAvg: number;
    chamberTempTotalHistory: number;
    ipColdDailyAvg: number;
    createdAt: Date;
    updatedAt: Date;
    timeKey: number;
    judgementDate: Date;
    //processDate: Date;
};

export type ExternalSystemMddBesTyreLogExtended = ExternalSystemMddBesTyreLogModel & {
    createdAtConverted: string;
};

export type ExternalSystemMddBesTyreLogRecords = {
    externalSystemMddBesTyreLog: ExternalSystemMddBesTyreLogModel[];
};

export type ExternalSystemMddBesOverviewResponse = {
    externalSystemMddBesTyreLogOverview: ExternalSystemMddBesOverview;
};

export type ExternalSystemMddBesOverview = {
    rank: RankAndCount[];
};

export type RankAndCount = {
    level: number;
    count: number;
};

export enum ExternalSystemMddBesTyreLogQueryKeys {
    getTyreLogDetailByVehicle = 'externalSystemMddBesTyreLog-getTyreLogDetailByVehicle',
    getTyreLogOverview = 'externalSystemMddBesTyreLog-getTyreLogOverview'
}
