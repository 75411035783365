import React from 'react';
import { WarnNotificationsContent } from './WarnNotifications.view';
import { WarnNotificationsProps } from './WarnNotifications.type';
import { Wrapper } from 'helpers/wrapper';
import { useQuery } from '@tanstack/react-query';
import CustomerApi from 'api/Customer';
import { CustomerByIdResponse } from 'models/Customer.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { CustomerQueryKeys } from 'models/Customer.type';

const customerApi = new CustomerApi();

const WarnNotifications: React.FC<WarnNotificationsProps> = (props): JSX.Element => {
    const [isTerminated, setIsTerminated] = React.useState(false);

    useQuery(
        [CustomerQueryKeys.getById, props.customerId],
        () => {
            return customerApi.getById({ id: props.customerId, apiProject: undefined });
        },

        {
            refetchInterval: false,
            staleTime: cacheTimeToMilliseconds(30, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(30, 'minutes'),
            onSuccess: (result: CustomerByIdResponse) => {
                setIsTerminated(result?.customer?.disabledOrActiveTerminatedAt ?? false);
            }
        }
    );

    return <>{isTerminated && <WarnNotificationsContent data-testid={'WarnNotifications-testid'} />}</>;
};

export default Wrapper(WarnNotifications);
