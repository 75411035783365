import React from 'react';
import { ShiftTimeNameContent } from './ShiftTimeName.view';
import { ShiftTimeNameProps } from './ShiftTimeName.type';
import { Wrapper } from 'helpers/wrapper';

const ShiftTimeName: React.FC<ShiftTimeNameProps> = (props): JSX.Element => {
    return <ShiftTimeNameContent data-testid='ShiftTimeName-testid' />;
};

export default Wrapper(ShiftTimeName);
