import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { useQuery } from '@tanstack/react-query';
import { UserNotificationProps } from './UserNotification.type';
import { UserInfo } from 'states/global/User';
import { Warning } from 'components/Popup/Popup';
import { useTranslation } from 'react-i18next';
import NotificationEventApi from 'api/NotificationEvent';
import { ModelGetTpmsAlerts } from 'models/TpmsAlerts.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { getImgSource } from 'helpers/image';
import { NotificationEventQueryKeys } from 'models/NotificationEvent.type';
import { Wrapper } from 'helpers/wrapper';

const SEGS_TO_MS = 1000;
const NotificationEvent = new NotificationEventApi();

const UserNotification: React.FC<UserNotificationProps> = (): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();
    const { fromTimezoneToUTC } = useConverter();
    const notificationIntervalRef: { current: NodeJS.Timeout | null } = useRef(null);
    const notificationLevel1IntervalRef: { current: NodeJS.Timeout | null } = useRef(null);
    const notificationLevel2IntervalRef: { current: NodeJS.Timeout | null } = useRef(null);
    const notificationLevel3IntervalRef: { current: NodeJS.Timeout | null } = useRef(null);

    const { data } = useQuery<unknown, Error, ModelGetTpmsAlerts>(
        [NotificationEventQueryKeys.getNotification, ''],
        () =>
            NotificationEvent.getNotification({
                solved: false,
                measuredFrom: fromTimezoneToUTC(DateTime.local().minus({ hours: 1 }).valueOf()),
                page: 1,
                limit: 200
            }),
        {
            refetchInterval: 50000,
            staleTime: 0,
            cacheTime: 0
        }
    );

    const showNotification = (
        title: string,
        options: Object,
        notificationRef: { current: NodeJS.Timeout | null },
        timer: number,
        level?: number
    ) => {
        if (!('Notification' in window)) {
            Warning({ text: translate('p.alerts_message_incompatibility') });
        } else if (Notification.permission === 'granted') {
            let notification = setInterval(() => {
                if (data?.items && data?.items.length > 0) {
                    if (!level) {
                        new Notification(title, options);
                    } else if (level == 1) {
                        if (data?.items && data?.items.find((element) => element.notification.level == 1) != undefined)
                            new Notification(title, options);
                    } else if (level == 2) {
                        if (data?.items && data?.items.find((element) => element.notification.level == 2) != undefined)
                            new Notification(title, options);
                    } else if (level == 3) {
                        if (data?.items && data?.items.find((element) => element.notification.level == 3) != undefined)
                            new Notification(title, options);
                    }
                }
            }, timer);
            notificationRef.current = notification;
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then(function (permission) {
                if (permission === 'granted') {
                    let notification = setInterval(() => {
                        if (data?.items && data?.items.length > 0) {
                            if (!level) {
                                new Notification(title, options);
                            } else if (level == 1) {
                                if (
                                    data?.items &&
                                    data?.items.find((element) => element.notification.level == 1) != undefined
                                )
                                    new Notification(title, options);
                            } else if (level == 2) {
                                if (
                                    data?.items &&
                                    data?.items.find((element) => element.notification.level == 2) != undefined
                                )
                                    new Notification(title, options);
                            } else if (level == 3) {
                                if (
                                    data?.items &&
                                    data?.items.find((element) => element.notification.level == 3) != undefined
                                )
                                    new Notification(title, options);
                            }
                        }
                    }, timer);
                    notificationRef.current = notification;
                }
            });
        }
    };

    useEffect(() => {
        if (userInfo.user?.userSetting.alertReminderLevel1 === 0) {
            clearInterval(notificationLevel1IntervalRef.current as NodeJS.Timeout);
            notificationLevel1IntervalRef.current = null;
        }

        if (userInfo.user?.userSetting.alertReminderLevel2 === 0) {
            clearInterval(notificationLevel2IntervalRef.current as NodeJS.Timeout);
            notificationLevel2IntervalRef.current = null;
        }

        if (userInfo.user?.userSetting.alertReminderLevel3 === 0) {
            clearInterval(notificationLevel3IntervalRef.current as NodeJS.Timeout);
            notificationLevel3IntervalRef.current = null;
        }

        if (!userInfo.user?.userSetting.desktopNotification) {
            clearInterval(notificationIntervalRef.current as NodeJS.Timeout);
            clearInterval(notificationLevel1IntervalRef.current as NodeJS.Timeout);
            clearInterval(notificationLevel2IntervalRef.current as NodeJS.Timeout);
            clearInterval(notificationLevel3IntervalRef.current as NodeJS.Timeout);
        } else {
            if (userInfo.user?.userSetting.alertReminderLevel1 && userInfo.user?.userSetting.alertReminderLevel1 > 0) {
                showNotification(
                    translate('t.unresolved_alert_reminder_level1'),
                    { body: translate('p.alerts_message_notification'), icon: getImgSource('logo') },
                    notificationLevel1IntervalRef,
                    userInfo.user?.userSetting.alertReminderLevel1 * SEGS_TO_MS,
                    1
                );
            }

            if (userInfo.user?.userSetting.alertReminderLevel2 && userInfo.user?.userSetting.alertReminderLevel2 > 0) {
                showNotification(
                    translate('t.unresolved_alert_reminder_level2'),
                    { body: translate('p.alerts_message_notification'), icon: getImgSource('logo') },
                    notificationLevel2IntervalRef,
                    userInfo.user?.userSetting.alertReminderLevel2 * SEGS_TO_MS,
                    2
                );
            }

            if (userInfo.user?.userSetting.alertReminderLevel3 && userInfo.user?.userSetting.alertReminderLevel3 > 0) {
                showNotification(
                    translate('t.unresolved_alert_reminder_level3'),
                    { body: translate('p.alerts_message_notification'), icon: getImgSource('logo') },
                    notificationLevel3IntervalRef,
                    userInfo.user?.userSetting.alertReminderLevel3 * SEGS_TO_MS,
                    3
                );
            }
        }

        return () => {
            clearInterval(notificationIntervalRef.current as NodeJS.Timeout);
            clearInterval(notificationLevel1IntervalRef.current as NodeJS.Timeout);
            clearInterval(notificationLevel2IntervalRef.current as NodeJS.Timeout);
            clearInterval(notificationLevel3IntervalRef.current as NodeJS.Timeout);

            notificationIntervalRef.current = null;
            notificationLevel1IntervalRef.current = null;
            notificationLevel2IntervalRef.current = null;
            notificationLevel3IntervalRef.current = null;
        };
    }, [userInfo.user?.userSetting, data]);

    return <></>;
};

export default Wrapper(UserNotification);
