import React, { useCallback, useEffect } from 'react';
import { RankApplyButtonContent } from './RankApplyButton.view';
import { DatePickerAction, RankDateTimePicker, SeverityWheels } from 'pages/SeverityRank/atom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { RankApplyButtonProps } from './RankApplyButton.type';
import { SelectedVehicle } from 'states/global/Statistics';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import WheelApi from 'api/Wheel';
import { TopBarRankDateTimePicker } from '../RankDateRangePickers/RankDateRangePickers.atom';
import { UnconfirmedVehicleAtom } from '../RankVehicleDropdown/RankVehicleDropdown.atom';
import { cacheTimeToMilliseconds, getCache, isCacheAvailable } from 'helpers/cache';
import { TyreDetailResponse, WheelQueryKeys } from 'models/Wheel.type';
import { Wrapper } from 'helpers/wrapper';

const Wheel = new WheelApi();

const RankApplyButton: React.FC<RankApplyButtonProps> = (): JSX.Element => {
    const setSeverityWheels = useSetRecoilState(SeverityWheels);
    const dateRange = useRecoilValue(TopBarRankDateTimePicker);
    const setDateTimePicker = useSetRecoilState(RankDateTimePicker);
    const queryCache = useQueryClient().getQueryCache();

    const [selectedVehicle, setSelectedVehicle] = useRecoilState(SelectedVehicle);
    const unconfirmedSelectedVehicle = useRecoilValue(UnconfirmedVehicleAtom);

    const { isLoading, isFetching, refetch } = useQuery(
        [WheelQueryKeys.getTyresDetail, selectedVehicle.id],
        () => Wheel.getTyresDetail(selectedVehicle.id),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(15, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(15, 'minutes'),
            onSuccess: (data) => {
                const sortedData = [...data].sort(
                    (wheel1, wheel2) => wheel1.wheel.customPosition - wheel2.wheel.customPosition
                );
                setSeverityWheels({ isLoading: false, wheels: sortedData });
            },
            enabled: false
        }
    );

    const isButtonDisabled = useCallback((): boolean => {
        return (
            !selectedVehicle.id ||
            isLoading ||
            isFetching ||
            (dateRange.isValidRange !== undefined && !dateRange.isValidRange)
        );
    }, [selectedVehicle, isLoading, isFetching, dateRange]);

    const onApplyAction = () => {
        selectedVehicle.id === unconfirmedSelectedVehicle.id && refetch();
        setSeverityWheels({ isLoading: true, wheels: [] });
        setSelectedVehicle({ ...unconfirmedSelectedVehicle, icon: '' });
        setDateTimePicker({
            original: {
                dateFrom: dateRange.dateFrom,
                dateTo: dateRange.dateTo
            },
            current: {
                dateFrom: dateRange.dateFrom,
                dateTo: dateRange.dateTo
            },
            action: DatePickerAction.APPLY
        });
    };

    useEffect(() => {
        if (!isCacheAvailable([WheelQueryKeys.getTyresDetail, selectedVehicle.id], queryCache)) {
            refetch();
        } else if (getCache([WheelQueryKeys.getTyresDetail, selectedVehicle.id], queryCache)[0]?.state?.data) {
            const sortedData = [
                ...(getCache([WheelQueryKeys.getTyresDetail, selectedVehicle.id], queryCache)[0]?.state
                    ?.data as TyreDetailResponse)
            ].sort((wheel1, wheel2) => wheel1.wheel.customPosition - wheel2.wheel.customPosition);
            setSeverityWheels({ isLoading: false, wheels: sortedData });
        }

        selectedVehicle.id && refetch();
    }, [selectedVehicle.id]);

    return <RankApplyButtonContent isButtonDisabled={isButtonDisabled} onApplyAction={onApplyAction} />;
};

export default Wrapper(RankApplyButton);
