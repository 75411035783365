import { HeaderGroup } from 'react-table';
import { PassProps } from '../UiTable/UiTable.type';
import { Column } from 'react-table';

export enum FilterType {
    DATETIME = 'DATETIME',
    SIGNED_DATETIME = 'SIGNED_DATETIME',
    SIGNED_VALUE = 'SIGNED_VALUE',
    VERSION_VALUE = 'VERSION_VALUE',
    NEGATE_VALUE = 'NEGATE_VALUE',
    DROPDOWN = 'DROPDOWN'
}

type Accessor = {
    accessor: string;
};

type genericFilterApply = {
    onFilterApply: {
        lowerThan?: Accessor;
        higherThan?: Accessor;
        exact?: Accessor;
        fullText?: Accessor;
        negation?: Accessor;
    };
};

export type FilterSignedDateTime = {
    type: FilterType.SIGNED_DATETIME;
} & genericFilterApply;

export type FilterSignedValue = {
    type: FilterType.SIGNED_VALUE;
} & genericFilterApply;

export type FilterVersionValue = {
    type: FilterType.VERSION_VALUE;
} & genericFilterApply;

export type FilterNegateCustomer = {
    type: FilterType.NEGATE_VALUE;
} & genericFilterApply;

export type FilterDropdown = {
    type: FilterType.DROPDOWN;
    options: { label: string; value: number | string | undefined }[];
} & genericFilterApply;

export type FilterOptionProps =
    | FilterSignedDateTime
    | FilterVersionValue
    | FilterSignedValue
    | FilterNegateCustomer
    | FilterDropdown;

export type FilterOption = (Column & {
    filterProps?: FilterOptionProps;
})[];

export type HeaderGroupFilterOption = HeaderGroup<object> &
    FilterOption[0] & {
        canSort?;
        isSorted?;
        isSortedDesc?;
        getSortByToggleProps?;
        getResizerProps?;
        disableFilters?;
        canResize?;
        isResizing?;
    };

export type UiTableHeaderProps = {
    headerGroups: HeaderGroup<object>[];
} & Pick<PassProps, 'useColumnFiltering' | 'useColumnAction' | 'queryKey' | 'useColumnCheckbox'>;
