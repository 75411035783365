import React, { useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { CustomerTableContainer, SettingsContent } from './CustomerTable.style';
import { CustomerTableViewProps } from './CustomerTable.type';
import { useTranslation } from 'react-i18next';
import AddGlobalAdmin from '../AddGlobalAdmin';
import UiWidget from '../../Ui/Components/UiWidget/UiWidget';
import { applyStyleByMode } from 'helpers';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';

export const CustomerTableContent: React.FC<CustomerTableViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const refEditCustomer = useRef<HTMLButtonElement>(null);
    const ThemeMode = useRecoilValue(Theme);
    return (
        <CustomerTableContainer data-testid='CustomerTableContent'>
            <UiWidget
                title={translate('t.customer')}
                applyPadding={false}
                avatar={
                    <UiIcon
                        data-testid='CustomerTableAvatar'
                        icon={faUser}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                loading={props.isLoading}
            >
                {props.customerData?.id && (
                    <SettingsContent>
                        <AddGlobalAdmin
                            fullEdit={true}
                            customerValues={{
                                id: props.customerData?.id || 0,
                                name: props.customerData?.name || '',
                                registeredAt: props.customerData?.registeredAt || '',
                                dbAppRegion: props.customerData?.dbAppRegion || '',
                                repAppRegion: props.customerData?.repAppRegion || '',
                                hostname: props.customerData?.hostname || '',
                                enabled: props.customerData?.enabled || false,
                                timezone: props.customerData?.timezone,
                                externalTemperatureEnabled:
                                    (props.customerData?.externalTemperatureEnabled as boolean) || false,
                                activeTerminatedAt: props.customerData?.activeTerminatedAt || null,
                                autoGenerateCmd7: props.customerData?.autoGenerateCmd7 || false,
                                autoGenerateApn: props.customerData?.autoGenerateApn || false,
                                hubsMaintenanceConnection: props.customerData?.hubsMaintenanceConnection || null,
                                internalTestSite: props.customerData?.internalTestSite || false,
                                deviceSerialConnectionTypeId:
                                    props.customerData?.deviceSerialConnectionType?.id || null,
                                timezoneId: props.customerData?.timezone?.id || null,
                                jiraOrganisationId: props.customerData?.jiraOrganisationId || null,
                                itrackStockPrimaryDeliveryLocationId:
                                    props.customerData?.itrackStockPrimaryDeliveryLocationId || null,
                                hasExternalSystem: props.customerData.hasExternalSystem || []
                            }}
                            refEditCustomer={refEditCustomer}
                            editCustomerMutate={props.editCustomerMutate}
                        />
                    </SettingsContent>
                )}
            </UiWidget>
        </CustomerTableContainer>
    );
};
